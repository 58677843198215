import { Component, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { RechargeRequestService } from 'src/app/_services/Rechargues/rechargue-request.service';
import { NgForm } from '@angular/forms';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';
import Swal from 'sweetalert2';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { Router } from '@angular/router';

type PaginationType = 'envio' | 'empaque' | 'servicio' | 'recarga';
@Component({
  selector: 'app-licensees-dashboard',
  templateUrl: './licensees-dashboard.component.html',
  styleUrls: ['./licensees-dashboard.component.scss'],
})
export class LicenseesDashboardComponent implements OnInit {
  public chart: Chart | undefined;
  userProfit: number = 0;
  ProfitPacking: number = 0;
  isShipmentsOpen = true;
  isPackingOpen = false;
  isServicesOpen = false;
  isRechargesOpen = false;
  userid: any;
  isAddFundsModalOpen = false;
  user: any;
  userSelected: any;
  data: any = {
    amount: null,
    referenceNumber: '',
    paymentMethod: 'transferencia',
    proofImage: null,
    rechargeType: '',
  };

  showToast = false;
  toastMessage = '';
  toastType: 'success' | 'error' = 'success';

  isAdmin: boolean = false;
  users: any[] = [];
  selectedUserId: any;

  transactionHistory: any[] = [];
  showTransactionHistoryModal: boolean = false;
  currentPageTransaction: number = 1;
  currentPage: number = 1;
  totalPagesTransaction: number = 0;
  pageSize: number = 10;
  totalItemsTransaction: number = 0;
  hasNextPage: boolean = false;
  hasPreviousPage: boolean = false;

  //meses 
  quincenaLeyenda: string = '';
  months = [
    { value: 1, name: 'Enero' },
    { value: 2, name: 'Febrero' },
    { value: 3, name: 'Marzo' },
    { value: 4, name: 'Abril' },
    { value: 5, name: 'Mayo' },
    { value: 6, name: 'Junio' },
    { value: 7, name: 'Julio' },
    { value: 8, name: 'Agosto' },
    { value: 9, name: 'Septiembre' },
    { value: 10, name: 'Octubre' },
    { value: 11, name: 'Noviembre' },
    { value: 12, name: 'Diciembre' }
  ];



  // Nuevas propiedades para la utilidad quincenal
  selectedYear: number = new Date().getFullYear();
  selectedMonth: number = new Date().getMonth() + 1;
  selectedQuincena: 1 | 2 = 1;
  quincenalProfit: any = null;
  loadingProfit: boolean = false;
  errorLoadingProfit: string | null = null;
  enteredPin: string = '';
  storedPin: string = '';
  showPinModal: boolean = false;
  pendingAction: Function | null = null;
  profitData: any;

  //envios
  isModalOpenEnvios = false;
  shipments: any[] = [];
  filteredPackages: any[] = [];
  startDate: string = '';
  endDate: string = '';
  statusFilter: string = '';
  packages: any[] = [];

  //empaques
  isModalOpenEmpaques = false;
  empaques: any[] = [];

  //recargas
  isModalOpenRecargas = false;
  recargas: any[] = [];

  //servicios
  isModalOpenServicios = false;
  servicios: any[] = [];


  pagination: Record<PaginationType, { currentPage: number; totalPages: number; pageSize: number; hasNextPage: boolean; hasPreviousPage: boolean }> = {
    envio: { currentPage: 1, totalPages: 0, pageSize: 10, hasNextPage: false, hasPreviousPage: false },
    empaque: { currentPage: 1, totalPages: 0, pageSize: 10, hasNextPage: false, hasPreviousPage: false },
    servicio: { currentPage: 1, totalPages: 0, pageSize: 10, hasNextPage: false, hasPreviousPage: false },
    recarga: { currentPage: 1, totalPages: 0, pageSize: 10, hasNextPage: false, hasPreviousPage: false }
  };
  ultimoFiltro: string = ''; // Para controlar cambios en el filtro


  //filtro 
  filtroEmpaque: string = ''; // Valor por defecto

  

  constructor(
    private userService: UserServiceService,
    private authService: AuthLoginService,
    private rechargeRequestService: RechargeRequestService,
    private profitService: ShipmentsService, 
    private transactionService: TransactionService, 
    private router: Router,

  ) {
    this.userid = this.authService.getId();
  }

  ngOnInit(): void {
    Chart.register(...registerables);
    this.loadUserProfile();
    this.loadQuincenalProfit(); 
    this.getUserPin();
    this.fetchQuincenalProfit();
    this.fetchTransactionsByTypeAndUser();
    this.hasNextPage = this.currentPageTransaction < this.totalPagesTransaction;
    this.hasPreviousPage = this.currentPageTransaction > 1;
    this.actualizarLeyendaQuincena(); 
    this.getPackingShipments();

    this.userService.userlistLicencees(1, 10).subscribe({
      next: (response) => {
          console.log('Datos de licenciatarios:', response);
      },
      error: (error) => {
          console.error('Error al obtener los datos:', error);
      }
    } )
  }

  // Obtén el NIP del usuario
  getUserPin(): void {
    this.authService.userProfile().subscribe(
      (res) => {
        this.storedPin = res.data.pin;
      },
      (error) => {
        console.log('Error al obtener el perfil del usuario', error);
      }
    );
  }

  // Abre el modal de NIP y establece la acción pendiente
  openPinModal(action: Function): void {
    this.enteredPin = '';
    this.pendingAction = action;
    this.showPinModal = true;
  }

  // Cierra el modal de NIP
  closePinModal(): void {
    this.showPinModal = false;
    this.enteredPin = '';
    this.pendingAction = null;
  }

  // Valida el NIP y ejecuta la acción pendiente si es correcto
  validateNip(): void {
    console.log('NIP ingresado:', this.enteredPin);
    console.log('NIP almacenado:', this.storedPin);

    if (this.enteredPin === this.storedPin) {
      this.showPinModal = false;
      if (this.pendingAction) this.pendingAction(); // Ejecuta la acción pendiente
    } else {
      Swal.fire({
        icon: 'error',
        title: 'NIP incorrecto',
        text: 'El NIP ingresado no es correcto.',
      });
    }
  }

  openPinModalForAddFunds(): void {
    this.openPinModal(() => this.openAddFundsModal());
  }

  private loadUserProfile(): void {
    this.authService.userProfile().subscribe(
      (response) => {
        this.user = response.data;
        if (!this.user.wallet) {
          this.user.wallet = {
            sendBalance: { $numberDecimal: '0' },
            servicesBalance: { $numberDecimal: '0' },
            rechargeBalance: { $numberDecimal: '0' },
          };
        }
        this.isAdmin = response.data.role === 'ADMIN';
        this.userid = this.isAdmin
          ? this.selectedUserId || this.userid
          : this.userid;
        this.loadUsers();
        this.loadStatistics();
      },
      (error) => {
        console.error('Error loading user profile:', error);
      }
    );
  }

  private loadUsers(): void {
    if (this.isAdmin) {
        this.userService.userlistLicencees(1, 100).subscribe(
            (response) => {
                if (response.success) {
                    this.users = response.data.users; 

                    if (this.selectedUserId) {
                        this.userid = this.selectedUserId;
                    } else {
                        this.selectedUserId = this.userid;
                    }

                    this.loadStatistics();
                } else {
                    console.error('Error: ', response.message);
                }
            },
            (error) => {
                console.error('Error loading users:', error);
            }
        );
    }
  }


  onUserChange(): void {
    this.userid = this.selectedUserId;
    this.userSelected = this.users.find((user) => user._id === this.userid);
    console.log('User selected:', this.userSelected);
    console.log('Selected user:', this.userid);
    this.fetchTransactionsByTypeAndUser();
    this.loadStatistics();
    this.loadQuincenalProfit();
  }

  private loadStatistics(): void {
    this.userService.shipmentProfit(this.userid).subscribe(
      (res) => {
        this.userProfit = Number(res.data.currentMonthProfit.$numberDecimal);
        console.log('Profit:', this.userProfit);
      },
      (error) => {
        console.error('Error loading shipment profit:', error);
      }
    );



    this.userService.getProfitPacking(this.userid).subscribe(
      (res) => {
        this.ProfitPacking = Number(
          res.message.totalPackingCost.$numberDecimal
        );
        console.log('Profit Packing:', this.ProfitPacking);
      },
      (error) => {
        console.error('Error loading packing profit:', error);
      }
    );
  }

  loadQuincenalProfit(): void {
    this.loadingProfit = true;
    this.errorLoadingProfit = null;

    // Obtener envío y empaque
    this.profitService.getQuincenalProfit(this.userid, this.selectedYear, this.selectedMonth, this.selectedQuincena)
    .subscribe(
        (response) => {
            if (response?.success && response.data) { // 🔹 Se validó `response.data`
                this.quincenalProfit = {
                    ...this.quincenalProfit,
                    shipmentProfit: response.data.shipmentProfit || 0,
                    packingProfit: response.data.packingProfit || 0
                };
                this.updateProfitChart();
            }
        },
        (error) => {
            console.error('Error obteniendo profit de envíos y empaques:', error);
        }
    );

    // Obtener servicios (Cambio de `getQuincenalProfit()` a `getQuincenalProfitServices()`)
    this.transactionService.getQuincenalProfitServices(this.userid, this.selectedYear, this.selectedMonth, this.selectedQuincena)
    .subscribe(
        (response) => {
            if (response?.success && response.data) { // 🔹 Se validó `response.data`
                this.quincenalProfit = {
                    ...this.quincenalProfit,
                    servicesProfit: response.data.servicesProfit || 0
                };
                this.updateProfitChart();
            }
        },
        (error) => {
            console.error('Error obteniendo profit de servicios:', error);
        }
    );
}



  extractProfitValue(profit: any): number {
    if (typeof profit === 'number') {
      return profit;
    } else if (profit && profit.$numberDecimal) {
      return parseFloat(profit.$numberDecimal);
    }
    return 0;
  }

  getProfitValue(profit: any): number {
    if (typeof profit === 'number') {
      return profit;
    } else if (profit && profit.$numberDecimal) {
      return parseFloat(profit.$numberDecimal);
    }
    return 0; // Valor por defecto si no hay datos
  }

  updateProfitChart(): void {
    if (this.chart) {
      this.chart.destroy();
    }

    if (!this.quincenalProfit) {
      console.log('No hay datos de utilidad para mostrar en el gráfico');
      return;
    }

    const ctx = document.getElementById('profitChart') as HTMLCanvasElement;
    const dataValues = [
      this.getProfitValue(this.quincenalProfit.shipmentProfit),
      this.getProfitValue(this.quincenalProfit.packingProfit),
      this.getProfitValue(this.quincenalProfit.servicesProfit),
      this.getProfitValue(this.quincenalProfit.profitData),
    ];

    console.log('Datos para el gráfico:', dataValues);

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Envíos', 'Empaques', 'Servicios', 'Recargas'],
        datasets: [
          {
            label: 'Utilidad Quincenal',
            data: dataValues,
            backgroundColor: (context: any) => {
              const value = context.raw;
              return value >= 0
                ? 'rgba(75, 192, 192, 0.2)'
                : 'rgba(255, 99, 132, 0.2)';
            },
            borderColor: (context: any) => {
              const value = context.raw;
              return value >= 0
                ? 'rgba(75, 192, 192, 1)'
                : 'rgba(255, 99, 132, 1)';
            },
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value}`, // Formatea los valores en el eje Y
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                const value = context.raw;
                return `Utilidad: ${value >= 0 ? '+' : ''}${value}`;
              },
            },
          },
        },
      },
    });
  }

  onQuincenaChange(): void {
    this.loadQuincenalProfit();
    this.actualizarLeyendaQuincena();
  }

  showShipments(): void {
    this.setActiveTab('shipments');
  }
  showPacking(): void {
    this.setActiveTab('packing');
  }
  showServices(): void {
    this.setActiveTab('services');
  }
  showRecharges(): void {
    this.setActiveTab('recharges');
  }

  private setActiveTab(tab: string): void {
    this.isShipmentsOpen = tab === 'shipments';
    this.isPackingOpen = tab === 'packing';
    this.isServicesOpen = tab === 'services';
    this.isRechargesOpen = tab === 'recharges';
  }

  formatProfit(profit: any): string {
    if (typeof profit === 'number') {
      return `$${profit.toFixed(2)}`;
    } else if (profit && profit.$numberDecimal) {
      return `$${parseFloat(profit.$numberDecimal).toFixed(2)}`;
    }
    return '$0.00';
  }

  openAddFundsModal(): void {
    this.isAddFundsModalOpen = true;
  }

  validateAmount(): void {
    if (this.data.amount !== null) {
      this.data.amount = Math.max(0, this.data.amount);
    }
  }

  onFileSelected(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.data.proofImage = file;
    }
  }

  closeAddFundsModal(): void {
    this.isAddFundsModalOpen = false;
    this.resetForm();
  }

  submitAddFunds(form: NgForm): void {
    if (form.valid && this.data.rechargeType) {
      this.rechargeRequestService.createRechargeRequest(this.data).subscribe(
        (res) => {
          if (res.success === true) {
            this.showToastMessage('Recarga solicitada con éxito', 'success');
            this.closeAddFundsModal();
          } else {
            this.showToastMessage(
              'Error al solicitar la recarga: ' + res.message,
              'error'
            );
          }
        },
        (error) => {
          this.showToastMessage(
            'Error en la solicitud de recarga: ' + error.message,
            'error'
          );
        }
      );
    } else {
      this.showToastMessage(
        'El formulario no es válido. Por favor, revisa los campos y asegúrate de seleccionar un tipo de recarga.',
        'error'
      );
    }
  }

  private resetForm(): void {
    this.data = {
      amount: null,
      referenceNumber: '',
      paymentMethod: 'transferencia',
      proofImage: null,
      rechargeType: '',
    };
  }

  private showToastMessage(message: string, type: 'success' | 'error'): void {
    this.toastMessage = message;
    this.toastType = type;
    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 3000);
  }

  getPageNumbers(): number[] {
    return Array.from({ length: this.totalPagesTransaction }, (_, i) => i + 1);
  }

  showTransactionHistory() {
    this.isRechargesOpen = true;
    this.isShipmentsOpen = false;
    this.isPackingOpen = false;
    this.isServicesOpen = false;
    this.transactionService
      .getTransaccionByUser(
        this.userid,
        this.currentPageTransaction,
        this.pageSize
      )
      .subscribe(
        (response) => {
          console.log(
            'Historial de transacciones:',
            response.message.transactions
          );
          this.transactionHistory = response.message.transactions; // Datos específicos de recargas
          this.hasNextPage = response.message.hasNextPage;
          this.hasPreviousPage = response.message.hasPreviousPage;
          this.totalItemsTransaction = response.message.total;
          this.totalPagesTransaction = response.message.totalPages;
        },
        (error) => {
          console.error(
            'Error al cargar el historial de transacciones:',
            error
          );
        }
      );
  }

  onPageSizeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.pageSize = +selectElement.value;
    this.currentPageTransaction = 1; // Resetear a la primera página con el nuevo tamaño
    this.showTransactionHistory();
  }

  cerrarModalTransactionHistory() {
    this.showTransactionHistoryModal = false;
  }
  
  fetchQuincenalProfit(): void {
    console.log('Ejecutando fetchQuincenalProfit()'); // 🔹 Verifica si la función se ejecuta

    const userId = this.authService.getId();
    if (!userId) {
        console.error('No se encontró el ID del usuario.');
        return;
    }

    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const quincena = 1;

    this.transactionService
        .getQuincenalProfitServices(userId, year, month, quincena)
        .subscribe({
            next: (data: any) => {
                console.log('Respuesta completa de utilidad quincenal:', data);

                if (data?.data) { 
                    const profitValue = parseFloat(data.data.servicesProfit || '0'); 
                    console.log('Valor de profitData:', profitValue);

                    this.quincenalProfit = {
                        ...this.quincenalProfit,
                        profitData: profitValue, 
                    };
                } else {
                    console.warn('No se encontró información de utilidad quincenal.');
                    this.quincenalProfit = {
                        ...this.quincenalProfit,
                        profitData: 0,
                    };
                }
                this.updateProfitChart();
            },
            error: (error: any) => {
                console.error('Error al obtener la utilidad quincenal:', error);
                this.quincenalProfit = {
                    ...this.quincenalProfit,
                    profitData: 0,
                };
            },
        });
}




  //funcion que hace todo alv
  fetchTransactionsByTypeAndUser(): void {
    const userId = this.userid;
    if (!userId) {
        console.error('No se encontró el ID del usuario.');
        return;
    }

    const types: PaginationType[] = ['envio', 'empaque', 'recarga', 'servicio'];

    types.forEach((type) => {
        console.log(`Cargando datos para el tipo "${type}" y usuario "${userId}" en página ${this.currentPageTransaction}`);

        // Si el usuario ha seleccionado un filtro de empaque, hacer una llamada diferente
        if (type === 'envio' && this.filtroEmpaque) {
            console.log(`Aplicando filtro de empaque: ${this.filtroEmpaque}`);

            // Reiniciar la paginación
            if (this.filtroEmpaque !== this.ultimoFiltro) {
              this.pagination.envio.currentPage = 1;
              this.ultimoFiltro = this.filtroEmpaque; // Guarda el último filtro aplicado
            }
          

            this.profitService.getPackingShipments(
                this.pagination.envio.currentPage, 
                this.pagination.envio.pageSize, 
                'createdAt', 'user_id', 
                this.filtroEmpaque, 
                userId
            ).subscribe({
                next: (response) => {
                    if (response.data && response.data.shipments) {
                        this.filteredPackages = response.data.shipments.map((shipment: any) => ({
                            provider: shipment.provider || 'Sin proveedor',
                            licensee: shipment.user_id?.name || 'Sin licencia',
                            user: shipment.sub_user_id?.name || 'Sin usuario',
                            distribution_at: shipment.distribution_at || null,
                            price: shipment.price?.$numberDecimal || '0.00',
                            cost: shipment.cost?.$numberDecimal || '0.00',
                            globalProfit: shipment.dagpacket_profit?.$numberDecimal || '0.00',
                            userProfit: shipment.utilitie_lic?.$numberDecimal || '0.00',
                            dagpacketProfit: shipment.utilitie_dag?.$numberDecimal || '0.00',
                            origin: shipment.from?.city || 'Sin origen',
                            destination: shipment.to?.city || 'Sin destino',
                            paymentStatus: shipment.payment?.status || 'Estado de pago desconocido',
                            shipmentStatus: shipment.status || 'Estado no especificado',
                            id: shipment._id || 'Sin ID',
                        }));
                      this.pagination.envio.totalPages = response.data.totalPages || 1;
                      this.pagination.envio.currentPage = response.data.currentPage || 1; // Asegurar que se mantenga en la página correcta
                      this.pagination.envio.hasNextPage = response.data.hasNextPage || (this.pagination.envio.currentPage < this.pagination.envio.totalPages);
                      this.pagination.envio.hasPreviousPage = response.data.hasPreviousPage || (this.pagination.envio.currentPage > 1);
                    }
                },
                error: (error) => {
                    console.error('Error al obtener los envíos con empaque:', error);
                }
            });

        } else {
            // Si no hay filtro o el tipo no es 'envio', cargar los datos normales
            this.transactionService.getTransactionsByTypeAndUser(
                type, userId, this.pagination[type].currentPage, this.pagination[type].pageSize
            ).subscribe({
                next: (response) => {
                    if (response.success) {
                        const transactions = response.message.transactions || [];
                        if (type === 'envio') {
                            this.filteredPackages = transactions.flatMap(
                                (transaction: any) => (transaction.shipment_ids || []).map(
                                    (shipment: any) => ({
                                        provider: shipment.provider || 'Sin proveedor',
                                        licensee: transaction.user_id?.name || 'Sin licencia',
                                        user: shipment.sub_user_id?.name || transaction.user_id?.name || 'Sin usuario',
                                        distribution_at: shipment.distribution_at || null,
                                        price: shipment.price?.$numberDecimal || '0.00',
                                        cost: shipment.cost?.$numberDecimal || '0.00',
                                        globalProfit: shipment.dagpacket_profit?.$numberDecimal || '0.00',
                                        userProfit: shipment.utilitie_lic?.$numberDecimal || '0.00',
                                        dagpacketProfit: shipment.utilitie_dag?.$numberDecimal || '0.00',
                                        origin: shipment.from?.city || 'Sin origen',
                                        destination: shipment.to?.city || 'Sin destino',
                                        paymentStatus: shipment.payment?.status || 'Estado de pago desconocido',
                                        shipmentStatus: shipment.status || 'Estado no especificado',
                                        id: shipment._id || 'Sin ID',
                                    })
                                )
                            );
                            console.log('Todos los envíos:', this.filteredPackages);
                        }

                        // Procesar otros tipos de datos
                        switch (type) {
                            case 'empaque':
                                this.empaques = transactions;
                                console.log('Empaques asignados:', this.empaques);
                                break;
                            case 'recarga':
                                this.recargas = transactions;
                                console.log('Recargas asignadas:', this.recargas);
                                break;
                            case 'servicio':
                                this.servicios = transactions;
                                console.log('Servicios asignados:', this.servicios);
                                break;
                        }

                        // Actualiza la paginación específica de cada tabla
                        this.pagination[type].totalPages = response.message.totalPages || 0;
                        this.pagination[type].hasNextPage = this.pagination[type].currentPage < this.pagination[type].totalPages;
                        this.pagination[type].hasPreviousPage = this.pagination[type].currentPage > 1;
                    } else {
                        console.error(`Error al cargar datos para el tipo "${type}":`, response.message);
                    }
                },
                error: (error) => {
                    console.error(`Error al obtener datos para el tipo "${type}":`, error);
                }
            });
        }
    });
}


  // Función de filtrado
  applyFilters(): void {
    const start = this.startDate ? new Date(this.startDate) : null;
    const end = this.endDate ? new Date(this.endDate) : null;

    this.filteredPackages = this.packages.filter((envio) => {
      const distributionDate = new Date(envio.distribution_at);
      const isWithinDateRange =
        (!start || distributionDate >= start) &&
        (!end || distributionDate <= end);
      const matchesStatus =
        !this.statusFilter || envio.payment.status === this.statusFilter;

      return isWithinDateRange && matchesStatus;
    });

    // Verificar si `filteredPackages` tiene datos después del filtrado
    console.log('Paquetes filtrados:', this.filteredPackages);
  }

  
  previousPage(type: PaginationType): void {
    if (this.pagination[type].hasPreviousPage) {
        this.pagination[type].currentPage--;
        this.fetchTransactionsByTypeAndUser();
    }
  }

  nextPage(type: PaginationType): void {
    if (this.pagination[type].hasNextPage) {
        this.pagination[type].currentPage++;
        this.fetchTransactionsByTypeAndUser();
    }
  }

  goToPage(type: PaginationType, page: number): void {
    this.pagination[type].currentPage = page;
    this.fetchTransactionsByTypeAndUser();
  }


  //envios

  openModalEnvios(): void {
    this.isModalOpenEnvios = true;
  }

  closeModalEnvios(): void {
    this.isModalOpenEnvios = false;
  }

  viewDetails(shipmentId: string) {
    if (!shipmentId) {
      console.error('El ID del envío no es válido:', shipmentId);
      return;
    }
    console.log('Navigating to details for shipment:', shipmentId);
    this.router.navigateByUrl(`/shipment-details/${shipmentId}`);
  }

  onFilterChange(): void {
    this.pagination.envio.currentPage = 1; // Reiniciar paginación
    this.fetchTransactionsByTypeAndUser();
  }


  //empaques

  openModalEmpaques(): void {
    this.isModalOpenEmpaques = true;
  }

  closeModalEmpaques(): void {
    this.isModalOpenEmpaques = false;
  }

  //servicios

  openModalServicios(): void {
    this.isModalOpenServicios = true;
  }

  closeModalServicios(): void {
    this.isModalOpenServicios = false;
  }

  //recargas

  openModalRecargas(): void {
    this.isModalOpenRecargas = true;
  }

  closeModalRecarga(): void {
    this.isModalOpenRecargas = false;
  }
  actualizarLeyendaQuincena(): void {
    const mesSeleccionadoObj = this.months.find(m => m.value == this.selectedMonth);
    const mesSeleccionado = mesSeleccionadoObj ? mesSeleccionadoObj.name : 'Mes Desconocido';
    const quincenaTexto = this.selectedQuincena === 1 ? 'Primera Quincena' : 'Segunda Quincena';
    this.quincenaLeyenda = `${quincenaTexto} de ${mesSeleccionado}`;
  }

  getPackingShipments(): void {
    this.profitService.getPackingShipments(1, 10, 'createdAt', 'user_id', 'Si', this.userid)
      .subscribe({
        next: (response) => {
          console.log('Con paquete', response);
        },
        error: (error) => {
          console.error('Error al obtener los datos:', error);
        }
      });
  }

  Reporte(): void {
    console.log("el juego del amor")
  }
  
  
}

