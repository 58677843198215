import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GavetaService } from 'src/app/_services/gavetas/gavetas.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gabetas-details',
  templateUrl: './gabetas-details.component.html',
  styleUrls: ['./gabetas-details.component.scss']
})
export class GabetasDetailsComponent implements OnInit {
  gaveta: any; // Aquí se almacenarán los detalles de la gaveta
  id_gabeta: string | null = null;
  enteredPin: string = '';
  storedPin: string = '';
  showPinModal: boolean = false;
  pendingAction: Function | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private gavetaService: GavetaService,
    private authService: AuthLoginService
  ) {}

  ngOnInit(): void {
    this.id_gabeta = this.route.snapshot.paramMap.get('id_gabeta');
    
    if (this.id_gabeta) {
      this.gavetaService.getGavetasByLocker(this.id_gabeta).subscribe(
        (response) => {
          this.gaveta = response;
          console.log('Datos de la gaveta recibidos:', this.gaveta);
        },
        (error) => {
          console.error('Error al obtener los detalles de la gaveta:', error);
        }
      );
    }

    this.getUserPin(); 
  }

  // Obtén el NIP del usuario
  getUserPin(): void {
    this.authService.userProfile().subscribe(
      (res) => {
        this.storedPin = res.data.pin;
      },
      (error) => {
        console.log('Error al obtener el perfil del usuario', error);
      }
    );
  }

  // Abre el modal de NIP y establece la acción pendiente
  openPinModal(action: Function): void {
    this.enteredPin = '';
    this.pendingAction = action;
    this.showPinModal = true;
  }

  // Cierra el modal de NIP
  closePinModal(): void {
    this.showPinModal = false;
    this.enteredPin = '';
    this.pendingAction = null;
  }

  // Valida el NIP y ejecuta la acción pendiente si es correcto
  validateNip(): void {
    console.log("NIP ingresado:", this.enteredPin);
    console.log("NIP almacenado:", this.storedPin);

    if (this.enteredPin === this.storedPin) {
      this.showPinModal = false;
      if (this.pendingAction) this.pendingAction(); // Ejecuta la acción pendiente
    } else {
      Swal.fire({
        icon: 'error',
        title: 'NIP incorrecto',
        text: 'El NIP ingresado no es correcto.',
      });
    }
  }

  // Acciones con validación de NIP
  openGaveta(): void {
    this.openPinModal(() => {
      console.log('Gaveta abierta'); // Aquí puedes llamar a tu servicio para abrir la gaveta
    });
  }

  printGuide(): void {
    this.openPinModal(() => {
      console.log('Imprimir guía'); // Lógica para imprimir guía
    });
  }

  emptyGaveta(): void {
    this.openPinModal(() => {
      console.log('Gaveta vaciada'); // Lógica para vaciar la gaveta
    });
  }

  toggleGavetaStatus(): void {
    this.openPinModal(() => {
      console.log('Gaveta desactivada'); // Lógica para cambiar el estado de la gaveta
    });
  }
}
