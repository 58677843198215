<nav class="navbar fixed top-0 w-full">
  <div
    class="px-3 py-3 lg:px-5 lg:pl-3 flex justify-between items-center relative overflow-hidden"
  >
    <!-- Moto animada -->
    <div class="vehicle-container relative pointer-events-none">
      <img
        src="../../../assets/images/Moto_animacion.png"
        alt="Moto"
        class="vehicle-image moto"
      />
      <img
        src="../../../assets/images/Camion_animacion.png"
        alt="Camión"
        class="vehicle-image camion"
      />
      <img
        src="../../../assets/images/Avion_animacion.png"
        alt="Avión"
        class="vehicle-image avion"
      />
    </div>

    <!-- Botón de hamburguesa -->
    <button
      class="hamburger-button pointer-events-auto"
      (click)="toggleSidebar()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 5.25h16.5M3.75 12h16.5m-16.5 6.75h16.5"
        />
      </svg>
    </button>
  </div>
</nav>

<div
  *ngIf="isCartOpen"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
>
  <div
    class="bg-white rounded-xl shadow-2xl w-full max-w-lg mx-4 overflow-hidden"
  >
    <div
      class="bg-dagblue text-white py-4 px-6 flex justify-between items-center"
    >
      <h2 class="text-2xl font-bold">Carrito de Compras</h2>
      <button
        (click)="closeCart()"
        class="text-white hover:text-gray-300 transition duration-300"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
    </div>
    <div class="p-6">
      <div
        *ngIf="cartItems.length === 0"
        class="text-gray-600 text-center py-8"
      >
        <svg
          class="w-16 h-16 mx-auto mb-4 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          ></path>
        </svg>
        <p class="font-medium">No hay envíos pendientes en el carrito.</p>
      </div>
      <ul
        *ngIf="cartItems.length > 0"
        class="space-y-4 max-h-64 overflow-y-auto"
      >
        <li
          *ngFor="let item of cartItems"
          class="bg-gray-50 rounded-lg overflow-hidden"
        >
          <div class="p-4">
            <div class="flex justify-between items-center mb-2">
              <span class="font-medium text-dagblue"
                >Envío: {{ item.provider }}</span
              >
              <button
                class="text-red-500 hover:text-red-700"
                (click)="removeItem(item)"
              >
                <svg
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  ></path>
                </svg>
              </button>
            </div>
            <div class="text-sm text-gray-600">
              <p>Origen: {{ item.from.city || "No especificado" }}</p>
              <p>Destino: {{ item.to.city || "No especificado" }}</p>
            </div>
          </div>
          <div class="bg-gray-100 px-4 py-2 flex justify-between items-center">
            <span class="text-sm font-bold">ID de envío: {{ item._id }}</span>
            <span class="font-medium"
              >${{ getItemPrice(item) | number : "1.2-2" }}</span
            >
          </div>
        </li>
      </ul>
    </div>
    <div class="bg-gray-100 px-6 py-4">
      <div
        *ngIf="cartItems.length > 0"
        class="flex justify-between items-center mb-4"
      >
        <span class="font-bold text-lg">Total:</span>
        <span class="font-bold text-lg text-dagblue"
          >${{ getTotalPrice() | number : "1.2-2" }}</span
        >
      </div>
      <div class="flex justify-between items-center">
        <button
          (click)="closeCart()"
          class="bg-gray-300 rounded-full hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 transition duration-300"
        >
          Cerrar
        </button>
        <button
          *ngIf="cartItems.length > 0"
          [routerLink]="['/pay-shipments']"
          class="bg-dagpk hover:bg-dagpk-dark text-white font-bold py-2 px-4 rounded-full transition duration-300"
        >
          Ir a pagar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- sidebar.component.html -->
<div class="relative">
  <!-- Sidebar Container -->
  <div
    class="fixed inset-y-0 left-0 z-50 flex flex-col w-64 h-screen transition-all duration-300 ease-in-out bg-indigo-900"
    [ngClass]="{ 'w-64': !collapsed, 'w-20': collapsed }"
  >
    <!-- Toggle Button -->
    <button
      (click)="toggleSidebar()"
      class="absolute -right-3 top-20 z-50 flex items-center justify-center w-6 h-6 bg-indigo-500 rounded-full shadow-md focus:outline-none hover:bg-indigo-600 transition-all duration-200"
    >
      <svg
        *ngIf="!collapsed"
        class="w-3 h-3 text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
      <svg
        *ngIf="collapsed"
        class="w-3 h-3 text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <!-- Logo Section -->
    <div
      class="flex items-center justify-center h-16 border-b border-indigo-800"
    >
      <img
        *ngIf="!collapsed"
        src="../../../assets/images/LogotipoM.png"
        alt="DagPacket"
        class="mx-auto"
        style="max-width: 180px; height: auto"
      />
      <img
        *ngIf="collapsed"
        src="../../../assets/images/Icon Dagpacket.png"
        alt="D"
        class="h-8 transition-all duration-300 logo-white brightness-0 invert"
      />
    </div>

    <!-- Profile Section -->
    <div
      class="flex items-center p-4"
      [ngClass]="{ 'justify-start': !collapsed, 'justify-center': collapsed }"
    >
      <div class="relative">
        <div
          class="w-10 h-10 rounded-lg bg-cover bg-center"
          style="background-image: url('assets/images/profile.jpg')"
        ></div>
        <div
          class="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 rounded-full border-2 border-indigo-900"
        ></div>
      </div>
      <div *ngIf="!collapsed" class="ml-3 overflow-hidden">
        <h3 *ngIf="user" class="text-sm font-semibold text-white truncate">
          {{ user.name }} {{ user.surname }}
        </h3>
        <p *ngIf="user" class="text-xs text-indigo-300">{{ user.role }}</p>
      </div>
    </div>

    <!-- Balance Card (hidden when collapsed) -->
    <div
      *ngIf="!collapsed"
      class="p-4 overflow-hidden transition-all duration-300 max-h-[300px]"
    >
      <div
        *ngIf="hasWallet && roleHasWallet"
        (click)="openWalletModalCard()"
        class="p-4 rounded-xl bg-black/20"
      >
        <div class="flex items-center justify-between mb-3">
          <span class="text-xs font-medium text-indigo-300 uppercase"
            >Balance</span
          >
          <svg
            class="w-4 h-4 text-indigo-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"
            />
          </svg>
        </div>
        <div *ngIf="hasRole('ADMIN')" class="text-xl font-bold text-white mb-3">
          Balance: {{ rechargeBalance || "Cargando..." }}
        </div>
        <ul class="space-y-2">
          <li class="flex justify-between text-xs">
            <span class="text-indigo-300">Recargas</span>
            <span class="text-white font-medium">{{
              recharguesBalance | currency
            }}</span>
          </li>

          <li class="flex justify-between text-xs">
            <span class="text-indigo-300">Envíos</span>
            <span class="text-white font-medium">
              {{ shipmentBalance | currency }}</span
            >
          </li>
          <li class="flex justify-between text-xs">
            <span class="text-indigo-300">Servicios</span>
            <span class="text-white font-medium">
              {{ servicesBalance | currency }}</span
            >
          </li>
        </ul>
      </div>
      <div
        *ngIf="hasWallet && parentHasWallet"
        (click)="openWalletModalCard()"
        class="p-4 rounded-xl bg-black/20"
      >
        <div class="flex items-center justify-between mb-3">
          <span class="text-xs font-medium text-indigo-300 uppercase"
            >Balance</span
          >
          <svg
            class="w-4 h-4 text-indigo-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"
            />
          </svg>
        </div>
        <div *ngIf="hasRole('ADMIN')" class="text-xl font-bold text-white mb-3">
          EMIDA: {{ rechargeBalance || "Cargando..." }}
        </div>
        <ul class="space-y-2">
          <li class="flex justify-between text-xs">
            <span class="text-indigo-300">Recargas</span>
            <span class="text-white font-medium">{{
              recharguesBalance | currency
            }}</span>
          </li>

          <li class="flex justify-between text-xs">
            <span class="text-indigo-300">Envíos</span>
            <span class="text-white font-medium">
              {{ shipmentBalance | currency }}</span
            >
          </li>
          <li class="flex justify-between text-xs">
            <span class="text-indigo-300">Servicios</span>
            <span class="text-white font-medium">
              {{ servicesBalance | currency }}</span
            >
          </li>
        </ul>
      </div>
      <div
        *ngIf="!hasWallet && roleHasWallet"
        class="flex flex-col items-center justify-center bg-black/20 rounded-lg shadow-md p-6 max-w-md mx-auto"
      >
        <div class="flex items-center mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-7 w-7 text-white mr-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
            />
          </svg>
          <h2 class="text-lg font-semibold text-white">Wallet no disponible</h2>
        </div>

        <p class="text-white text-center mb-5">
          Aún no tienes una wallet creada, crea una para poder realizar envíos
        </p>

        <button
          (click)="initializeWallet(id)"
          class="text-dagblue bg-white font-semibold rounded-md py-2 px-4 hover:bg-gray-100 transition duration-200 ease-in-out flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          Crear wallet
        </button>
      </div>
    </div>

    <!-- Navigation Menu -->
    <div class="flex-1 overflow-y-auto py-4 px-3">
      <!-- Paquetes Section -->
      <div
        *ngIf="
          hasPermission('Paquetes', 'Cotizar Envio') ||
          hasPermission('Paquetes', 'Envios') ||
          hasPermission('Paquetes', 'Recoleccion') ||
          hasPermission('Paquetes', 'Empaques')
        "
        class="mb-4"
      >
        <h3
          *ngIf="!collapsed"
          class="px-2 mb-2 text-xs font-semibold text-indigo-300 uppercase tracking-wider"
        >
          Paquetes
        </h3>
        <ul class="space-y-1">
          <li>
            <a
              routerLink="/quote"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium': isCurrentPage('/quote'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/quote')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/quote'),
                  'text-dagblue': isCurrentPage('/quote')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 2a1 1 0 011 1v1.323l3.954 1.582 1.599-.8a1 1 0 01.894 1.79l-1.233.616 1.738 5.42a1 1 0 01-.285 1.05A3.989 3.989 0 0115 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.715-5.349L11 6.477V16h2a1 1 0 110 2H7a1 1 0 110-2h2V6.477L6.237 7.582l1.715 5.349a1 1 0 01-.285 1.05A3.989 3.989 0 015 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.738-5.42-1.233-.617a1 1 0 01.894-1.788l1.599.799L9 4.323V3a1 1 0 011-1zm-5 8.274l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L5 10.274zm10 0l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L15 10.274z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Cotizar Envío</span>
            </a>
          </li>
          <li>
            <a
              routerLink="/shipments"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('/shipments'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/shipments')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/shipments'),
                  'text-dagblue': isCurrentPage('/shipments')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                />
                <path
                  d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7a1 1 0 00-1 1v6.05A2.5 2.5 0 0115.95 16H17a1 1 0 001-1v-5a1 1 0 00-.293-.707l-2-2A1 1 0 0015 7h-1z"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Envíos</span>
            </a>
          </li>
          <li>
            <a
              href="/recoleccion"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('/recoleccion'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/recoleccion')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/recoleccion'),
                  'text-dagblue': isCurrentPage('/recoleccion')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Recolección</span>
            </a>
          </li>
          <li *ngIf="hasPermission('Paquetes', 'Empaques')">
            <a
              href="/packing"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium': isCurrentPage('/packing'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/packing')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/packing'),
                  'text-dagblue': isCurrentPage('/packing')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17A3 3 0 015 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
                  clip-rule="evenodd"
                />
                <path
                  d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Empaques</span>
            </a>
          </li>
        </ul>
      </div>

      <!-- Licenciatarios Section -->
      <div
        *ngIf="
          hasPermission('Licenciatarios', 'Resumen') ||
          hasPermission('Licenciatarios', 'Caja') ||
          hasPermission('Licenciatarios', 'Cortes de Caja') ||
          hasPermission('Licenciatarios', 'Pago de Servicios') ||
          hasPermission('Licenciatarios', 'Solicitudes de Recarga') ||
          hasPermission('Licenciatarios', 'Solicitudes de Cancelacion') ||
          hasPermission('Licenciatarios', 'Transferencia Empaques')
        "
        class="mb-4"
      >
        <h3
          *ngIf="!collapsed"
          class="px-2 mb-2 text-xs font-semibold text-indigo-300 uppercase tracking-wider"
        >
          Licenciatarios
        </h3>
        <ul class="space-y-1">
          <li *ngIf="hasPermission('Licenciatarios', 'Resumen')">
            <a
              routerLink="/licensees"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('/licensees'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/licensees')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/licensees'),
                  'text-dagblue': isCurrentPage('/licensees')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Resumen</span>
            </a>
          </li>
          <li *ngIf="hasPermission('Licenciatarios', 'Caja')">
            <a
              routerLink="/cashiers"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium': isCurrentPage('/cashiers'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/cashiers')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/cashiers'),
                  'text-dagblue': isCurrentPage('/cashiers')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 000 2h10a1 1 0 100-2H3zm0 4a1 1 0 000 2h6a1 1 0 100-2H3zm0 4a1 1 0 100 2h10a1 1 0 100-2H3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Caja</span>
            </a>
          </li>
          <li *ngIf="hasPermission('Licenciatarios', 'Cortes de Caja')">
            <a
              [routerLink]="['/cash-register-list-license/' + id]"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium': isCurrentPage(
                  '/cash-register-list-license/'
                ),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/cash-register-list-license/')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue': !isCurrentPage(
                    '/cash-register-list-license/'
                  ),
                  'text-dagblue': isCurrentPage('/cash-register-list-license/')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm4.707 3.707a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L8.414 9H10a3 3 0 013 3v1a1 1 0 102 0v-1a5 5 0 00-5-5H8.414l1.293-1.293z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Cortes de caja</span>
            </a>
          </li>
          <li *ngIf="hasPermission('Licenciatarios', 'Pago de Servicios')">
            <a
              routerLink="/services"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium': isCurrentPage('/services'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/services')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/services'),
                  'text-dagblue': isCurrentPage('/services')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                <path
                  fill-rule="evenodd"
                  d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Pago de servicios</span>
            </a>
          </li>
          <li *ngIf="hasPermission('Licenciatarios', 'Solicitudes de Recarga')">
            <a
              routerLink="/request-refill"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('/request-refill'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/request-refill')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/request-refill'),
                  'text-dagblue': isCurrentPage('/request-refill')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                <path
                  fill-rule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Solicitud Recarga</span>
            </a>
          </li>
          <li
            *ngIf="
              hasPermission('Licenciatarios', 'Solicitudes de Cancelacion')
            "
          >
            <a
              routerLink="/tickets"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium': isCurrentPage('/tickets'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/tickets')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/tickets'),
                  'text-dagblue': isCurrentPage('/tickets')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                <path
                  fill-rule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3"
                >Solicitudes de Cancelacion</span
              >
            </a>
          </li>
          <li *ngIf="hasPermission('Licenciatarios', 'Transferencia Empaques')">
            <a
              routerLink="/solicitud-empaque"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('/solicitud-empaque'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/solicitud-empaque')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/solicitud-empaque'),
                  'text-dagblue': isCurrentPage('/solicitud-empaque')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                <path
                  fill-rule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3"
                >Transferencia Empaques</span
              >
            </a>
          </li>
        </ul>
      </div>
      <div
        *ngIf="
          hasPermission('Gestion Administrativa', 'Dashboard General') ||
          hasPermission('Gestion Administrativa', 'Panel de Administracion') ||
          hasPermission('Gestion Administrativa', 'Solicitudes de Recarga') ||
          hasPermission('Gestion Administrativa', 'Historial de Recargas') ||
          hasPermission('Gestion Administrativa', 'Repartidor') ||
          hasPermission('Gestion Administrativa', 'Historial de Recargas') ||
          hasPermission('Gestion Administrativa', 'Almacen General') ||
          hasPermission(
            'Gestion Administrativa',
            'Almacen Inventario Licenciatarios'
          )
        "
        class="mb-4"
      >
        <h3
          *ngIf="!collapsed"
          class="px-2 mb-2 text-xs font-semibold text-indigo-300 uppercase tracking-wider"
        >
          Gestión Administrativa
        </h3>
        <ul class="space-y-1">
          <!-- Dashboard General -->
          <li
            *ngIf="hasPermission('Gestion Administrativa', 'Dashboard General')"
          >
            <a
              href="/administracion"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('/administracion'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/administracion')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/administracion'),
                  'text-dagblue': isCurrentPage('/administracion')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Dashboard General</span>
            </a>
          </li>

          <!-- Panel de Administración -->
          <li
            *ngIf="
              hasPermission('Gestion Administrativa', 'Panel de Administracion')
            "
          >
            <a
              href="/olimpo"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium': isCurrentPage('/olimpo'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/olimpo')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/olimpo'),
                  'text-dagblue': isCurrentPage('/olimpo')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 000 2h10a1 1 0 100-2H3zm0 4a1 1 0 000 2h6a1 1 0 100-2H3zm0 4a1 1 0 100 2h10a1 1 0 100-2H3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3"
                >Panel de Administracion</span
              >
            </a>
          </li>
          <li
            *ngIf="
              hasPermission(
                'Gestion Administrativa',
                'Historial de Transacciones'
              )
            "
          >
            <a
              href="/registro-pagos"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('/registro-pagos'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/registro-pagos')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/registro-pagos'),
                  'text-dagblue': isCurrentPage('/registro-pagos')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 000 2h10a1 1 0 100-2H3zm0 4a1 1 0 000 2h6a1 1 0 100-2H3zm0 4a1 1 0 100 2h10a1 1 0 100-2H3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3"
                >Historial de Transacciones</span
              >
            </a>
          </li>

          <!-- Solicitudes de Recarga -->
          <li
            *ngIf="
              hasPermission('Gestion Administrativa', 'Solicitudes de Recarga')
            "
          >
            <a
              href="displayRefills"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('displayRefills'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('displayRefills')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('displayRefills'),
                  'text-dagblue': isCurrentPage('displayRefills')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                <path
                  fill-rule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3"
                >Solicitudes de Recarga</span
              >
            </a>
          </li>
          <!-- Repartidor -->
          <li *ngIf="hasPermission('Gestion Administrativa', 'Repartidor')">
            <a
              href="/repartidor"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('/repartidor'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('/repartidor')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('/repartidor'),
                  'text-dagblue': isCurrentPage('/repartidor')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Repartidor</span>
            </a>
          </li>

          <!-- Historial de Recargas -->
          <li
            *ngIf="
              hasPermission('Gestion Administrativa', 'Historial de Recargas')
            "
          >
            <a
              href="History-Refills"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('History-Refills'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('History-Refills')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('History-Refills'),
                  'text-dagblue': isCurrentPage('History-Refills')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                <path
                  fill-rule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Historial de Recargas</span>
            </a>
          </li>
          <!-- Almacen General -->
          <li
            *ngIf="hasPermission('Gestion Administrativa', 'Almacen General')"
          >
            <a
              href="almacen-general"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('almacen-general'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('almacen-general')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('almacen-general'),
                  'text-dagblue': isCurrentPage('almacen-general')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                <path
                  fill-rule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Almacen General</span>
            </a>
          </li>
          <!-- Inventario Licenciatarios -->
          <li
            *ngIf="
              hasPermission(
                'Gestion Administrativa',
                'Inventario Licenciatarios'
              )
            "
          >
            <a
              href="inventario-personal"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium': isCurrentPage(
                  'inventario-personal'
                ),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('inventario-personal')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue': !isCurrentPage(
                    'inventario-personal'
                  ),
                  'text-dagblue': isCurrentPage('inventario-personal')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                <path
                  fill-rule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3"
                >Inventario Licenciatarios</span
              >
            </a>
          </li>
        </ul>
      </div>

      <!-- Configuración Section -->
      <div
        *ngIf="
          hasPermission('Lockers', 'Operaciones de Lockers') ||
          hasPermission('Lockers', 'Estatus de Lockers') ||
          hasPermission('Lockers', 'Publicidad de Lockers')
        "
        class="mb-4"
      >
        <h3
          *ngIf="!collapsed"
          class="px-2 mb-2 text-xs font-semibold text-indigo-300 uppercase tracking-wider"
        >
          LOCKERS
        </h3>
        <ul class="space-y-1">
          <li *ngIf="hasPermission('Lockers', 'Estatus de Lockers')">
            <a
              href="viewlockers"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('viewlockers'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('viewlockers')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('viewlockers'),
                  'text-dagblue': isCurrentPage('viewlockers')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Estatus de Lockers</span>
              <!-- <span
                *ngIf="!collapsed"
                class="ml-auto bg-indigo-500 text-white text-xs font-medium px-1.5 py-0.5 rounded-full"
                >5</span
              > -->
            </a>
          </li>
          <li *ngIf="hasPermission('Lockers', 'Operaciones de Lockers')">
            <a
              href="operaciones-lockers"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium': isCurrentPage(
                  'operaciones-lockers'
                ),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('operaciones-lockers')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue': !isCurrentPage(
                    'operaciones-lockers'
                  ),
                  'text-dagblue': isCurrentPage('operaciones-lockers')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3"
                >Operaciones de Lockers</span
              >
            </a>
          </li>
          <li *ngIf="hasPermission('Lockers', 'Publicidad de Lockers')">
            <a
              href="publicidad"
              class="flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors group"
              [ngClass]="{
                'justify-start': !collapsed,
                'justify-center': collapsed,
                'bg-white text-dagblue font-medium':
                  isCurrentPage('publicidad-lockers'),
                'text-indigo-100 hover:bg-white/90 hover:text-dagblue hover:font-medium':
                  !isCurrentPage('publicidad-lockers')
              }"
            >
              <svg
                class="w-5 h-5 transition-colors"
                [ngClass]="{
                  'text-indigo-300 group-hover:text-dagblue':
                    !isCurrentPage('publicidad-lockers'),
                  'text-dagblue': isCurrentPage('publicidad-lockers')
                }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd"
                />
              </svg>
              <span *ngIf="!collapsed" class="ml-3">Publicidad Lockers</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Footer -->
    <div
      class="flex items-center justify-between p-4 border-t border-indigo-800"
    >
      <!-- Notificaciones -->
      <div
        (click)="openNotificationModal()"
        class="relative cursor-pointer group"
      >
        <svg
          class="w-5 h-5 text-indigo-300 group-hover:text-white transition-colors"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
          />
        </svg>
      </div>

      <!-- Carrito -->
      <div
        (click)="toggleCart()"
        class="relative cursor-pointer group"
        *ngIf="!collapsed"
      >
        <svg
          class="w-5 h-5 text-indigo-300 group-hover:text-white transition-colors"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          />
        </svg>
        <span
          class="absolute -top-2 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white"
          *ngIf="cartItems.length > 0"
        >
          {{ cartItems.length < 10 ? cartItems.length : "9+" }}
        </span>
      </div>

      <!-- Mi Perfil -->
      <a
        routerLink="/settings"
        class="flex items-center cursor-pointer text-indigo-300 hover:text-white transition-colors"
      >
        <svg
          class="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 2a5 5 0 00-5 5v3a5 5 0 0010 0V7a5 5 0 00-5-5zM3 17a7 7 0 0114 0H3z"
            clip-rule="evenodd"
          />
        </svg>
        <span *ngIf="!collapsed" class="ml-2 text-xs">Mi Perfil</span>
      </a>

      <!-- Cerrar Sesión -->
      <div
        class="flex items-center cursor-pointer text-indigo-300 hover:text-white transition-colors"
        (click)="logout()"
      >
        <svg
          class="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  </div>

  <div
    *ngIf="walletCardModalIsOpen"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
  >
    <div class="bg-white w-[600px] rounded-xl shadow-2xl p-6">
      <!-- Sección de Saldos -->
      <div class="mb-6">
        <h2 class="text-2xl font-bold text-[#342C6A] mb-4">Mi Wallet</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="bg-gray-100 rounded-lg p-4 text-center">
            <span class="block text-sm text-gray-600">Saldo Envíos</span>
            <span class="block text-xl font-bold text-[#342C6A]">
              {{ shipmentBalance | currency }}
            </span>
          </div>
          <div class="bg-gray-100 rounded-lg p-4 text-center">
            <span class="block text-sm text-gray-600">Saldo Servicios</span>
            <span class="block text-xl font-bold text-[#342C6A]">
              {{ servicesBalance | currency }}
            </span>
          </div>
          <div class="bg-gray-100 rounded-lg p-4 text-center">
            <span class="block text-sm text-gray-600">Saldo Recargas</span>
            <span class="block text-xl font-bold text-[#342C6A]">
              {{ recharguesBalance | currency }}
            </span>
          </div>
        </div>
      </div>

      <!-- Últimas 5 Transacciones -->
      <div class="mb-6">
        <h3 class="text-lg font-semibold text-gray-800 mb-3">
          Últimas Transacciones
        </h3>
        <table class="w-full">
          <thead class="bg-gray-100">
            <tr>
              <th class="px-3 py-2 text-left text-xs text-gray-600">Fecha</th>
              <th class="px-3 py-2 text-left text-xs text-gray-600">
                Servicio
              </th>
              <th class="px-3 py-2 text-left text-xs text-gray-600">Monto</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let transaction of transactionHistory.slice(0, 5)"
              class="border-b last:border-b-0"
            >
              <td class="px-3 py-2 text-sm">
                {{ transaction.transaction_date | date : "dd/MM/yyyy HH:mm" }}
              </td>
              <td class="px-3 py-2 text-sm">
                {{ transaction.service }}
              </td>
              <td
                [ngClass]="{
                  'text-green-600':
                    transaction.payment_method === 'Reembolso' ||
                    transaction.service === 'Abono a wallet',
                  'text-red-600':
                    transaction.payment_method !== 'Reembolso' &&
                    transaction.service !== 'Abono a wallet'
                }"
                class="px-4 py-3 text-sm font-semibold flex items-center space-x-1"
              >
                <span>
                  {{
                    transaction.payment_method === "Reembolso" ||
                    transaction.service === "Abono a wallet"
                      ? "+"
                      : "-"
                  }}
                </span>
                <span>
                  {{ transaction.amount?.$numberDecimal || "vacio" }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Sección de Cajeros -->
      <div class="cash-transaction-history overflow-x-auto">
        <!-- Verificamos si hay transacciones disponibles -->
        <div *ngIf="cashTransactionHistory.length > 0; else noTransactions">
          <table class="min-w-full table-auto text-left border-collapse">
            <thead>
              <tr class="bg-gray-200">
                <th class="px-4 py-2 border-b">Caja Registradora</th>
                <th class="px-4 py-2 border-b">Operador</th>
                <th class="px-4 py-2 border-b">Email</th>
                <th class="px-4 py-2 border-b">Fecha</th>
                <th class="px-4 py-2 border-b">Importe</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cashier of cashTransactionHistory">
                <td class="px-4 py-2 border-b font-semibold">
                  {{ cashier.name }}
                </td>
                <td class="px-4 py-2 border-b">
                  <div *ngFor="let transaction of cashier.transactions">
                    {{ transaction.operation_by.name }}
                  </div>
                </td>
                <td class="px-4 py-2 border-b">
                  <div *ngFor="let transaction of cashier.transactions">
                    {{ transaction.operation_by.email }}
                  </div>
                </td>
                <td class="px-4 py-2 border-b">
                  <div *ngFor="let transaction of cashier.transactions">
                    {{ transaction.createdAt | date : "short" }}
                  </div>
                </td>
                <td class="px-4 py-2 border-b">
                  <div *ngFor="let transaction of cashier.transactions">
                    {{ transaction.amount | currency : "USD" }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Mensaje en caso de que no haya transacciones -->
        <ng-template #noTransactions>
          <p class="text-center text-gray-500">
            No hay transacciones registradas.
          </p>
        </ng-template>
      </div>

      <!-- Botones de Acción -->
      <div class="flex justify-between items-center mt-6">
        <button
          (click)="closeWalletModalCard()"
          class="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
        >
          Cerrar
        </button>
        <a
          href="/licensees"
          class="px-4 py-2 bg-[#342C6A] text-white rounded-md hover:bg-opacity-90 transition-colors"
        >
          Ir a resumen
        </a>
        <button
          (click)="openWalletModal()"
          class="px-4 py-2 bg-[#342C6A] text-white rounded-md hover:bg-opacity-90 transition-colors"
        >
          Ver Historial Completo
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="walletModalIsOpen"
    class="fixed inset-0 z-50 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4"
  >
    <div
      class="bg-white rounded-xl shadow-2xl w-full max-w-6xl max-h-[90vh] flex flex-col overflow-hidden"
    >
      <!-- Modal Header -->
      <div
        class="bg-[#342C6A] text-white px-6 py-4 flex justify-between items-center"
      >
        <h2 class="text-2xl font-bold">Historial de transacciones recientes</h2>
        <button
          (click)="closeModal()"
          class="text-white hover:text-gray-200 transition-colors"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="flex-grow p-6 overflow-auto">
        <div class="w-full bg-white rounded-lg p-4">
          <!-- Table Controls -->
          <div class="flex justify-between items-center mb-4">
            <div class="flex items-center space-x-2">
              <label for="pageSize" class="text-sm text-gray-600"
                >Mostrar</label
              >
              <select
                id="pageSize"
                (change)="onPageSizeChange($event)"
                class="form-select px-3 py-2 border rounded-md text-sm focus:ring-2 focus:ring-[#342C6A]/50"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
            <p class="text-sm text-gray-600">
              Total de elementos: {{ totalItemsTransaction }}
            </p>
          </div>

          <!-- Table -->
          <div class="overflow-x-auto">
            <table class="min-w-full bg-white shadow-md rounded-lg">
              <thead class="bg-gray-100">
                <tr>
                  <th
                    class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Fecha
                  </th>
                  <th
                    class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Servicio
                  </th>
                  <th
                    class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tipo
                  </th>
                  <th
                    class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Método de Pago
                  </th>
                  <th
                    class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Monto
                  </th>
                  <th
                    class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Saldo anterior
                  </th>
                  <th
                    class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Saldo nuevo
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr *ngIf="transactionHistory.length === 0">
                  <td colspan="7" class="px-4 py-3 text-center text-gray-500">
                    No hay transacciones para mostrar
                  </td>
                </tr>
                <tr
                  *ngFor="let transaction of transactionHistory"
                  class="hover:bg-gray-50 transition-colors"
                >
                  <td class="px-4 py-3 text-sm text-gray-900">
                    {{
                      transaction.transaction_date | date : "dd/MM/yyyy HH:mm"
                    }}
                  </td>
                  <td class="px-4 py-3 text-sm text-gray-900">
                    {{ transaction.service }}
                  </td>
                  <td class="px-4 py-3 text-sm text-gray-900">
                    {{ transaction.details }}
                  </td>
                  <td class="px-4 py-3 text-sm text-gray-900">
                    {{ transaction.payment_method }}
                  </td>
                  <td
                    [ngClass]="{
                      'text-green-600':
                        transaction.payment_method === 'Reembolso' ||
                        transaction.service === 'Abono a wallet',
                      'text-red-600':
                        transaction.payment_method !== 'Reembolso' &&
                        transaction.service !== 'Abono a wallet'
                    }"
                    class="px-4 py-3 text-sm font-semibold flex items-center space-x-1"
                  >
                    <span>
                      {{
                        transaction.payment_method === "Reembolso" ||
                        transaction.service === "Abono a wallet"
                          ? "+"
                          : "-"
                      }}
                    </span>
                    <span>
                      {{ transaction.amount?.$numberDecimal || "vacio" }}
                    </span>
                  </td>

                  <td class="px-4 py-3 text-sm text-gray-900">
                    {{
                      transaction.previous_balance?.$numberDecimal || "vacio"
                    }}
                  </td>
                  <td class="px-4 py-3 text-sm text-gray-900">
                    {{ transaction.new_balance?.$numberDecimal || "vacio" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Modal Footer -->
      <div class="mt-4 flex justify-between items-center p-4">
        <!-- Botón para cerrar -->
        <button
          (click)="closeModal()"
          class="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
        >
          Volver
        </button>

        <!-- Contenedor de paginación -->
        <div class="flex items-center space-x-4">
          <!-- Botón Anterior -->
          <button
            (click)="previousPage()"
            [disabled]="!hasPreviousPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Anterior
          </button>

          <!-- Números de página -->
          <div class="flex space-x-1">
            <button
              *ngFor="let page of getPageNumbers()"
              (click)="goToPage(page)"
              [ngClass]="{
                'bg-[#342C6A] text-white': page === currentPageTransaction,
                'bg-gray-300 text-gray-800': page !== currentPageTransaction
              }"
              class="px-4 py-2 rounded-md"
            >
              {{ page }}
            </button>
          </div>

          <!-- Botón Siguiente -->
          <button
            (click)="nextPage()"
            [disabled]="!hasNextPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal de notificacion -->

  <div
    *ngIf="isNotificationModalOpen"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="bg-white rounded-lg shadow-lg w-96 p-6">
      <!-- Modal Header -->
      <div class="flex justify-between items-center border-b pb-2">
        <h2 class="text-xl font-bold">Notificaciones</h2>
        <button
          (click)="closeNotificationModal()"
          class="text-gray-500 hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <!-- Modal Content -->
      <div class="mt-4">
        <p class="text-gray-600">
          Aquí aparecerán tus notificaciones recientes.
        </p>
      </div>
      <!-- Modal Footer -->
      <div class="mt-4 flex justify-end">
        <button
          (click)="closeNotificationModal()"
          class="bg-dagblue text-white px-4 py-2 rounded-lg"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="walletCardModalIsOpen"
  class="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
>
  <div class="bg-white w-[600px] rounded-xl shadow-2xl p-6">
    <!-- Sección de Saldos -->
    <div class="mb-6">
      <h2 class="text-2xl font-bold text-[#342C6A] mb-4">Mi Wallet</h2>
      <div class="grid grid-cols-3 gap-4">
        <div class="bg-gray-100 rounded-lg p-4 text-center">
          <span class="block text-sm text-gray-600">Saldo Envíos</span>
          <span class="block text-xl font-bold text-[#342C6A]">
            {{ shipmentBalance | currency }}
          </span>
        </div>
        <div class="bg-gray-100 rounded-lg p-4 text-center">
          <span class="block text-sm text-gray-600">Saldo Servicios</span>
          <span class="block text-xl font-bold text-[#342C6A]">
            {{ servicesBalance | currency }}
          </span>
        </div>
        <div class="bg-gray-100 rounded-lg p-4 text-center">
          <span class="block text-sm text-gray-600">Saldo Recargas</span>
          <span class="block text-xl font-bold text-[#342C6A]">
            {{ recharguesBalance | currency }}
          </span>
        </div>
      </div>
    </div>

    <!-- Últimas 5 Transacciones -->
    <div class="mb-6">
      <h3 class="text-lg font-semibold text-gray-800 mb-3">
        Últimas Transacciones
      </h3>
      <table class="w-full">
        <thead class="bg-gray-100">
          <tr>
            <th class="px-3 py-2 text-left text-xs text-gray-600">Fecha</th>
            <th class="px-3 py-2 text-left text-xs text-gray-600">Servicio</th>
            <th class="px-3 py-2 text-left text-xs text-gray-600">Monto</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let transaction of transactionHistory.slice(0, 5)"
            class="border-b last:border-b-0"
          >
            <td class="px-3 py-2 text-sm">
              {{ transaction.transaction_date | date : "dd/MM/yyyy HH:mm" }}
            </td>
            <td class="px-3 py-2 text-sm">
              {{ transaction.service }}
            </td>
            <td
              [ngClass]="{
                'text-green-600':
                  transaction.payment_method === 'Reembolso' ||
                  transaction.service === 'Abono a wallet',
                'text-red-600':
                  transaction.payment_method !== 'Reembolso' &&
                  transaction.service !== 'Abono a wallet'
              }"
              class="px-4 py-3 text-sm font-semibold flex items-center space-x-1"
            >
              <span>
                {{
                  transaction.payment_method === "Reembolso" ||
                  transaction.service === "Abono a wallet"
                    ? "+"
                    : "-"
                }}
              </span>
              <span>
                {{ transaction.amount?.$numberDecimal || "vacio" }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Sección de Cajeros -->
    <div class="cash-transaction-history overflow-x-auto">
      <!-- Verificamos si hay transacciones disponibles -->
      <div *ngIf="cashTransactionHistory.length > 0; else noTransactions">
        <table class="min-w-full table-auto text-left border-collapse">
          <thead>
            <tr class="bg-gray-200">
              <th class="px-4 py-2 border-b">Caja Registradora</th>
              <th class="px-4 py-2 border-b">Operador</th>
              <th class="px-4 py-2 border-b">Email</th>
              <th class="px-4 py-2 border-b">Fecha</th>
              <th class="px-4 py-2 border-b">Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cashier of cashTransactionHistory">
              <td class="px-4 py-2 border-b font-semibold">
                {{ cashier.name }}
              </td>
              <td class="px-4 py-2 border-b">
                <div *ngFor="let transaction of cashier.transactions">
                  {{ transaction.operation_by.name }}
                </div>
              </td>
              <td class="px-4 py-2 border-b">
                <div *ngFor="let transaction of cashier.transactions">
                  {{ transaction.operation_by.email }}
                </div>
              </td>
              <td class="px-4 py-2 border-b">
                <div *ngFor="let transaction of cashier.transactions">
                  {{ transaction.createdAt | date : "short" }}
                </div>
              </td>
              <td class="px-4 py-2 border-b">
                <div *ngFor="let transaction of cashier.transactions">
                  {{ transaction.amount | currency : "USD" }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Mensaje en caso de que no haya transacciones -->
      <ng-template #noTransactions>
        <p class="text-center text-gray-500">
          No hay transacciones registradas.
        </p>
      </ng-template>
    </div>

    <!-- Botones de Acción -->
    <div class="flex justify-between items-center mt-6">
      <button
        (click)="closeWalletModalCard()"
        class="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
      >
        Cerrar
      </button>
      <a
        href="/licensees"
        class="px-4 py-2 bg-[#342C6A] text-white rounded-md hover:bg-opacity-90 transition-colors"
      >
        Ir a resumen
      </a>
      <button
        (click)="openWalletModal()"
        class="px-4 py-2 bg-[#342C6A] text-white rounded-md hover:bg-opacity-90 transition-colors"
      >
        Ver Historial Completo
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="walletModalIsOpen"
  class="fixed inset-0 z-50 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4"
>
  <div
    class="bg-white rounded-xl shadow-2xl w-full max-w-6xl max-h-[90vh] flex flex-col overflow-hidden"
  >
    <!-- Modal Header -->
    <div
      class="bg-[#342C6A] text-white px-6 py-4 flex justify-between items-center"
    >
      <h2 class="text-2xl font-bold">Historial de transacciones recientes</h2>
      <button
        (click)="closeModal()"
        class="text-white hover:text-gray-200 transition-colors"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="flex-grow p-6 overflow-auto">
      <div class="w-full bg-white rounded-lg p-4">
        <!-- Table Controls -->
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-2">
            <label for="pageSize" class="text-sm text-gray-600">Mostrar</label>
            <select
              id="pageSize"
              (change)="onPageSizeChange($event)"
              class="form-select px-3 py-2 border rounded-md text-sm focus:ring-2 focus:ring-[#342C6A]/50"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
          <p class="text-sm text-gray-600">
            Total de elementos: {{ totalItemsTransaction }}
          </p>
        </div>

        <!-- Table -->
        <div class="overflow-x-auto">
          <table class="min-w-full bg-white shadow-md rounded-lg">
            <thead class="bg-gray-100">
              <tr>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Fecha
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Servicio
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Tipo
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Método de Pago
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Monto
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Saldo anterior
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Saldo nuevo
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr *ngIf="transactionHistory.length === 0">
                <td colspan="7" class="px-4 py-3 text-center text-gray-500">
                  No hay transacciones para mostrar
                </td>
              </tr>
              <tr
                *ngFor="let transaction of transactionHistory"
                class="hover:bg-gray-50 transition-colors"
              >
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.transaction_date | date : "dd/MM/yyyy HH:mm" }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.service }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.details }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.payment_method }}
                </td>
                <td
                  [ngClass]="{
                    'text-green-600':
                      transaction.payment_method === 'Reembolso' ||
                      transaction.service === 'Abono a wallet',
                    'text-red-600':
                      transaction.payment_method !== 'Reembolso' &&
                      transaction.service !== 'Abono a wallet'
                  }"
                  class="px-4 py-3 text-sm font-semibold flex items-center space-x-1"
                >
                  <span>
                    {{
                      transaction.payment_method === "Reembolso" ||
                      transaction.service === "Abono a wallet"
                        ? "+"
                        : "-"
                    }}
                  </span>
                  <span>
                    {{ transaction.amount?.$numberDecimal || "vacio" }}
                  </span>
                </td>

                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.previous_balance?.$numberDecimal || "vacio" }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.new_balance?.$numberDecimal || "vacio" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal Footer -->
    <div class="mt-4 flex justify-between items-center p-4">
      <!-- Botón para cerrar -->
      <button
        (click)="closeModal()"
        class="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
      >
        Volver
      </button>

      <!-- Contenedor de paginación -->
      <div class="flex items-center space-x-4">
        <!-- Botón Anterior -->
        <button
          (click)="previousPage()"
          [disabled]="!hasPreviousPage"
          class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
        >
          Anterior
        </button>

        <!-- Números de página -->
        <div class="flex space-x-1">
          <button
            *ngFor="let page of getPageNumbers()"
            (click)="goToPage(page)"
            [ngClass]="{
              'bg-[#342C6A] text-white': page === currentPageTransaction,
              'bg-gray-300 text-gray-800': page !== currentPageTransaction
            }"
            class="px-4 py-2 rounded-md"
          >
            {{ page }}
          </button>
        </div>

        <!-- Botón Siguiente -->
        <button
          (click)="nextPage()"
          [disabled]="!hasNextPage"
          class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal de notificacion -->

<div
  *ngIf="isNotificationModalOpen"
  class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
>
  <div class="bg-white rounded-lg shadow-lg w-96 p-6">
    <!-- Modal Header -->
    <div class="flex justify-between items-center border-b pb-2">
      <h2 class="text-xl font-bold">Notificaciones</h2>
      <button
        (click)="closeNotificationModal()"
        class="text-gray-500 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
    <!-- Modal Content -->
    <div class="mt-4">
      <p class="text-gray-600">Aquí aparecerán tus notificaciones recientes.</p>
    </div>
    <!-- Modal Footer -->
    <div class="mt-4 flex justify-end">
      <button
        (click)="closeNotificationModal()"
        class="bg-dagblue text-white px-4 py-2 rounded-lg"
      >
        Cerrar
      </button>
    </div>
  </div>
</div>
