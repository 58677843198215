<app-sidebar class="hidden sm:block"></app-sidebar>
<div class="sm:ml-64 mt-14 bg-gray-100 min-h-screen">
    <div class="p-4 sm:p-6 lg:p-8">
        <h1 class="font-bold text-dagblue uppercase text-2xl sm:text-3xl mb-6">
          Operaciones De Lockers
        </h1>
    
        <!-- Filtros -->
        <!-- <div class="bg-transparent p-4 rounded-lg shadow-md mb-6">
          <div
            class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4"
          >
            <input
              class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
            />
            <input
              class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
            />
            <select
              class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
            >
              <option value="">Todos</option>
              <option value="Pendiente">Pendiente</option>
              <option value="Pagado">Pagado</option>
            </select>
            <button
              class="bg-[#D6542B] text-white px-6 py-2 rounded-full font-bold text-sm sm:text-base w-full sm:w-auto hover:bg-blue-900 transition"
            >
              Filtrar
            </button>
          </div>
        </div> -->
    
        <!-- Tabla Historial -->
        <div class="bg-[#F0F0F0] rounded-lg shadow-md overflow-x-auto">
          <table class="w-full text-sm sm:text-base">
            <thead class="uppercase bg-[#F0F0F0] text-dagblue font-bold">
              <tr>
                <th class="px-4 py-2 text-left">Id</th>
                <th class="px-4 py-2 text-left">Fecha</th>
                <th class="px-4 py-2 text-left">Locker</th>
                <th class="px-4 py-2 text-left">Status</th>
                <th class="px-4 py-2 text-left">Cuidad</th>
                <th class="px-4 py-2 text-left">Acciones</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 text-gray-500">
              <tr *ngFor="let locker of lockers; let i = index" class="hover:bg-gray-50">
                <td class="px-4 py-2">{{ locker.id_locker }}</td>
                <td class="px-4 py-2">{{ locker.fecha || 'Sin fecha' }}</td>
                <td class="px-4 py-2">{{ locker.street}}</td>
                <td class="px-4 py-2">
                  <span
                    class="px-2 py-1 rounded-full font-bold"
                    [ngClass]="{
                      'text-green-600': locker.status,
                      'text-red-600': !locker.status
                    }"
                  >
                    {{ locker.status ? 'Activo' : 'Inactivo' }}
                  </span>
                </td>
                <td class="px-4 py-2">{{ locker.city}}</td>
                <td class="px-4 py-2">
                  <button
                    [routerLink]="['/locker-details', locker._id]"
                    class="w-full sm:w-auto bg-dagblue font-bold text-white px-4 py-2 rounded-full hover:bg-blue-900 transition"
                  >
                    Ver
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</div>

