<app-sidebar></app-sidebar>
<div class="sm:ml-64 mt-14 bg-gray-100 min-h-screen p-4">
  <!-- Mobile view card layout -->
  <h1 class="text-2xl font-semibold text-dagblue px-4 py-2">Almacen General</h1>
  <div class="grid grid-cols-1 gap-4 md:hidden">
    <div
      class="bg-white p-4 rounded-lg shadow"
      *ngFor="let item of warehouseInventory"
    >
      <div class="flex items-center space-x-4 mb-3">
        <img
          [src]="getImageUrl(item.packing.image.data)"
          alt="Producto"
          class="w-16 h-16 rounded-full object-cover"
        />
        <div>
          <h3 class="font-bold uppercase text-lg">{{ item.packing.name }}</h3>
          <p class="text-gray-500">{{ item.packing.type }}</p>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-y-2 text-sm mb-3">
        <div class="font-medium">Cantidad:</div>
        <div>{{ item.quantity }}</div>

        <div class="font-medium">Última Entrada:</div>
        <div>{{ item.last_entry | date : "MMM d, y" }}</div>

        <div class="font-medium">Última Salida:</div>
        <div>{{ item.last_output | date : "MMM d, y" }}</div>

        <div class="font-medium">Precio:</div>
        <div>${{ item.packing.sell_price.$numberDecimal }}</div>
      </div>

      <p class="text-sm text-gray-600 mb-3">{{ item.packing.description }}</p>

      <button
        class="w-full bg-dagblue text-white px-4 py-2 rounded-lg font-bold hover:bg-blue-900"
      >
        Editar
      </button>
    </div>

    <div
      class="bg-white p-4 rounded-lg shadow text-center text-gray-500"
      *ngIf="!warehouseInventory || warehouseInventory.length === 0"
    >
      No hay productos en el almacén
    </div>
  </div>

  <!-- Desktop table view -->
  <div class="hidden md:block bg-white rounded-lg shadow-md overflow-x-auto">
    <table class="w-full text-sm">
      <thead class="bg-dagblue text-white">
        <tr>
          <th class="px-4 py-3 text-left font-medium">Imagen</th>
          <th class="px-4 py-3 text-left font-medium">Nombre</th>
          <th class="px-4 py-3 text-left font-medium">Tipo</th>
          <th class="px-4 py-3 text-left font-medium">Cantidad</th>
          <th class="px-4 py-3 text-left font-medium">Última Entrada</th>
          <th class="px-4 py-3 text-left font-medium">Última Salida</th>
          <th class="px-4 py-3 text-left font-medium">Alto × Ancho × Largo</th>
          <th class="px-4 py-3 text-left font-medium">Descripción</th>
          <th class="px-4 py-3 text-left font-medium">Precio</th>
          <th class="px-4 py-3 text-left font-medium">Acciones</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr *ngIf="!warehouseInventory || warehouseInventory.length === 0">
          <td colspan="10" class="px-4 py-4 text-center text-gray-500">
            No hay productos en el almacén
          </td>
        </tr>
        <tr *ngFor="let item of warehouseInventory">
          <td class="px-4 py-4 whitespace-nowrap">
            <img
              [src]="item.imageUrl"
              alt="Producto"
              class="w-12 h-12 rounded-full object-cover"
              *ngIf="item.imageUrl"
            />
          </td>
          <td class="px-4 py-4 uppercase font-medium">
            {{ item.packing.name }}
          </td>
          <td class="px-4 py-4">{{ item.packing.type }}</td>
          <td class="px-4 py-4">{{ item.quantity }}</td>
          <td class="px-4 py-4">
            {{ item.last_entry | date : "MMM d, y" }}
          </td>
          <td class="px-4 py-4">
            {{ item.last_output | date : "MMM d, y" }}
          </td>
          <td class="px-4 py-4">
            <span class="font-medium">Alto:</span> {{ item.packing.height }} ×
            <span class="font-medium">Ancho:</span> {{ item.packing.width }} ×
            <span class="font-medium">Largo:</span> {{ item.packing.length }}
          </td>
          <td class="px-4 py-4">
            <p class="truncate max-w-xs">{{ item.packing.description }}</p>
          </td>
          <td class="px-4 py-4">
            ${{ item.packing.sell_price.$numberDecimal }}
          </td>
          <td class="px-4 py-4 whitespace-nowrap">
            <button
              class="bg-dagblue text-white px-3 py-1 rounded-lg font-medium hover:bg-blue-900 transition-colors"
              (click)="openModal(item.packing._id, item.quantity)"
            >
              Editar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    *ngIf="showModal"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
  >
    <div class="bg-white p-6 rounded-lg shadow-xl w-full max-w-2xl">
      <h2 class="text-2xl font-bold mb-4">Actualizar cantidad de el almacen</h2>
      <div class="mb-4">
        <label
          for="quantity"
          class="block text-sm font-medium text-gray-700 mb-1"
          >Cantidad</label
        >
        <input
          type="number"
          id="quantity"
          [(ngModel)]="requestQuantity"
          name="quantity"
          min="1"
          class="w-full p-2 border rounded-md"
        />
      </div>
      <div class="flex justify-end space-x-2">
        <button
          (click)="updateQuantity()"
          class="px-4 py-2 bg-dagblue text-white rounded-md hover:bg-blue-900 transition duration-300"
        >
          Aceptar
        </button>
        <button
          (click)="closeModal()"
          class="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition duration-300"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>
