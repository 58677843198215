import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LockersService } from 'src/app/_services/Lockers/lockers.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  advertisementId: string | null = null;
  advertisementData: any[] = [];
  currentImageIndex: number = 0;
  currentImage: any = null;
  intervalId: any;
  pollingIntervalId: any; // For AJAX polling

  constructor(
    private route: ActivatedRoute,
    private lockersService: LockersService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.advertisementId = params.get('id');
      if (this.advertisementId) {
        this.loadAdvertisementDetails(this.advertisementId);

        // Start polling for updates every 5 seconds
        this.startPollingForUpdates();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.pollingIntervalId) {
      clearInterval(this.pollingIntervalId);
    }
  }

  loadAdvertisementDetails(id: string): void {
    this.lockersService.getPublicityById(id).subscribe(
      (response) => {
        this.advertisementData = response.data.advertisements;
        if (this.advertisementData.length > 0) {
          this.startCarousel();
        }
      },
      (error) => {
        console.error('Error al cargar los detalles de la publicidad:', error);
      }
    );
  }

  startCarousel(): void {
    this.currentImage = this.advertisementData[this.currentImageIndex]?.images[0];

    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, this.advertisementData[this.currentImageIndex]?.duration * 1000);
  }

  prevSlide(): void {
    this.currentImageIndex =
      this.currentImageIndex > 0
        ? this.currentImageIndex - 1
        : this.advertisementData.length - 1;
    this.currentImage = this.advertisementData[this.currentImageIndex]?.images[0];
  }

  nextSlide(): void {
    this.currentImageIndex =
      (this.currentImageIndex + 1) % this.advertisementData.length;
    this.currentImage = this.advertisementData[this.currentImageIndex]?.images[0];
  }

  goToSlide(index: number): void {
    this.currentImageIndex = index;
    this.currentImage = this.advertisementData[this.currentImageIndex]?.images[0];
  }

  startPollingForUpdates(): void {
    this.pollingIntervalId = setInterval(() => {
      if (this.advertisementId) {
        this.lockersService.getPublicityById(this.advertisementId).subscribe(
          (response) => {
            const newAdvertisements = response.data.advertisements;

            // Check if there are new images
            if (JSON.stringify(this.advertisementData) !== JSON.stringify(newAdvertisements)) {
              console.log('Nuevas imágenes detectadas, actualizando...');
              this.advertisementData = newAdvertisements;
              this.currentImageIndex = 0;
              this.startCarousel(); // Restart carousel with new data
            }
          },
          (error) => {
            console.error('Error al verificar nuevas imágenes:', error);
          }
        );
      }
    }, 14400000  ); // Poll every 5 seconds
  }
}
