<app-sidebar class="hidden sm:block"></app-sidebar>
<div class="sm:ml-64 mt-14 bg-gray-100 min-h-screen">
    <div class="p-4 sm:p-6 lg:p-8">
        <h1 class="font-bold text-dagblue uppercase text-2xl sm:text-3xl mb-6">
          Detalles del Locker
        </h1>

        <div *ngIf="lockerDetails; else loading">
            <p class="text-gray-500 text-lg"><strong>ID:</strong> {{ lockerDetails.id_locker }}</p>
            <p class="text-gray-500 text-lg"><strong>Ubicación:</strong> {{ lockerDetails.street }}, {{ lockerDetails.city }}</p>
            <p class="text-gray-500 text-lg"><strong>Status:</strong>
              <span
                class="px-2 py-1 rounded-full font-bold"
                [ngClass]="{
                  'text-green-600': lockerDetails.status,
                  'text-red-600': !lockerDetails.status
                }"
              >
                {{ lockerDetails.status ? 'Activo' : 'Inactivo' }}
              </span>
            </p>

            <!-- Tabla de Envíos -->
            <h2 class="text-xl font-bold text-dagblue uppercase mt-6">Historial de Envíos</h2>
            <div class="bg-[#F0F0F0] rounded-lg shadow-md overflow-x-auto mt-4">
                <table class="w-full text-sm sm:text-base">
                    <thead class="uppercase bg-[#F0F0F0] text-dagblue font-bold">
                        <tr>
                            <th class="px-4 py-2 text-left">ID</th>
                            <th class="px-4 py-2 text-left">Fecha</th>
                            <th class="px-4 py-2 text-left">Estado</th>
                            <th class="px-4 py-2 text-left">Acciones</th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 text-gray-500">
                        <tr *ngFor="let envio of shipments" class="hover:bg-gray-50">
                            <td class="px-4 py-2">{{ envio._id }}</td>
                            <td class="px-4 py-2">{{ envio.date || 'Sin fecha' }}</td>
                            <td class="px-4 py-2">
                                <span
                                    class="px-2 py-1 rounded-full font-bold"
                                    [ngClass]="{
                                        'text-green-600': envio.status === 'Entregado',
                                        'text-yellow-600': envio.status === 'En tránsito',
                                        'text-red-600': envio.status === 'Pendiente'
                                    }"
                                >
                                    {{ envio.status }}
                                </span>
                            </td>
                            <td class="px-4 py-2">
                                <button
                                    (click)="viewDetails(envio._id)"
                                    class="w-full sm:w-auto bg-dagblue font-bold text-white px-4 py-2 rounded-full hover:bg-blue-900 transition"
                                >
                                    Ver
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <ng-template #loading>
            <p class="text-gray-500">Cargando detalles del locker...</p>
        </ng-template>
    </div>
</div>
