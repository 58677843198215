<div
  *ngIf="visible"
  class="fixed inset-0 bg-gray-900 bg-opacity-80 flex justify-center items-center z-50 backdrop-blur-sm"
>
  <div
    class="bg-white rounded-xl overflow-hidden shadow-2xl transform transition-all max-w-7xl w-full max-h-[90vh] overflow-y-auto"
  >
    <div class="container mx-auto p-6">
      <!-- Header con gradiente -->
      <div
        class="flex justify-between items-center mb-6 pb-4 border-b border-gray-200"
      >
        <h2
          class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-800 to-indigo-600"
        >
          Detalles del Envío
        </h2>
        <button
          (click)="cerrarModal()"
          class="text-gray-400 hover:text-gray-600 transition-colors focus:outline-none"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Filtros con mejor organización -->
      <div class="mb-6 bg-gray-50 p-4 rounded-lg shadow-sm">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <!-- Búsqueda -->
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
            >
              <svg
                class="w-5 h-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              placeholder="Buscar por nombre o proveedor..."
              class="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
            />
          </div>

          <!-- Fechas -->
          <div class="flex space-x-2">
            <div class="relative flex-1">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  class="w-5 h-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="date"
                placeholder="Desde"
                class="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
              />
            </div>
            <div class="relative flex-1">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  class="w-5 h-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="date"
                placeholder="Hasta"
                class="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
              />
            </div>
          </div>

          <!-- Estado y Acciones -->
          <div class="flex items-center space-x-2">
            <select
              class="flex-1 px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
            >
              <option value="Guia Generada">Guía generada</option>
              <option value="">Todos los estados</option>
              <option value="Cotizado">Cotizado</option>
              <option value="Cancelado">Cancelado</option>
            </select>
            <button
              class="flex items-center justify-center px-4 py-2.5 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-lg hover:from-green-600 hover:to-green-700 transition-all shadow-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffffff"
                viewBox="0 0 24 24"
                class="w-5 h-5 mr-2"
              >
                <path
                  d="M4.5 3.75A1.5 1.5 0 0 1 6 2.25h12a1.5 1.5 0 0 1 1.5 1.5v16.5a1.5 1.5 0 0 1-1.5 1.5H6a1.5 1.5 0 0 1-1.5-1.5V3.75z"
                />
                <path
                  fill="#217346"
                  d="M8.5 8.25h2.75L12.75 12l-1.5 3.75H8.5L10 12 8.5 8.25zm7 0h1.5v7.5H15.5V8.25z"
                />
              </svg>
              Exportar
            </button>
          </div>
        </div>
      </div>

<!-- Tabla con datos de ejemplo -->
<div class="overflow-x-auto rounded-lg shadow">
  <table class="w-full">
    <thead
      class="bg-gradient-to-r from-blue-700 to-indigo-800 text-white"
    >
      <tr>
        <th class="px-4 py-3 text-left">Paquetería</th>
        <th class="px-4 py-3 text-left">Licencia</th>
        <th class="px-4 py-3 text-left">Usuario</th>
        <th class="px-4 py-3 text-left">Fecha</th>
        <th class="px-4 py-3 text-left">Precio</th>
        <th class="px-4 py-3 text-left">Costo</th>
        <th class="px-4 py-2 text-left">Utilidad Global</th>
        <th class="px-4 py-2 text-left">Utilidad Dag</th>
        <th class="px-4 py-2 text-left">Utilidad Lic</th>
        <th class="px-4 py-3 text-left">Origen-Destino</th>
        <th class="px-4 py-3 text-left">Estado de Pago</th>
        <th class="px-4 py-3 text-center">Estado de Envio</th>
        <th class="px-4 py-3 text-center">Acciones</th>
      </tr>
    </thead>
    <tbody
      *ngFor="let shipment of shipments"
      class="divide-y divide-gray-200 bg-white"
    >
      <!-- Fila 1 -->
      <tr class="hover:bg-blue-50 transition-colors">
        <td class="px-4 py-3 uppercase font-medium">
          {{
            shipment.apiProvider === "Superenvios"
              ? "SE " + (shipment.provider || "No disponible")
              : (shipment.provider || "No disponible")
          }}
        </td>
        <td class="px-4 py-3">{{ shipment.user_id?.email || "No disponible" }}</td>
        <td class="px-4 py-3">
          <div class="flex items-center">
            <div
              class="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-800 font-bold mr-2"
            >
              {{
                getInitials(
                  shipment.sub_user_id?.name
                    ? shipment.sub_user_id.name
                    : shipment.user_id?.name || "No disponible"
                )
              }}
            </div>

            <div>
              <div class="font-medium">
                {{
                  shipment.sub_user_id?.name
                    ? shipment.sub_user_id.name
                    : shipment.user_id?.name || "No disponible"
                }}
              </div>
              <div class="text-xs text-gray-500">
                {{
                  shipment.sub_user_id?.email
                    ? shipment.sub_user_id.email
                    : shipment.user_id?.email || "No disponible"
                }}
              </div>
            </div>
          </div>
        </td>
        <td class="px-4 py-3">
          {{ shipment.createdAt ? (shipment.createdAt | date : "dd/MM/yyyy HH:mm:ss") : "No disponible" }}
        </td>
        <td class="px-4 py-3 font-medium">
          ${{ shipment.price?.$numberDecimal ? (shipment.price.$numberDecimal | number : "1.2-2") : "0.00" }}
        </td>
        <td class="px-4 py-3 font-medium">
          ${{ shipment.cost?.$numberDecimal ? (shipment.cost.$numberDecimal | number : "1.2-2") : "0.00" }}
        </td>
        <td class="px-4 py-3 font-medium">
          ${{
            shipment.dagpacket_profit?.$numberDecimal ? (shipment.dagpacket_profit.$numberDecimal | number : "1.2-2") : "0.00"
          }}
        </td>
        <td class="px-4 py-3 font-medium">
          ${{ shipment.utilitie_dag?.$numberDecimal ? (shipment.utilitie_dag.$numberDecimal | number : "1.2-2") : "0.00" }}
        </td>
        <td class="px-4 py-3 font-medium">
          ${{ shipment.utilitie_lic?.$numberDecimal ? (shipment.utilitie_lic.$numberDecimal | number : "1.2-2") : "0.00" }}
        </td>

        <td class="px-4 py-3">
          <div class="flex items-center">
            <span
              class="inline-block px-2 py-1 rounded-md bg-gray-100 text-xs mr-1"
              >{{ shipment.from?.city || "No disponible" }}</span
            >
            <svg
              class="h-4 w-4 text-gray-400 mx-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
            <span
              class="inline-block px-2 py-1 rounded-md bg-gray-100 text-xs"
              >{{ shipment.to?.city || "No disponible" }}</span
            >
          </div>
        </td>
        <td class="px-4 py-3 text-center">
          <span
            class="inline-flex px-2 py-1 text-xs font-medium rounded-full bg-green-100 text-green-800"
          >
            {{ shipment.payment?.status || "No disponible" }}
          </span>
        </td>
        <td class="px-4 py-3 text-center">
          <span
            class="inline-flex px-2 py-1 text-xs font-medium rounded-full bg-green-100 text-green-800"
          >
            {{ shipment.status || "No disponible" }}
          </span>
        </td>
        <td class="px-4 py-3">
          <div class="flex space-x-1 justify-center">
            <button
              class="p-1.5 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors shadow-sm"
            >
              <svg
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </button>
            <button
              class="p-1.5 rounded-lg bg-emerald-600 text-white hover:bg-emerald-700 transition-colors shadow-sm"
            >
              <svg
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

      <!-- Paginación mejorada -->
      <div class="flex justify-between items-center mt-6">
        <div class="text-sm text-gray-600">
          Mostrando <span class="font-medium">1-3</span> de
          <span class="font-medium">24</span> envíos
        </div>
        <div class="flex items-center space-x-2">
          <button
            class="flex items-center px-3 py-2 rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            <svg
              class="h-5 w-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            Anterior
          </button>
          <div class="flex space-x-1">
            <button
              *ngFor="let page of getPages(); let i = index"
              (click)="selectPage(i + 1)"
              [class.bg-blue-600]="currentPage === i + 1"
              [class.text-white]="currentPage === i + 1"
              [class.text-black]="currentPage !== i + 1"
              [class.bg-gray-200]="currentPage !== i + 1"
              class="w-9 h-9 rounded-lg  text-black flex items-center justify-center font-medium transition-colors hover:bg-blue-500 hover:text-white"
            >
              {{ i + 1 }}
            </button>
          </div>

          <button
            class="flex items-center px-3 py-2 rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 transition-all"
          >
            Siguiente
            <svg
              class="h-5 w-5 ml-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
