<app-sidebar class="hidden sm:block"></app-sidebar>

<!-- Filtros con mejor organización -->

<div class="sm:ml-64 mt-14 min-h-screen p-6 bg-gray-50">
  <!-- Filtros con mejor organización -->
  <div class="mb-6 bg-gray-50 p-4 rounded-lg shadow-sm">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <!-- Búsqueda -->
      <div class="relative">
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <svg
            class="w-5 h-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          placeholder="Buscar por nombre o proveedor..."
          class="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
        />
      </div>

      <!-- Fechas -->
      <div class="flex space-x-2">
        <div class="relative flex-1">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          >
            <svg
              class="w-5 h-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="date"
            placeholder="Desde"
            class="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
          />
        </div>
        <div class="relative flex-1">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          >
            <svg
              class="w-5 h-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="date"
            placeholder="Hasta"
            class="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
          />
        </div>
      </div>

      <!-- Estado y Acciones -->
      <div class="flex items-center space-x-2">
        <select
          class="flex-1 px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
        >
          <option value="Guia Generada">Guía generada</option>
          <option value="">Todos los estados</option>
          <option value="Cotizado">Cotizado</option>
          <option value="Cancelado">Cancelado</option>
        </select>
        <button
          class="flex items-center justify-center px-4 py-2.5 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-lg hover:from-green-600 hover:to-green-700 transition-all shadow-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
            viewBox="0 0 24 24"
            class="w-5 h-5 mr-2"
          >
            <path
              d="M4.5 3.75A1.5 1.5 0 0 1 6 2.25h12a1.5 1.5 0 0 1 1.5 1.5v16.5a1.5 1.5 0 0 1-1.5 1.5H6a1.5 1.5 0 0 1-1.5-1.5V3.75z"
            />
            <path
              fill="#217346"
              d="M8.5 8.25h2.75L12.75 12l-1.5 3.75H8.5L10 12 8.5 8.25zm7 0h1.5v7.5H15.5V8.25z"
            />
          </svg>
          Exportar
        </button>
      </div>
    </div>
  </div>

  <!-- Tabla con datos de ejemplo -->
  <div class="overflow-x-auto rounded-lg shadow">
    <table class="w-full">
      <thead class="bg-gradient-to-r from-blue-700 to-indigo-800 text-white">
        <tr>
          <th class="px-4 py-3 text-left">Fecha</th>
          <th class="px-4 py-3 text-left">Num.Referencia</th>
          <th class="px-4 py-3 text-left">Num.Autorizacion</th>

          <th class="px-4 py-3 text-left">Servicio</th>
          <th class="px-4 py-3 text-left">Detalles</th>
          <th class="px-4 py-3 text-left">Licencia</th>
          <th class="px-4 py-3 text-left">Usuario</th>
          <th class="px-4 py-3 text-center">Metodo de Pago</th>
          <th class="px-4 py-3 text-center">Monto</th>
          <th class="px-4 py-3 text-center">Acciones</th>

        </tr>
      </thead>
      <tbody
        *ngFor="let transaction of transactions"
        class="divide-y divide-gray-200 bg-white"
      >
        <tr class="hover:bg-blue-50 transition-colors">
          <td class="px-4 py-3">
            {{
              transaction.createdAt
                ? (transaction.createdAt | date : "dd/MM/yyyy HH:mm:ss")
                : "No disponible"
            }}
          </td>
          <td class="px-4 py-3">
            {{ transaction.reference_number || "No disponible" }}
          </td>
          <td class="px-4 py-3">
            {{ transaction.emida_code || "No disponible" }}
          </td>
          <td class="px-4 py-3">
            {{ (transaction ? transaction.service  + (" Detalles: " +transaction.emida_details  || "") : "No disponible") }}
          </td>
          <td class="px-4 py-3">
            {{ transaction.details || "No disponible" }}
          </td>
          <td class="px-4 py-3">
            {{ transaction.user_id?.email || "No disponible" }}
          </td>
          <td class="px-4 py-3">
            <div class="flex items-center">
              <div
                class="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-800 font-bold mr-2"
              >
                {{
                getInitials(
                  transaction.sub_user_id?.name
                    ? transaction.sub_user_id.name
                    : transaction.user_id?.name || "No disponible"
                )
              }}
              </div>

              <div>
                <div class="font-medium">
                  {{
                    transaction.sub_user_id?.name
                      ? transaction.sub_user_id.name
                      : transaction.user_id?.name || "No disponible"
                  }}
                </div>
                <div class="text-xs text-gray-500">
                  {{
                    transaction.sub_user_id?.email
                      ? transaction.sub_user_id.email
                      : transaction.user_id?.email || "No disponible"
                  }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-4 py-3">
            {{ transaction.payment_method || "No disponible" }}
          </td>

          <td class="px-4 py-3">
            {{ transaction.amount.$numberDecimal || "No disponible" }}
          </td>

          <td class="px-4 py-3">
            <div class="flex space-x-1 justify-center">
              <button
                class="p-1.5 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors shadow-sm"
              >
                <svg
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </button>
              <button
                class="p-1.5 rounded-lg bg-emerald-600 text-white hover:bg-emerald-700 transition-colors shadow-sm"
              >
                <svg
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

<!-- Paginación responsiva -->
<div class="flex flex-col sm:flex-row justify-between items-center mt-6 gap-4">
  <!-- Información de resultados - se apila en móvil, se muestra en línea en pantallas más grandes -->
  <div class="text-sm text-gray-600 text-center sm:text-left w-full sm:w-auto">
    Mostrando <span class="font-medium">{{ (currentPage - 1) * limit + 1 }}-{{ 
      (currentPage * limit) > totalItems ? totalItems : (currentPage * limit) 
    }}</span> de
    <span class="font-medium">{{ totalItems }}</span> envíos
  </div>
  
  <!-- Controles de paginación - adaptable a diferentes tamaños de pantalla -->
  <div class="flex items-center justify-center sm:justify-end w-full sm:w-auto">
    <!-- Botón anterior - Se oculta texto en móvil, solo muestra ícono -->
    <button
      [disabled]="currentPage === 1"
      (click)="prevPage()"
      class="flex items-center px-2 sm:px-3 py-2 rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
    >
      <svg
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
      <span class="hidden sm:inline ml-1">Anterior</span>
    </button>
    
    <!-- Números de página - Se ocultan algunos en pantallas pequeñas -->
    <div class="flex space-x-1 mx-1 sm:mx-2">
      <!-- Mostrar primera página si no está en el rango visible actual -->
      <button
        *ngIf="currentPage > 3"
        (click)="selectPage(1)"
        class="w-8 h-8 sm:w-9 sm:h-9 rounded-lg bg-gray-200 flex items-center justify-center font-medium transition-colors hover:bg-blue-500 hover:text-white"
      >
        1
      </button>
      
      <!-- Indicador de elipsis si hay páginas ocultas -->
      <span
        *ngIf="currentPage > 3"
        class="w-8 h-8 sm:w-9 sm:h-9 flex items-center justify-center text-gray-500"
      >
        ...
      </span>
      
      <!-- Páginas cercanas a la actual (limitadas para pantallas pequeñas) -->
      <button
        *ngFor="let page of getVisiblePages()"
        (click)="selectPage(page)"
        [class.bg-blue-600]="currentPage === page"
        [class.text-white]="currentPage === page"
        [class.text-black]="currentPage !== page"
        [class.bg-gray-200]="currentPage !== page"
        class="w-8 h-8 sm:w-9 sm:h-9 rounded-lg flex items-center justify-center font-medium transition-colors hover:bg-blue-500 hover:text-white"
      >
        {{ page }}
      </button>
      
      <!-- Indicador de elipsis si hay páginas ocultas al final -->
      <span
        *ngIf="currentPage < totalPage - 2"
        class="w-8 h-8 sm:w-9 sm:h-9 flex items-center justify-center text-gray-500"
      >
        ...
      </span>
      
      <!-- Mostrar última página si no está en el rango visible actual -->
      <button
        *ngIf="currentPage < totalPage - 2"
        (click)="selectPage(totalPage)"
        class="w-8 h-8 sm:w-9 sm:h-9 rounded-lg bg-gray-200 flex items-center justify-center font-medium transition-colors hover:bg-blue-500 hover:text-white"
      >
        {{ totalPage }}
      </button>
    </div>
    
    <!-- Botón siguiente - Se oculta texto en móvil, solo muestra ícono -->
    <button
      [disabled]="currentPage === totalPage"
      (click)="nextPage()"
      class="flex items-center px-2 sm:px-3 py-2 rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
    >
      <span class="hidden sm:inline mr-1">Siguiente</span>
      <svg
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
  </div>
</div>
</div>
