import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { CuponInterface } from 'src/app/_models/cupon';
interface CuponResponse {
  success: boolean;
  data: any[];
}

@Injectable({
  providedIn: 'root'
})


export class CuponService {

  constructor(private http: HttpClient) { }


  getAllCupon(page: number, limit: number): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());


    return this.http.get(`${environment.apiUrl}/cupon/all`, { params });
  }


  createCupon(cupon: CuponInterface): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cupon/create`, cupon);
  }

 
  searchCupon(cupon: string): Observable<any> {
    return this.http.get<CuponResponse>(`${environment.apiUrl}/cupon/code/${cupon}`);
  }


  getCuponsByUser(userId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cupon/user/${userId}`).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('Error en la petición:', error);
    return throwError(() => new Error('Error en la solicitud, intenta de nuevo más tarde.'));
  }
  



}
