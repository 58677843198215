// cash-register.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CashRegisterService {
  constructor(private http: HttpClient) {}

  getAllCashRegisters(page: number, limit: number, startDate?: string, endDate?: string): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());

    if (startDate) params = params.set('startDate', startDate);
    if (endDate) params = params.set('endDate', endDate);

    return this.http.get(`${environment.apiUrl}/cash-register/all`, { params });
  }
  //licensee
  getAllCashRegistersByLicense(page: number, limit: number,user_id:string, startDate?: string, endDate?: string): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('parentUser', user_id.toString());

    if (startDate) params = params.set('startDate', startDate);
    if (endDate) params = params.set('endDate', endDate);

    return this.http.get(`${environment.apiUrl}/cash-register/parentUser`, { params });
  }

  getCashTransactionByParent(page: number, limit: number,user_id:string, startDate?: string, endDate?: string): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('parentUser', user_id.toString());

    if (startDate) params = params.set('startDate', startDate);
    if (endDate) params = params.set('endDate', endDate);

    return this.http.get(`${environment.apiUrl}/cash-register/transactionByUser`, { params });
  }

  openCashRegister(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cash-register/open`, {})
  }

  closeCashRegister(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cash-register/close`, {}).pipe(
      catchError(this.handleError)
    );
  }

  getCurrentCashRegister(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cash-register/current`).pipe(
      catchError(this.handleError)
    );
  }

  getCashTransactions(page: number = 1, limit: number = 10): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());

    return this.http.get(`${environment.apiUrl}/cash-register/transactions`, { params }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Ocurrió un error desconocido';
    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // El backend retornó un código de error
      errorMessage = error.error.message || `Código de error: ${error.status}, mensaje: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}