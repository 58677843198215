import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';	
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http:HttpClient) {}

  // Servicio para registrar un nuevo rol
  register(role: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/roles/create`, role);
  }

  // Servicio para obtener todos los roles
  listRoles(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/roles/roles`);
  }

  //Obtener un rol por ID
  getRole(roleId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/roles/role/${roleId}`);
  }

  // Servicio para cambiar los permisos de un rol
  updateRolePermissions(roleId: string, permissions: string[]): Observable<any> {
    return this.http.put(`${environment.apiUrl}/roles/add-permission/${roleId}`, { permissions });
  }


  getRoleByName(roleName: string): Observable<string> {
    return this.http.get<string>(`${environment.apiUrl}/roles/role-name/${roleName}`);
  }

}
