<div class="h-screen w-screen flex items-center justify-center bg-black">
  <div class="relative w-full max-w-8xl h-full overflow-hidden bg-black rounded-none shadow-lg">
    <!-- Imagen Actual -->
    <div class="absolute inset-0 flex items-center justify-center">
      <img
        [src]="currentImage?.url"
        [alt]="currentImage?.alt"
        class="object-cover w-full h-full"
      />
    </div>

    <!-- Información superpuesta -->
    <div class="absolute bottom-0 w-full bg-gradient-to-t from-black/80 to-transparent text-white px-6 py-4">
      <h3 class="font-bold text-2xl">
        {{ advertisementData[currentImageIndex]?.enterprise }}
      </h3>
      <p class="text-lg">
        {{ advertisementData[currentImageIndex]?.description }}
      </p>
    </div>

    <!-- Indicadores -->
<!-- Indicadores de Advertisement y sus imágenes -->
<div class="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-4">
  <!-- Indicadores de Advertisement -->
  <div class="flex space-x-3">
    <button
      *ngFor="let ad of advertisementData; let i = index"
      (click)="goToSlide(i)"
      [ngClass]="{
        'w-4 h-4 rounded-full transition-all duration-300': true,
        'bg-orange-500 opacity-100 scale-125': currentAdIndex === i,
        'bg-gray-400 opacity-50': currentAdIndex !== i
      }"
    ></button>
  </div>
  
  <!-- Indicadores de imágenes del Advertisement actual -->
  <div class="flex space-x-2">
    <button
      *ngFor="let image of advertisementData[currentAdIndex]?.images; let i = index"
      [ngClass]="{
        'w-2 h-2 rounded-full transition-all duration-300': true,
        'bg-white opacity-100': currentImageIndex === i,
        'bg-gray-400 opacity-50': currentImageIndex !== i
      }"
    ></button>
  </div>
</div>

    <!-- Botones de Navegación -->
    <button
      class="absolute top-1/2 left-4 transform -translate-y-1/2 bg-orange-500 text-white rounded-full p-3 shadow-lg hover:bg-orange-600 focus:outline-none"
      (click)="prevSlide()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </button>
    <button
      class="absolute top-1/2 right-4 transform -translate-y-1/2 bg-orange-500 text-white rounded-full p-3 shadow-lg hover:bg-orange-600 focus:outline-none"
      (click)="nextSlide()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
  </div>
</div>
