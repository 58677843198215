import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LockersService } from 'src/app/_services/Lockers/lockers.service';

@Component({
  selector: 'app-locker-details',
  templateUrl: './locker-details.component.html',
  styleUrls: ['./locker-details.component.scss']
})
export class LockerDetailsComponent implements OnInit {
  lockerId: string = '';
  lockerDetails: any = null;
  shipments: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private lockersService: LockersService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.lockerId = params.get('id') || '';
      if (this.lockerId) {
        this.fetchLockerDetails();
        this.fetchShipments();
      }
    });
  }

  fetchLockerDetails(): void {
    this.lockersService.getLocker(this.lockerId).subscribe(
      (response: any) => {
        this.lockerDetails = response.message;
      },
      (error: any) => {
        console.error('Error al obtener detalles del locker:', error);
      }
    );
  }

  fetchShipments(): void {
    this.lockersService.getShipmentsByLocker(this.lockerId).subscribe(
      (response: any) => {
        if (response.success) {
          this.shipments = response.message;
          console.log('Envíos:', this.shipments); 
        } else {
          console.error('Error al obtener envíos:', response.message);
        }
      },
      (error: any) => {
        console.error('Error en la petición al backend:', error);
      }
    );
  }

  viewDetails(shipmentId: string): void {
    console.log('Navegando a detalles del envío:', shipmentId);
    this.router.navigate(['/shipment-details', shipmentId]);
  }
}
