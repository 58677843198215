import { Component, OnInit } from '@angular/core';
import { ChartData } from 'chart.js/auto';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';
import { CancellationService } from 'src/app/_services/cancellations/cancellation.service';
import { AddressService } from 'src/app/_services/Address/address.service';
import { CustomerService } from 'src/app/_services/Customer/customer.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { Router } from '@angular/router';
import { CuponInterface } from 'src/app/_models/cupon';
import { CuponService } from 'src/app/_services/cupon/cupon.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.scss'],
})
export class AdministracionComponent implements OnInit {
  reportes = [
    {
      folio: '4026',
      usuario: 'gtoleon-001 Fabian Gonzalez Villanueva',
      usuarioImagen: 'ruta_a_la_imagen_del_usuario',
      cajero: 'gtoleon-001 Fabian Gonzalez Villanueva',
      fechaPago: '23 July 2024',
      fechaCreacion: '23 Jul 2024 09:21',
      fechaAutorizacion: '-',
      monto: 410,
      estado: 'Pendiente',
    },
  ];

  userid: any;
  totalPagesTransaction: number = 0;
  showRembolsoModal: boolean = false;
  userForCupon: any = null;
  showTransactionsModal = false;
  selectedUser: any = null;
  transactions: any[] = [];
  showDownloadForm = false;
  showUserModal = false;
  showCancellationModal = false;
  showReporteModal = false;
  showPedidoModal = false;
  showEmpaques = false;
  showPagoS = false;
  showPagoR = false;
  showRepartoM = false;
  showCupones = false;
  showCuponConfigModal: boolean = false;
  showCrearCuponModal: boolean = false;
  showDirecciones = false;
  showPMercado = false;
  showRecotizciones = false;
  selectedDate: string = '';
  users: any[] = [];
  shipments: any[] = [];
  cancellations: any[] = [];
  filteredPackages: any[] = [];
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10;
  totalCancellations: number = 0;
  showEnviosModal: boolean = false;
  totalShipments: number = 0;
  quincenalProfit: number = 0;

  hasNextPage: boolean = false;
  hasPreviousPage: boolean = false;

  EmpaquequincenalProfit = {
    packingProfit: 0, // Valor inicial
  };

  profitData: any;
  errorMessage: string | null = null;

  cupons: any[] = [];

  chartData: ChartData<'bar'> = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
    datasets: [
      {
        label: 'Series A',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Series B',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  showDetallesModal: boolean = false;
  reporteSeleccionado: any = null;

  globalProfit: any;
  shipmentDetails: any;

  addresses: any[] = [];
  totalDirecciones: number = 0;
  totalUsers: number = 0;

  searchTerm: string = '';
  filteredUsers: any[] = [];

  empaques: any[] = [];
  recargas: any[] = [];
  servicios: any[] = [];

  currentPageTransaction: number = 1;
  //Paginador de envios
  currentPageShipment: number = 1;
  totalPageShipment: number = 0;
  filterName: string = '';
  filterDateFrom: string = '';
  filterDateTo: string = '';
  filterStatus: string = '';


  constructor(
    private authService: AuthLoginService,
    private userService: UserServiceService,
    private shipmentsService: ShipmentsService,
    private cancellationService: CancellationService,
    private addressService: AddressService,
    private refillRequestService: RefillRequestService,
    private customerService: CustomerService,
    private router: Router,
    private transactionService: TransactionService,
    private cuponService: CuponService
  ) {
    this.userid = this.authService.getId();
  }

  cuponData: CuponInterface = {
    user_id: this.userForCupon?._id || '',
    code: '',
    type: 'Cupon Dagpacket',
    type_value: 'Porcentaje',
    value: 0,
    quantity: 0,
    is_unlimited: false,
    start_date: new Date(),
    end_date: new Date(),
    status: true,
    description: '',
  };
  //carga al iniciar
  ngOnInit(): void {
    this.loadGlobalProfit();
    this.loadShipments(1, 10, 'date', 'asc');
    this.loadCancellations(
      this.currentPage,
      this.itemsPerPage,
      'requested_at',
      'desc',
      ''
    );
    this.updateChartData();
    this.loadAddresses();
    this.loadTotalDirecciones();
    this.loadAllUsers();
    this.loadTotalUsers();
    this.fetchQuincenalProfit();
    this.fetchTransactionsByType();
    this.hasNextPage = this.currentPageTransaction < this.totalPagesTransaction;
    const userId = this.authService.getId(); // Obtén el ID del usuario desde el almacenamiento local
    if (userId) {
      // Verifica si userId no es null
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const quincena = 1;

      this.loadQuincenalProfit(userId, year, month, quincena); // Llama a la función
    } else {
      console.error('No se encontró el ID de usuario en localStorage');
    }
  }

  loadGlobalProfit(): void {
    this.shipmentsService.getGlobalProfit().subscribe(
      (data) => {
        this.globalProfit = data.message.monthlyProfit.totalProfit;
        console.log('Utilidad global:', this.globalProfit);
      },
      (error) => {
        console.error('Error al cargar los datos de ganancias:', error);
      }
    );
  }
  applyFilters(): void {
    this.currentPageShipment = 1; // Reset to first page when applying filters
    this.loadShipmentsModal(
      1, 
      10, 
      'date', 
      'asc', 
      this.filterName, 
      this.filterStatus, 
      this.filterDateFrom, 
      this.filterDateTo
    );
  }
  loadShipments(
    page: number,
    limit: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    searchBy: string = 'name'
    
  ): void {
    this.shipmentsService
      .getAllShipments(page, limit, sortBy, sortOrder, searchBy)
      .subscribe(
        (data) => {
          console.log('Datos de envíos pagados:', data);
          this.totalShipments = data.data.totalShipments;
        },
        (error) => {
          console.error('Error al cargar los envíos pagados:', error);
        }
      );
  }

  

  loadShipmentsModal(
    page: number,
    limit: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    searchName?: string,
    status?: string,
    dateFrom?: string,
    dateTo?: string
  ): void {
    this.shipmentsService
      .getAllShipments(
        page, 
        limit, 
        sortBy, 
        sortOrder, 
        searchName, 
        status, 
        dateFrom, 
        dateTo
      )
      .subscribe(
        (data) => {
          console.log('Datos de envíos:', data);
          this.shipments = data.data.shipments;
          this.totalShipments = data.data.totalShipments;
          this.totalPageShipment = data.data.totalPages;
        },
        (error) => {
          console.error('Error al cargar los envíos:', error);
        }
      );
  }


  //direcciones
  loadAddresses(): void {
    this.addressService.getAddresses().subscribe(
      (response: any) => {
        console.log('Respuesta de getAddresses:', response); // Verifica la estructura de la respuesta
        this.addresses = response.data || response; // Ajusta aquí según corresponda
      },
      (error: any) => {
        console.error('Error al cargar las direcciones:', error);
      }
    );
  }

  loadTotalDirecciones(): void {
    this.addressService.getAddresses(10000).subscribe(
      (response: any) => {
        this.totalDirecciones = response.data.length; // Usa la longitud de data
        console.log('Total de direcciones:', this.totalDirecciones);
      },
      (error: any) => {
        console.error('Error al obtener el total de direcciones:', error);
      }
    );
  }

  openTransactionsModal(user: any): void {
    this.selectedUser = user;
    this.showTransactionsModal = true;
    this.loadUserTransactions(user.id);
  }

  loadUserTransactions(userId: number): void {
    this.transactions = [
      { date: '2023-01-01', amount: 100, description: 'Transaction 1' },
      { date: '2023-02-01', amount: 200, description: 'Transaction 2' },
    ];
  }

  closeTransactionsModal(): void {
    this.showTransactionsModal = false;
    this.selectedUser = null;
    this.transactions = [];
  }

  onApply() {
    if (this.selectedDate) {
      this.updateChartData();
    }
  }

  onDateChange(event: any) {
    this.selectedDate = event.target.value;
  }

  toggleDownloadForm() {
    this.showDownloadForm = !this.showDownloadForm;
  }

  updateChartData() {
    this.chartData = {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
      datasets: [
        {
          label: 'Series A',
          data: [65, 59, 80, 81, 56, 55, 40],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
        {
          label: 'Series B',
          data: [28, 48, 40, 19, 86, 27, 90],
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
        },
      ],
    };
  }

  //usuarios

  openUserModal() {
    this.showUserModal = true;
    this.loadAllUsers(); // Cargar usuarios al abrir el modal
  }

  closeUserModal() {
    this.showUserModal = false;
  }

  // Cargar todos los usuarios y mostrarlos al abrir el modal
  loadAllUsers(): void {
    this.userService.listUsers(1, 10000).subscribe(
      (response: any) => {
        this.users = response.data.users;
        this.filteredUsers = [...this.users]; // Mostrar todos los usuarios inicialmente
        console.log('Usuarios obtenidos:', this.users);
      },
      (error: any) => {
        console.error('Error al obtener usuarios:', error);
      }
    );
  }

  // Método para buscar en la lista de usuarios
  onSearch(): void {
    const term = this.searchTerm.toLowerCase();
    if (!term) {
      // Si no hay término de búsqueda, mostrar todos los usuarios
      this.filteredUsers = [...this.users];
    } else {
      // Filtrar la lista de usuarios según el término de búsqueda
      this.filteredUsers = this.users.filter(
        (user) =>
          user.name.toLowerCase().includes(term) ||
          user.email.toLowerCase().includes(term) ||
          user.role.toLowerCase().includes(term)
      );
    }
  }

  loadTotalUsers(): void {
    this.userService.listUsers(1, 10000).subscribe(
      (response: any) => {
        console.log('Usuarios obtenidos:', response);
        if (
          response &&
          response.data &&
          response.data.users &&
          Array.isArray(response.data.users)
        ) {
          this.totalUsers = response.data.users.length;
        } else {
          this.totalUsers = 0;
          console.warn(
            'No se encontró la lista de usuarios o la estructura es incorrecta.'
          );
        }
        console.log('Total de usuarios:', this.totalUsers);
      },
      (error: any) => {
        console.error('Error al obtener el total de usuarios:', error);
      }
    );
  }

  openReporteModal() {
    this.showReporteModal = true;
  }

  openRembolsoModal(): void {
    this.showRembolsoModal = true;
  }

  openPedidoModal() {
    this.showPedidoModal = true;
  }

  closeEnviosModal(): void {
    this.showEnviosModal = false;
  }

  openEnviosModal(): void {
    this.showEnviosModal = true;
    this.loadShipmentsModal(1, 10, 'date', 'asc');
  }

  loadShipmentDetails(shipmentId: string): void {
    this.shipmentsService.getShipmentDetails(shipmentId).subscribe(
      (data) => {
        console.log('Datos de cancelaciones:', data);
        this.shipmentDetails = data;
      },
      (error) => {
        console.error('Error al cargar los detalles del envío:', error);
      }
    );
  }

  // utilidad quincenal de los envios y de los empaques

  loadQuincenalProfit(
    userId: string,
    year: number,
    month: number,
    quincena: 1 | 2
  ): void {
    this.shipmentsService
      .getQuincenalProfit(userId, year, month, quincena)
      .subscribe(
        (response) => {
          if (response && response.data) {
            // Verificar y asignar shipmentProfit
            if (response.data.shipmentProfit) {
              const shipmentProfit = response.data.shipmentProfit as any;
              this.quincenalProfit = parseFloat(shipmentProfit.$numberDecimal);
            } else {
              console.warn(
                'No se recibió shipmentProfit en el formato esperado'
              );
            }

            // Verificar y asignar packingProfit
            if (response.data.packingProfit) {
              const packingProfit = response.data.packingProfit as any;
              this.EmpaquequincenalProfit.packingProfit = parseFloat(
                packingProfit.$numberDecimal
              );
              console.log(
                'Packing Profit:',
                this.EmpaquequincenalProfit.packingProfit
              );
            } else {
              console.warn(
                'No se recibió packingProfit en el formato esperado'
              );
            }
          } else {
            console.warn('No se recibió respuesta válida del servidor.');
          }
        },
        (error) => {
          console.error('Error al obtener la utilidad quincenal:', error);
        }
      );
  }

  openCancellationModal(): void {
    this.showCancellationModal = true;
    this.loadCancellations(
      this.currentPage,
      this.itemsPerPage,
      'requested_at',
      'desc',
      ''
    );
  }

  openEmpaquesModal() {
    this.showEmpaques = true;
  }

  openPagoSModal() {
    this.showPagoS = true;
  }

  openPagoRModal() {
    this.showPagoR = true;
  }

  openRepartoMModal() {
    this.showRepartoM = true;
  }

  openCuponesModal() {
    this.showCupones = true;
    this.fetchCupons();
  }
  openCrearCuponModal() {
    this.showCrearCuponModal = true;
    this.loadAllUsers();
  }

  openDireccionesModal() {
    this.showDirecciones = true;
  }

  openRecotizcionesModal() {
    this.showRecotizciones = true;
  }

  closeCancellationModal() {
    this.showCancellationModal = false;
  }

  closeReporteModal() {
    this.showReporteModal = false;
  }

  closePedidoModal() {
    this.showPedidoModal = false;
  }

  closeEmpaquesModal() {
    this.showEmpaques = false;
  }

  closeRembolsoModal(): void {
    this.showRembolsoModal = false;
  }

  closePagosSModal() {
    this.showPagoS = false;
  }

  closePagoRModal() {
    this.showPagoR = false;
  }

  closeRepartoMModal() {
    this.showRepartoM = false;
  }

  closeCuponesModal() {
    this.showCupones = false;
  }
  closeCrearCuponModal() {
    this.showCrearCuponModal = false;
  }

  closeCuponConfigModal(): void {
    this.showCuponConfigModal = false;
    this.userForCupon = null; // Restablece la variable si es necesario
  }

  closeDireccionesModal() {
    this.showDirecciones = false;
  }

  closeRecotizcionesModal() {
    this.showRecotizciones = false;
  }

  verDetalles(solicitud: any): void {
    this.showDetallesModal = true;
  }

  aceptarSolicitud(solicitud: any): void {
    console.log('Aceptar solicitud:', solicitud);
  }

  rechazarSolicitud(solicitud: any): void {
    const motivoRechazo = prompt('Ingrese el motivo de rechazo:');
    if (motivoRechazo) {
      console.log('Rechazar solicitud:', solicitud, 'Motivo:', motivoRechazo);
    }
  }

  closeDetallesModal(): void {
    this.showDetallesModal = false;
  }

  aplicarReporte(reporte: any): void {
    console.log('Aplicar reporte:', reporte);
  }

  rechazarReporte(reporte: any): void {
    const motivoRechazo = prompt('Ingrese el motivo de rechazo del reporte:');
    if (motivoRechazo) {
      console.log('Rechazar reporte:', reporte, 'Motivo:', motivoRechazo);
    }
  }

  loadCancellations(
    page: number = 1,
    limit: number = 10,
    sortBy: string = 'requested_at',
    sortOrder: 'desc',
    search: string = ''
  ): void {
    this.cancellationService
      .getAllCancellations(page, limit, sortBy, sortOrder, search)
      .subscribe(
        (data) => {
          this.cancellations = data.data.cancellations;
          this.totalCancellations = data.data.totalCancellations;
          this.totalPages = data.totalPages;
        },
        (error) => {
          console.error('Error al cargar los envíos:', error);
        }
      );
  }

  fetchQuincenalProfit(): void {
    const userId = this.authService.getId();
    if (!userId) {
      console.error('No se encontró el ID del usuario.');
      return;
    }

    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const quincena = 1;

    this.transactionService
      .getQuincenalProfit(userId, year, month, quincena)
      .subscribe({
        next: (data) => {
          console.log('Utilidad quincenal:', data);
          if (data?.message?.length > 0) {
            const firstMessage = data.message[0];
            this.profitData = parseFloat(firstMessage.total.$numberDecimal);
          } else {
            console.warn('No se encontró información de utilidad quincenal.');
            this.profitData = 0;
          }
        },
        error: (error) => {
          console.error('Error al obtener la utilidad quincenal:', error);
          this.profitData = 0;
        },
      });
  }

  fetchTransactionsByType(): void {
    const userId = this.userid; // Asegúrate de que `userid` esté definido en tu componente.
    if (!userId) {
      console.error('No se encontró el ID del usuario.');
      return;
    }

    const types = ['empaque', 'recarga', 'servicio', 'envio']; // Tipos de transacciones

    types.forEach((type) => {
      console.log(
        `Cargando datos para el tipo "${type}" y usuario "${userId}" en página ${this.currentPageTransaction}`
      );

      this.transactionService
        .getTransactionsByTypeAndUser(
          type,
          userId,
          this.currentPageTransaction,
          this.itemsPerPage
        )
        .subscribe(
          (response) => {
            if (response.success) {
              const transactions = response.message.transactions || [];

              // Procesar datos para "envio"
              if (type === 'envio') {
                this.filteredPackages = transactions.flatMap(
                  (transaction: any) => {
                    return (transaction.shipment_ids || []).map(
                      (shipment: any) => {
                        console.log('Shipment data:', shipment); // Verifica los datos de cada envío
                        return {
                          provider: shipment.provider || 'Sin proveedor',
                          licensee: transaction.user_id?.name || 'Sin licencia',
                          user:
                            shipment.sub_user_id?.name ||
                            transaction.user_id?.name ||
                            'Sin usuario',
                          distribution_at: shipment.distribution_at || null,
                          price: shipment.price?.$numberDecimal || '0.00',
                          cost: shipment.cost?.$numberDecimal || '0.00',
                          globalProfit:
                            shipment.dagpacket_profit?.$numberDecimal || '0.00',
                          userProfit:
                            shipment.utilitie_lic?.$numberDecimal || '0.00',
                          dagpacketProfit:
                            shipment.utilitie_dag?.$numberDecimal || '0.00',
                          origin: shipment.from?.city || 'Sin origen',
                          destination: shipment.to?.city || 'Sin destino',
                          paymentStatus:
                            shipment.payment?.status ||
                            'Estado de pago desconocido',
                          shipmentStatus:
                            shipment.status || 'Estado no especificado',
                          id: shipment._id || 'Sin ID',
                        };
                      }
                    );
                  }
                );
                console.log('Filtered Packages:', this.filteredPackages); // Verifica los paquetes procesados
              }
              // Procesar otros tipos (empaque, recarga, servicio)
              switch (type) {
                case 'empaque':
                  this.empaques = transactions;
                  console.log('Empaques asignados:', this.empaques);
                  break;
                case 'recarga':
                  this.recargas = transactions;
                  console.log('Recargas asignadas:', this.recargas);
                  break;
                case 'servicio':
                  this.servicios = transactions;
                  console.log('Servicios asignados:', this.servicios);
                  break;
              }

              // Actualizar paginación
              this.totalPagesTransaction = response.message.totalPages || 0;
              this.hasNextPage =
                this.currentPageTransaction < this.totalPagesTransaction;
              this.hasPreviousPage = this.currentPageTransaction > 1;
            } else {
              console.error(
                `Error al cargar datos para el tipo "${type}":`,
                response.message
              );
            }
          },
          (error) => {
            console.error(
              `Error al obtener datos para el tipo "${type}":`,
              error
            );
          }
        );
    });
  }

  viewDetails(shipmentId: string) {
    if (!shipmentId) {
      console.error('El ID del envío no es válido:', shipmentId);
      return;
    }
    console.log('Navigating to details for shipment:', shipmentId);
    this.router.navigateByUrl(`/shipment-details/${shipmentId}`);
  }

  formatProfit(profit: any): string {
    if (typeof profit === 'number') {
      return `$${profit.toFixed(2)}`;
    } else if (profit && profit.$numberDecimal) {
      return `$${parseFloat(profit.$numberDecimal).toFixed(2)}`;
    }
    return '$0.00';
  }

  //paginador
  nextPage(): void {
    if (this.hasNextPage) {
      this.currentPageTransaction++;
      this.fetchTransactionsByType();
    }
  }

  previousPage(): void {
    if (this.hasPreviousPage) {
      this.currentPageTransaction--;
      this.fetchTransactionsByType();
    }
  }

  goToPage(page: number): void {
    this.currentPageTransaction = page;
    this.fetchTransactionsByType();
  }

  goToPageShipment(page: number): void {
    this.currentPageShipment = page;
    
    // Mantener los filtros actuales al cambiar de página
    this.loadShipmentsModal(
      page, 
      10, 
      'distribution_at', 
      'desc', 
      this.filterName, 
      this.filterStatus, 
      this.filterDateFrom, 
      this.filterDateTo
    );
  }

  getPageNumbers(): number[] {
    return Array.from({ length: this.totalPagesTransaction }, (_, i) => i + 1);
  }

  getPageNumbersOfShipment(): number[] {
return Array.from({ length: this.totalPageShipment }, (_, i) => i + 1);
  }

  //cupones
  selectUser(user: any): void {
    this.userForCupon = user;
    this.showCrearCuponModal = false;
    this.showCuponConfigModal = true;
    console.log('ID del usuario seleccionado:', user._id);
  }

  clearUserForCupon(): void {
    this.userForCupon = null;
    this.showCuponConfigModal = false;
    this.showCrearCuponModal = true;
  }
  goBackToUserSelection(): void {
    this.showCuponConfigModal = false;
    this.showCrearCuponModal = true;
  }

  createCupon(form: NgForm) {
    if (form.valid) {
      const formData = new FormData();
      (Object.keys(this.cuponData) as (keyof CuponInterface)[]).forEach(
        (key) => {
          formData.append(key, this.cuponData[key] as any);
        }
      );

      console.log('Formulario:', form.value);

      if (this.cuponData.value < 0) {
        alert('El valor del cupón no puede ser negativo.');
        return;
      }

      this.cuponData.user_id = this.userForCupon?._id || '';

      if (this.cuponData.is_unlimited) {
        this.cuponData.quantity = 0;
      }

      this.cuponService.createCupon(this.cuponData).subscribe(
        (response) => {
          console.log('Cupón creado:', response);
          this.clearUserForCupon();
          alert('Cupón creado exitosamente');
          this.showCrearCuponModal = false;
          this.fetchCupons();
        },
        (error) => {
          console.error('Error al crear el cupón:', error);
          alert('Ocurrió un error al crear el cupón');
        }
      );
    }
  }

  fetchCupons(): void {
    this.cuponService.getAllCupon(1, 10).subscribe(
      (response) => {
        console.log('Cupones obtenidos:', response);
        this.cupons = response.message.cupones || [];
      },
      (error) => {
        console.error('Error al cargar los cupones:', error);
      }
    );
  }
}
