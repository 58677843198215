import { Component } from '@angular/core';

@Component({
  selector: 'app-sucursales-lockers',
  templateUrl: './sucursales-lockers.component.html',
  styleUrls: ['./sucursales-lockers.component.scss']
})
export class SucursalesLockersComponent {

}
