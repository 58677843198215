<div class="max-w-4xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
  <h2 class="text-2xl font-bold mb-4 text-center text-gray-800">
    Términos y Condiciones de Paqueterías
  </h2>
  <p class="text-gray-600 mb-6 text-center">
    Te presentamos los términos y condiciones de cada paquetería. Por favor, tómalos en consideración para tu envío.
  </p>

  <div class="space-y-4">
    <div class="border-b pb-2 break-words">
      <h3 class="text-lg font-semibold text-gray-700">Estafeta</h3>
      <a href="https://www.estafeta.com/TerminosServiciosOpcionales" target="_blank" class="text-blue-600 hover:underline">
        https://www.estafeta.com/TerminosServiciosOpcionales
      </a>
    </div>

    <div class="border-b pb-2 break-words">
      <h3 class="text-lg font-semibold text-gray-700">Redpack</h3>
      <a href="https://www.redpack.com.mx/es/terminos-y-condiciones/" target="_blank" class="text-blue-600 hover:underline">
        https://www.redpack.com.mx/es/terminos-y-condiciones/
      </a>
    </div>

    <div class="border-b pb-2 break-words">
      <h3 class="text-lg font-semibold text-gray-700">DHL</h3>
      <a href="https://mydhl.express.dhl/mx/es/legal/terms-and-conditions.html" target="_blank" class="text-blue-600 hover:underline">
        https://mydhl.express.dhl/mx/es/legal/terms-and-conditions.html
      </a>
    </div>

    <div class="border-b pb-2 break-words">
      <h3 class="text-lg font-semibold text-gray-700">UPS</h3>
      <a href="https://www.ups.com/assets/resources/media/es_ES/IGUP.pdf" target="_blank" class="text-blue-600 hover:underline">
        https://www.ups.com/assets/resources/media/es_ES/IGUP.pdf
      </a>
    </div>

    <div class="border-b pb-2 break-words">
      <h3 class="text-lg font-semibold text-gray-700">FedEx</h3>
      <a href="https://www.fedex.com/es-mx/terms-and-conditions.html" target="_blank" class="text-blue-600 hover:underline">
        https://www.fedex.com/es-mx/terms-and-conditions.html
      </a>
    </div>

    <div class="border-b pb-2 break-words">
      <h3 class="text-lg font-semibold text-gray-700">Paquetexpress</h3>
      <a href="https://www.paquetexpress.com.mx/carta-porte" target="_blank" class="text-blue-600 hover:underline">
        https://www.paquetexpress.com.mx/carta-porte
      </a>
    </div>

    <div class="break-words">
      <h3 class="text-lg font-semibold text-gray-700">J&T Express</h3>
      <a href="https://www.jtexpress.mx/informationNew?flag=4" target="_blank" class="text-blue-600 hover:underline">
        https://www.jtexpress.mx/informationNew?flag=4
      </a>
    </div>
  </div>
</div>
