<app-sidebar></app-sidebar>
<div class="ml-64 mt-14 bg-gray-100 min-h-screen">
  <!-- Filtro y Búsqueda -->
  <div class="p-6">
    <h1 class="font-bold text-dagblue uppercase text-3xl mb-6">
      Solicitud Recarga
    </h1>

    <div class="bg-transparent p-4 rounded-lg shadow-md mb-6">
      <div class="flex items-center space-x-2">
        <!-- Cambiado de space-x-4 a space-x-2 -->
        <input
          [(ngModel)]="searchTerm"
          class="flex-grow border-2 border-gray-400 rounded-full p-2 text-gray-500 focus:outline-none focus:ring-2 focus:ring-dagblue"
          placeholder="Buscar por usuario, folio, etc."
        />
        <button
          (click)="search()"
          class="bg-[#D6542B] text-white px-6 py-2 rounded-full font-bold hover:bg-blue-900 transition duration-300"
        >
          Buscar
        </button>
        <select
          [(ngModel)]="selectedStatus"
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 focus:outline-none focus:ring-2 focus:ring-dagblue"
        >
          <option value="">Todos los Estados</option>
          <option value="pendiente">Pendiente</option>
          <option value="aprobada">Aprobada</option>
          <option value="rechazada">Rechazada</option>
        </select>
        <button
          (click)="filterByStatus()"
          class="bg-[#D6542B] text-white px-4 py-2 rounded-full font-bold hover:bg-blue-900 transition duration-300 flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="size-5"
          >
            <path
              fill-rule="evenodd"
              d="M2.628 1.601C5.028 1.206 7.49 1 10 1s4.973.206 7.372.601a.75.75 0 0 1 .628.74v2.288a2.25 2.25 0 0 1-.659 1.59l-4.682 4.683a2.25 2.25 0 0 0-.659 1.59v3.037c0 .684-.31 1.33-.844 1.757l-1.937 1.55A.75.75 0 0 1 8 18.25v-5.757a2.25 2.25 0 0 0-.659-1.591L2.659 6.22A2.25 2.25 0 0 1 2 4.629V2.34a.75.75 0 0 1 .628-.74Z"
              clip-rule="evenodd"
            />
          </svg>

          Filtrar
        </button>
      </div>
    </div>

    <!-- Tabla de Solicitudes -->
    <div class="bg-[#F0F0F0] rounded-lg shadow-md overflow-hidden">
      <table class="w-full">
        <thead class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold">
          <tr>
            <th class="px-4 py-2 text-left">Usuario</th>
            <th class="px-4 py-2 text-left">Correo</th>
            <th class="px-4 py-2 text-left">Folio</th>
            <th class="px-4 py-2 text-left">Fecha</th>
            <th class="px-4 py-2 text-left">Monto</th>
            <th class="px-4 py-2 text-left">Tipo</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 text-gray-500">
          <tr *ngFor="let request of filteredRequests" class="hover:bg-gray-50">
            <td class="px-6 py-4">{{ request.user_id.name }}</td>
            <td class="px-6 py-4">{{ request.user_id.email }}</td>
            <td class="px-6 py-4">{{ request.referenceNumber }}</td>
            <td class="px-6 py-4">
              {{ request.requestDate | date : "dd/MM/yyyy" }}
            </td>
            <td class="px-6 py-4">${{ request.amount.$numberDecimal }}</td>
            <td class="px-6 py-4">
              {{ getRechargeTypeLabel(request.rechargeType) }}
            </td>
            <td class="px-6 py-4">
              <span
                [ngClass]="{
                  'px-2 py-1 rounded-full text-lg font-bold': true,
                  'text-yellow-500': request.status === 'pendiente',
                  'text-green-600': request.status === 'aprobada',
                  'text-red-600': request.status === 'rechazada'
                }"
              >
                {{ request.status | titlecase }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Paginación -->
    <div class="flex justify-center items-center mt-4">
      <div class="flex items-center space-x-4">
        <button
          (click)="previousPage()"
          [disabled]="currentPage === 1"
          class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300"
          [ngClass]="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        <span class="text-xl font-bold text-gray-500">{{ currentPage }} </span>
        <button
          (click)="nextPage()"
          [disabled]="currentPage === totalPages"
          class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300"
          [ngClass]="{
            'opacity-50 cursor-not-allowed': currentPage === totalPages
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
