<!-- new-packing-form.component.html -->
<div 
  *ngIf="alertMessage"
  class="fixed top-4 right-4 z-50 p-4 rounded-lg shadow-lg transition-all duration-300 ease-in-out"
  [ngClass]="{
    'bg-green-100 text-green-700': alertSuccess, 
    'bg-red-100 text-red-700': !alertSuccess
  }"
>
  <p class="font-bold">{{ alertSuccess ? 'Éxito' : 'Error' }}</p>
  <p>{{ alertMessage }}</p>
</div>

<div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-40 p-4 overflow-y-auto">
  <div class="bg-white p-5 rounded-lg shadow-lg w-full max-w-[600px] mx-4 my-8">
    <h2 class="text-xl font-bold mb-4">Crear Nuevo Empaque</h2>
    
    <form #packingForm="ngForm" (ngSubmit)="submitPacking(packingForm)" class="space-y-4">
      <div>
        <label for="image" class="block text-sm font-bold text-gray-700 mb-2">Imagen</label>
        <input 
          type="file" 
          id="image" 
          name="image" 
          [(ngModel)]="data.image" 
          (change)="onImageSelected($event)" 
          accept="image/*" 
          required
          class="block w-full text-sm text-gray-500 
                 file:mr-4 file:py-2 file:px-4 
                 file:rounded-full file:border-0 
                 file:text-sm file:font-semibold 
                 file:bg-blue-50 file:text-blue-700 
                 hover:file:bg-blue-100"
        >
      </div>

      <div class="grid md:grid-cols-2 gap-4">
        <div>
          <label for="name" class="block text-sm font-bold text-gray-700 mb-2">Nombre</label>
          <input 
            type="text" 
            id="name" 
            [(ngModel)]="data.name" 
            name="name" 
            required
            class="block w-full p-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          >
        </div>
        <div>
          <label for="sell_price" class="block text-sm font-bold text-gray-700 mb-2">Precio de Venta</label>
          <input 
            type="number" 
            id="sell_price" 
            [(ngModel)]="data.sell_price" 
            name="sellPrice" 
            min="0" 
            required
            class="block w-full p-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          >
        </div>
      </div>

      <div>
        <label for="cost_price" class="block text-sm font-bold text-gray-700 mb-2">Costo del empaque</label>
        <input 
          type="number" 
          id="cost_price" 
          [(ngModel)]="data.cost_price" 
          name="costPrice" 
          min="0" 
          required
          class="block w-full p-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200"
        >
      </div>

      <div>
        <label for="type" class="block text-sm font-bold text-gray-700 mb-2">Tipo</label>
        <input 
          type="text" 
          id="type" 
          [(ngModel)]="data.type" 
          name="type" 
          required
          class="block w-full p-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200"
        >
      </div>

      <div class="grid md:grid-cols-2 gap-4">
        <div>
          <label for="weight" class="block text-sm font-bold text-gray-700 mb-2">Peso</label>
          <input 
            type="number" 
            id="weight" 
            [(ngModel)]="data.weight" 
            name="weight"
            class="block w-full p-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          >
        </div>
        <div>
          <label for="height" class="block text-sm font-bold text-gray-700 mb-2">Altura</label>
          <input 
            type="number" 
            id="height" 
            [(ngModel)]="data.height" 
            name="height"
            class="block w-full p-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          >
        </div>
        <div>
          <label for="width" class="block text-sm font-bold text-gray-700 mb-2">Ancho</label>
          <input 
            type="number" 
            id="width" 
            [(ngModel)]="data.width" 
            name="width"
            class="block w-full p-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          >
        </div>
        <div>
          <label for="length" class="block text-sm font-bold text-gray-700 mb-2">Longitud</label>
          <input 
            type="number" 
            id="length" 
            [(ngModel)]="data.length" 
            name="length"
            class="block w-full p-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          >
        </div>
      </div>

      <div>
        <label for="description" class="block text-sm font-bold text-gray-700 mb-2">Descripción</label>
        <textarea 
          id="description" 
          [(ngModel)]="data.description" 
          name="description" 
          required
          class="block w-full p-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 h-24"
        ></textarea>
      </div>

      <div class="flex flex-col sm:flex-row gap-4">
        <button 
          type="button"
          (click)="closeModal.emit({ success: false, message: 'Operación cancelada' })"
          class="w-full px-6 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-300"
        >
          Cancelar
        </button>
        <button 
          type="submit"
          class="w-full px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300"
        >
          Guardar
        </button>
      </div>
    </form>
  </div>
</div>