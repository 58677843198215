<app-sidebar></app-sidebar>
<div class="ml-64 pt-20">
  <div class="container mx-auto px-5 py-2">
    <h1 class="text-3xl font-bold mb-6 text-dagblue">Inventario</h1>

    <!-- Botón para agregar inventario -->
    <button
      (click)="openUsersModal()"
      class="bg-dagblue hover:bg-dagblue-dark text-white px-4 py-2 rounded-lg mb-2"

      >
      Abrir Lista de Usuarios
    </button>

    <!-- Cargando datos -->
    <div *ngIf="loading" class="text-center py-4">
      <div class="spinner"></div>
      <p class="mt-2 text-gray-600">Cargando inventario...</p>
    </div>

    <!-- Mensaje de error -->
    <div
      *ngIf="error"
      class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4"
      role="alert"
    >
      <p>{{ error }}</p>
    </div>

    <!-- Mensaje cuando no hay inventario -->
    <div
      *ngIf="!loading && inventories.length === 0"
      class="text-center py-4 text-gray-600"
    >
      No hay inventario disponible.
    </div>



    <!-- Lista de inventarios -->
    <div *ngIf="!loading && inventories.length > 0" class="space-y-6">
      <div
        *ngFor="let inventoryItem of inventories"
        class="bg-white rounded-lg shadow-md overflow-hidden"
      >
        <div
          class="px-6 py-4 bg-dagblue border-b border-gray-200 flex justify-between"
        >
          <div>
            <h2 class="text-xl font-semibold text-white">
              Usuario: {{ inventoryItem.user.name }}
            </h2>
            <p class="text-sm text-white">
              Correo: {{ inventoryItem.user.email }}
            </p>
          </div>
          <button
            (click)="openModal(inventoryItem.user._id)"
            class="bg-blue-500 hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-lg"
          >
            Transferir Empaque
          </button>
          <p class="text-lg font-medium text-white mt-2">
            Rol: {{ inventoryItem.user.role }}
          </p>
        </div>
        <div class="px-6 py-4">
          <h3 class="text-lg font-semibold text-gray-700 mb-3">
            Productos en Inventario
          </h3>
    
          <div
            *ngIf="inventoryItem.inventory.length === 0"
            class="text-gray-500 text-sm"
          >
            No hay productos en inventario.
          </div>
    
          <div *ngIf="inventoryItem.inventory.length > 0" class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    Producto
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    Cantidad
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    Dimensiones
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    Descripción
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    Precio Compra
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    Precio Venta
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    Último Reabastecimiento
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  *ngFor="let item of inventoryItem.inventory"
                  class="hover:bg-gray-50"
                >
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ item.packing.name }} - {{ item.packing.type }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ item.quantity }}
                  </td>
                  <td class="px-6 py-4 text-sm text-gray-900">
                    Alto: {{ item.packing.height }} × Ancho: {{ item.packing.width }} × Largo: {{ item.packing.length }}
                  </td>
                  <td class="px-6 py-4 text-sm text-gray-900">
                    {{ item.packing.description || 'Sin descripción' }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ item.packing.cost_price.$numberDecimal | currency }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ item.packing.sell_price.$numberDecimal | currency }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.last_restock_date | date : "short" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal simple para lista de usuarios -->
<div 
  *ngIf="showUsersModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-[100]"
>
  <div class="bg-white rounded-lg shadow-lg w-full max-w-3xl max-h-[80vh] flex flex-col">
    <!-- Encabezado del modal -->
    <div class="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
      <h2 class="text-xl font-bold text-gray-800">Lista de Usuarios</h2>
      <button 
        (click)="closeUsersModal()" 
        class="text-gray-500 hover:text-gray-700 focus:outline-none"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    
    <!-- Contenido principal -->
    <div class="flex-grow overflow-auto p-6">
      <!-- Mensaje cuando no hay usuarios -->
      <div *ngIf="userList.length === 0" class="text-center py-6 text-gray-500">
        No hay usuarios disponibles
      </div>
      
      <!-- Lista de usuarios en cuadros -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div 
          *ngFor="let user of userList" 
          (click)="selectUser(user._id)"

          class="bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow p-4"
        >
          <!-- Avatar/Iniciales del usuario -->
          <div 
          class="w-12 h-12 rounded-full bg-dagblue text-white flex items-center justify-center text-xl font-semibold mx-auto mb-2">
            {{user.name.charAt(0)}}{{user.surname?.charAt(0) || ''}}
          </div>
          
          <!-- Información básica del usuario -->
          <div class="text-center">
            <h3 class="font-medium text-gray-800 truncate">{{user.name}} {{user.surname}}</h3>
            <p class="text-sm text-gray-600 truncate mt-1">{{user.email}}</p>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Pie del modal -->
    <div class="px-6 py-3 border-t border-gray-200 flex justify-end">
      <button 
        (click)="closeUsersModal()" 
        class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
      >
        Cerrar
      </button>
    </div>
  </div>
</div>

<!-- HTML del modal con lista de empaques -->
<div 
  *ngIf="showModal"
  class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20 ">
  <div class="bg-white w-11/12 max-w-md rounded-xl shadow-xl overflow-hidden">
    
    <!-- Header con degradado -->
    <div class="bg-gradient-to-r  bg-dagblue px-6 py-4 flex justify-between items-center">
      <h2 class="text-xl font-semibold text-white">Transferir Empaque</h2>
      <button 
        (click)="closeModal()"
        class="bg-white bg-opacity-20 rounded-full p-1.5 text-white hover:bg-opacity-30">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>

    <!-- Contenedor Scroll -->
    <div class="max-h-96 overflow-y-auto">
      <!-- Lista de Empaques -->
      <div *ngFor="let packing of packings" class="p-4">
        <div class="flex items-center gap-4 p-3 mb-3 rounded-lg border border-transparent hover:border-gray-200 hover:bg-gray-50">
          <div class="h-14 w-14 rounded-lg overflow-hidden border border-gray-200 flex-shrink-0">
            <img [src]="'data:image/jpeg;base64,' + packing.image" alt="Empaque Ecológico" class="h-full w-full object-cover">
          </div>
          <div class="flex-1 min-w-0">
            <h3 class="text-gray-800 font-medium text-base truncate">{{packing.name}}</h3>
            <p class="text-gray-500 text-sm">{{packing.description}}</p>
          </div>
          <button
          (click)="selectPacking(packing)"
          class="flex-shrink-0 bg-dagblue text-white text-sm font-medium py-2 px-4 rounded-md">
            Seleccionar
          </button>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="bg-gray-50 px-6 py-4 border-t border-gray-200">
      <button
        (click)="closeModal()"
        class="w-full py-2.5 px-4 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100 font-medium">
        Cancelar
      </button>
    </div>

  </div>
</div>


<!-- HTML del modal con inputs y condicional showModalTransfer -->
<div *ngIf="showModalTransfer" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
  <div class="bg-white w-11/12 max-w-md rounded-xl shadow-xl overflow-hidden">
    <!-- Header con degradado -->
    <div class="bg-gradient-to-r bg-dagblue px-6 py-4 flex justify-between items-center">
      <h2 class="text-xl font-semibold text-white">Transferir Empaque</h2>
      <button class="bg-white bg-opacity-20 rounded-full p-1.5 text-white hover:bg-opacity-30" (click)="showModalTransfer = false">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>

    <!-- Formulario con inputs -->
    <div class="p-6">
      <form (ngSubmit)="confirmTransfer()">
        <!-- Input para cantidad -->
        <div class="mb-4">
          <label for="cantidad" class="block text-sm font-medium text-gray-700 mb-1">Cantidad</label>
          <div class="relative">
            <input 
              type="number" 
              id="cantidad" 
              name="cantidad" 
              [(ngModel)]="cantidadTransferir" 
              class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Ingrese la cantidad"
              min="1"
              required>
            <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <span class="text-gray-500">Pzs.</span>
            </div>
          </div>
        </div>
        
        <!-- Input para notas -->
        <div class="mb-6">
          <label for="notas" class="block text-sm font-medium text-gray-700 mb-1">Notas (opcional)</label>
          <textarea 
            id="notas" 
            name="notas" 
            [(ngModel)]="notasTransferencia" 
            rows="3" 
            class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Añadir notas o comentarios adicionales..."></textarea>
        </div>
        
        <!-- Botones de acción -->
        <div class="flex gap-3">
          <button 
            type="button" 
            class="flex-1 py-2.5 px-4 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100 font-medium"
            (click)="showModalTransfer = false">
            Cancelar
          </button>
          <button 
            type="submit" 
            class="flex-1 py-2.5 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md font-medium">
            Confirmar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>