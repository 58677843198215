import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-packing-request',
  templateUrl: './packing-request.component.html',
  styleUrls: ['./packing-request.component.scss']
})
export class PackingRequestComponent implements OnInit {

  // Refill properties
  refillRequests: any[] = [];
  refillCurrentPage: number = 1;
  refillTotalPages: number = 1;
  refillTotalItems: number = 0;
  refillItemsPerPage: number = 5;
  refillSearchTerm: string = '';
  userId: string = '';

 //transferencia 
 transferRequests: any[] = [];


  // Filtros
  selectedRefillStatus: string = '';
  selectedTransferStatus: string = '';
  

  constructor(
    private refillRequestService: RefillRequestService,
    private authService: AuthLoginService,
  ) {}



  ngOnInit(): void {
    this.loadRefillRequests();
    this.loadUserTransferRequests();
    this.loadUserId();
  }

  //transferencia
  loadUserId(): void {
    const userId = this.authService.getId(); // Usa el método correcto aquí
    if (userId) {
      this.userId = userId;
      this.loadUserTransferRequests();
    } else {
      console.error('No se pudo obtener el ID del usuario.');
    }
  }

  // Cargar transferencias con filtro de estado
  loadUserTransferRequests(): void {
    if (!this.userId) return;

    let statusToSend = this.selectedTransferStatus.trim().toLowerCase();
    let finalStatus = statusToSend === '' ? undefined : statusToSend;

    console.log("Estado seleccionado para filtrar:", finalStatus); // Debugging

    this.refillRequestService.getUserTransferRequests(this.userId, finalStatus).subscribe(
      (response) => {
        if (response.success) {
          console.log("Transferencias obtenidas:", response.data.requests); // Verifica cuántas devuelve la API
          this.transferRequests = response.data.requests;
        } else {
          console.error('Error obteniendo transferencias:', response.message);
        }
      },
      (error) => {
        console.error('Error obteniendo transferencias:', error);
      }
    );
  }

  // Cargar solicitudes de empaques con filtro de estado
  loadRefillRequests(): void {
    this.refillRequestService
      .getRefillRequests(
        this.refillCurrentPage,
        this.refillItemsPerPage,
        this.refillSearchTerm
      )
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.refillRequests = response.data.requests.filter((request: any) => 
              this.selectedRefillStatus === '' || request.status.toLowerCase() === this.selectedRefillStatus.toLowerCase()
            );            
            this.refillTotalPages = response.data.totalPages;
            this.refillTotalItems = response.data.totalItems;
          } else {
            console.error('Error loading refill requests:', response.message);
          }
        },
        (error) => {
          console.error('Error loading refill requests:', error);
        }
      );
  }

  // Filtrar solicitudes de empaques
  filterRefillRequests(): void {
    this.loadRefillRequests();
  }

  // Filtrar transferencias
  filterTransferRequests(): void {
    this.selectedTransferStatus = this.selectedTransferStatus.toLowerCase(); 
    this.loadUserTransferRequests();
  }


  // Cambio de página para solicitudes de empaques
  onRefillPageChange(page: number): void {
    if (page >= 1 && page <= this.refillTotalPages) {
      this.refillCurrentPage = page;
      this.loadRefillRequests();
    }
  }

  // Aprobar solicitud de empaque
  approveRefillRequest(requestId: string): void {
    const adminNotes = '';
    this.refillRequestService.approveRefillRequest(requestId, adminNotes).subscribe(
      (response) => {
        if (response.success) {
          this.loadRefillRequests();
        } else {
          console.error('Error approving refill request:', response.message);
        }
      },
      (error) => {
        console.error('Error approving refill request:', error);
      }
    );
  }

  // Rechazar solicitud de empaque
  rejectRefillRequest(requestId: string): void {
    const adminNotes = '';
    this.refillRequestService.rejectRefillRequest(requestId, adminNotes).subscribe(
      (response) => {
        if (response.success) {
          this.loadRefillRequests();
        } else {
          console.error('Error rejecting refill request:', response.message);
        }
      },
      (error) => {
        console.error('Error rejecting refill request:', error);
      }
    );
  }


  
  


}
