// cashiers.component.ts
import { Component, OnInit } from '@angular/core';
import { CashRegisterService } from 'src/app/_services/Cashiers/cash-register.service';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';

@Component({
  selector: 'app-cashiers',
  templateUrl: './cashiers.component.html',
  styleUrls: ['./cashiers.component.scss'],
})
export class CashiersComponent implements OnInit {
  cashiers: any[] = [];
  paginatedCashiers: any[] = [];
  filteredCashiers: any[] = [];
  searchTerm: string = '';
  startDate: string = '';
  endDate: string = '';
  currentPage: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  totalPages: number = 0;
  Math: any = Math;
  //Variables para alaerta
  alertMessage: string = '';
  alertSuccess: boolean = false;

  currentCashRegister: any = null;
  transactions: any[] = [];
  loading: boolean = false;
  error: string | null = null;
  canCloseCashRegister: boolean = false;
  modalOpen: boolean = false;
  transactionDetails: any = null;
  constructor(private cashRegisterService: CashRegisterService, private AuthService: AuthLoginService,
    private transactionService: TransactionService
  ) { }

  ngOnInit() {
    this.checkCurrentCashRegister();
    this.verifyRole();
  }



  verifyRole() {
    this.AuthService.userRole$.subscribe((role) => {
      console.log('Role:', role);
      if (role == 'ADMIN' || role == 'SUPERADMIN' || role == 'LICENCIATARIO_TRADICIONAL') {
        this.canCloseCashRegister = true;
      } else {
        this.canCloseCashRegister = false;
      }
    });
  }



  openModal() {
    this.modalOpen = true;
  }
  


// Puedes añadir este método si aún no lo tienes
closeModal() {
  this.modalOpen = false;
  this.transactionDetails = null;
}

  transactionDetailsModal(transaction: any) {
    this.modalOpen = true;
    this.transactionDetails = null; // Resetear para mostrar el loader
    
    this.transactionService.getTransactionById(transaction._id).subscribe(
      (response) => {
        console.log('Detalles de la transacción:', response);
        this.transactionDetails = response;
      },
      (error) => {
        console.error('Error al obtener los detalles de la transacción:', error);
        // Mostrar mensaje de error
        this.alertMessage = 'Error al cargar los detalles de la transacción';
        this.alertSuccess = false;
        setTimeout(() => {
          this.alertMessage = '';
        }, 3000);
        this.modalOpen = false;
      }
    );
  }

  checkCurrentCashRegister() {
    this.loading = true;
    this.cashRegisterService.getCurrentCashRegister().subscribe(
      (response) => {
        console.log('Caja actual:', response.data);
        this.currentCashRegister = response.data;
        if (this.currentCashRegister) {
          this.loadTransactions();
        }
        this.loading = false;
      },
      (error) => {
        this.error = 'Error al obtener el estado de la caja';
        this.loading = false;
      }
    );
  }

  loadTransactions() {
    this.loading = true;
    this.cashRegisterService
      .getCashTransactions(this.currentPage, this.pageSize)
      .subscribe(
        (response) => {
          if (response.success) {
            console.log('Transacciones:', response.data);
            this.transactions = response.data.transactions;
            this.totalItems = response.data.totalTransactions;
            this.totalPages = response.data.totalPages;
            this.currentPage = response.data.currentPage;
          } else {
            this.error = 'Error al cargar las transacciones';
          }
          this.loading = false;
        },
        (error) => {
          this.error = 'Error al cargar las transacciones';
          this.loading = false;
        }
      );
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.loadTransactions();
  }

  openCashRegister() {
    this.loading = true;
    this.cashRegisterService.openCashRegister().subscribe(
      (data) => {
        console.log('Caja abierta:', data);
        this.currentCashRegister = data.data;
        this.loading = false;
        this.error = null;
        this.loadTransactions(); // Cargar transacciones después de abrir la caja
      },
      (error) => {
        this.error = 'Error al abrir la caja';
        this.loading = false;
      }
    );
  }
  closeCashRegister() {
    this.loading = true;
    this.error = null;
    this.cashRegisterService.closeCashRegister().subscribe(
      (response) => {
        if (response.success) {
          this.currentCashRegister = null;
          this.transactions = [];
          this.totalItems = 0;
          this.totalPages = 0;
          console.log(response.message);
        } else {
          this.error = response.message;
        }
        this.loading = false;
      },
      (error) => {
        this.error = error.message || 'Error al cerrar la caja';
        this.loading = false;
      }
    );
  }
  closeCashRegisterCashierCash() {
    this.loading = true;
    this.error = null;
    this.cashRegisterService.closeCashRegisterCashier().subscribe(
      (response) => {
        if (response.success) {
          this.currentCashRegister = null;
          this.transactions = [];
          this.totalItems = 0;
          this.totalPages = 0;
          console.log(response.message);
        } else {
          this.error = response.message;
        }
        this.loading = false;
      },
      (error) => {
        this.error = error.message || 'Error al cerrar la caja';
        this.loading = false;
      }
    );
  }
  applyFilters() {
    this.currentPage = 1; // Resetear a la primera página al aplicar filtros
    this.loadTransactions();
  }

  getPaginationRange(): number[] {
    const range = 2; // Número de páginas a mostrar antes y después de la página actual
    const start = Math.max(1, this.currentPage - range);
    const end = Math.min(this.totalPages, this.currentPage + range);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }
  // En tu cashiers.component.ts

  getPaymentMethodIcon(method: string): string {
    // Retorna la URL del ícono basado en el método de pago
    switch (method) {
      case 'td-debito':
        return '../../../assets/icons/debit-card.png';
      case 'td-credito':
        return '../../../assets/icons/debit-card.png';
      case 'efectivo':
        return '../../../assets/icons/cash.png';
      default:
        return '../../../assets/icons/saldo.png';
    }
  }

  getPaymentMethodName(method: string): string {
    // Retorna un nombre más amigable para el método de pago
    switch (method) {
      case 'credit':
        return 'Tarjeta de Crédito';
      case 'debit':
        return 'Tarjeta de Débito';
      case 'cash':
        return 'Efectivo';
      default:
        return method;
    }
  }
}
