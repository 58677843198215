<app-sidebar class="hidden sm:block"></app-sidebar>
<div class="sm:ml-64 mt-14 bg-gray-100 min-h-screen">
  <div class="p-4 sm:p-6 lg:p-8">
    <!-- Título -->
    <h1 class="font-bold text-dagblue uppercase text-2xl sm:text-3xl mb-6">
      Estatus de los Paquetes
    </h1>
    <p class="text-gray-600 text-sm sm:text-base mb-4">
      Detalles de los envíos
    </p>

    <!-- Filtros -->
    <div class="bg-transparent p-4 rounded-lg shadow-md mb-6">
      <div
        class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4"
      >
        <button
          class="bg-gray-300 text-gray-700 px-4 py-2 rounded-full flex items-center justify-center w-full sm:w-auto text-sm sm:text-base"
          (click)="exportToCSV()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#217346"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-5 sm:w-6 h-5 sm:h-6 mr-2"
          >
            <path
              d="M4.5 3.75A1.5 1.5 0 0 1 6 2.25h12a1.5 1.5 0 0 1 1.5 1.5v16.5a1.5 1.5 0 0 1-1.5 1.5H6a1.5 1.5 0 0 1-1.5-1.5V3.75z"
              fill="#217346"
            />
            <path
              fill="#fff"
              d="M8.5 8.25h2.75L12.75 12l-1.5 3.75H8.5L10 12 8.5 8.25zm7 0h1.5v7.5H15.5V8.25z"
            />
          </svg>
          Exportar a CSV
        </button>
        <input
          type="date"
          id="start-date"
          [(ngModel)]="startDate"
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
        />
        <input
          type="date"
          id="end-date"
          [(ngModel)]="endDate"
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
        />
        <select
          id="status-filter"
          [(ngModel)]="statusFilter"
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
        >
          <option value="">Todos</option>
          <option value="Pendiente">Pendiente</option>
          <option value="Pagado">Pagado</option>
        </select>
        <button
          (click)="applyFilters()"
          class="bg-[#D6542B] text-white px-6 py-2 rounded-full font-bold text-sm sm:text-base w-full sm:w-auto hover:bg-blue-900 transition"
        >
          Filtrar
        </button>
      </div>
    </div>

    <!-- Tabla -->
    <div class="bg-[#F0F0F0] rounded-lg shadow-md overflow-x-auto">
      <table class="w-full text-sm sm:text-base">
        <thead class="uppercase bg-[#F0F0F0] text-dagblue font-bold">
          <tr>
            <th class="px-4 py-2 text-left">Paquetería</th>
            <th *ngIf="!verifyRole()" class="px-4 py-2 text-left">Licencia</th>
            <th class="px-4 py-2 text-left">Usuario</th>

            <th class="px-4 py-2 text-left">Fecha</th>
            <th class="px-4 py-2 text-left">Precio</th>

            <th class="px-4 py-2 text-left">Costo</th>
            <th *ngIf="!verifyRole()"  class="px-4 py-2 text-left">Utilidad Global</th>
            <th *ngIf="!verifyRole()"  class="px-4 py-2 text-left">Tu Utilidad</th>
            <th *ngIf="!verifyRole()" class="px-4 py-2 text-left">U. Dagpacket</th>
            <th class="px-4 py-2 text-left">Origen</th>
            <th class="px-4 py-2 text-left">Destino</th>
            <th class="px-4 py-2 text-center">Estado de Pago</th>
            <th  class="px-4 py-2 text-center">Estado de Envío</th>
            <th  class="px-4 py-2 text-left">Acciones</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 text-gray-500">
          <tr *ngFor="let envio of filteredPackages" class="hover:bg-gray-50">
            <td class="px-4 py-2 uppercase">{{ envio.provider }}</td>
            <td *ngIf="!verifyRole()" class="px-4 py-2">{{ envio.user_id.name }}</td>
            <td class="px-4 py-2">
              {{ envio.sub_user_id?.name || envio.user_id?.name }}
            </td>

            <td class="px-4 py-2">
              {{ envio.distribution_at | date : "dd/MM/yyyy" }}
            </td>
            <td class="px-4 py-2">
              ${{ envio.price.$numberDecimal | number : "1.2-2" }}
            </td>

            <td class="px-4 py-2">${{ envio.cost.$numberDecimal }}</td>
            <td *ngIf="!verifyRole()"  class="px-4 py-2">
              ${{ envio.dagpacket_profit.$numberDecimal | number : "1.2-2" }}
            </td>
            <td *ngIf="!verifyRole()"  class="px-4 py-2">
              ${{ envio.utilitie_lic.$numberDecimal | number : "1.2-2" }}
            </td>
            <td *ngIf="!verifyRole()"  class="px-4 py-2">
              ${{ envio.utilitie_dag.$numberDecimal | number : "1.2-2" }}
            </td>
            <td class="px-4 py-2">{{ envio.from.city }}</td>
            <td class="px-4 py-2">{{ envio.to.city }}</td>
            <td class="px-4 py-2 text-center">
              <span
                [ngClass]="{
                  'px-2 py-1 rounded-full text-lg font-bold': true,
                  'text-yellow-500': envio.payment.status === 'Pendiente',
                  'text-green-600': envio.payment.status === 'Pagado'
                }"
              >
                {{ envio.payment.status }}
              </span>
            </td>
            <td class="px-4 py-2 text-center font-bold">{{ envio.status }}</td>
            <td class="px-4 py-2">
              <button
                (click)="viewDetails(envio._id)"
                class="w-full sm:w-auto bg-dagblue font-bold text-white px-4 py-2 rounded-full hover:bg-blue-900 transition"
              >
                Ver
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="flex justify-center items-center gap-2 sm:gap-4 py-4">
    <button
      (click)="changePage(currentPage - 1)"
      [disabled]="currentPage === 1"
      class="flex items-center justify-center w-8 sm:w-10 h-8 sm:h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-4 sm:w-6 h-4 sm:h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </button>
    <span class="text-sm sm:text-xl font-bold">{{ currentPage }}</span>
    <button
      (click)="changePage(currentPage + 1)"
      [disabled]="currentPage === totalPages"
      class="flex items-center justify-center w-8 sm:w-10 h-8 sm:h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-4 sm:w-6 h-4 sm:h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </button>
  </div>
</div>