import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inversor',
  templateUrl: './inversor.component.html',
  styleUrls: ['./inversor.component.scss'],
})
export class InversorComponent implements OnInit {
  totalClientes: number = 0;
  clientesHoy: number = 0;
  clientesAyer: number = 0;
  clientesSemanaPasada: number = 0;
  lockerInfo: string = '';
  lockers: { name: string; status: string }[] = [];
  showProvedores = false;
  showLockerModal = false;
  showHistorialModal = false;
  showPedidosModal = false; // Nuevo estado para el modal de pedidos
  selectedLocker: { name: string; status: string } | null = null;
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 5;
  gananciasLocker: any[] = [];
  totalGananciasLocker = '4,000';
  porcentajeCambioGanancias: number = 0;
  historialTransacciones: any[] = [];
  totalGananciasTransacciones = 0;
  pedidos: {
    id: number;
    estado: string;
    fecha: string;
    cliente: string;
    direccion: string;
    gananciaspedidos: string;
  }[] = []; // Lista de pedidos
  searchTerm: string = '';
  filterStatus: string = '';

  // Chart properties
  chartWidth: number = 600;
  chartHeight: number = 400;
  barWidth: number = 40;
  barSpacing: number = 10;
  chartData: { value: number }[] = [];

  constructor() {}

  ngOnInit(): void {
    this.totalClientes = 3462;
    this.clientesHoy = 5334;
    this.clientesAyer = 4324;
    this.clientesSemanaPasada = 4567;
    this.lockerInfo = 'Disponible';
    this.lockers = [
      { name: 'Locker 1', status: 'En operación' },
      { name: 'Locker 2', status: 'En operación' },
      { name: 'Locker 3', status: 'En operación' },
    ];

    this.porcentajeCambioGanancias = 55; // Ejemplo de valor, puedes cambiarlo por el valor real

    // Datos de ganancias del locker
    this.gananciasLocker = [
      { fecha: '01/10/2023', monto: 500 },
      { fecha: '02/10/2023', monto: 700 },
      { fecha: '03/10/2023', monto: 800 },
      { fecha: '04/10/2023', monto: 600 },
      { fecha: '05/10/2023', monto: 900 },
    ];

    // Calcular el total de ganancias del locker
    this.totalGananciasLocker = this.gananciasLocker
      .reduce((acc, transaccion) => acc + transaccion.monto, 0)
      .toLocaleString();

    // Datos de historial de transacciones
    this.historialTransacciones = [
      { fecha: '01/10/2023', monto: 1500 },
      { fecha: '02/10/2023', monto: 1700 },
      { fecha: '03/10/2023', monto: 1800 },
      { fecha: '04/10/2023', monto: 1600 },
      { fecha: '05/10/2023', monto: 1900 },
    ];

    // Calcular el total de ganancias de transacciones
    this.totalGananciasTransacciones = this.historialTransacciones.reduce(
      (acc, transaccion) => acc + transaccion.monto,
      0
    );

    // Populate chart data
    this.chartData = this.gananciasLocker.map((g) => ({ value: g.monto }));

    // Datos de pedidos
    this.pedidos = [
      {
        id: 1,
        estado: 'En reparto',
        fecha: '05/10/2023',
        cliente: 'Juan Perez',
        direccion: 'Calle Falsa 123',
        gananciaspedidos: '300',
      },
      {
        id: 2,
        estado: 'Entregado',
        fecha: '04/10/2023',
        cliente: 'Maria Lopez',
        direccion: 'Avenida Siempre Viva 456',
        gananciaspedidos: '500',
      },
      {
        id: 3,
        estado: 'Cancelado',
        fecha: '03/10/2023',
        cliente: 'Carlos Sanchez',
        direccion: 'Boulevard de los Sueños 789',
        gananciaspedidos: '200',
      },
    ];

    this.totalPages = Math.ceil(this.pedidos.length / this.itemsPerPage);
  }

  openCuponesModal() {
    this.showProvedores = true;
  }

  closeCuponesModal() {
    this.showProvedores = false;
  }

  openLockerModal(locker: { name: string; status: string }) {
    this.selectedLocker = locker;
    this.showLockerModal = true;
  }

  closeLockerModal() {
    this.showLockerModal = false;
    this.selectedLocker = null;
  }

  openHistorialModal() {
    this.showHistorialModal = true;
  }

  closeHistorialModal() {
    this.showHistorialModal = false;
  }

  openPedidosModal() {
    this.showPedidosModal = true;
  }

  closePedidosModal() {
    this.showPedidosModal = false;
  }

  filteredPedidos() {
    return this.pedidos
      .filter(
        (pedido) =>
          (!this.searchTerm ||
            pedido.cliente
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())) &&
          (!this.filterStatus || pedido.estado === this.filterStatus)
      )
      .slice(
        (this.currentPage - 1) * this.itemsPerPage,
        this.currentPage * this.itemsPerPage
      );
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  onpageChange(page: number) {
    this.currentPage = page;
  }
}
