import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { LockersService } from 'src/app/_services/Lockers/lockers.service';
import Swal from 'sweetalert2';

interface Panel {
  id: any;
  name: string;
  hasAd: boolean;
  images: FileWithPreview[];
  currentIndex: number;
  isEditing: boolean;
  selectedImage1?: string;
  selectedImage2?: string;
}

interface FileWithPreview {
  file: File | null;
  preview: string;
  contract: {
    url: string;
  };
  enterprise: string;
}

@Component({
  selector: 'app-publicidad',
  templateUrl: './publicidad.component.html',
  styleUrls: ['./publicidad.component.scss'],
})
export class PublicidadComponent implements OnInit {
  isModalOpen = false;
  currentPanel: Panel | null = null;
  intervaloTiempo: number = 20;
  ingresosPorAnuncio: number = 0;
  contratoPDF: File | null = null;
  tempImages: FileWithPreview[] = [];
  enterprise: string = '';
  panels: Panel[] = [];
  selectedPanel: Panel | null = null;

  constructor(
    private http: HttpClient,
    private lockersService: LockersService
  ) {}

  ngOnInit(): void {
    this.loadPanels();
    this.loadLocker();
  }

  loadPanels(): void {}

  openModal(panel: Panel): void {
    this.currentPanel = panel;
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.currentPanel = null;
    this.tempImages = [];
    this.contratoPDF = null;
    this.enterprise = '';
  }

  loadLocker(): void {
    this.lockersService.getLockers().subscribe(
      (response: any) => {
        this.panels = response.message.map((locker: any) => ({
          id: locker._id,
          name: locker.ubication,
          hasAd: false,
          images: [],
          currentIndex: 0,
          isEditing: false,
        }));

        this.panels.forEach((panel) => this.loadLockerImages(panel.id, panel));
        console.log('Lockers:', this.panels);
      },
      (error) => {
        console.error('Error al cargar los lockers:', error);
      }
    );
  }

  loadLockerImages(id: string, panel: Panel): void {
    this.lockersService.getPublicityByLockerId(id).subscribe(
      (response: any) => {
        console.log('Publicidad del locker:', response.message.advertisements);
        panel.images = response.message.advertisements.map((image: any) => ({
          preview: image.images[0].url,
          url: image.images[0].image64,
          contract: image.contract,
          enterprise: image.enterprise,
        }));

        panel.hasAd = panel.images.length > 0;
        panel.selectedImage1 = panel.images[0]?.preview;
        panel.selectedImage2 = panel.images[1]?.preview;
        console.log('Panel:', panel);
      },

      (error) => {
        console.error('Error al cargar la publicidad del locker:', error);
      }
    );
  }

  // Helper method to convert base64 to File

  onImageSelected(event: Event, panelId: number, imageNumber: number): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const panel = this.panels.find((p) => p.id === panelId);
        if (panel) {
          if (imageNumber === 1) {
            panel.selectedImage1 = e.target.result;
          } else if (imageNumber === 2) {
            panel.selectedImage2 = e.target.result;
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onPDFSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.contratoPDF = input.files[0];
      console.log('Contrato PDF:', this.contratoPDF);
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      Array.from(input.files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.tempImages.push({
            file,
            preview: e.target.result,
            contract: { url: '' },
            enterprise: '',
          });
        };
        reader.readAsDataURL(file);
      });
    }
  }

  viewDetails(panelId: number): void {
    const panel = this.panels.find((p) => p.id === panelId);
    if (panel) {
      console.log('Panel:', panel);
      this.selectedPanel = panel;
    }
  }

  openFullImage(base64Image: string) {
    const fullImageWindow = window.open('', '_blank');
    if (fullImageWindow) {
      fullImageWindow.document.write(`
        <html>
          <head><title>Full Image</title></head>
          <body style="margin:0;display:flex;justify-content:center;align-items:center;height:100vh;background:black;">
            <img src="${base64Image}" style="max-width:100%;max-height:100%;object-fit:contain;">
          </body>
        </html>
      `);
      fullImageWindow.document.close();
    }
  }
  viewContract(contractUrl: { url: string }): void {
    console.log('Contrato:', contractUrl.url); // Mostrar el nombre del archivo en la consola
    if (contractUrl && contractUrl.url) {
      // Extraemos el nombre del archivo de la URL
      const fileName = contractUrl.url.split('/').pop();
      if (fileName) {
        // Redirigir a la URL con el nombre del archivo en el endpoint local
        window.location.href = `http://localhost:3000/contracts/${fileName}`;
      } else {
        alert('No se encontró el nombre del archivo.');
      }
    } else {
      alert('No se encontró un contrato asociado.');
    }
  }

  guardarPublicidad(): void {
    // Validation checks
    if (!this.currentPanel) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se ha seleccionado ningún panel'
      });
      return;
    }
  
    if (this.tempImages.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe seleccionar al menos una imagen'
      });
      return;
    }
  
    if (this.intervaloTiempo < 20 || this.intervaloTiempo > 30) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El intervalo de tiempo debe estar entre 20 y 30 segundos'
      });
      return;
    }
  
    if (!this.contratoPDF) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe subir un contrato en formato PDF'
      });
      return;
    }
  
    if (!this.enterprise.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe ingresar el nombre de la empresa'
      });
      return;
    }
  
    // Show loading state
    Swal.fire({
      title: 'Guardando publicidad',
      text: 'Por favor espere...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
  
    const formData = new FormData();
    formData.append('locker_id', this.currentPanel.id.toString());
    formData.append('enterprise', this.enterprise);
    formData.append('type', 'digital');
    formData.append('duration', this.intervaloTiempo.toString());
    formData.append('income', this.ingresosPorAnuncio.toString());
    formData.append('description', 'Descripción del anuncio');
    formData.append('contract', this.contratoPDF);
    
    this.tempImages.forEach((fileWithPreview, index) => {
      formData.append(`images[${index}][url]`, fileWithPreview.preview);
      formData.append(`images[${index}][alt]`, `Publicidad ${index + 1}`);
      formData.append(`images[${index}][image64]`, 'base64string');
    });
  
    this.http.post(`${environment.apiUrl}/publicity/create`, formData)
      .subscribe(
        (response) => {
          Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'La publicidad se ha guardado correctamente',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            // Refresh the page data
            this.loadPanels();
            this.loadLocker();
            this.closeModal();
          });
        },
        (error) => {
          console.error('Error al guardar la publicidad:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ha ocurrido un error al guardar la publicidad. Por favor, inténtelo de nuevo.'
          });
        }
      );
  }
  removeTempImage(index: number): void {
    URL.revokeObjectURL(this.tempImages[index].preview);
    this.tempImages.splice(index, 1);
  }

  removeSelectedImage(panelId: number, imageNumber: number): void {
    const panel = this.panels.find((p) => p.id === panelId);
    if (panel) {
      if (imageNumber === 1) {
        panel.selectedImage1 = undefined;
      } else if (imageNumber === 2) {
        panel.selectedImage2 = undefined;
      }
    }
  }

  removeImage(panelId: number, index: number): void {
    const panel = this.panels.find((p) => p.id === panelId);
    if (panel) {
      URL.revokeObjectURL(panel.images[index].preview);
      panel.images.splice(index, 1);
      if (panel.currentIndex >= panel.images.length) {
        panel.currentIndex = panel.images.length - 1;
      }
    }
  }

  getTransform(panelId: number): string {
    const panel = this.panels.find((p) => p.id === panelId);
    return panel
      ? `translateX(-${panel.currentIndex * 100}%)`
      : 'translateX(0)';
  }

  prevImage(panelId: number): void {
    const panel = this.panels.find((p) => p.id === panelId);
    if (panel && panel.images.length > 1) {
      panel.currentIndex =
        (panel.currentIndex - 1 + panel.images.length) % panel.images.length;
    }
  }

  nextImage(panelId: number): void {
    const panel = this.panels.find((p) => p.id === panelId);
    if (panel && panel.images.length > 1) {
      panel.currentIndex = (panel.currentIndex + 1) % panel.images.length;
    }
  }

  toggleEditMode(panelId: number): void {
    const panel = this.panels.find((p) => p.id === panelId);
    if (panel) {
      panel.isEditing = !panel.isEditing;
    }
  }

  onEnterpriseChange(event: any): void {
    this.enterprise = event.target.value;
  }

  closeDetailsModal(): void {
    this.selectedPanel = null;
  }
}
