import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';
import {
  Subscription,
  Subject,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
} from 'rxjs';
import { CuponService } from 'src/app/_services/cupon/cupon.service';
import Swal from 'sweetalert2';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { ShipmentData, Address } from 'src/app/_models/shipments';
import { Router, ActivatedRoute } from '@angular/router';

import { parse } from 'postcss';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  shipmentData: any;
  fromData: Address = {} as Address;
  toData: Address = {} as Address;
  paqueteriaData: any;
  discountType: 'subtract' | 'add' = 'subtract';
  discountAmount: number = 0;

  from: any = {};
  to: any = {};
  isEditModalOpen = false;
  selectedFormType: 'remitente' | 'destinatario' = 'remitente';
  editForm: any = {};
  appliedCouponValue: number = 0;
  extraPrice: number = 0;
  folio = '';
  userId!: any;
  SubUserId!: any;
  subtotal: number = 0;
  packageCost: number = 0;
  discount: number = 0;
  total: number = 0;
  dagpacketProfit: number = 0;
  licenseProfit: number = 0;
  cuponDiscount: number = 0;
  isPackageDialogOpen = false;
  totalUtilidad: number = 0;
  isDiscountDialogOpen = false;
  selectedPackage: any = null;
  description: string = '';
  precio_minimo: number = 0;
  isDisabled = false;
  dimensionesData: any = {
    alto: 0,
    ancho: 0,
    largo: 0,
    peso: 0,
  };

  //cupon
  isCouponDialogOpen = false;
  selectedCoupon: any = null;
  dag_cupon: number = 0;
  lic_cupon: number = 0;
  private subscriptions: Subscription[] = [];
  Math: any = Math;
  couponCode = '';
  couponResults: any[] = [];
  isLoading = false;
  searchSubject = new Subject<string>();

  constructor(
    private sharedDataService: SharedDataService,
    private shipmentService: ShipmentsService,
    private authService: AuthLoginService,
    private router: Router,
    private route: ActivatedRoute,
    private zone: NgZone,
    private cuponService: CuponService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getUserInfo();
    this.loadShipmentData();

    if (this.shipmentData?.paqueteria) {
      this.precio_minimo = this.shipmentData.paqueteria.precio_regular;
    } else {
      console.warn('shipmentData.paqueteria no está definido.');
    }

    this.searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((code) => this.cuponService.searchCupon(code)),
        catchError((error) => {
          console.error('Error al buscar cupones:', error);
          this.isLoading = false;
          this.couponResults = [];
          return [];
        })
      )
      .subscribe((response) => {
        this.isLoading = false;

        if (response?.success && response.data?.cupones) {
          this.couponResults = response.data.cupones; // Accede a `cupones`
        } else {
          this.couponResults = [];
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getUserInfo() {
    this.authService.userProfile().subscribe(
      (response) => {
        console.log('User info:', response.data);
        const currentUserId = this.authService.getId();

        if (response.data.parentUser) {
          console.log('El primer usuario padre es', response.data.parentUser);
          this.resolveParentUser(response.data.parentUser._id).then(
            (rootParentId) => {
              console.log('rootParentId', rootParentId);
              this.userId = rootParentId;
              this.SubUserId = currentUserId;
              console.log('User ID (root parent):', this.userId);
              console.log('SubUserID:', this.SubUserId);
            }
          );
        } else {
          this.userId = currentUserId;
          this.SubUserId = currentUserId;
          console.log('User ID sin padre:', this.userId);
          console.log('SubUserID:', this.SubUserId);
        }
      },
      (error) => {
        console.error('Error getting user info', error);
      }
    );
  }

  resolveParentUser(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.authService.getUserProfile(id).subscribe(
        (response) => {
          if (response.data.parentUser) {
            console.log(
              'El usuario tiene un usuario padre',
              response.data.parentUser
            );
            // Si tiene un usuario padre, sigue buscando hacia arriba
            this.resolveParentUser(response.data.parentUser._id)
              .then(resolve)
              .catch(reject);
          } else {
            // Si no tiene usuario padre, devuelve el ID actual
            console.log('El ultimo usuario padre fue', response.data);
            resolve(response.data._id);
          }
        },
        (error) => {
          console.error('Error fetching parent user profile', error);
          reject(error);
        }
      );
    });
  }

  private loadShipmentData() {
    this.route.queryParams.subscribe((params) => {
      if (params['datosEnvio']) {
        this.shipmentData = JSON.parse(params['datosEnvio']);
        this.fromData = this.shipmentData.from;
        this.toData = this.shipmentData.to;
        this.paqueteriaData = this.shipmentData.paqueteria;
        this.dimensionesData = this.shipmentData.dimensiones || {
          alto: 0,
          ancho: 0,
          largo: 0,
          peso: 0,
        };

        this.subtotal = parseFloat(this.paqueteriaData.precio);
        this.total = this.subtotal;
        this.dagpacketProfit = this.shipmentData.dagpacketProfit;
        this.licenseProfit = this.shipmentData.licenciatarioProfit;
        console.log('Utilidad DagPacket:', this.dagpacketProfit);
        console.log('Utilidad Licenciatario:', this.licenseProfit);
        // Asegurarse de que ambas variables son números antes de hacer la suma
        this.totalUtilidad =
          Number(this.dagpacketProfit) + Number(this.licenseProfit);
        console.log('Total utilidad:', this.totalUtilidad);
        if (this.shipmentData.packing) {
          this.selectedPackage = {
            _id: this.shipmentData.packing.packing_id,
            name: this.shipmentData.packing.packing_type,
            price: parseFloat(this.shipmentData.packing.packing_cost),
          };
          this.packageCost = this.selectedPackage.price;
        }

        this.updateTotal();
      }
    });
  }

  resetExtraCost() {
    this.extraPrice = 0;
    this.updateTotal();
  }

  openDiscountDialog(): void {
    this.isDiscountDialogOpen = true;
    this.discountAmount = this.discount; // Inicializar con el descuento actual
  }

  openCouponDialog(): void {
    this.isCouponDialogOpen = true;
  }

  closeDiscountDialog(): void {
    this.isDiscountDialogOpen = false;
  }

  openPackageDialog() {
    this.isPackageDialogOpen = true;
  }

  closePackageDialog() {
    this.isPackageDialogOpen = false;
  }

  selectPackage(pkg: any) {
    this.selectedPackage = pkg;
    this.packageCost = parseFloat(pkg.price);
    this.shipmentData.packing = {
      answer: 'Si',
      packing_id: pkg._id,
      packing_type: pkg.name,
      packing_cost: this.packageCost.toString(),
    };
    this.updateTotal();
    this.closePackageDialog();
  }

  removePackage() {
    this.selectedPackage = null;
    this.packageCost = 0;
    this.shipmentData.packing = {
      answer: 'No',
      packing_type: 'None',
      packing_cost: '0',
    };
    this.updateTotal();
  }

  addExtraCost(cost: number) {
    this.extraPrice += cost;
    this.updateTotal();
  }

  updateTotal() {
    this.total =
      this.subtotal + this.packageCost + this.extraPrice - this.discount;
    console.log('Total:', this.total);
    console.log('Subtotal:', this.subtotal);
    console.log('Paquete:', this.packageCost);
    console.log('Extra:', this.extraPrice);
    console.log('Descuento:', this.discount);
    if (this.total < this.precio_minimo) {
      console.log(
        'El precio total no puede ser menor al precio mínimo',
        this.precio_minimo
      );
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          'El precio total no puede ser menor al precio mínimo, el precio mínimo es de: $' +
          this.precio_minimo,
      });
      this.total = this.precio_minimo;
      this.discount = 0;
      this.updateTotal();
      return;
    }

    this.shipmentData.cost = this.paqueteriaData.precioOriginal.toString();
    this.shipmentData.price = this.total.toString();
    this.shipmentData.extra_price = this.extraPrice.toString();
    this.shipmentData.discount = (
      this.discount + this.cuponDiscount
    ).toString();


    console.log(
      'Ala utilidad del licenciatario se le restan:',
      this.discount,
      'y queda en:',
      this.shipmentData.licenciatarioProfit
    );

    console.log('Total:', this.total);
    console.log('Dagpacket profit:', this.dagpacketProfit);
  }

  applyDiscount() {
    // Almacenamos el precio original para referencia
    const originalPrice = this.total;

    // Si discountAmount es negativo, es un descuento, si es positivo, es un aumento
    if (this.discountAmount < 0) {
      // Si es un descuento, se aplica de la forma tradicional
      this.discount = Math.min(Math.abs(this.discountAmount), originalPrice);
      // Ajusta el subtotal con el descuento
      this.subtotal -= this.discount; // Se resta el descuento al subtotal
    } else {
      // Si es un aumento, se ajusta el extraPrice
      console.log('Aumento:', this.discountAmount);
      console.log('Descuento:', this.discount);
      console.log('Subtotal:', this.subtotal);
      this.discount = 0; // No se aplica descuento en caso de aumento
      this.extraPrice = Math.max(0, this.discountAmount); // Aseguramos que el aumento no sea negativo
      // Ajusta el subtotal con el aumento
      this.subtotal += this.extraPrice; // Se suma el aumento al subtotal
    }

    // Verifica que el descuento no sea mayor al precio mínimo (en caso de descuento)
    if (this.total < this.precio_minimo) {
      console.log(
        'El descuento no puede ser mayor al precio mínimo',
        this.precio_minimo
      );
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          'El descuento no puede ser mayor al precio mínimo, el precio mínimo es de: $' +
          this.precio_minimo,
      });
      this.discount = 0;
      this.extraPrice = 0; // Reseteamos el extraPrice si hubo error
      return;
    }

    // Calcula el precio total después de aplicar el descuento o aumento
    const totalWithDiscountOrIncrease = this.subtotal + this.packageCost;

    console.log('Total con descuento/aumento:', totalWithDiscountOrIncrease);
    console.log('Precio aumentado (extraPrice):', this.extraPrice);
    console.log('Subtotal:', this.subtotal);
    console.log('Paquete:', this.packageCost);

    console.log('Total:', this.total);
    // Actualiza el total
    this.total = totalWithDiscountOrIncrease;

    console.log('Total Actualizado:', this.total);

    // Si el total es menor que el precio mínimo, ajustamos
    if (this.total < this.precio_minimo) {
      console.log(
        'El precio total no puede ser menor al precio mínimo',
        this.precio_minimo
      );
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          'El precio total no puede ser menor al precio mínimo, el precio mínimo es de: $' +
          this.precio_minimo,
      });
      this.total = this.precio_minimo;
      this.discount = 0;
      this.extraPrice = 0;
      this.subtotal = originalPrice; // Restablecemos el subtotal original en caso de error
      this.updateTotal();
      return;
    }

    // Actualiza los datos de envío
    this.shipmentData.cost = this.paqueteriaData.precioOriginal.toString();
    this.shipmentData.price = this.total.toString();
    this.shipmentData.extra_price = this.extraPrice.toString();
    this.shipmentData.discount = this.discount.toString();
    this.shipmentData.dagpacket_profit = this.dagpacketProfit.toString();

    this.shipmentData.licenciatarioProfit = (
      this.licenseProfit -
      this.discount
    ).toFixed(2);

    console.log('a', this.licenseProfit);
    console.log(
      'Ala utilidad del licenciatario se le restan:',
      this.discount,
      'y queda en:',
      this.shipmentData.licenciatarioProfit
    );

    console.log('Total:', this.total);
    console.log('Dagpacket profit:', this.dagpacketProfit);

    // Cierra el diálogo de descuento o aumento
    this.closeDiscountDialog();

    console.log('Descuento/Aumento aplicado:', this.discount);
    console.log('Precio aumentado (extraPrice):', this.extraPrice);
  }

  createNewShipment() {
    this.isDisabled = true; // Deshabilita el botón
    console.log('Pedido generado');

    Swal.fire({
      title: 'Creando envío...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading(Swal.getConfirmButton());
      },
    });

    let dagPacketProfit = parseFloat(this.dagpacketProfit.toString());
    let licenseProfit = parseFloat(this.licenseProfit.toString());
    console.log('Utilidad DagPacket:', dagPacketProfit);
    console.log('Utilidad Licenciatario:', licenseProfit);
    console.log('Total utilidad:', this.totalUtilidad);
    const newShipment: ShipmentData = {
      user_id: this.userId,
      sub_user_id: this.SubUserId,
      shipment_type: this.shipmentData.shippingType as 'Paquete' | 'Sobre',
      from: this.fromData,
      to: this.toData,
      payment: {
        method: 'saldo',
        status: 'Pendiente',
      },
      packing: this.shipmentData.packing || {
        answer: 'No',
        packing_type: 'None',
        packing_cost: '0',
      },
      shipment_data: {
        height: this.dimensionesData.alto,
        width: this.dimensionesData.ancho,
        length: this.dimensionesData.largo,
        package_weight: this.dimensionesData.peso,
      },
      insurance: this.shipmentData.valor_declarado
        ? this.shipmentData.valor_declarado.toString()
        : '0',
      cost: this.paqueteriaData.precio_guia.toString(),
      price: this.total.toString(),
      extra_price: this.extraPrice.toString(),
      discount: (this.discount + this.cuponDiscount).toString(),
      status: 'Cotizado',
      dagpacket_profit: this.totalUtilidad.toFixed(2),
      utilitie_lic: this.licenseProfit + this.extraPrice,
      utilitie_dag: this.dagpacketProfit,
      cupon:
        this.selectedCoupon && (this.dag_cupon > 0 || this.lic_cupon > 0)
          ? {
              cupon_id: this.selectedCoupon._id,
              cupon_code: this.selectedCoupon.code,
              cupon_type: this.selectedCoupon.type,
              cupon_discount_dag: this.dag_cupon,
              cupon_discount_lic: this.lic_cupon,
            }
          : undefined, // Si no hay cupón, asigna 'undefined'
      description: this.shipmentData.description,
      provider: this.paqueteriaData.proveedor,
      apiProvider: this.shipmentData.apiProvider,
      idService: this.paqueteriaData.idServicio,
      isInternational: this.shipmentData.isInternational || false,
    };
    // console.log(this.shipmentData.description);
    localStorage.removeItem('fromData');
    localStorage.removeItem('toData');
    console.log('Sending shipment data:', newShipment);
    this.shipmentService.createShipment(newShipment, this.userId).subscribe(
      (response) => {
        Swal.close();
        this.isDisabled = false; // Habilita el botón
        if (response.success) {
          Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'El envío se ha creado exitosamente',
          });
          console.log('Shipment created successfully', response);
          console.log('Folio:', response.shipment);
          this.folio = response.shipment;
          this.router.navigate(['/pay-shipments']);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.message,
          });
        }
      },
      (error) => {
        console.error('Error creating shipment', error);
        // Add error handling here, like showing an error message to the user
      }
    );
  }

  updateDescription(newDescription: string) {
    this.shipmentData.description = newDescription;
  }

  formatCurrency(amount: number): string {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(amount);
  }

  hasSelectedPackage(): boolean {
    return this.selectedPackage !== null;
  }

  openEditForm(type: 'remitente' | 'destinatario') {
    this.isEditModalOpen = true;
    this.selectedFormType = type;
    console.log('Selected form type:', this.selectedFormType);
    if (type === 'remitente') {
      this.editForm = { ...this.fromData };
    } else {
      this.editForm = { ...this.toData };
    }
    this.isEditModalOpen = true;
  }

  closeEditModal() {
    this.isEditModalOpen = false;
    this.editForm = {};
  }

  saveEditedAddress() {
    if (this.selectedFormType === 'remitente') {
      this.fromData = { ...this.fromData, ...this.editForm };
    } else {
      this.toData = { ...this.toData, ...this.editForm };
    }

    // Update the shipment data
    this.shipmentData = {
      ...this.shipmentData,
      from: this.fromData,
      to: this.toData,
    };

    // Close the modal
    this.closeEditModal();
  }

  searchCoupons() {
    if (this.couponCode.trim() !== '') {
      this.isLoading = true;
      this.searchSubject.next(this.couponCode);
    } else {
      this.couponResults = [];
    }
  }
  selectCoupon(coupon: any) {
    this.couponCode = coupon.code;
    this.couponResults = [];
  }

  applyCoupon() {
    if (this.couponCode.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor, ingresa un código de cupón válido.',
      });
      return;
    }

    this.isLoading = true;
    this.cuponService.searchCupon(this.couponCode).subscribe(
      (response) => {
        this.isLoading = false;
        if (response.success && response.data.cupones.length > 0) {
          this.selectedCoupon = response.data.cupones[0]; // Guardar el cupón
          const { type, value, type_value } = this.selectedCoupon;
          console.log('Tipo de cupón:', type);

          // Inicializar las variables de descuento
          let couponDeduction = 0;
          let initialDagpacketProfit = this.dagpacketProfit;
          let initialLicenseProfit = this.licenseProfit;
          this.dag_cupon = 0; // Reiniciar el descuento para dagpacket
          this.lic_cupon = 0; // Reiniciar el descuento para licenciatario

          // Calcular el descuento según el tipo de cupón
          if (type_value === 'Porcentaje') {
            console.log('Descuento por porcentaje:', value);
            // Si es un porcentaje, aplicar el descuento sobre el subtotal
            if (type === 'Cupon Dagpacket') {
              couponDeduction = (this.dagpacketProfit * value) / 100;
              this.dagpacketProfit -= couponDeduction;
              this.dag_cupon = initialDagpacketProfit - this.dagpacketProfit; // Diferencia de lo que se ha restado
            } else if (type === 'Cupon Licenciatario') {
              couponDeduction = (this.licenseProfit * value) / 100;
              this.licenseProfit -= couponDeduction; // No sumar extraPrice a lic_cupon
              this.lic_cupon = initialLicenseProfit - this.licenseProfit; // Diferencia de lo que se ha restado
            } else if (type === 'Cupon Compuesto') {
              // Si es compuesto, aplicar el descuento sobre la utilidad total
              const totalProfit = this.totalUtilidad;
              console.log('Utilidad total:', totalProfit);
              couponDeduction = (value / 100) * totalProfit;

              console.log('Descuento compuesto:', couponDeduction);

              // Distribuir el descuento en las proporciones correspondientes
              const dagpacketDeduction = (totalProfit - couponDeduction) * 0.3;
              const licenseDeduction = (totalProfit - couponDeduction) * 0.7;

              console.log('Descuento compuesto:', couponDeduction);
              console.log('Utilidad total:', this.totalUtilidad);
              console.log('Utilidad DagPacket:', dagpacketDeduction);
              console.log('Utilidad Licenciatario:', licenseDeduction);
              // Restar el descuento de la utilidad total
              this.totalUtilidad -= couponDeduction;

              // Actualizar las utilidades individuales
              this.dagpacketProfit = dagpacketDeduction;
              this.licenseProfit = licenseDeduction; // No sumar extraPrice aquí

              // Guardar los descuentos aplicados
              this.dag_cupon = initialDagpacketProfit - this.dagpacketProfit; // Diferencia de lo que se ha restado
              this.lic_cupon = initialLicenseProfit - this.licenseProfit; // Diferencia de lo que se ha restado
            }
          } else if (type_value === 'Numero') {
            // Si es un valor fijo, restar el valor directamente
            if (type === 'Cupon Dagpacket') {
              couponDeduction = value;
              this.dagpacketProfit -= couponDeduction;
              this.dag_cupon = initialDagpacketProfit - this.dagpacketProfit; // Diferencia de lo que se ha restado
            } else if (type === 'Cupon Licenciatario') {
              couponDeduction = value;
              this.licenseProfit -= couponDeduction; // No sumar extraPrice a lic_cupon
              this.lic_cupon = initialLicenseProfit - this.licenseProfit; // Diferencia de lo que se ha restado
            } else if (type === 'Cupon Compuesto') {
              // Si es compuesto, aplicar el descuento sobre la utilidad total
              const totalProfit = this.totalUtilidad;
              couponDeduction = value;

              // Restar el descuento de la utilidad total
              this.totalUtilidad = totalProfit - couponDeduction;

              const dagpacketDeduction = this.totalUtilidad * 0.3;
              const licenseDeduction = this.totalUtilidad * 0.7;

              console.log('Descuento compuesto:', couponDeduction);
              console.log('Utilidad total:', this.totalUtilidad);
              console.log('Utilidad DagPacket:', dagpacketDeduction);
              console.log('Utilidad Licenciatario:', licenseDeduction);

              // Actualizar las utilidades individuales
              this.dagpacketProfit = dagpacketDeduction;
              this.licenseProfit = licenseDeduction; // No sumar extraPrice a licenciatario

              const FirstDagpacketDeduction = this.dagpacketProfit;
              const FirstLicenseDeduction = this.licenseProfit;

              // Guardar los descuentos aplicados
              this.dag_cupon = FirstDagpacketDeduction - dagpacketDeduction; // Diferencia de lo que se ha restado
              this.lic_cupon = FirstLicenseDeduction - licenseDeduction; // Diferencia de lo que se ha restado
            }
          }

          // Aquí se suma extraPrice solo a licenseProfit

          // Guardar el valor del cupón aplicado como descuento
          this.cuponDiscount = couponDeduction;
          this.total -= this.cuponDiscount;

          // Aplicar el descuento al subtotal
          this.subtotal -= this.discount; // Restamos el descuento al subtotal

          // Forzar detección de cambios en Angular
          this.cdRef.detectChanges();

          Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'Cupón aplicado correctamente.',
          });

          this.selectCoupon(this.selectedCoupon);

          console.log('Cupón encontrado:', this.selectedCoupon);
          console.log('Valor del cupón aplicado:', this.discount); // Mostrar el valor del cupón
          console.log('Descuento aplicado para Dagpacket:', this.dag_cupon);
          console.log('Descuento aplicado para Licenciatario:', this.lic_cupon);
          console.log('Nuevo totalUtilidad:', this.totalUtilidad); // Mostrar el nuevo valor de totalUtilidad
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El cupón ingresado no es válido.',
          });
        }
        this.isCouponDialogOpen = false;
      },
      (error) => {
        this.isLoading = false;
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al buscar el cupón. Por favor, intenta de nuevo.',
        });
      }
    );

    this.closeCouponDialog();
  }

  closeCouponDialog() {
    this.couponCode = '';
    this.couponResults = [];
    this.isCouponDialogOpen = false;
  }
}
