import { Component } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  isSidebarOpen: boolean = false;
  isCartOpen: boolean = false;
  cartItems: any[] = []; // Asegúrate de inicializar esto con los datos correctos

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
    const sidebarElement = document.querySelector('app-sidebar');
    if (sidebarElement) {
      if (this.isSidebarOpen) {
        sidebarElement.classList.add('open');
      } else {
        sidebarElement.classList.remove('open');
      }
    }
  }

  toggleCart(): void {
    this.isCartOpen = !this.isCartOpen;
  }

  openNotifications(): void {
    // Lógica para abrir notificaciones
  }

  logout(): void {
    // Lógica para cerrar sesión
  }
}
