<app-sidebar></app-sidebar>
<div
  *ngIf="alertMessage"
  class="fixed top-4 right-4 z-50 p-4 rounded-lg shadow-lg transition-all duration-300 ease-in-out"
  [ngClass]="{
    'bg-green-100 text-green-700': alertSuccess,
    'bg-red-100 text-red-700': !alertSuccess
  }"
>
  <p class="font-bold">{{ alertSuccess ? "Éxito" : "Error" }}</p>
  <p>{{ alertMessage }}</p>
</div>
<div class="ml-64 mt-14 bg-gray-100 min-h-screen">
  <div class="p-6">
    <div class="container mx-auto px-4 py-8">
      <h1 class="text-3xl font-bold mb-6 text-gray-800">Gestión de Caja</h1>

      <div *ngIf="loading" class="text-center p-4">
        <app-loader></app-loader>
      </div>

      <div
        *ngIf="error"
        class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded-r"
      >
        {{ error }}
      </div>

      <div
        *ngIf="!loading && !currentCashRegister"
        class="mb-6 bg-white p-6 rounded-lg shadow-md"
      >
        <p class="mb-4 text-gray-700">No hay caja abierta actualmente.</p>
        <button
          (click)="openCashRegister()"
          class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Abrir Caja
        </button>
      </div>

      <div *ngIf="!loading && currentCashRegister" class="mb-6">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-2xl font-semibold text-gray-800">Caja Actual</h2>
          <button
            (click)="closeCashRegister()"
            class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            Cerrar Caja
          </button>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div
            class="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg hover:scale-105"
          >
            <div class="bg-dagblue text-white p-4">
              <h3 class="text-lg font-semibold">Cajero</h3>
            </div>
            <div class="p-4">
              <p class="text-gray-700 font-medium break-all">
               ( {{ currentCashRegister.opened_by.name }}) {{ currentCashRegister.opened_by.email }}
              </p>
            </div>
          </div>

          <div
            class="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg hover:scale-105"
          >
            <div class="bg-dagblue text-white p-4">
              <h3 class="text-lg font-semibold">Fecha de Apertura</h3>
            </div>
            <div class="p-4">
              <p class="text-gray-700 font-medium">
                {{ currentCashRegister.opened_at | date : "medium" }}
              </p>
            </div>
          </div>

          <div
            class="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg hover:scale-105"
          >
            <div class="bg-dagblue text-white p-4">
              <h3 class="text-lg font-semibold">Total de Ventas</h3>
            </div>
            <div class="p-4">
              <p class="text-gray-700 font-medium text-xl">
                {{ currentCashRegister.total_sales | currency }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="!loading && currentCashRegister && transactions.length > 0"
        class="mb-8"
      >
        <h2 class="text-2xl font-bold text-gray-800 mb-6">
          Transacciones Recientes
        </h2>
        <div
          class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4 bg-[#F0F0F0]"
        >
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead
              class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold"
            >
              <tr>
                <th scope="col" class="px-6 py-3">Método de Pago</th>
                <th scope="col" class="px-6 py-3">Realizada por:</th>
                <th scope="col" class="px-6 py-3">Detalles</th>

                <th scope="col" class="px-6 py-3">Monto</th>
                <th scope="col" class="px-6 py-3">Fecha</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr
                *ngFor="let transaction of transactions"
                class="hover:bg-gray-50"
              >
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        class="h-10 w-10 rounded-full"
                        [src]="getPaymentMethodIcon(transaction.payment_method)"
                        alt="Payment method icon"
                      />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ getPaymentMethodName(transaction.payment_method) }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900">
                    ({{ transaction.operation_by.name }})
                    {{ transaction.operation_by.email }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900">
                    {{ transaction.description }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div
                    class="text-sm font-semibold"
                    [ngClass]="{
                      'text-green-600': transaction.amount.$numberDecimal > 0,
                      'text-red-600': transaction.amount.$numberDecimal < 0
                    }"
                  >
                    {{ transaction.amount.$numberDecimal | currency }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900">
                    {{ transaction.createdAt | date : "medium" }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Paginación mejorada -->
        <div class="flex justify-center items-center space-x-4 py-4">
          <button
            (click)="onPageChange(currentPage - 1)"
            [disabled]="currentPage === 1"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
          <span class="text-xl font-bold">{{ currentPage }}</span>
          <button
            (click)="onPageChange(currentPage + 1)"
            [disabled]="currentPage === totalPages"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      </div>

      <div
        *ngIf="!loading && currentCashRegister && transactions.length === 0"
        class="mb-6 bg-white p-6 rounded-lg shadow-md"
      >
        <p class="text-gray-700">
          No hay transacciones registradas en esta caja.
        </p>
      </div>
    </div>
  </div>
</div>
