<app-sidebar></app-sidebar>
<div class="ml-64 mt-16 p-8">
  <h1 class="text-3xl font-bold mb-6">Mis Solicitudes de Tickets</h1>

  <!-- Filtros y búsqueda -->
  <div class="mb-6 flex justify-between items-center">
    <div class="flex space-x-4">
      <select class="border rounded-md p-2">
        <option>Todos los estados</option>
        <option>Abierto</option>
        <option>En progreso</option>
        <option>Cerrado</option>
      </select>
      <select class="border rounded-md p-2">
        <option>Última semana</option>
        <option>Último mes</option>
        <option>Último año</option>
      </select>
    </div>
    <input
      type="text"
      placeholder="Buscar ticket..."
      class="border rounded-md p-2 w-64"
    />
  </div>

  <!-- Tabla de tickets -->
  <div class="bg-white shadow-md rounded-lg overflow-hidden">
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            ID
          </th>
          <th
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Asunto
          </th>
          <th
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Estado
          </th>
          <th
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Fecha
          </th>
          <th
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Acciones
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr>
          <td class="px-6 py-4 whitespace-nowrap">#1001</td>
          <td class="px-6 py-4">Problema con el envío</td>
          <td class="px-6 py-4">
            <span
              class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
            >
              Abierto
            </span>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">2023-07-15</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
            <a href="#" class="text-indigo-600 hover:text-indigo-900"
              >Ver detalles</a
            >
          </td>
        </tr>
        <tr>
          <td class="px-6 py-4 whitespace-nowrap">#1002</td>
          <td class="px-6 py-4">Solicitud de reembolso</td>
          <td class="px-6 py-4">
            <span
              class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
            >
              En progreso
            </span>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">2023-07-10</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
            <a href="#" class="text-indigo-600 hover:text-indigo-900"
              >Ver detalles</a
            >
          </td>
        </tr>
        <tr>
          <td class="px-6 py-4 whitespace-nowrap">#1003</td>
          <td class="px-6 py-4">Consulta sobre tarifas</td>
          <td class="px-6 py-4">
            <span
              class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
            >
              Cerrado
            </span>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">2023-07-05</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
            <a href="#" class="text-indigo-600 hover:text-indigo-900"
              >Ver detalles</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Paginación -->
  <div class="mt-6 flex justify-between items-center">
    <span class="text-sm text-gray-700">
      Mostrando <span class="font-medium">1</span> a
      <span class="font-medium">3</span> de
      <span class="font-medium">3</span> resultados
    </span>
    <div class="flex space-x-2">
      <button
        class="px-4 py-2 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
      >
        Anterior
      </button>
      <button
        class="px-4 py-2 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
      >
        Siguiente
      </button>
    </div>
  </div>
</div>
