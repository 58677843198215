import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ShipmentData } from 'src/app/_models/shipments';
import { AuthLoginService } from '../users/auth-login.service';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Packing } from 'src/app/_models/packing';

interface ApiResponse<T> {
  success: boolean;
  message?: string;
  data?: T;
}

interface QuincenalProfit {
  shipmentProfit: number;
  packingProfit: number;
  servicesProfit: number;
  rechargesProfit: number;
}

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {
  private apiUrl = environment.apiUrl;

  constructor(
    private authService: AuthLoginService,
    private http: HttpClient
  ) { }

  // Métodos relacionados con empaques
  getPacking(userId: string)  : Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/stock/inventory/${userId}`);
  }

  createPacking(formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/packing/create`, formData).pipe(
      catchError(this.handleError('crear el empaque'))
    );
  }

  updatePacking(id: string, packing: Packing): Observable<any> {
    return this.http.patch(`${this.apiUrl}/packing/${id}`, packing).pipe(
      catchError(this.handleError('actualizar el empaque'))
    );
  }

  deletePacking(id: string): Observable<any>{
    return this.http.delete(`${this.apiUrl}/packing/${id}`)
  }

  getAllPackingList(page: number = 1, limit: number = 1000, search: string = ''): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    
    if (search) {
      params = params.set('search', search);
    }
    return this.http.get(`${this.apiUrl}/packing/list`, { params });
  }

  getPackingList(): Observable<any> {
    const userId = this.authService.getId();
    return this.http.get<any>(`${this.apiUrl}/stock/inventory/${userId}`);
  }

  // Métodos relacionados con envíos
  createShipment(shipmentData: ShipmentData, user_id: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/shipments/create/${user_id}`, shipmentData);
  }

  getPendingShipments(): Observable<any> {
    const userId = this.authService.getId();
    return this.http.get<any>(`${this.apiUrl}/shipments/pending/${userId}`);;
  }

  payShipments(ids: string[], paymentMethod: string, transactionNumber: string | null, cashRegisterId: string | null): Observable<any> {
    const userId = this.authService.getId();
    const payload = { ids, paymentMethod, transactionNumber, cashRegisterId };
    return this.http.patch(`${this.apiUrl}/shipments/pay`, payload);

  }

  cancelShipments(shipmentIds: string[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/cancel-shipments`, { shipmentIds });
  }

  getUserShipments(page: number = 1, limit: number = 10, sortBy: string = 'createdAt', sortOrder: 'asc' | 'desc' = 'desc'): Observable<any> {
    const userId = this.authService.getId();
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get(`${this.apiUrl}/shipments/list-shipments/${userId}`, { params });
  }

  getShipmentDetails(shipmentId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/shipments/details/${shipmentId}`);
  }

  getShipmentTracking(shipmentId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/tracking/tracking/${shipmentId}`);
  }


  getGlobalProfit(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/shipments/global-profit`);
  }

  createShipmentRecolect(data:any):Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/recolect/recolect`,data);
  }

  getAllShipments(
    page: number, 
    limit: number, 
    sortBy: string, 
    sortOrder: 'asc' | 'desc' = 'asc',
    searchName?: string,
    status?: string,
    dateFrom?: string,
    dateTo?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
  
    if (status) {
      params = params.set('status', status);
    }
  
    if (searchName) {
      params = params.set('searchName', searchName);
    }
  
    if (dateFrom) {
      params = params.set('dateFrom', dateFrom);
    }
  
    if (dateTo) {
      params = params.set('dateTo', dateTo);
    }
  
    return this.http.get<any>(`${environment.apiUrl}/shipments/all-shipments`, { params });
  }


  getQuincenalProfit(userId: string, year: number, month: number, quincena: 1 | 2): Observable<any> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('year', year.toString())
      .set('month', month.toString())
      .set('quincena', quincena.toString());
  
    return this.http.get<any>(`${this.apiUrl}/shipments/quincenal-profit`, { params })
      .pipe(
        map(response => {
          if (response.success) {
            console.log("Utilidad quincenal envíos:", JSON.stringify(response, null, 2));
  
            return {
              success: response.success,
              data: {
                shipmentProfit: response.data?.shipmentProfit ? parseFloat(response.data.shipmentProfit) : 0,
                packingProfit: response.data?.packingProfit ? parseFloat(response.data.packingProfit) : 0,
                totalShipments: response.data?.totalShipments || 0,
                totalProfit: response.data?.totalProfit ? parseFloat(response.data.totalProfit) : 0,
                period: response.data?.period || null
              }
            };
          } else {
            throw new Error(response.message || "Error al obtener datos de envíos y empaques");
          }
        }),
        catchError(this.handleError('obtener utilidad quincenal de envíos y empaques'))
      );
  }
  


  
  private handleError(operation = 'operation') {
    return (error: HttpErrorResponse): Observable<any> => {
      console.error(`Error en ${operation}:`, error);
      let errorMessage = `Error en ${operation}. `;
      if (error.error instanceof ErrorEvent) {
        // Error del cliente
        errorMessage += `Error: ${error.error.message}`;
      } else {
        // El backend devolvió un código de error
        errorMessage += `Código de error: ${error.status}, ` +
          `mensaje: ${error.error.message || 'Ocurrió un error desconocido'}`;
      }
      return throwError(() => new Error(errorMessage));
    };
  }

  
  getPaidShipments(page: number, limit: number, sortBy: string, sortOrder: 'asc' | 'desc', searchBy: string): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
      .set('searchBy', searchBy);
  
    return this.http.get<ApiResponse<any>>(`${this.apiUrl}/shipments/shipment-paid`, { params })
      .pipe(
        map(response => {
          console.log("Respuesta del servicio:", response); // Imprime la respuesta completa del servicio
          return response;
        }),
        catchError(this.handleError('obtener envíos pagados'))
      );
  }

  getPackingShipments(
    page: number = 1,
    limit: number = 10,
    sortBy: string = 'createdAt',
    searchBy: string = 'user_id',
    packing: string = 'Si',
    searchValue: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('sortBy', sortBy)
      .set('searchBy', searchBy)
      .set('packing', packing)
      .set('searchValue', searchValue);

    return this.http.get<any>(`${this.apiUrl}/shipments/shipment-paid`, { params }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error en la petición:', error);
        return throwError(() => new Error('Error al obtener los envíos con empaque.'));
      })
    );
  }
  

  

}
