<app-sidebar  class="hidden sm:block"></app-sidebar>
<div class="ml-64 mt-14">
  <div class="p-6">
    <span class="font-bold text-dagblue uppercase text-2xl">Solicitudes de cancelación</span>
    <p>Detalles de las solicitudes</p>
    
    <div class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4">            
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-dagblue rounded-t-lg">
          <tr class="text-sm text-white">
            <th scope="col" class="px-6 py-3 first:rounded-tl-lg cursor-pointer" (click)="changeSort('user_id.name')">
              Usuario
              <span *ngIf="sortBy === 'user_id.name'">[{{ sortOrder === 'asc' ? '↑' : '↓' }}]</span>
            </th>
            <th scope="col" class="px-6 py-3 cursor-pointer" (click)="changeSort('user_id.email')">
              Email
              <span *ngIf="sortBy === 'user_id.email'">[{{ sortOrder === 'asc' ? '↑' : '↓' }}]</span>
            </th>
            <th scope="col" class="px-6 py-3 cursor-pointer" (click)="changeSort('shipment_id')">
              ID del Envío
              <span *ngIf="sortBy === 'shipment_id'">[{{ sortOrder === 'asc' ? '↑' : '↓' }}]</span>
            </th>
            <th scope="col" class="px-6 py-3">Motivo</th>
            <th scope="col" class="px-6 py-3 cursor-pointer" (click)="changeSort('status')">
              Estatus
              <span *ngIf="sortBy === 'status'">[{{ sortOrder === 'asc' ? '↑' : '↓' }}]</span>
            </th>
            <th scope="col" class="px-6 py-3">MOTIVO DE RECHAZO</th>
            <th scope="col" class="px-6 py-3 cursor-pointer" (click)="changeSort('resolved_at')">
              Resuelta
              <span *ngIf="sortBy === 'resolved_at'">[{{ sortOrder === 'asc' ? '↑' : '↓' }}]</span>
            </th>
            <th scope="col" class="px-6 py-3 last:rounded-tr-lg cursor-pointer" (click)="changeSort('requested_at')">
              Solicitada
              <span *ngIf="sortBy === 'requested_at'">[{{ sortOrder === 'asc' ? '↑' : '↓' }}]</span>
            </th>
          </tr>
        </thead>
        <tbody class="text-gray-900 text-medium">
          <tr class="bg-white border-b duration-200" *ngFor="let request of userCancellationRequests">
            <td class="px-6 py-4 uppercase">{{ request.user_id?.name || 'No disponible' }}</td>
            <td class="px-6 py-4">{{ request.user_id?.email || 'No disponible' }}</td>
            <td class="px-6 py-4">{{ request.shipment_id || 'No disponible' }}</td>
            <td class="px-6 py-4">{{ request.motive || 'No disponible' }}</td>
            <td class="px-6 py-4">
              <span 
                [ngClass]="{
                  'bg-gradient-to-r': true,
                  'from-dagpk to-yellow-600': request.status === 'Pendiente',
                  'from-green-500 to-green-600': request.status === 'Aprobado',
                  'from-red-500 to-red-600': request.status === 'Rechazado'
                }"
                class="text-white px-2 font-bold rounded-lg"
              >
                {{ request.status || 'No disponible' }}
              </span>
            </td>
            <!-- Si tiene mensaje de rechazo, lo muestra; si no, pone 'No' -->
            <td class="px-6 py-4">{{ request.rejection_reason && request.status === 'Rechazado' ? request.rejection_reason : request.status === 'Aprobado' ? 'La solicitud se aprobo': " La solicitud esta pendiente"}}</td>
            <!-- Si tiene fecha de resolución, la muestra; si no, pone 'Aún no resuelta' -->
            <td class="px-6 py-4">{{ request.resolved_at ? (request.resolved_at | date: 'short') : 'Aún no resuelta' }}</td>
            <td class="px-6 py-4">{{ request.requested_at ? (request.requested_at | date:'short') : 'No disponible' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    
  </div>

  <!-- Paginación -->
  <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
    <div class="flex flex-1 justify-between sm:hidden">
      <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Anterior</button>
      <button (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Siguiente</button>
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Mostrando
          <span class="font-medium">{{ (currentPage - 1) * pageSize + 1 }}</span>
          a
          <span class="font-medium">{{ Math.min(currentPage * pageSize, totalItems) }}</span>
          de
          <span class="font-medium">{{ totalItems }}</span>
          resultados
        </p>
      </div>
      <div>
        <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button (click)="changePage(1)" [disabled]="currentPage === 1" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Primera</span>
            <span aria-hidden="true">&laquo;&laquo;</span>
          </button>
          <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Anterior</span>
            <span aria-hidden="true">&laquo;</span>
          </button>
          <ng-container *ngFor="let page of pageRange()">
            <button (click)="changePage(page)" 
                    [class.bg-indigo-600]="currentPage === page" 
                    [class.text-white]="currentPage === page" 
                    class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              {{ page }}
            </button>
          </ng-container>
          <button (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Siguiente</span>
            <span aria-hidden="true">&raquo;</span>
          </button>
          <button (click)="changePage(totalPages)" [disabled]="currentPage === totalPages" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Última</span>
            <span aria-hidden="true">&raquo;&raquo;</span>
          </button>
        </nav>
      </div>
    </div>
  </div>
</div>