<app-sidebar></app-sidebar>
<div class="ml-64 mt-14 bg-gray-100 min-h-screen">
  <div class="p-6">
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-dagblue uppercase text-3xl mb-6">
        Historiala de Recargas
      </h1>

      <!-- Botón para descargar CSV -->
      <button
        (click)="downloadCSV()"
        class="bg-dagblue text-white py-2 px-4 rounded hover:bg-[#af3f23]"
      >
        Descargar CSV
      </button>
    </div>

    <!-- Tabla de Historial -->
    <div class="bg-[#F0F0F0] rounded-lg shadow-md overflow-x-auto">
      <table class="w-full min-w-max">
        <thead class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold">
          <tr>
            <th class="px-6 py-3 text-left">Fecha</th>
            <th class="px-6 py-3 text-left">Terminal</th>
            <th class="px-6 py-3 text-left">Respuesta</th>
            <th class="px-6 py-3 text-left">Invoice</th>
            <th class="px-6 py-3 text-left">Producto</th>
            <th class="px-6 py-3 text-left">Cantidad</th>
            <th class="px-6 py-3 text-left">Cuenta</th>
            <th class="px-6 py-3 text-left">Response Code</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 text-gray-500">
          <tr
            *ngFor="let transaction of paginatedTransactions"
            class="hover:bg-gray-50"
          >
            <td class="px-6 py-4">
              {{ transaction.Date_Time | date : "dd/MM/yyyy HH:mm:ss" }}
            </td>
            <td class="px-6 py-4">{{ transaction.Terminal_Id }}</td>
            <td class="px-6 py-4 w-10 h-10">
              {{ transaction.Response_Transaction }}
            </td>
            <td class="px-6 py-4">{{ transaction.Inovice_Id }}</td>
            <td class="px-6 py-4">{{ transaction.Product_Id }}</td>
            <td class="px-6 py-4">{{ transaction.Amount_Id }}</td>
            <td class="px-6 py-4">{{ transaction.Account_Id }}</td>
            <td class="px-6 py-4">{{ transaction.ResponseCode }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Controles de paginación -->
    <div class="flex justify-center items-center space-x-4 py-4">
      <button
        (click)="changePage(currentPage - 1)"
        [disabled]="currentPage === 1"
        class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </button>
      <span class="text-xl font-bold">{{ currentPage }}</span>
      <button
        (click)="changePage(currentPage + 1)"
        [disabled]="currentPage === totalPages"
        class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    </div>
  </div>
</div>
