<app-sidebar></app-sidebar>
<div class="ml-64 pt-20">
  <div class="container mx-auto">
    <h1 class="text-3xl font-bold mb-6 text-dagblue">
      Historial de Cortes de Caja
    </h1>

<!-- Panel de filtros y contadores mejorado -->
<div class="mb-6 bg-white p-6 rounded-lg shadow-md">
  <!-- Contadores de estado -->
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
    <!-- Contador: Abiertas -->
    <div class="bg-green-50 rounded-lg p-4 border-l-4 border-green-500 transition-all duration-300 hover:shadow-md">
      <div class="flex items-center">
        <div class="rounded-full bg-green-100 p-3 mr-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-green-600">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
          </svg>
        </div>
        <div>
          <p class="text-sm text-green-700 font-medium">Cajas Abiertas</p>
          <p class="text-2xl font-bold text-green-600">{{ statusCount.open }}</p>
        </div>
      </div>
    </div>
    
    <!-- Contador: Cerradas -->
    <div class="bg-blue-50 rounded-lg p-4 border-l-4 border-blue-500 transition-all duration-300 hover:shadow-md">
      <div class="flex items-center">
        <div class="rounded-full bg-blue-100 p-3 mr-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-blue-600">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
          </svg>
        </div>
        <div>
          <p class="text-sm text-blue-700 font-medium">Cajas Cerradas</p>
          <p class="text-2xl font-bold text-blue-600">{{ statusCount.closed }}</p>
        </div>
      </div>
    </div>
    
    <!-- Contador: Pendientes -->
    <div class="bg-amber-50 rounded-lg p-4 border-l-4 border-amber-500 transition-all duration-300 hover:shadow-md">
      <div class="flex items-center">
        <div class="rounded-full bg-amber-100 p-3 mr-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-amber-600">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
          </svg>
        </div>
        <div>
          <p class="text-sm text-amber-700 font-medium">Pendientes</p>
          <p class="text-2xl font-bold text-amber-600">{{ statusCount.pending_review }}</p>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Formulario de filtros -->
  <div class="border-t border-gray-200 pt-4">
    <h3 class="text-lg font-medium text-gray-700 mb-4">Filtros de búsqueda</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 items-end">
      <!-- Fecha Inicio -->
      <div>
        <label for="startDate" class="block text-sm font-medium text-gray-700 mb-1">
          Fecha Inicio
        </label>
        <input
          type="date"
          id="startDate"
          [(ngModel)]="startDate"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-dagpk focus:ring focus:ring-dagpk focus:ring-opacity-30 transition-all duration-300"
        />
      </div>
      
      <!-- Fecha Fin -->
      <div>
        <label for="endDate" class="block text-sm font-medium text-gray-700 mb-1">
          Fecha Fin
        </label>
        <input
          type="date"
          id="endDate"
          [(ngModel)]="endDate"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-dagpk focus:ring focus:ring-dagpk focus:ring-opacity-30 transition-all duration-300"
        />
      </div>
      
      <!-- Usuarios -->
      <div>
        <label for="childUsers" class="block text-sm font-medium text-gray-700 mb-1">
          Usuarios
        </label>
        <select
          name="childUsers"
          id="childUsers"
          (change)="onSelectChildUser($event)"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-dagpk focus:ring focus:ring-dagpk focus:ring-opacity-30 transition-all duration-300"
        >
          <option value="">Todos los usuarios</option>
          <option *ngFor="let user of childUsers" [value]="user._id">
            {{ user.email }}
          </option>
        </select>
      </div>
      
      <!-- Estado -->
      <div>
        <label for="status" class="block text-sm font-medium text-gray-700 mb-1">
          Estado
        </label>
        <select
          name="status"
          id="status"
          (change)="onSelectStatus($event)"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-dagpk focus:ring focus:ring-dagpk focus:ring-opacity-30 transition-all duration-300"
        >
          <option value="">Todos</option>
          <option value="open">Abierta</option>
          <option value="closed">Cerrada</option>
          <option value="pending_review">Pendiente</option>
        </select>
      </div>
      
      <!-- Botones -->
      <div class="flex space-x-2">
        <button
          (click)="applyDateFilter()"
          class="px-4 py-2 bg-dagpk text-white rounded-md hover:bg-orange transition duration-300 flex-1"
        >
          <span class="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
            Filtrar
          </span>
        </button>
        <button
          (click)="clearDateFilter()"
          class="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-300 flex-1"
        >
          <span class="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            Limpiar
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

    <!-- Lista de cortes de caja -->
    <div *ngIf="loading" class="text-center py-4">
      <div class="spinner"></div>
      <p class="mt-2 text-gray-600">Cargando registros...</p>
    </div>

    <div
      *ngIf="error"
      class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4"
      role="alert"
    >
      <p>{{ error }}</p>
    </div>

    <div
      *ngIf="!loading && cashRegisters.length === 0"
      class="text-center py-4 text-gray-600"
    >
      No se encontraron registros de caja para el período seleccionado.
    </div>

    <div *ngIf="!loading && cashRegisters.length > 0" class="space-y-8">
      <div
        *ngFor="let register of cashRegisters"
        class="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
      >
        <!-- Encabezado del registro con barra de estado -->
        <div class="relative">
          <div
            [ngClass]="{
              'bg-green-600': register.status === 'open',
              'bg-red-600': register.status === 'closed',
              'bg-amber-500': register.status === 'pending_review'
            }"
            class="h-1 w-full"
          ></div>

          <div
            class="px-6 py-5 flex flex-wrap md:flex-nowrap justify-between items-start gap-4 border-b border-gray-200"
          >
            <!-- Información principal -->
            <div class="flex-grow">
              <div class="flex items-center gap-3 mb-2">
                <h2 class="text-xl font-bold text-gray-800">
                  Corte de Caja #{{ register.opened_by.name }}
                </h2>
                <span
                  [ngClass]="{
                    'bg-red-50 text-red-800': register.status === 'closed',
                    'bg-green-50 text-green-800 animate-pulse':
                      register.status === 'open',
                    'bg-amber-50 text-amber-800':
                      register.status === 'pending_review'
                  }"
                  class="px-3 py-1 rounded-full text-xs font-medium"
                >
                  <span *ngIf="register.status === 'closed'">Cerrada</span>
                  <span *ngIf="register.status === 'open'">Abierta</span>
                  <span *ngIf="register.status === 'pending_review'"
                    >Pendiente</span
                  >
                </span>
              </div>

              <p class="text-sm text-gray-600 flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Abierta: {{ register.opened_at | date : "dd MMM yyyy, HH:mm" }}
                <span
                  *ngIf="register.closed_at"
                  class="flex items-center gap-1 ml-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Cerrada:
                  {{ register.closed_at | date : "dd MMM yyyy, HH:mm" }}
                </span>
              </p>
            </div>

            <!-- Resumen de pagos y totales -->
            <div
              class="flex flex-wrap md:flex-nowrap gap-6 mt-2 w-full md:w-auto"
            >
              <!-- Métodos de pago -->
              <div
                class="bg-blue-50 p-4 rounded-lg flex-1 shadow-sm border border-blue-100"
              >
                <h3
                  class="text-sm font-semibold text-gray-700 mb-2 border-b border-gray-200 pb-1"
                >
                  Métodos de Pago
                </h3>
                <div class="space-y-1">
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Tarjeta de Débito:</span>
                    <span class="font-medium">{{
                      formatCurrency(
                        getSumByTypePayment(register.transactions, "td-debito")
                      )
                    }}</span>
                  </div>
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Tarjeta de Crédito:</span>
                    <span class="font-medium">{{
                      formatCurrency(
                        getSumByTypePayment(register.transactions, "td-credito")
                      )
                    }}</span>
                  </div>
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Saldo:</span>
                    <span class="font-medium">{{
                      formatCurrency(
                        getSumByTypePayment(register.transactions, "saldo")
                      )
                    }}</span>
                  </div>
                </div>
              </div>

              <!-- Totales por tipo -->
              <div
                class="bg-gray-50 p-4 rounded-lg flex-1 shadow-sm border border-gray-200"
              >
                <h3
                  class="text-sm font-semibold text-gray-700 mb-2 border-b border-gray-200 pb-1"
                >
                  Resumen por Tipo
                </h3>
                <div class="space-y-1">
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Envíos:</span>
                    <span class="font-medium">{{
                      formatCurrency(
                        getSumByType(register.transactions, "envio")
                      )
                    }}</span>
                  </div>
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Servicios:</span>
                    <span class="font-medium">{{
                      formatCurrency(
                        getSumByType(register.transactions, "servicio")
                      )
                    }}</span>
                  </div>
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Recargas:</span>
                    <span class="font-medium">{{
                      formatCurrency(
                        getSumByType(register.transactions, "recarga")
                      )
                    }}</span>
                  </div>
                  <div
                    class="flex justify-between font-medium pt-1 border-t border-gray-200 mt-1"
                  >
                    <span class="text-gray-800">Total:</span>
                    <span class="text-gray-800">{{
                      formatCurrency(getTotalSum(register.transactions))
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tabla de transacciones -->
        <div class="px-6 py-4">
          <h3
            class="text-lg font-semibold text-gray-700 mb-4 flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mr-2 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
            Transacciones
          </h3>

          <div
            *ngIf="register.transactions.length === 0"
            class="text-gray-500 text-sm bg-gray-50 p-6 rounded-lg text-center italic shadow-inner"
          >
            No hay transacciones registradas para esta caja.
          </div>

          <div
            *ngIf="register.transactions.length > 0"
            class="overflow-x-auto rounded-lg border border-gray-200 shadow-sm"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                  >
                    Método de Pago
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                  >
                    Operacion
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                  >
                    Realizada por
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                  >
                    Monto
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                  >
                    Fecha
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  *ngFor="let transaction of register.transactions"
                  class="hover:bg-gray-50 transition-colors"
                >
                  <td class="px-4 py-3 text-sm">
                    <span
                      [ngClass]="{
                        'bg-blue-50 text-blue-700':
                          transaction.payment_method === 'td-debito',
                        'bg-purple-50 text-purple-700':
                          transaction.payment_method === 'td-credito',
                        'bg-green-50 text-green-700':
                          transaction.payment_method === 'efectivo',
                        'bg-yellow-50 text-yellow-700':
                          transaction.payment_method === 'saldo',
                        'bg-gray-50 text-gray-700': !transaction.payment_method
                      }"
                      class="px-2 py-1 rounded-full text-xs font-medium border"
                    >
                      {{ transaction.payment_method || "Desconocido" }}
                    </span>
                  </td>
                  <td class="px-4 py-3 text-sm text-gray-900">
                    {{ transaction.transaction_id.details || "Desconocido" }}
                  </td>
                  <td class="px-4 py-3 text-sm">
                    <div class="flex items-center">
                      <div
                        class="h-8 w-8 rounded-full bg-gray-100 text-gray-700 flex items-center justify-center mr-2 border border-gray-200"
                      >
                        <span class="font-bold text-xs m-auto">{{
                          (transaction?.operation_by?.name || "?").charAt(0)
                        }}</span>
                      </div>
                      <div>
                        <div class="font-medium">
                          {{ transaction?.operation_by?.name || "Desconocido" }}
                        </div>
                        <div class="text-xs text-gray-500">
                          {{
                            transaction?.operation_by?.email || "Desconocido"
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-4 py-3 text-sm font-medium">
                    <span
                      [ngClass]="{
                        'text-green-700': transaction.amount.$numberDecimal > 0,
                        'text-red-700': transaction.amount.$numberDecimal < 0
                      }"
                    >
                      {{ formatCurrency(transaction.amount.$numberDecimal) }}
                    </span>
                  </td>
                  <td class="px-4 py-3 text-sm text-gray-500">
                    {{ transaction.createdAt | date : "dd/MM/yyyy HH:mm" }}
                  </td>
                  <!-- Añade este código dentro del <tr> de cada transacción -->
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <button
                      (click)="transactionDetailsModal(transaction)"
                      class="text-dagblue hover:text-blue-900 bg-blue-100 hover:bg-blue-200 p-2 rounded-full transition-all duration-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Botón para cerrar caja -->
        <div
          class="px-6 py-4 border-t border-gray-200 bg-gray-50"
          *ngIf="
            register.status === 'open' || register.status === 'pending_review'
          "
        >
          <button
            (click)="closeCashRegister(register._id)"
            class="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all duration-300 flex items-center shadow-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
            Cerrar Caja
          </button>
        </div>
      </div>
    </div>
    <!-- Paginación -->
    <div
      *ngIf="totalPages > 1"
      class="mt-6 flex justify-between items-center bg-white p-4 rounded-lg shadow"
    >
      <div class="text-sm text-gray-700">
        Mostrando
        <span class="font-medium">{{
          (currentPage - 1) * itemsPerPage + 1
        }}</span>
        a
        <span class="font-medium">{{
          Math.min(currentPage * itemsPerPage, totalItems)
        }}</span>
        de <span class="font-medium">{{ totalItems }}</span> registros
      </div>
      <div class="flex space-x-2">
        <button
          (click)="onPageChange(currentPage - 1)"
          [disabled]="currentPage === 1"
          class="px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out"
        >
          Anterior
        </button>
        <button
          (click)="onPageChange(currentPage + 1)"
          [disabled]="currentPage === totalPages"
          class="px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal de Detalles de Transacción -->
<div
  *ngIf="modalOpen"
  class="fixed inset-0 z-[1001] flex items-center justify-center"
  style="position: fixed; top: 0; right: 0; bottom: 0; left: 0"
>
  <div
    class="absolute inset-0 bg-black bg-opacity-50"
    style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
  ></div>
  <div
    class="relative bg-white rounded-lg shadow-xl p-6 mx-4 sm:mx-0 max-w-lg w-full max-h-[90vh] overflow-y-auto"
    style="margin: 0 auto; max-width: 32rem"
  >
    <div
      class="flex justify-between items-center mb-4"
      style="display: flex; justify-content: space-between"
    >
      <h2 class="text-xl font-bold text-dagblue">Detalles de la Transacción</h2>
      <button
        (click)="modalOpen = false"
        class="text-gray-500 hover:text-gray-800"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>

    <div *ngIf="!transactionDetails" class="py-4 text-center">
      <app-loader></app-loader>
    </div>

    <div *ngIf="transactionDetails" class="space-y-4">
      <!-- Información principal -->
      <div class="bg-gray-50 p-4 rounded-lg">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <p class="text-sm text-gray-500">No. Transacción</p>
            <p class="font-medium">
              {{ transactionDetails.transaction_number || "No disponible" }}
            </p>
          </div>
          <div>
            <p class="text-sm text-gray-500">Fecha</p>
            <p class="font-medium">
              {{
                transactionDetails.transaction_date
                  ? (transactionDetails.transaction_date | date : "medium")
                  : "No disponible"
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- Método de pago -->
      <div class="flex items-center p-3 bg-blue-50 rounded-lg">
        <div class="flex-shrink-0 h-10 w-10 mr-3">
          <div
            class="bg-blue-600 text-white rounded-full w-10 h-10 flex items-center justify-center"
            style="display: flex; align-items: center; justify-content: center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
              style="position: relative; top: 0; left: 0"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
              />
            </svg>
          </div>
        </div>
        <div>
          <p class="text-sm text-gray-500">Método de Pago</p>
          <p class="font-semibold capitalize">
            {{ transactionDetails.payment_method || "No disponible" }}
          </p>
        </div>
      </div>

      <!-- Servicio -->
      <div class="flex items-center p-3 bg-indigo-50 rounded-lg">
        <div class="flex-shrink-0 h-10 w-10 mr-3">
          <div
            class="bg-indigo-600 text-white rounded-full w-10 h-10 flex items-center justify-center"
            style="display: flex; align-items: center; justify-content: center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
              style="position: relative; top: 0; left: 0"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
              />
            </svg>
          </div>
        </div>
        <div>
          <p class="text-sm text-gray-500">Servicio</p>
          <p class="font-semibold">
            {{ transactionDetails.service || "No disponible" }}
          </p>
        </div>
      </div>

      <!-- Monto y balance -->
      <div class="grid grid-cols-1 gap-3">
        <!-- Monto -->
        <div class="p-3 rounded-lg bg-red-50">
          <p class="text-sm text-gray-500">Monto</p>
          <p class="text-xl font-bold text-red-600">
            {{
              transactionDetails.amount &&
              transactionDetails.amount.$numberDecimal
                ? "-" + (transactionDetails.amount.$numberDecimal | currency)
                : "No disponible"
            }}
          </p>
        </div>

        <!-- Balance previo -->
        <div class="p-3 rounded-lg bg-gray-50">
          <p class="text-sm text-gray-500">Balance Previo</p>
          <p class="font-semibold text-gray-700">
            {{
              transactionDetails.previous_balance &&
              transactionDetails.previous_balance.$numberDecimal
                ? (transactionDetails.previous_balance.$numberDecimal
                  | currency)
                : "No disponible"
            }}
          </p>
        </div>

        <!-- Nuevo balance -->
        <div class="p-3 rounded-lg bg-gray-50">
          <p class="text-sm text-gray-500">Nuevo Balance</p>
          <p class="font-semibold text-gray-700">
            {{
              transactionDetails.new_balance &&
              transactionDetails.new_balance.$numberDecimal
                ? (transactionDetails.new_balance.$numberDecimal | currency)
                : "No disponible"
            }}
          </p>
        </div>
      </div>

      <!-- Licencia (User) -->
      <div class="p-3 bg-yellow-50 rounded-lg">
        <p class="text-sm text-gray-500 font-medium">Licencia</p>
        <div class="flex items-center mt-1">
          <div
            *ngIf="
              transactionDetails.user_id && transactionDetails.user_id.name
            "
            class="bg-yellow-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-2"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 1;
            "
          >
            <span style="transform: translateY(0.5px)">{{
              transactionDetails.user_id.name.charAt(0)
            }}</span>
          </div>
          <div
            *ngIf="
              !transactionDetails.user_id || !transactionDetails.user_id.name
            "
            class="bg-yellow-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-2"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 1;
            "
          >
            <span style="transform: translateY(0.5px)">?</span>
          </div>
          <div>
            <p class="font-medium">
              {{
                transactionDetails.user_id
                  ? (transactionDetails.user_id.name || "No disponible") +
                    " " +
                    (transactionDetails.user_id.surname || "")
                  : "No disponible"
              }}
            </p>
            <p class="text-sm text-gray-500">
              {{
                transactionDetails.user_id && transactionDetails.user_id.email
                  ? transactionDetails.user_id.email
                  : "No disponible"
              }}
            </p>
            <p class="text-sm text-gray-500">
              Tel:
              {{
                transactionDetails.user_id && transactionDetails.user_id.phone
                  ? transactionDetails.user_id.phone
                  : "No disponible"
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- Usuario que ejecutó (SubUser) -->
      <div class="p-3 bg-green-50 rounded-lg">
        <p class="text-sm text-gray-500 font-medium">Ejecutado por</p>
        <div class="flex items-center mt-1">
          <div
            *ngIf="
              transactionDetails.sub_user_id &&
              transactionDetails.sub_user_id.name
            "
            class="bg-green-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-2"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 1;
            "
          >
            <span style="transform: translateY(0.5px)">{{
              transactionDetails.sub_user_id.name.charAt(0)
            }}</span>
          </div>
          <div
            *ngIf="
              !transactionDetails.sub_user_id ||
              !transactionDetails.sub_user_id.name
            "
            class="bg-green-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-2"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 1;
            "
          >
            <span style="transform: translateY(0.5px)">?</span>
          </div>
          <div>
            <p class="font-medium">
              {{
                transactionDetails.sub_user_id
                  ? (transactionDetails.sub_user_id.name || "No disponible") +
                    " " +
                    (transactionDetails.sub_user_id.surname || "")
                  : "No disponible"
              }}
            </p>
            <p class="text-sm text-gray-500">
              {{
                transactionDetails.sub_user_id &&
                transactionDetails.sub_user_id.email
                  ? transactionDetails.sub_user_id.email
                  : "No disponible"
              }}
            </p>
            <p class="text-sm text-gray-500">
              Tel:
              {{
                transactionDetails.sub_user_id &&
                transactionDetails.sub_user_id.phone
                  ? transactionDetails.sub_user_id.phone
                  : "No disponible"
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- Estado -->
      <div class="p-3 bg-blue-50 rounded-lg">
        <p class="text-sm text-gray-500">Estado</p>
        <div class="flex items-center mt-1">
          <span
            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
          >
            {{ transactionDetails.status || "No disponible" }}
          </span>
        </div>
      </div>

      <!-- Descripción -->
      <div class="p-3 bg-gray-50 rounded-lg">
        <p class="text-sm text-gray-500">Detalles</p>
        <p class="mt-1">{{ transactionDetails.details || "No disponible" }}</p>
      </div>

      <!-- Envíos relacionados como enlaces -->
      <div
        *ngIf="
          transactionDetails.shipment_ids &&
          transactionDetails.shipment_ids.length > 0
        "
        class="p-3 bg-purple-50 rounded-lg"
      >
        <p class="text-sm text-gray-500 mb-2">Envíos relacionados</p>
        <div class="space-y-2">
          <div
            *ngFor="let shipmentId of transactionDetails.shipment_ids"
            class="flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 mr-2 text-purple-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
              />
            </svg>
            <a
              [routerLink]="['/shipment-details', shipmentId]"
              class="text-sm font-medium text-blue-600 hover:text-blue-800 hover:underline cursor-pointer transition-colors duration-200"
            >
              {{ shipmentId || "No disponible" }}
            </a>
          </div>
        </div>
      </div>

      <!-- Mensaje cuando no hay envíos relacionados -->
      <div
        *ngIf="
          !transactionDetails.shipment_ids ||
          transactionDetails.shipment_ids.length === 0
        "
        class="p-3 bg-purple-50 rounded-lg"
      >
        <p class="text-sm text-gray-500 mb-2">Envíos relacionados</p>
        <p class="text-sm text-gray-600 italic">
          No hay envíos relacionados con esta transacción
        </p>
      </div>
    </div>

    <div class="mt-6 flex justify-end">
      <button
        (click)="modalOpen = false"
        class="bg-dagblue hover:bg-blue-800 text-white font-medium py-2 px-4 rounded transition duration-300"
      >
        Cerrar
      </button>
    </div>
  </div>
</div>
