<app-sidebar></app-sidebar>
<div class="ml-64 mt-14">
  <div
    *ngIf="alertMessage"
    class="fixed top-4 right-4 z-50 p-4 rounded-lg shadow-lg transition-all duration-300 ease-in-out"
    [ngClass]="{
      'bg-green-100 text-green-700': alertSuccess,
      'bg-red-100 text-red-700': !alertSuccess
    }"
  >
    <p class="font-bold">{{ alertSuccess ? "Éxito" : "Error" }}</p>
    <p>{{ alertMessage }}</p>
  </div>

  <div
    *ngIf="showPinModal"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[1050]"
  >
    <div
      class="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white z-[1050]"
    >
      <h3 class="text-lg font-bold mb-4">Ingresar NIP</h3>
      <form (ngSubmit)="validateNipAndProceed()">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="nipInput"
            >NIP de seguridad</label
          >
          <input
            type="password"
            id="nipInput"
            [(ngModel)]="enteredPin"
            name="nipInput"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div class="flex items-center justify-between">
          <button
            class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Validar NIP
          </button>
          <button
            class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            (click)="closePinModal()"
            type="button"
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="p-6">
    <!-- Sección para Crear Nuevos Empaques -->
    <div class="ml-8">
      <div class="p-6">
        <span class="font-bold text-dagblue uppercase text-xl"
          >Crear Nuevo Empaque</span
        >
        <p>Gestión y creación de empaques</p>

        <div class="flex justify-end items-center mb-4">
          <div class="flex items-center">
            <input
              type="text"
              [(ngModel)]="packingSearchTerm"
              placeholder="Buscar empaque..."
              class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold w-64 mr-2"
            />
            <button
              (click)="searchPackings()"
              class="bg-[#D6542B] text-white px-4 py-2 rounded-full font-bold hover:bg-blue-900 mr-2"
            >
              Buscar
            </button>
            <button
              (click)="openNewPackingModal()"
              class="bg-[#D6542B] text-white px-4 py-2 rounded-full font-bold hover:bg-blue-900"
            >
              Nuevo Empaque
            </button>
          </div>
        </div>

        <app-new-packing-form
          (closeModal)="closeNewPackingModal($event)"
          *ngIf="showNewPackingModal"
        ></app-new-packing-form>

        <div
          class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4 bg-[#F0F0F0]"
        >
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead
              class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold"
            >
              <tr>
                <th scope="col" class="px-6 py-3">Imagen</th>
                <th scope="col" class="px-6 py-3">Nombre</th>
                <th scope="col" class="px-6 py-3">Tipo</th>
                <th scope="col" class="px-6 py-3">Precio de Venta</th>
                <th scope="col" class="px-6 py-3">Precio de Costo</th>
                <th scope="col" class="px-6 py-3">Acciones</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr *ngFor="let packing of packings" class="hover:bg-gray-50">
                <td class="px-6 py-4">
                  <img
                    [src]="'data:image/jpeg;base64,' + packing.image"
                    [alt]="packing.name"
                    class="w-16 h-16 object-cover rounded-full"
                  />
                </td>
                <td class="px-6 py-4">{{ packing.name }}</td>
                <td class="px-6 py-4">{{ packing.type }}</td>
                <td class="px-6 py-4">
                  ${{ packing.sell_price.$numberDecimal }}
                </td>
                <td class="px-6 py-4">
                  ${{ packing.cost_price.$numberDecimal }}
                </td>
                <td class="px-6 py-4">
                  <div class="flex justify-center space-x-2">
                    <button
                      (click)="editPacking(packing)"
                      class="bg-dagblue font-bold hover:bg-blue-900 duration-200 text-white px-4 py-2 rounded-full"
                    >
                      Editar
                    </button>
                    <button
                      (click)="openDeletePacking(packing._id)"
                      class="bg-red-500 font-bold hover:bg-red-700 duration-200 text-white px-4 py-2 rounded-full"
                    >
                      Eliminar
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Paginación para Empaques -->
        <div class="flex justify-center items-center space-x-4 py-4">
          <button
            (click)="onPackingPageChange(packingCurrentPage - 1)"
            [disabled]="packingCurrentPage === 1"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
          <span class="text-xl font-bold">{{ packingCurrentPage }}</span>
          <button
            (click)="onPackingPageChange(packingCurrentPage + 1)"
            [disabled]="packingCurrentPage === packingTotalPages"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- roles -->

    <div class="ml-8">
      <div class="p-6">
        <span class="font-bold text-dagblue uppercase text-2xl"
          >Roles Disponibles</span
        >
        <p>Gestión de los roles del sistema</p>

        <div class="flex justify-end items-center mb-4">
          <div class="flex items-center">
            <input
              type="text"
              [(ngModel)]="roleSearchTerm"
              (keyup.enter)="searchRoles()"
              class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold w-64 mr-2"
              placeholder="Buscar roles"
            />

            <button
              (click)="validateAndShowRoleCreationModal()"
              class="bg-[#D6542B] text-white px-4 py-2 rounded-full font-bold hover:bg-blue-900"
            >
              Crear rol
            </button>

            <button
              (click)="searchRoles()"
              class="bg-[#D6542B] text-white px-4 py-2 rounded-full font-bold hover:bg-blue-900"
            >
              Buscar
            </button>
          </div>
        </div>

        <div
          class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4 bg-[#F0F0F0]"
        >
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead
              class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold"
            >
              <tr>
                <th scope="col" class="px-6 py-3">Rol</th>
                <th scope="col" class="px-6 py-3">Tipo</th>
                <th scope="col" class="px-6 py-3">Acciones</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr *ngFor="let role of roles" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ role.role_name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">{{ role.type }}</td>

                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    class="bg-dagblue text-white px-4 py-2 rounded-full font-bold hover:bg-blue-900"
                    (click)="openDetallesRolModal(role)"
                  >
                    Detalles
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Paginación -->
        <div class="flex justify-center items-center space-x-4 py-4">
          <button
            (click)="onRolePageChange(roleCurrentPage - 1)"
            [disabled]="roleCurrentPage === 1"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
          <span class="text-xl font-bold">{{ roleCurrentPage }}</span>
          <button
            (click)="onRolePageChange(roleCurrentPage + 1)"
            [disabled]="roleCurrentPage === roleTotalPages"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="showDetallesRolModal"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div class="bg-white p-6 rounded-lg w-[90%] md:w-[50%] shadow-lg">
        <h2 class="text-xl font-bold mb-4">
          Detalles del Rol: {{ selectedRole?.role_name }}
        </h2>

        <div *ngFor="let category of data" class="category mb-4">
          <h3 class="font-semibold text-lg">{{ category.category }}</h3>
          <div
            *ngFor="let item of category.items"
            class="item flex items-center space-x-2"
          >
            <input
              type="checkbox"
              [checked]="selectedItems[category.category][item] || false"
              (change)="toggleItem(category.category, item)"
            />
            <label>{{ item }}</label>
          </div>
        </div>

        <div class="flex justify-end space-x-4 mt-4">
          <button
            (click)="closeDetallesRolModal()"
            class="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Cancelar
          </button>
          <button
            (click)="savePermissions()"
            class="bg-[#D6542B] text-white px-4 py-2 rounded hover:bg-blue-900"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="showCreatePermissionModal"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div class="bg-white p-6 rounded-lg w-[90%] md:w-[50%] shadow-lg">
        <h2 class="text-xl font-bold mb-4">Crear Nuevo Permiso</h2>

        <form (ngSubmit)="createPermission()" class="space-y-4">
          <!-- Role Name -->
          <div>
            <label for="roleName" class="block font-semibold mb-1"
              >Nombre del Rol</label
            >
            <input
              id="roleName"
              [(ngModel)]="newPermission.role_name"
              name="role_name"
              type="text"
              placeholder="Ingrese el nombre del rol"
              class="border border-gray-300 rounded px-4 py-2 w-full"
              required
            />
          </div>

          <!-- Has Wallet -->
          <div>
            <label for="hasWallet" class="block font-semibold mb-1"
              >Tiene Wallet</label
            >
            <select
              id="hasWallet"
              [(ngModel)]="newPermission.has_wallet"
              name="has_wallet"
              class="border border-gray-300 rounded px-4 py-2 w-full"
              required
            >
              <option value="" disabled selected>Seleccione</option>
              <option value="Si">Sí</option>
              <option value="No">No</option>
            </select>
          </div>

          <!-- Type -->
          <div>
            <label for="type" class="block font-semibold mb-1">Tipo</label>
            <input
              id="type"
              [(ngModel)]="newPermission.type"
              name="type"
              type="text"
              placeholder="Ingrese el tipo"
              class="border border-gray-300 rounded px-4 py-2 w-full"
              required
            />
          </div>

          <!-- Buttons -->
          <div class="flex justify-end space-x-4">
            <button
              type="button"
              (click)="closeCreatePermissionModal()"
              class="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="bg-[#D6542B] text-white px-4 py-2 rounded hover:bg-blue-900"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>

    <div
      *ngIf="showDeleteModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
    >
      <div class="bg-white p-5 rounded-lg shadow-lg w-[600px]">
        <div class="text-center">
          <h2 class="text-2xl font-bold mb-4">Confirmar eliminación</h2>
          <p class="text-gray-700 mb-6">
            ¿Estás seguro de que deseas eliminar este empaque?<br />
            <span class="text-red-600 font-bold"
              >Esta acción es irreversible.</span
            >
          </p>
          <div class="flex justify-center">
            <button
              (click)="cancelDelete()"
              class="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 mr-4"
            >
              Cancelar
            </button>
            <button
              (click)="confirmDelete()"
              class="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400"
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Edición de Empaque -->
    <div
      *ngIf="showEditModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
    >
      <div class="bg-white p-5 rounded-lg shadow-lg w-[600px]">
        <h2 class="text-xl font-bold mb-4">Editar Empaque</h2>
        <form
          #updatePackingForm="ngForm"
          (submit)="updatePacking(updatePackingForm)"
        >
          <div class="mb-4">
            <label for="editImage" class="block text-sm font-bold text-gray-700"
              >Imagen</label
            >
            <input
              type="file"
              id="editImage"
              name="image"
              (change)="onImageSelected($event)"
              accept="image/*"
              ngModel
              class="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
            />
          </div>
          <div class="flex gap-2">
            <div class="mb-4 w-1/2">
              <label
                for="editName"
                class="block text-sm font-bold text-gray-700"
                >Nombre</label
              >
              <input
                type="text"
                id="editName"
                name="name"
                required
                class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                [(ngModel)]="selectedPacking.name"
              />
            </div>
            <div class="mb-4 w-1/2">
              <label
                for="editSellPrice"
                class="block text-sm font-bold text-gray-700"
                >Precio de Venta</label
              >
              <input
                type="number"
                id="editSellPrice"
                name="sell_price"
                min="0"
                required
                class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                [(ngModel)]="selectedPacking.sell_price.$numberDecimal"
              />
            </div>
          </div>

          <div class="mb-4">
            <label
              for="editCostPrice"
              class="block text-sm font-bold text-gray-700"
              >Costo del empaque</label
            >
            <input
              type="number"
              id="editCostPrice"
              name="cost_price"
              min="0"
              required
              class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              [(ngModel)]="selectedPacking.cost_price.$numberDecimal"
            />
          </div>

          <div class="mb-4">
            <label for="editType" class="block text-sm font-bold text-gray-700"
              >Tipo</label
            >
            <input
              type="text"
              id="editType"
              [(ngModel)]="selectedPacking.type"
              name="type"
              required
              class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>

          <div class="grid grid-cols-2 gap-4">
            <div class="mb-4">
              <label
                for="editType"
                class="block text-sm font-bold text-gray-700"
                >Peso</label
              >
              <input
                type="text"
                id="editType"
                name="weigth"
                required
                class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                [(ngModel)]="selectedPacking.weigth"
              />
            </div>

            <div class="mb-4">
              <label
                for="editHeight"
                class="block text-sm font-bold text-gray-700"
                >Altura</label
              >
              <input
                type="number"
                id="editHeight"
                [(ngModel)]="selectedPacking.height"
                name="height"
                class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div class="mb-4">
              <label
                for="editWidth"
                class="block text-sm font-bold text-gray-700"
                >Ancho</label
              >
              <input
                type="number"
                id="editWidth"
                [(ngModel)]="selectedPacking.width"
                name="width"
                class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div class="mb-4">
              <label
                for="editLength"
                class="block text-sm font-bold text-gray-700"
                >Longitud</label
              >
              <input
                type="number"
                id="editLength"
                [(ngModel)]="selectedPacking.length"
                name="length"
                class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>

          <div class="mb-4">
            <label
              for="editDescription"
              class="block text-sm font-bold text-gray-700"
              >Descripción</label
            >
            <textarea
              id="editDescription"
              [(ngModel)]="selectedPacking.description"
              name="description"
              required
              class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            ></textarea>
          </div>

          <div class="flex justify-center mt-6 w-full">
            <button
              type="button"
              (click)="cancelEdit()"
              class="w-full mr-4 px-6 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-300"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="w-full px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300"
            >
              Guardar Cambios
            </button>
          </div>
        </form>
      </div>
    </div>
    <app-loader *ngIf="isLoading"></app-loader>
    <div
      *ngIf="showAlertPacking"
      [ngClass]="{
        'bg-green-100 border-green-400 text-green-700': !isError,
        'bg-red-100 border-red-400 text-red-700': isError
      }"
      class="border-l-4 p-4 mb-4"
      role="alert"
    >
      <p class="font-bold">{{ isError ? "Error" : "Éxito" }}</p>
      <p>{{ alertMessage }}</p>
    </div>

    
    <!-- Solicitudes de cancelación -->
    <div class="ml-8">
      <div class="p-6">
        <span class="font-bold text-dagblue uppercase text-2xl"
          >Solicitudes de Cancelación</span
        >
        <p>Gestión de las solicitudes de cancelación</p>

        <div class="flex justify-end items-center mb-4">
          <div class="flex items-center">
            <input
              type="text"
              [(ngModel)]="cancellationSearchTerm"
              (keyup.enter)="searchCancellationRequests()"
              class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold w-64 mr-2"
              placeholder="Buscar solicitudes"
            />
            <button
              (click)="searchCancellationRequests()"
              class="bg-[#D6542B] text-white px-4 py-2 rounded-full font-bold hover:bg-blue-900"
            >
              Buscar
            </button>
          </div>
        </div>

        <div
          class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4 bg-[#F0F0F0]"
        >
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead
              class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold"
            >
              <tr>
                <th scope="col" class="px-6 py-3">Usuario</th>
                <th scope="col" class="px-6 py-3">Motivo</th>
                <th scope="col" class="px-6 py-3">Estado</th>
                <th scope="col" class="px-6 py-3">Fecha de Solicitud</th>
                <th scope="col" class="px-6 py-3">Acciones</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr
                *ngFor="let request of cancellationRequests"
                class="hover:bg-gray-50"
              >
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ request.user_id?.name || "Desconocido" }} ({{
                    request.user_id?.email || "No disponible"
                  }})
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ request.motive }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span
                    [ngClass]="{
                      'text-yellow-500': request.status === 'Pendiente',
                      'text-green-600': request.status === 'Aprobado',
                      'text-red-600': request.status === 'Rechazado'
                    }"
                    class="font-bold text-lg"
                  >
                    {{ request.status }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ request.requested_at | date : "short" }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    class="bg-dagblue text-white px-4 py-2 rounded-full font-bold hover:bg-blue-900"
                    (click)="verDetalles(request)"
                  >
                    Detalles
                  </button>
                  <!-- <button
                    *ngIf="request.status === 'Pendiente'"
                    class="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-700"
                    (click)="abrirModalAccion(request, 'aprobar')"
                  >
                    Aprobar
                  </button>
                  <button
                    *ngIf="request.status === 'Pendiente'"
                    class="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-700"
                    (click)="abrirModalAccion(request, 'rechazar')"
                  >
                    Rechazar
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Paginación -->
        <div class="flex justify-center items-center space-x-4 py-4">
          <button
            (click)="onCancellationPageChange(cancellationCurrentPage - 1)"
            [disabled]="cancellationCurrentPage === 1"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
          <span class="text-xl font-bold">{{ cancellationCurrentPage }}</span>
          <button
            (click)="onCancellationPageChange(cancellationCurrentPage + 1)"
            [disabled]="cancellationCurrentPage === cancellationTotalPages"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- Nueva sección para Lista de Usuarios -->
    <div class="ml-8">
      <div class="p-6">
        <span class="font-bold text-dagblue uppercase text-2xl"
          >Lista de Usuarios</span
        >
        <p>Gestión de los usuarios registrados en el sistema</p>

        <div class="flex justify-end items-center mb-4">
          <div class="flex items-center">
            <input
              type="text"
              [(ngModel)]="searchTerm"
              (keyup.enter)="onSearch()"
              class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold w-64 mr-2"
              placeholder="Buscar usuario"
            />
            <button
              (click)="onSearch()"
              class="bg-[#D6542B] text-white px-4 py-2 rounded-full font-bold hover:bg-blue-900"
            >
              Buscar
            </button>
          </div>
        </div>

        <div
          class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4 bg-[#F0F0F0]"
        >
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead
              class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold"
            >
              <tr>
                <th scope="col" class="px-6 py-3">Nombre</th>
                <th scope="col" class="px-6 py-3">Email</th>
                <th scope="col" class="px-6 py-3">Rol</th>
                <th scope="col" class="px-6 py-3">Status</th>
                <th scope="col" class="px-6 py-3">Acciones</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr *ngFor="let user of users" class="hover:bg-gray-50">
                <td class="px-6 py-4 flex items-center">
                  <img
                    [src]="
                      user.image
                        ? 'data:image/jpeg;base64,' + user.image
                        : '../../../../assets/images/default-picture.jpg'
                    "
                    [alt]="user.name"
                    class="w-16 h-16 object-cover rounded-full mr-3"
                  />
                  {{ user.name }}
                </td>
                <td class="px-6 py-4">{{ user.email }}</td>
                <td class="px-6 py-4">{{ user.role }}</td>
                <td class="px-6 py-4">
                  <span
                    class="font-bold text-lg"
                    [ngClass]="{
                      'text-green-600': user.active,
                      'text-red-600': !user.active
                    }"
                  >
                    {{ user.active ? "Activado" : "Desactivado" }}
                  </span>
                </td>
                <td class="px-6 py-4">
                  <div class="flex justify-center space-x-2">
                    <button
                      (click)="openUserModal(user)"
                      class="bg-dagblue hover:bg-blue-900 text-white font-bold px-4 py-2 rounded-full transition duration-200 flex items-center space-x-2"
                      aria-label="Abrir opciones de usuario"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span>Gestionar Usuario</span>
                    </button>
                    <button
                      (click)="toggleUserAccount(user._id, user.active)"
                      class="bg-green-500 font-bold duration-200 text-white px-4 py-2 rounded-full"
                      [ngClass]="{
                        'bg-red-500 hover:bg-red-700 ': user.active,
                        'hover:bg-green-700': !user.active
                      }"
                    >
                      {{ user.active ? "Desactivar" : "Activar" }}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Paginación -->
        <div class="flex justify-center items-center space-x-4">
          <button
            (click)="onPageChange(currentPage - 1)"
            [disabled]="currentPage === 1"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
          <span class="text-xl font-bold">{{ currentPage }}</span>
          <button
            (click)="onPageChange(currentPage + 1)"
            [disabled]="currentPage === totalPages"
            class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- Nueva sección para Contenedores de Proveedores -->
    <div class="w-full px-4 py-8">
      <div
        class="bg-white rounded-lg shadow-xl border border-gray-300 overflow-hidden"
      >
        <div class="p-6">
          <h2 class="text-3xl font-bold text-dagblue mb-4">
            Proveedores de Servicio
          </h2>
          <p class="text-gray-600 mb-6">
            Gestión de proveedores y sus paqueterías
          </p>

          <div class="space-y-6">
            <div
              *ngFor="let provider of providers"
              class="bg-gray-50 rounded-lg overflow-hidden shadow-md"
            >
              <div
                class="bg-gray-100 px-6 py-4 flex justify-between items-center cursor-pointer hover:bg-gray-200 transition-colors duration-200"
                (click)="toggleProvider(provider)"
              >
                <div class="flex items-center space-x-4">
                  <img
                    [src]="getProviderLogo(provider.name)"
                    [alt]="provider.name + ' logo'"
                    class="w-12 h-12 object-contain"
                  />
                  <h3 class="text-xl font-semibold text-dagblue">
                    {{ provider.name }}
                  </h3>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-dagblue transform transition-transform duration-200"
                  [ngClass]="{ 'rotate-180': provider.isExpanded }"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <div
                class="overflow-hidden transition-all duration-300 ease-in-out"
                [ngClass]="{
                  'max-h-0': !provider.isExpanded,
                  'max-h-[2000px]': provider.isExpanded
                }"
              >
                <div class="p-6 space-y-4">
                  <div
                    *ngFor="let subProvider of provider.providers"
                    class="bg-white rounded-lg p-4 shadow-sm"
                  >
                    <h4 class="font-semibold text-lg text-dagblue mb-3">
                      {{ subProvider.name }}
                    </h4>
                    <div
                      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
                    >
                      <div
                        *ngFor="let service of subProvider.services"
                        class="bg-gray-50 rounded-lg p-4 flex flex-col justify-between transition-all duration-200 hover:shadow-md"
                      >
                        <div>
                          <div
                            (click)="
                              toggleServiceStatus(
                                service,
                                subProvider.name,
                                provider.name
                              )
                            "
                            class="w-16 h-8 flex items-center rounded-full p-1 cursor-pointer transition duration-200"
                            [class.bg-blue-500]="service.status"
                            [class.bg-gray-300]="!service.status"
                          >
                            <div
                              class="bg-white w-6 h-6 rounded-full shadow-md transform transition duration-200"
                              [class.translate-x-8]="service.status"
                            ></div>
                          </div>

                          <p class="font-medium text-gray-800">
                            {{ service.name }}
                          </p>
                          <p class="text-sm text-gray-600">
                            ID: {{ service.idServicio }}
                          </p>
                          <p class="text-sm font-semibold text-dagblue mt-2">
                            Utilidad actual: {{ service.percentage }}%
                          </p>
                          <p class="text-sm font-semibold text-dagblue mt-2">
                            Estatus :
                            {{ service.status ? "activo" : "inactivo" }}
                          </p>
                        </div>
                        <button
                          (click)="
                            openUtilityModal(
                              service,
                              subProvider.name,
                              provider.name
                            )
                          "
                          class="mt-4 bg-dagblue text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300 ease-in-out flex items-center justify-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Editar Utilidad
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para editar utilidad -->
    <div
      *ngIf="showUtilityModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center"
    >
      <div
        class="relative p-8 bg-white w-full max-w-md mx-auto rounded-xl shadow-2xl"
      >
        <h2 class="text-2xl font-bold mb-4 text-dagblue">Editar Utilidad</h2>
        <p class="mb-6 text-gray-600">
          {{ selectedService?.name }} - {{ selectedService?.providerName }}
        </p>
        <form (ngSubmit)="saveUtility()">
          <div class="mb-6">
            <label
              for="utilityPercentage"
              class="block text-sm font-medium text-gray-700 mb-2"
              >Porcentaje de Utilidad</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                type="number"
                id="utilityPercentage"
                name="utilityPercentage"
                [(ngModel)]="utilityPercentage"
                class="focus:ring-dagblue focus:border-dagblue block w-full pl-4 pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder="0.00"
                min="0"
                max="100"
                step="0.01"
                required
              />
              <div
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <span class="text-gray-500 sm:text-sm">%</span>
              </div>
            </div>
          </div>
          <div class="flex justify-end space-x-3">
            <button
              type="button"
              (click)="closeUtilityModal()"
              class="px-4 py-2 bg-gray-200 text-gray-800 text-base font-medium rounded-md shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-300"
            >
              Cancelar
            </button>
            <button
              class="px-4 py-2 bg-dagblue text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
    <div
      *ngIf="showUserModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center"
    >
      <div
        class="bg-white rounded-lg shadow-xl p-6 m-4 max-w-4xl w-full h-3/4 flex flex-col"
      >
        <h2 class="text-2xl font-bold mb-4">Detalle del usuario</h2>
        <div class="flex flex-grow overflow-hidden">
          <!-- Barra lateral de opciones -->
          <div class="w-1/4 bg-gray-100 rounded-l-lg overflow-y-auto">
            <ul class="space-y-2 p-4">
              <li>
                <button
                  (click)="selectUserOption('info')"
                  class="flex items-center space-x-3 w-full p-2 rounded-md"
                  [ngClass]="{
                    'bg-blue-100 text-blue-600': selectedUserOption === 'info'
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Datos del usuario</span>
                </button>
              </li>
              <li>
                <button
                  (click)="selectUserOption('wallet')"
                  class="flex items-center space-x-3 w-full p-2 rounded-md"
                  [ngClass]="{
                    'bg-blue-100 text-blue-600': selectedUserOption === 'wallet'
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Wallet</span>
                </button>
              </li>
              <li>
                <button
                  (click)="selectUserOption('permissions')"
                  class="flex items-center space-x-3 w-full p-2 rounded-md"
                  [ngClass]="{
                    'bg-blue-100 text-blue-600':
                      selectedUserOption === 'permissions'
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Permisos</span>
                </button>
              </li>
              <li>
                <button
                  (click)="selectUserOption('contract')"
                  class="flex items-center space-x-3 w-full p-2 rounded-md"
                  [ngClass]="{
                    'bg-blue-100 text-blue-600':
                      selectedUserOption === 'contract'
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Tipo de Contrato</span>
                </button>
              </li>
              <li>
                <button
                  (click)="selectUserOption('utilities')"
                  class="flex items-center space-x-3 w-full p-2 rounded-md"
                  [ngClass]="{
                    'bg-blue-100 text-blue-600':
                      selectedUserOption === 'utilities'
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Utilidades</span>
                </button>
              </li>
              <li>
                <button
                  (click)="selectUserOption('discounts')"
                  class="flex items-center space-x-3 w-full p-2 rounded-md"
                  [ngClass]="{
                    'bg-blue-100 text-blue-600':
                      selectedUserOption === 'discounts'
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Descuentos</span>
                </button>
              </li>
            </ul>
          </div>

          <!-- Área de contenido principal -->
          <div class="w-3/4 bg-white rounded-r-lg p-6 overflow-y-auto">
            <ng-container [ngSwitch]="selectedUserOption">
              <div *ngSwitchCase="'info'">
                <h3 class="text-xl font-semibold mb-4">Datos del usuario</h3>
                <form (ngSubmit)="saveUserInfo()" #userForm="ngForm">
                  <div class="grid grid-cols-2 gap-4">
                    <div class="mb-4">
                      <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="name"
                      >
                        Nombre
                      </label>
                      <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="name"
                        type="text"
                        [(ngModel)]="selectedUser.name"
                        name="name"
                        required
                      />
                    </div>
                    <div class="mb-4">
                      <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="surname"
                      >
                        Apellido
                      </label>
                      <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="surname"
                        type="text"
                        [(ngModel)]="selectedUser.surname"
                        name="surname"
                        required
                      />
                    </div>
                    <div class="mb-4">
                      <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="phone"
                      >
                        Teléfono
                      </label>
                      <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="phone"
                        type="tel"
                        [(ngModel)]="selectedUser.phone"
                        name="phone"
                        required
                      />
                    </div>
                    <div class="mb-4">
                      <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="email"
                      >
                        Email
                      </label>
                      <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        [(ngModel)]="selectedUser.email"
                        name="email"
                        required
                      />
                    </div>
                  </div>

                  <div *ngIf="selectedUser.address" class="mt-6">
                    <h4 class="text-lg font-semibold mb-2">Dirección</h4>
                    <div class="grid grid-cols-2 gap-4">
                      <div class="mb-4">
                        <label
                          class="block text-gray-700 text-sm font-bold mb-2"
                          for="street"
                        >
                          Calle
                        </label>
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="street"
                          type="text"
                          [(ngModel)]="selectedUser.address.street"
                          name="street"
                        />
                      </div>
                      <div class="mb-4">
                        <label
                          class="block text-gray-700 text-sm font-bold mb-2"
                          for="external_number"
                        >
                          Número Exterior
                        </label>
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="external_number"
                          type="text"
                          [(ngModel)]="selectedUser.address.external_number"
                          name="external_number"
                        />
                      </div>
                      <div class="mb-4">
                        <label
                          class="block text-gray-700 text-sm font-bold mb-2"
                          for="internal_number"
                        >
                          Número Interior
                        </label>
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="internal_number"
                          type="text"
                          [(ngModel)]="selectedUser.address.internal_number"
                          name="internal_number"
                        />
                      </div>
                      <div class="mb-4">
                        <label
                          class="block text-gray-700 text-sm font-bold mb-2"
                          for="settlement"
                        >
                          Colonia
                        </label>
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="settlement"
                          type="text"
                          [(ngModel)]="selectedUser.address.settlement"
                          name="settlement"
                        />
                      </div>
                      <div class="mb-4">
                        <label
                          class="block text-gray-700 text-sm font-bold mb-2"
                          for="city"
                        >
                          Ciudad
                        </label>
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="city"
                          type="text"
                          [(ngModel)]="selectedUser.address.city"
                          name="city"
                        />
                      </div>
                      <div class="mb-4">
                        <label
                          class="block text-gray-700 text-sm font-bold mb-2"
                          for="state"
                        >
                          Estado
                        </label>
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="state"
                          type="text"
                          [(ngModel)]="selectedUser.address.state"
                          name="state"
                        />
                      </div>
                      <div class="mb-4">
                        <label
                          class="block text-gray-700 text-sm font-bold mb-2"
                          for="zip_code"
                        >
                          Código Postal
                        </label>
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="zip_code"
                          type="text"
                          [(ngModel)]="selectedUser.address.zip_code"
                          name="zip_code"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="mt-6 flex justify-end">
                    <button
                      type="submit"
                      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      [disabled]="!userForm.form.valid"
                    >
                      Guardar cambios
                    </button>
                  </div>
                </form>
              </div>

              <div *ngSwitchCase="'wallet'">
                <h3 class="text-xl font-semibold mb-4">Wallet</h3>
                <div *ngIf="selectedUser.wallet; else noWallet">
                  <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div class="bg-white shadow rounded-lg p-4">
                      <h4 class="font-semibold mb-2">Balance de Envíos</h4>
                      <p class="text-2xl font-bold text-green-600">
                        ${{
                          selectedUser.wallet.sendBalance | number : "1.2-2"
                        }}
                      </p>
                      <button
                        (click)="triggerAddFunds('envios')"
                        class="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Agregar fondos
                      </button>
                    </div>
                    <div class="bg-white shadow rounded-lg p-4">
                      <h4 class="font-semibold mb-2">Balance de Recargas</h4>
                      <p class="text-2xl font-bold text-blue-600">
                        ${{
                          selectedUser.wallet.rechargeBalance | number : "1.2-2"
                        }}
                      </p>
                      <button
                        (click)="triggerAddFunds('recargas')"
                        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Agregar fondos
                      </button>
                    </div>
                    <div class="bg-white shadow rounded-lg p-4">
                      <h4 class="font-semibold mb-2">Balance de Servicios</h4>
                      <p class="text-2xl font-bold text-purple-600">
                        ${{
                          selectedUser.wallet.servicesBalance | number : "1.2-2"
                        }}
                      </p>
                      <button
                        (click)="triggerAddFunds('servicios')"
                        class="mt-2 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Agregar fondos
                      </button>
                    </div>
                  </div>
                  <div class="mt-4">
                    <button
                      (click)="showTransactionHistory()"
                      class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Ver historial de transacciones
                    </button>
                  </div>
                </div>
                <ng-template #noWallet>
                  <p class="mb-4">
                    Este usuario aún no ha inicializado su wallet.
                  </p>
                  <button
                    (click)="initializeWallet(selectedUser._id)"
                    [disabled]="isInitializing"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    {{
                      isInitializing ? "Inicializando..." : "Inicializar Wallet"
                    }}
                  </button>
                </ng-template>
              </div>

              <div *ngSwitchCase="'permissions'">
                <h3 class="text-xl font-semibold mb-4">Permisos</h3>
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="role"
                  >
                    Rol
                  </label>
                  <select
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="role"
                    [(ngModel)]="selectedUser.role"
                    (change)="openRoleConfirmation($event)"
                  >
                    <option *ngFor="let role of roles" [value]="role.role_name">
                      {{ role.role_name }}
                    </option>
                    <!-- Agrega más opciones según los roles disponibles en tu sistema -->
                  </select>
                </div>

                <div
                  *ngIf="showRoleConfirmation"
                  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center"
                >
                  <div class="bg-white p-5 rounded-lg shadow-xl">
                    <h3 class="text-lg font-bold mb-4">
                      Confirmar cambio de rol
                    </h3>
                    <p>
                      ¿Estás seguro de que deseas cambiar el rol de este usuario
                      a {{ newRole }}?
                    </p>
                    <div class="mt-4 flex justify-end">
                      <button
                        (click)="cancelRoleChange()"
                        class="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                      >
                        Cancelar
                      </button>
                      <!-- aqui -->
                      <button
                        (click)="validateAndOpenRoleModal()"
                        class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                      >
                        Confirmar
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Sección para asignar usuario padre si el rol es CAJERO -->
                <div
                  *ngIf="
                    selectedUser.role === 'CAJERO' ||
                    selectedUser.role === 'CLIENTE_CORPORATIVO'
                  "
                  class="mt-4"
                >
                  <!-- Your existing content for parent user selection -->
                  <h4 class="text-lg font-semibold mb-2">
                    Asignar Usuario Padre
                  </h4>
                  <div class="mb-4">
                    <label
                      class="block text-gray-700 text-sm font-bold mb-2"
                      for="parentUser"
                    >
                      Usuario Padre
                    </label>
                    <select
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="parentUser"
                      [(ngModel)]="selectedParentUserId"
                    >
                      <option value="">Seleccione un usuario padre</option>
                      <option
                        *ngFor="let user of potentialParentUsers"
                        [value]="user._id"
                      >
                        {{ user.name }} {{ user.surname }} ({{ user.email }})
                      </option>
                    </select>
                  </div>
                  <button
                    (click)="assignParentUser()"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Asignar Usuario Padre
                  </button>
                </div>
              </div>

              <div *ngSwitchCase="'utilities'">
                <div class="mb-6 border-b pb-4">
                  <h3 class="text-lg font-semibold mb-4">
                    Utilidades Actuales
                  </h3>
                  <div class="grid grid-cols-2 gap-4">
                    <div class="bg-gray-100 p-3 rounded">
                      <p class="font-bold">Envíos:</p>
                      <p>
                        {{
                          selectedUser?.dagpacketPercentaje?.$numberDecimal ??
                            selectedUser.dagpacketPercentaje
                        }}%
                      </p>
                    </div>
                    <div class="bg-gray-100 p-3 rounded">
                      <p class="font-bold">Servicios:</p>
                      <p>
                        {{
                          selectedUser?.servicesPercentaje?.$numberDecimal ??
                            selectedUser.servicesPercentaje
                        }}%
                      </p>
                    </div>
                    <div class="bg-gray-100 p-3 rounded">
                      <p class="font-bold">Recargas:</p>
                      <p>
                        {{
                          selectedUser?.recharguesPercentage?.$numberDecimal ??
                            selectedUser.recharguesPercentage
                        }}%
                      </p>
                    </div>
                    <div class="bg-gray-100 p-3 rounded">
                      <p class="font-bold">Empaque:</p>
                      <p>
                        {{
                          selectedUser?.packingPercentage?.$numberDecimal ??
                            selectedUser.packingPercentage
                        }}%
                      </p>
                    </div>
                  </div>
                </div>

                <h3 class="text-lg font-semibold mb-4">
                  Actualizar Utilidades
                </h3>
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="dagpacketPercentaje"
                  >
                    Utilidades en envíos
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="dagpacketPercentaje"
                    type="number"
                    [(ngModel)]="selectedUser.dagpacketPercentaje"
                    [ngClass]="{
                      'border-red-500':
                        selectedUser.dagpacketPercentaje < 1 && isUpdating
                    }"
                    min="1"
                    max="100"
                    step="0.01"
                  />
                </div>
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="servicesPercentaje"
                  >
                    Utilidades de Servicios
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="servicesPercentaje"
                    type="number"
                    [(ngModel)]="selectedUser.servicesPercentaje"
                    min="1"
                    max="100"
                    step="0.01"
                  />
                </div>
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="recharguesPercentage"
                  >
                    Utilidades de Recargas
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="recharguesPercentage"
                    type="number"
                    [(ngModel)]="selectedUser.recharguesPercentage"
                    min="1"
                    max="100"
                    step="0.01"
                  />
                </div>
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="packingPercentage"
                  >
                    Utilidades de Empaque
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="packingPercentage"
                    type="number"
                    [(ngModel)]="selectedUser.packingPercentage"
                    min="1"
                    max="100"
                    step="0.01"
                  />
                </div>

                <!-- aqui -->
                <button
                  (click)="validateAndOpenUtilityUpdate()"
                  [disabled]="isUpdating"
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  [ngClass]="{ 'opacity-50 cursor-not-allowed': isUpdating }"
                >
                  {{ isUpdating ? "Guardando..." : "Guardar Utilidades" }}
                </button>
              </div>

              <div *ngSwitchCase="'discounts'">
                <h3 class="text-xl font-semibold mb-4">Descuentos</h3>
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="discountPercentage"
                  >
                    Porcentaje de Descuento
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="discountPercentage"
                    type="number"
                    [(ngModel)]="selectedUser.discountPercentage"
                    (change)="updateUserDiscounts()"
                  />
                </div>
                <!-- Aquí puedes agregar más campos de descuentos si es necesario -->
              </div>
            </ng-container>
          </div>
        </div>
        <div class="mt-4 flex justify-end">
          <button
            (click)="closeUserModal()"
            class="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
          >
            Volver
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="showAddFundsModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center"
    >
      <div class="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
        <h2 class="text-2xl font-bold mb-4 text-center">Añadir fondos</h2>

        <!-- Fondos Input Section -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="fundAmount"
          >
            Monto a añadir
          </label>
          <div class="flex items-center">
            <span class="mr-2 text-gray-600">$</span>
            <input
              id="fundAmount"
              type="number"
              [(ngModel)]="fundAmount"
              class="shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300 ease-in-out"
              placeholder="Ingrese el monto"
              min="0"
              step="0.01"
            />
          </div>
        </div>

        <div class="flex justify-between mt-6">
          <button
            (click)="closeModalAddFunds()"
            class="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
          >
            Cancelar
          </button>
          <button
            (click)="addFundsToWallet()"
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            Guardar
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="showTransactionHistoryModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50"
    >
      <div
        class="relative p-6 border w-full max-w-[70em] h-screen md:h-auto shadow-lg rounded-md bg-white overflow-auto"
      >
        <div class="text-center">
          <h3 class="text-xl font-bold text-gray-900 mb-4">
            Transacciones del usuario
          </h3>
          <div>
            <select (change)="onPageSizeChange($event)">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
          <div class="overflow-y-auto max-h-96">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Fecha
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Servicio
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tipo
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Metodo de Pago
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Monto
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Saldo anterior
                  </th>
                  <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Saldo nuevo
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr *ngIf="transactionHistory.length === 0">
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                    colspan="7"
                  >
                    No hay transacciones para mostrar
                  </td>
                </tr>

                <tr *ngFor="let transaction of transactionHistory">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{
                      transaction.transaction_date | date : "dd/MM/yyyy HH:mm"
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ transaction.service }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ transaction.details }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ transaction.payment_method }}
                  </td>
                  <td
                    [ngClass]="{
                      'text-orange-600': transaction.amount?.$numberDecimal < 0,
                      'text-[#342C6A]': transaction.amount?.$numberDecimal >= 0
                    }"
                    class="px-6 py-4 whitespace-nowrap text-sm font-semibold"
                  >
                    {{ transaction.amount?.$numberDecimal || "vacio" }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{
                      transaction.previous_balance?.$numberDecimal || "vacio"
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ transaction.new_balance?.$numberDecimal || "vacio" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex flex-row justify-center gap-2">
            <button
              (click)="previousPage()"
              [disabled]="!hasPreviousPage"
              class="px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
            >
              Anterior
            </button>
            <div *ngIf="totalPages > 0">
              <button
                *ngFor="let page of getPageNumbers()"
                (click)="goToPage(page)"
                [ngClass]="{
                  'bg-dagblue text-white': page === currentPageTransaction,
                  'bg-gray-300 text-gray-800': page !== currentPageTransaction
                }"
                class="mx-1 px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
              >
                {{ page }}
              </button>
            </div>

            <button
              (click)="nextPage()"
              [disabled]="!hasNextPage"
              class="px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
            >
              Siguiente
            </button>
            <p>Total de elementos {{ totalItemsTransaction }}</p>
          </div>
          <div class="mt-6 flex justify-end space-x-3">
            <button
              (click)="cerrarModalTransactionHistory()"
              class="px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para recharzar cancleaciones -->
    <div
      *ngIf="showRechazarModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[100] flex items-center justify-center"
    >
      <div
        class="relative p-6 border w-full max-w-md shadow-lg rounded-md bg-white"
      >
        <div class="mt-3 text-center">
          <h3 class="text-xl leading-6 font-bold text-gray-900 mb-4">
            Rechazar Solicitud de Cancelación
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500 mb-4">
              Por favor, ingrese la razón del rechazo:
            </p>
            <textarea
              [(ngModel)]="razonRechazo"
              class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              rows="4"
              placeholder="Ingrese la razón del rechazo aquí..."
            ></textarea>
          </div>
          <div class="mt-6 flex justify-end space-x-3">
            <button
              (click)="cerrarModalRechazo()"
              class="px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
            >
              Cancelar
            </button>
            <button
              (click)="confirmarAccion('Rechazado')"
              class="px-4 py-2 bg-red-600 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-150 ease-in-out"
            >
              Rechazar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Detalles de las cancelaciones -->
    <div
      *ngIf="showDetallesModal"
      class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
    >
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-4xl w-full"
      >
        <div class="p-6">
          <div class="flex justify-between items-center mb-6">
            <h2 class="text-2xl font-bold text-gray-800">
              Detalles de la Solicitud de Cancelación
            </h2>
            <button
              (click)="closeDetallesModal()"
              class="text-gray-400 hover:text-gray-600 transition duration-150"
            >
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div class="bg-gray-50 p-4 rounded-lg">
              <h3 class="text-lg font-semibold mb-3 text-gray-700">
                Información de la Solicitud
              </h3>
              <p class="mb-2">
                <span class="font-bold">ID:</span>
                {{ solicitudSeleccionada._id }}
              </p>
              <p class="mb-2">
                <span class="font-bold">Estado:</span>
                <span
                  [ngClass]="{
                    'text-yellow-600':
                      solicitudSeleccionada.status === 'Pendiente',
                    'text-green-600':
                      solicitudSeleccionada.status === 'Aprobado',
                    'text-red-600': solicitudSeleccionada.status === 'Rechazado'
                  }"
                  >{{ solicitudSeleccionada.status }}</span
                >
              </p>
              <p class="mb-2">
                <span class="font-bold">Fecha de Solicitud:</span>
                {{ solicitudSeleccionada.requested_at | date : "medium" }}
              </p>
              <p
                *ngIf="solicitudSeleccionada.status !== 'Pendiente'"
                class="mb-2"
              >
                <span class="font-bold">Fecha de Resolución:</span>
                {{ solicitudSeleccionada.resolved_at | date : "medium" }}
              </p>
            </div>

            <div class="bg-gray-50 p-4 rounded-lg">
              <h3 class="text-lg font-semibold mb-3 text-gray-700">
                Información del Usuario
              </h3>
              <p class="mb-2">
                <span class="font-bold">Nombre:</span>
                {{ solicitudSeleccionada.user_id?.name }}
              </p>
              <p class="mb-2">
                <span class="font-bold">Email:</span>
                {{ solicitudSeleccionada.user_id?.email }}
              </p>
            </div>
          </div>

          <div class="bg-gray-50 p-4 rounded-lg mb-6">
            <h3 class="text-lg font-semibold mb-3 text-gray-700">
              Detalles del Pedido
            </h3>
            <p class="mb-2">
              <span class="font-bold">Motivo de Cancelación:</span>
              {{ solicitudSeleccionada.motive }}
            </p>
            <p
              *ngIf="solicitudSeleccionada.status === 'Rechazado'"
              class="mb-2"
            >
              <span class="font-bold">Razón de Rechazo:</span>
              {{ solicitudSeleccionada.rejection_reason }}
            </p>
            <!-- Aquí puedes agregar más detalles del pedido si los tienes disponibles -->
          </div>

          <div class="flex justify-between items-center">
            <a
              *ngIf="solicitudSeleccionada.shipment_id?.guide"
              [href]="solicitudSeleccionada.shipment_id.guide"
              target="_blank"
              class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-150"
              (click)="verGuia(solicitudSeleccionada.shipment_id.guide)"
            >
              Ver Guía
            </a>

            <div
              *ngIf="solicitudSeleccionada.status === 'Pendiente'"
              class="space-x-2"
            >
              <button
                class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-150"
                (click)="abrirModalAccion(solicitudSeleccionada, 'aprobar')"
              >
                Aprobar
              </button>

              <button
                class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-150"
                (click)="abrirModalAccion(solicitudSeleccionada, 'rechazar')"
              >
                Rechazar
              </button>
            </div>

            <button
              class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition duration-150"
              (click)="closeDetallesModal()"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="showDetallesModal"
      class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
    >
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-4xl w-full"
      >
        <div class="p-6">
          <div class="flex justify-between items-center mb-6">
            <h2 class="text-2xl font-bold text-gray-800">
              Detalles de la Solicitud de Cancelación
            </h2>
            <button
              (click)="closeDetallesModal()"
              class="text-gray-400 hover:text-gray-600 transition duration-150"
            >
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div class="bg-gray-50 p-4 rounded-lg">
              <h3 class="text-lg font-semibold mb-3 text-gray-700">
                Información de la Solicitud
              </h3>
              <p class="mb-2">
                <span class="font-bold">ID:</span>
                {{ solicitudSeleccionada._id }}
              </p>
              <p class="mb-2">
                <span class="font-bold">Estado:</span>
                <span
                  [ngClass]="{
                    'text-yellow-600':
                      solicitudSeleccionada.status === 'Pendiente',
                    'text-green-600':
                      solicitudSeleccionada.status === 'Aprobado',
                    'text-red-600': solicitudSeleccionada.status === 'Rechazado'
                  }"
                  >{{ solicitudSeleccionada.status }}</span
                >
              </p>
              <p class="mb-2">
                <span class="font-bold">Fecha de Solicitud:</span>
                {{ solicitudSeleccionada.requested_at | date : "medium" }}
              </p>
              <p
                *ngIf="solicitudSeleccionada.status !== 'Pendiente'"
                class="mb-2"
              >
                <span class="font-bold">Fecha de Resolución:</span>
                {{ solicitudSeleccionada.resolved_at | date : "medium" }}
              </p>
            </div>

            <div class="bg-gray-50 p-4 rounded-lg">
              <h3 class="text-lg font-semibold mb-3 text-gray-700">
                Información del Usuario
              </h3>
              <p class="mb-2">
                <span class="font-bold">Nombre:</span>
                {{ solicitudSeleccionada.user_id?.name }}
              </p>
              <p class="mb-2">
                <span class="font-bold">Email:</span>
                {{ solicitudSeleccionada.user_id?.email }}
              </p>
            </div>
          </div>

          <div class="bg-gray-50 p-4 rounded-lg mb-6">
            <h3 class="text-lg font-semibold mb-3 text-gray-700">
              Detalles del Pedido
            </h3>
            <p class="mb-2">
              <span class="font-bold">Motivo de Cancelación:</span>
              {{ solicitudSeleccionada.motive }}
            </p>
            <p
              *ngIf="solicitudSeleccionada.status === 'Rechazado'"
              class="mb-2"
            >
              <span class="font-bold">Razón de Rechazo:</span>
              {{ solicitudSeleccionada.rejection_reason }}
            </p>
            <!-- Aquí puedes agregar más detalles del pedido si los tienes disponibles -->
          </div>

          <div class="flex justify-between items-center">
            <a
              *ngIf="solicitudSeleccionada.shipment_id?.guide"
              [href]="solicitudSeleccionada.shipment_id.guide"
              target="_blank"
              class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-150"
              (click)="verGuia(solicitudSeleccionada.shipment_id.guide)"
            >
              Ver Guía
            </a>

            <div
              *ngIf="solicitudSeleccionada.status === 'Pendiente'"
              class="space-x-2"
            >
              <button
                class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-150"
                (click)="triggerAccionConPin(solicitudSeleccionada, 'comision')"
              >
                Rembolso sin comisión
              </button>
              <button
                class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded transition duration-150"
                (click)="triggerAccionConPin(solicitudSeleccionada, 'aprobar')"
              >
                Rembolso Completo
              </button>
              <button
                class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-150"
                (click)="triggerAccionConPin(solicitudSeleccionada, 'rechazar')"
              >
                Rechazar
              </button>
            </div>

            <button
              class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition duration-150"
              (click)="closeDetallesModal()"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Aprobación -->
    <div
      *ngIf="showAprobarModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
    >
      <div
        class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
      >
        <div class="mt-3 text-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Confirmar Aprobación
          </h3>
          <div class="mt-2 px-7 py-3">
            <p class="text-sm text-gray-500">
              ¿Está seguro de que desea aprobar esta solicitud de cancelación?
            </p>
          </div>
          <div class="items-center px-4 py-3">
            <button
              (click)="confirmarAprobacion()"
              class="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
            >
              Aprobar
            </button>
            <button
              (click)="cerrarModalAprobacion()"
              class="mt-3 px-4 py-2 bg-gray-100 text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="showToast"
      class="fixed bottom-5 right-5 px-4 py-2 rounded-md text-white"
      [ngClass]="{
        'bg-green-500': toastType === 'success',
        'bg-red-500': toastType === 'error'
      }"
    >
      {{ toastMessage }}
    </div>
  </div>
</div>
