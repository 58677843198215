import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Users } from 'src/app/_models/user-model';
import { Observable, tap, BehaviorSubject, map } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginService {
  private userRole = new BehaviorSubject<string | null>(null);
  userRole$ = this.userRole.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService
  ) {
    this.checkToken();
  }

  signUpUser(user: Users){
    return this.http.post<any>(environment.apiUrl + '/users/signup', user);
  }

  signInUser(user: Users): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/users/login', {email: user.email, password: user.password})
      .pipe(
        map((response: any) => {
          if (response.success === true) {
            const id = response._id;
            const token = response.access_token;
            this.setToken(token);
            this.setID(id);
            this.setUserRole(token);
          }
          return response;
        })
      );
  }  

  userProfile(): Observable<any>{
    const id = this.getId();
    return this.http.get<any>(environment.apiUrl + '/users/profile/' + id)
  }

  

  getUserProfile(id: string): Observable<any>{
    return this.http.get<any>(environment.apiUrl + '/users/profile/' + id)
  }

  updateUser(user: Users){
    const id = this.getId();
    return this.http.patch<any>(environment.apiUrl + '/users/update/' + id, user);
  }

  addAddress(user: Users){
    const id = this.getId();
    return this.http.patch<any>(environment.apiUrl + '/users/address/' + id, user)
  }

  getCurrentUserRole(): Observable<string | null> {
    return this.userRole$;
  }

  setToken(token: string){
    localStorage.setItem('access_token', token);
    this.setUserRole(token);
  }

  setID(id: string){
    localStorage.setItem('id', id);
  }

  isLogged(){
    const token = this.getToken();    
    return !!token && !this.jwtHelper.isTokenExpired(token);
  }

  logout(){    
    localStorage.removeItem('access_token');
    localStorage.removeItem('id');
    this.userRole.next(null);
    this.router.navigate(['/login']);
  }

  getToken(){
    return localStorage.getItem('access_token');
  }

  getId(){
    return localStorage.getItem('id');
  }

  hasRole(role: string): boolean {
    return this.userRole.getValue() === role;
  }

  getRoleName(): string {
    return this.userRole.getValue() || '';
  }

  private setUserRole(token: string) {
    const decodedToken = this.jwtHelper.decodeToken(token);
    const role = decodedToken.role;
    this.userRole.next(role);
  }
  

  private checkToken() {
    const token = this.getToken();
    if (token) {
      this.setUserRole(token);
    }
  }
}