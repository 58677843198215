<div class="max-w-4xl mx-auto mt-48 p-6 bg-white shadow-lg rounded-lg">
    <h2 class="text-2xl font-bold mb-6 text-center">Seguimiento de Paquete</h2>

    
    <!-- Formulario de seguimiento -->
    <form class="flex items-center justify-center mb-8" (submit)="getTracking(); $event.preventDefault()">
      <input
        [(ngModel)]="trackingId"
        name="trackingId"
        type="text"
        class="w-1/2 p-2 border border-gray-300 rounded-l-md focus:outline-none"
        placeholder="Ingresa tu número de seguimiento"
      />
      <button
        type="submit"
        class="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 focus:outline-none"
      >
        Buscar
      </button>
    </form>
    
  
    <div 
      *ngIf="trackingData"
    class="bg-gray-100 p-4 rounded-md mb-8">
      <p class="text-gray-500">Número de Seguimiento:</p>
      <p class="text-lg font-semibold">{{obtenerNumeroSeguimiento()}}</p>
    </div>
  
    <!-- Línea de progreso -->
    <div *ngIf="trackingData">
      <p>Ruta: {{ obtenerDetallesUbicacion() }}</p>
    </div>
    
    <!-- Último evento -->
    <div *ngIf="trackingData">
      <p>Último evento: {{ obtenerUltimoEvento() }}</p>
    </div>
    
    <!-- Progreso de seguimiento -->
    <div class="flex justify-between items-center mb-8">
      <div *ngFor="let etapa of progreso" class="flex flex-col items-center">
        <div 
          [ngClass]="{
            'w-12 h-12 flex items-center justify-center rounded-full': true,
            'bg-green-500 text-white': etapa.estado === 'completado',
            'bg-gray-400 text-white': etapa.estado === 'pendiente'
          }"
        >
          {{ etapa.etapa === 'Recibido' ? '📦' : 
             etapa.etapa === 'En Tránsito' ? '🚚' : 
             etapa.etapa === 'En Proceso de Entrega' ? '📍' : '🏠' }}
        </div>
        <p class="mt-2 text-sm font-semibold">{{ etapa.etapa }}</p>
        <p *ngIf="etapa.fecha" class="text-xs text-gray-500">{{ etapa.fecha }}</p>
      </div>
    </div>
  

</div>
  
  
  
  
  