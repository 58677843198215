import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Transaction } from 'src/app/_models/history_refills';
// Asegúrate de ajustar la ruta de tu modelo
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private http: HttpClient) {}

  // Transacción de Emida

  // Método para obtener todas las transacciones
  getTransactions(): Observable<Transaction[]> {
    return this.http.get<Transaction[]>(
      `${environment.apiUrl}/transactionsRechargues`
    );
  }

  // Método para obtener una transacción por ID
  getTransactionById(id: string): Observable<Transaction> {
    return this.http.get<Transaction>(`${environment.apiUrl}/${id}`);
  }

  createTransaction(transaction: Transaction): Observable<Transaction> {
    return this.http.post<Transaction>(
      `${environment.apiUrl}/transactionsRechargues/create`,
      transaction
    );
  }

  // Método para obtener transacciones de un usuario por paginación
  getTransaccionByUser(
    id: string,
    page: number,
    limit: number
  ): Observable<any> {
    return this.http.get<Transaction[]>(
      `${environment.apiUrl}/transactions/user/${id}?page=${page}&limit=${limit}`
    );
  }

  // Método para obtener la utilidad quincenal
  getQuincenalProfit(
    userId: string,
    year: number,
    month: number,
    quincena: number
  ): Observable<any> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('year', year.toString())
      .set('month', month.toString())
      .set('quincena', quincena.toString());

    return this.http.get(
      `${environment.apiUrl}/transactions/quincenal-profit`,
      { params }
    );
  }

  // Método para obtener transacciones por tipo
  getTransactionsByType(
    type: string,
    page: number,
    limit: number
  ): Observable<any> {
    const params = new HttpParams()
      .set('type', type)
      .set('page', page)
      .set('limit', limit);
    return this.http.get<{ success: boolean; message: Transaction[] }>(
      `${environment.apiUrl}/transactions/list-by-type-all`,
      { params }
    );
  }

  getTransactionsByTypeAndUser(
    type: string,
    userId: string,
    page: number,
    limit: number
  ): Observable<any> {
    const params = new HttpParams()
      .set('type', type)
      .set('user_id', userId)
      .set('page', page)
      .set('limit', limit);

    return this.http.get<{ success: boolean; message: Transaction[] }>(
      `${environment.apiUrl}/transactions/list-by-type`,
      { params }
    );
  }
}
