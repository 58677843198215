import { Component, OnInit } from '@angular/core';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { CancellationService } from 'src/app/_services/cancellations/cancellation.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import Swal from 'sweetalert2';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-shipments-details',
  templateUrl: './shipments-details.component.html',
  styleUrls: ['./shipments-details.component.scss'],
})
export class ShipmentsDetailsComponent implements OnInit {
  shipmentId!: string;
  shipmentDetails: any;
  trackingDetails: any;
  paginatedTrackingDetails: any[] = [];
  itemsPerPage = 5;
  currentPage = 1;
  math = Math;
  showCancelDialog = false;
  cancellationReason = '';
  showSuccessAlert = false;
  showErrorAlert = false;
  alertMessage = '';
  isGeneratingGuide = false;
  showBotInstructions = false;
  solicitarCancelacion = false;
  showPinModal = false;
  enteredPin = '';
  storedPin = '';
  pendingAction: Function | null = null;

  constructor(
    private route: ActivatedRoute,
    private shipmentsService: ShipmentsService,
    private cancellationService: CancellationService,
    private authService: AuthLoginService // Asegúrate de importar y agregar este servicio
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.shipmentId = params['id'];
      this.checkCancellationRequest();
      this.loadShipmentDetails();
      this.loadTracking();
      this.getUserPin(); // Cargar el NIP del usuario
    });
  }

  checkCancellationRequest() {
    if (!this.shipmentId) {
      console.error('No shipment ID provided');
      return;
    }

    this.cancellationService
      .getCancellationById(this.shipmentId)
      .pipe(
        catchError((error) => {
          console.error('Error loading cancellation request:', error);
          // Puedes manejar errores específicos aquí
          return of(null); // Permite que el flujo continúe
        })
      )
      .subscribe((res) => {
        console.log('Solicitud de cancelación:', res.data);
        if (res.success) {
          this.solicitarCancelacion = true;
        }
      });
  }

  viewGuide() {
    if (this.shipmentDetails && this.shipmentDetails.guide_url) {
      window.open(this.shipmentDetails.guide_url, '_blank');
    } else {
      this.showErrorAlert = true;
      this.alertMessage = 'La guía no está disponible.';
    }
  }

  downloadReceipt() {
    this.generateReceipt();
  }

  generateReceipt() {
    const doc = new jsPDF({
      format: 'a5', // Se amplió el tamaño de la página
      unit: 'mm',
      orientation: 'portrait',
    });

    const drawLine = (y: number) => doc.line(10, y, 138, y);

    // Logo
    const logoUrl = '../../../assets/images/dagPacket-logo.png';
    doc.addImage(logoUrl, 'PNG', 10, 10, 40, 12);

    // Fecha y Hora cerca del logo
    doc.setFontSize(8);
    doc.text(
      `Fecha: ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
      10,
      25
    );

    drawLine(25);

    // Origen y Destino
    doc.setFontSize(9);
    doc.setFont('helvetica', 'bold');
    doc.text('Origen:', 10, 30);
    doc.text('Destino:', 75, 30);

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(this.shipmentDetails.from.name, 10, 35);
    doc.text(this.shipmentDetails.to.name, 75, 35);
    doc.text(`Tel. ${this.shipmentDetails.from.phone}`, 10, 40);
    doc.text(`Tel. ${this.shipmentDetails.to.phone}`, 75, 40);
    doc.text(`C.P. ${this.shipmentDetails.from.zip_code}`, 10, 45);
    doc.text(`C.P. ${this.shipmentDetails.to.zip_code}`, 75, 45);
    doc.text(this.shipmentDetails.from.street, 10, 50);
    doc.text(this.shipmentDetails.to.street, 75, 50);

    drawLine(55);

    // Seguro y Código de rastreo
    doc.setFontSize(8);
    doc.text(
      `Seguro: ${
        this.shipmentDetails.insurance.$numberDecimal > 0
          ? 'Asegurado'
          : 'No asegurado'
      }`,
      10,
      60
    );
    doc.text(
      `Código de rastreo Dagpacket: ${this.shipmentDetails.trackingNumber}`,
      10,
      65
    );
    doc.text(
      `${this.shipmentDetails.provider} Track. Number: ${
        this.shipmentDetails.guide_number || 'N/A'
      }`,
      10,
      70
    );

    drawLine(75);

    // Enlace de rastreo
    doc.setFontSize(7);
    doc.text(
      `Ingrese su código Dagpacket ${this.shipmentDetails.trackingNumber} para rastrear su paquete desde nuestra página:`,
      10,
      80,
      { maxWidth: 120 }
    );
    doc.text('https://dagpacket-plataforma.web.app/rastreo', 10, 85);

    drawLine(90);

    // Precio
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(`Precio: $${this.shipmentDetails.price.$numberDecimal}`, 10, 95);

    drawLine(100);

    // Declaración legal
    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    const legalText = [
      'Manifiesto bajo protesta de decir la verdad que la mercancía que ampara esta guía es legal y de procedencia lícita.',
      'Por lo que deslindo a DAGPACKET de cualquier responsabilidad asumiendo total penalización jurídica, legal y las que resulten en caso de lo contrario.',
      'El manejo de los paquetes así como el traslado y tiempos de entrega son responsabilidad exclusivamente de la paquetería seleccionada.',
      'Para más información revise por favor los términos y condiciones en el siguiente link:',
      'https://dagpacket.com.mx/terminos-y-condiciones/',
    ];

    let yPosition = 105;
    legalText.forEach((line) => {
      doc.text(line, 10, yPosition, { maxWidth: 120 });
      yPosition += 5;
    });

    drawLine(yPosition);

    // Firma del Cliente
    yPosition += 5;
    const rectWidth = 80;
    const rectHeight = 15;
    const rectX = (doc.internal.pageSize.width - rectWidth) / 2;
    doc.rect(rectX, yPosition, rectWidth, rectHeight);
    doc.text(
      'Nombre y Firma Cliente',
      doc.internal.pageSize.width / 2,
      yPosition + rectHeight - 3,
      { align: 'center' }
    );

    // Mensaje final fuera del recuadro
    yPosition += rectHeight + 10;
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(
      'GRACIAS POR SU ENVÍO',
      doc.internal.pageSize.width / 2,
      yPosition,
      { align: 'center' }
    );

    // Guardar el PDF
    doc.save(`recibo_${this.shipmentDetails.trackingNumber}.pdf`);
  }

  loadShipmentDetails() {
    this.shipmentsService.getShipmentDetails(this.shipmentId).subscribe(
      (res) => {
        this.shipmentDetails = res.data;
      },
      (error) => {
        console.error('Error loading shipment details', error);
        this.showErrorAlert = true;
        this.alertMessage = 'Error al cargar los detalles del envío';
      }
    );
  }

  loadTracking() {
    this.shipmentsService.getShipmentTracking(this.shipmentId).subscribe(
      (res) => {
        this.trackingDetails = res.data;
        this.updatePaginatedTrackingDetails();
      },
      (error) => {
        console.error('Error loading shipment tracking', error);
        this.showErrorAlert = true;
        this.alertMessage = 'Error al cargar el seguimiento del envío';
      }
    );
  }

  updatePaginatedTrackingDetails() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedTrackingDetails = this.trackingDetails.slice(
      startIndex,
      endIndex
    );
  }

  nextPage() {
    if (this.currentPage * this.itemsPerPage < this.trackingDetails.length) {
      this.currentPage++;
      this.updatePaginatedTrackingDetails();
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedTrackingDetails();
    }
  }

  openCancelDialog() {
    this.showCancelDialog = true;
  }

  closeCancelDialog() {
    this.showCancelDialog = false;
    this.cancellationReason = '';
    this.resetAlerts();
  }

  createCancellationRequest(cancellationForm: NgForm) {
    if (cancellationForm.valid) {
      this.cancellationService
        .createCancellationRequest(cancellationForm.value)
        .subscribe(
          (res) => {
            if (res.success) {
              this.showSuccessAlert = true;
              this.alertMessage = 'Solicitud de cancelación enviada con éxito';
              this.checkCancellationRequest();
              this.loadShipmentDetails();
              setTimeout(() => this.closeCancelDialog(), 1000);
            } else {
              this.showErrorAlert = true;
              this.alertMessage =
                res.message || 'Error al procesar la solicitud de cancelación';
            }
          },
          (error) => {
            console.error('Error en la solicitud de cancelación:', error);
            this.showErrorAlert = true;
            this.alertMessage = 'Error al procesar la solicitud de cancelación';
          }
        );
    } else {
      this.showErrorAlert = true;
      this.alertMessage = 'Por favor, complete todos los campos requeridos';
    }
  }

  getPaymentStatusClass(status: string): string {
    switch (status) {
      case 'Pagado':
        return 'bg-green-500';
      case 'Pendiente':
        return 'bg-orange-500';
      case 'Cancelado':
        return 'bg-red-600';
      case 'Reembolsado':
        return 'bg-blue-500';
      default:
        return 'bg-gray-500';
    }
  }

  resetAlerts() {
    this.showSuccessAlert = false;
    this.showErrorAlert = false;
    this.alertMessage = '';
  }

  toggleBotInstructions() {
    this.showBotInstructions = !this.showBotInstructions;
  }

  getUserPin(): void {
    this.authService.userProfile().subscribe(
      (res) => {
        this.storedPin = res.data.pin;
      },
      (error) => {
        console.log('Error al obtener el perfil del usuario', error);
      }
    );
  }

  openPinModal(action: Function): void {
    this.enteredPin = '';
    this.pendingAction = action;
    this.showPinModal = true;
  }

  closePinModal(): void {
    this.showPinModal = false;
    this.enteredPin = '';
    this.pendingAction = null;
  }

  validateNip(): void {
    if (this.enteredPin === this.storedPin) {
      this.showPinModal = false;
      if (this.pendingAction) this.pendingAction();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'NIP incorrecto',
        text: 'El NIP ingresado no es correcto.',
      });
    }
  }

  openPinModalForCancellation(): void {
    this.openPinModal(() => this.openCancelDialog());
  }

  getProviderLogo(provider: string): string {
    const basePath = 'assets/paqueterias/';
    switch (provider.toLowerCase()) {
      case 'estafeta':
        return `${basePath}Estafeta.png`;
      case 'dhl':
        return `${basePath}DHL.png`;
      case 'fedex':
        return `${basePath}Fedex.png`;
      case 'paquete express':
        return `${basePath}Paquete Express.png`;
      default:
        return `${basePath}default.png`;
    }
  }
  
  
}
