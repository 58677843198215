import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-shipment-status',
  templateUrl: './shipment-status.component.html',
  styleUrls: ['./shipment-status.component.scss'],
})
export class ShipmentStatusComponent implements OnInit {
  packages: any[] = [];
  filteredPackages: any[] = []; // Paquetes filtrados
  currentPage: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  totalPages: number = 0;
  Math: any = Math;
  sortBy: string = 'distribution_at';
  sortOrder: 'asc' | 'desc' = 'desc';
  role: string = '';
  startDate: string = '';
  endDate: string = '';
  statusFilter: string = '';

  constructor(
    private shipmentsService: ShipmentsService,
    private authService: AuthLoginService,
    private router: Router
  ) {
    this.Math = Math;
  }

  ngOnInit(): void {
    this.loadShipments();
    this.getUserProfile();
  }

  getUserProfile() {
    this.authService.userProfile().subscribe(
      (response) => {
        console.log('datos del usuario', response);
        this.role = response.data.role;
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  verifyRole(): boolean {
    if (this.role === 'CAJERO') {
      return true;
    }
    return false;
  }

  loadShipments() {
    this.shipmentsService
      .getUserShipments(
        this.currentPage,
        this.pageSize,
        this.sortBy,
        this.sortOrder
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.packages = response.data.shipments;
            this.applyFilters(); // Aplicar filtros después de cargar los datos
            this.totalItems = response.data.totalShipments;
            this.totalPages = response.data.totalPages;
          } else {
            console.error('Error loading shipments:', response.message);
          }
        },
        (error) => console.error('Error', error)
      );
  }

  applyFilters() {
    const start = this.startDate ? new Date(this.startDate) : null;
    const end = this.endDate ? new Date(this.endDate) : null;

    this.filteredPackages = this.packages.filter((envio) => {
      const distributionDate = new Date(envio.distribution_at);

      // Filtro por rango de fechas
      const isWithinDateRange =
        (!start || distributionDate >= start) &&
        (!end || distributionDate <= end);

      // Filtro por estado en `envio.payment.status` o `envio.status`
      const matchesStatus =
        !this.statusFilter ||
        envio.payment.status === this.statusFilter ||
        envio.status === this.statusFilter;

      return isWithinDateRange && matchesStatus;
    });

    // Verificar si `filteredPackages` tiene datos después del filtrado
    console.log('Paquetes filtrados:', this.filteredPackages);
  }


  clearDateFilter(): void {
    this.startDate = '';
    this.endDate = '';
    this.statusFilter = '';
    this.applyFilters();
  }

  exportToCSV1(): void {
    if (this.filteredPackages.length === 0) {
      console.warn('No hay datos para exportar');
      return;
    }

    // Encabezados del archivo CSV
    const headers = [
      'licenciatario',
      'name',
      'fecha de creación',
      'precio_venta_usuario',
      'costo',
      'utilidad',
      'precio venta de empaque',
      'utilidad de empaque',
      'utilidad bruta envio y empaque',
      'utilidad licenciatario',
      'Utilidad dagpacket',
      "cupon",
      "seguro",
      "proveedor",
      "trackingID",
      "formato de pago",
      "empaque",
      "declared_value",
      "assurancePrice",
      "dagpacket_code",
      "state",
      "userId",
      "tipo"
    ];

    console.log('Datos a exportar:', this.filteredPackages);

    // Formatear los datos
    const csvData = this.filteredPackages.map((envio) => [
      // Licenciatario: si tiene un sub_user_id, usa su nombre; si no, usa el nombre del licenciatario
      envio.user_id?.name || 'no aplica',

      // Nombre: si tiene un sub_user_id, usa su nombre; si no, usa el nombre del licenciatario
      envio.sub_user_id?.name || envio.user_id?.name || 'no aplica',

      envio.createdAt ? new Date(envio.createdAt).toLocaleDateString() : 'no aplica',
      envio.price?.$numberDecimal || 'no aplica',
      envio.cost?.$numberDecimal || 'no aplica',
      envio.dagpacket_profit?.$numberDecimal || 'no aplica',
      envio.packing_sell_price?.$numberDecimal || 'no aplica',
      envio.packing_cost?.$numberDecimal || 'no aplica',
      envio.dagpacket_profit?.$numberDecimal || 'no aplica',
      envio.utilitie_lic?.$numberDecimal || 'no aplica',
      envio.utilitie_dag?.$numberDecimal || 'no aplica',
      envio.discount?.$numberDecimal || 'no aplica',
      envio.insurance?.$numberDecimal || 'no aplica',
      envio.provider || 'no aplica',
      envio.trackingNumber || 'no aplica',
      envio.payment.method || 'no aplica',
      envio.packing.answer || 'no aplica',
      envio.cost?.$numberDecimal || 'no aplica',
      envio.insurance?.$numberDecimal || 'no aplica',
      envio.dagpacket_code || 'no aplica',
      envio.from.state || 'no aplica',
      envio.user_id?._id || 'no aplica',
      envio.shipment_type || 'no aplica'
    ]);

    // Crear el contenido CSV
    const csvContent = [
      headers.join(','), // Agregar encabezados
      ...csvData.map((row) => row.join(',')), // Agregar filas de datos
    ].join('\n');

    // Crear un blob con el contenido CSV y descargar el archivo
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Historial_Envios_${new Date().toLocaleDateString()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  exportToCSV(): void {
    if (this.filteredPackages.length === 0) {
      console.warn('No hay datos para exportar');
      return;
    }

    // Encabezados del archivo Excel
    const headers = [
      'licenciatario',
      'name',
      'fecha de creación',
      'precio_venta_usuario',
      'costo',
      'utilidad',
      'precio venta de empaque',
      'utilidad de empaque',
      'utilidad bruta envio y empaque',
      'utilidad licenciatario',
      'Utilidad dagpacket',
      "cupon",
      "seguro",
      "proveedor",
      "trackingID",
      "formato de pago",
      "empaque",
      "declared_value",
      "assurancePrice",
      "dagpacket_code",
      "state",
      "userId",
      "tipo"
    ];

    console.log('Datos a exportar:', this.filteredPackages);

    // Formatear los datos
    const data = this.filteredPackages.map((envio) => [
      // Licenciatario: si tiene un sub_user_id, usa su nombre; si no, usa el nombre del licenciatario
     "LICENCIATARIO "+ envio.user_id?.name + " " + envio.user_id?.email || 'no aplica',

      // Nombre: si tiene un sub_user_id, usa su nombre; si no, usa el nombre del licenciatario
    "CAJERO " + (envio.sub_user_id?.name + " " + envio.sub_user_id?.email || envio.user_id?.name + " " + envio.sub_user_id?.name || 'no aplica'
),
      envio.createdAt ? new Date(envio.createdAt).toLocaleDateString() : 'no aplica',
      envio.price?.$numberDecimal || 'no aplica',
      envio.cost?.$numberDecimal || 'no aplica',
      envio.dagpacket_profit?.$numberDecimal || 'no aplica',
      envio.packing.packing_sell_price?.$numberDecimal || 'no aplica',
      (envio.packing.packing_sell_price?.$numberDecimal - envio.packing.packing_cost?.$numberDecimal) || 'no aplica',
      (
        (Number(envio.dagpacket_profit?.$numberDecimal) || 0) +
        ((Number(envio.packing?.packing_sell_price?.$numberDecimal) || 0) -
          (Number(envio.packing?.packing_cost?.$numberDecimal) || 0))
      ) || 'no aplica',
      (
        (Number(envio.utilitie_lic?.$numberDecimal) || 0) +
        (Number(envio.packing?.utilitie_lic?.$numberDecimal) || 0)
      ) || 'no aplica',

      (
        (Number(envio.utilitie_dag?.$numberDecimal) || 0) +
        (Number(envio.packing?.utilitie_dag?.$numberDecimal) || 0)
      ) || 'no aplica',

      envio?.cupon ?
        "Si" :
        "No",
      envio.insurance?.$numberDecimal || 'no aplica',
      envio.provider || 'no aplica',
      envio.trackingNumber || 'no aplica',
      envio.payment.method || 'no aplica',
      envio.packing.answer || 'no aplica',
      envio.cost?.$numberDecimal || 'no aplica',
      envio.insurance?.$numberDecimal || 'no aplica',
      envio?.cupon ? envio.cupon.cupon_code : "no aplica",
      envio.from.state || 'no aplica',
      envio.user_id?._id || 'no aplica',
      envio.shipment_type || 'no aplica'
    ]);

    // Crear una hoja de trabajo (worksheet) con los datos
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

    // Crear un libro de trabajo (workbook) con esa hoja
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Historial Envios');

    // Descargar el archivo Excel
    XLSX.writeFile(wb, `Historial_Envios_${new Date().toLocaleDateString()}.xlsx`);
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadShipments();
    }
  }

  changeSort(column: string) {
    if (this.sortBy === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = column;
      this.sortOrder = 'asc';
    }
    this.currentPage = 1;
    this.loadShipments();
  }

  getPaymentStatusClass(status: string): string {
    switch (status) {
      case 'Pagado':
        return 'bg-green-500';
      case 'Pendiente':
        return 'bg-orange-500';
      case 'Cancelado':
        return 'bg-red-600';
      case 'Reembolsado':
        return 'bg-blue-500';
      default:
        return 'bg-gray-500';
    }
  }

  getStatus(status: string): string {
    switch (status) {
      case 'En recolección':
        return 'bg-blue-500';
      case 'En reparto':
        return 'bg-orange-500';
      case 'Cancelado':
        return 'bg-red-600';
      default:
        return 'bg-gray-500';
    }
  }

  viewDetails(shipmentId: string) {
    console.log('Navigating to details for shipment:', shipmentId);
    this.router.navigate(['/shipment-details', shipmentId]);
  }

  updateStartDate(event: Event) {
    this.startDate = (event.target as HTMLInputElement).value;
  }

  updateEndDate(event: Event) {
    this.endDate = (event.target as HTMLInputElement).value;
  }

  updateStatusFilter(event: Event) {
    this.statusFilter = (event.target as HTMLSelectElement).value;
  }
}