import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Packing } from 'src/app/_models/packing';
import { CancellationService } from 'src/app/_services/cancellations/cancellation.service';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { ServicesService } from 'src/app/_services/Proveedores/services.service';
import { WalletService } from 'src/app/_services/Wallets/wallet.service';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { RechargeRequestService } from 'src/app/_services/Rechargues/rechargue-request.service';
import { RolesService } from 'src/app/_services/Roles/roles.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import Swal from 'sweetalert2';
interface Utility {
  $numberDecimal: string;
}

const checklistData = [
  {
    category: 'Paquetes',
    items: ['Cotizar Envio', 'Envios', 'Empaques', 'Recoleccion'],
  },
  {
    category: 'Licenciatarios',
    items: [
      'Resumen',
      'Caja',
      'Cortes de Caja',
      'Pago de Servicios',
      'Solicitudes de Recarga',
      'Solicitudes de Cancelacion',
      'Transferencia Empaques',
      
    ],
  },
  {
    category: 'Gestion Administrativa',
    items: [
      'Dashboard General',
      'Panel de Administracion',
      'Solicitudes de Recarga',
      'Repartidor',
      'Historial de Recargas',
      'Almacen General',
      'Inventario Licenciatarios',
      'Historial de Transacciones'
    ],
  },
  {
    category: 'Lockers',
    items: [
      'Estatus de Lockers',
      'Publicidad de Lockers',
      'Operaciones de Lockers',
    ],
  },
];

interface User {
  _id: string;
  dagpacketPercentaje?: Utility;
  servicesPercentaje?: Utility;
  recharguesPercentage?: Utility;
  packingPercentage?: Utility;
  [key: string]: any;
}

interface NewUtilities {
  dagpacketPercentaje: number | null;
  servicesPercentaje: number | null;
  recharguesPercentage: number | null;
  packingPercentage: number | null;
  [key: string]: number | null;
}

@Component({
  selector: 'app-olimpo',
  templateUrl: './olimpo.component.html',
  styleUrls: ['./olimpo.component.scss'],
})
export class OlimpoComponent implements OnInit {
  @Output() closeModal = new EventEmitter<boolean>();

  newUtilities: NewUtilities = {
    dagpacketPercentaje: null,
    servicesPercentaje: null,
    recharguesPercentage: null,
    packingPercentage: null,
  };

  data = checklistData;
  selectedItems: { [key: string]: { [key: string]: boolean } } = {};

  showRoleConfirmation: boolean = false;
  newRole: string = '';
  isUpdating: boolean = false;

  // User properties
  users: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalItems: number = 0;
  totalPages: number = 0;
  searchTerm: string = '';
  Math = Math;
  isInitializing: boolean = false;

  showDeleteModal: boolean = false;

  // Cancellation properties
  cancellationRequests: any[] = [];
  cancellationCurrentPage: number = 1;
  cancellationItemsPerPage: number = 10;
  cancellationTotalPages: number = 1;
  cancellationTotalItems: number = 0;
  cancellationSearchTerm: string = '';
  cancellationSortBy: string = 'requested_at';
  cancellationSortOrder: 'asc' | 'desc' = 'desc';

  // Packing properties
  packings: any[] = [];
  packingCurrentPage: number = 1;
  packingTotalPages: number = 1;
  packingTotalItems: number = 0;
  packingItemsPerPage: number = 5;
  packingSearchTerm: string = '';

  // Refill properties
  refillRequests: any[] = [];
  refillCurrentPage: number = 1;
  refillTotalPages: number = 1;
  refillTotalItems: number = 0;
  refillItemsPerPage: number = 5;
  refillSearchTerm: string = '';

  // Modal properties
  showContainer: { [key: string]: boolean } = {};
  showEditUserDialog: boolean = false;
  showDetallesModal: boolean = false;
  showRechazarModal: boolean = false;
  showAprobarModal: boolean = false;
  showNewPackingModal: boolean = false;
  showUserModal: boolean = false;

  // Poveedores de servicios
  providers: any[] = [];
  showUtilityModal = false;
  selectedService: any;
  utilityPercentage: number = 0;

  // Other properties
  selectedUser: any = null;
  solicitudSeleccionada: any = null;
  solicitudParaAccion: any = null;
  razonRechazo: string = '';
  imageFile: File | null = null;
  alertMessage: string = '';
  isError: boolean = false;
  isLoading: boolean = false;
  showToast: boolean = false;
  toastMessage: string = '';
  toastType: 'success' | 'error' = 'success';
  selectedOption: string = '';
  fundAmount: number = 0;

  showEditModal: boolean = false;
  editingPacking: any = {};
  selectedImage: File | null = null;
  showAlert: boolean = false;
  showAlertPacking: boolean = false;
  showTransactionHistoryModal: boolean = false;
  showAddFundsModal: boolean = false;
  typeOfAddFunds: string = '';
  selectedPacking: any;
  selectedPackingId: string = '';

  // Nuevas propiedades para el modal de usuario
  selectedUserOption: string = 'info'; // Opción por defecto

  // Nuevas propiedades para la asignación de usuario padre
  potentialParentUsers: any[] = [];
  selectedParentUserId: string = '';
  isActive = false; // Initialize the toggle state
  page = 1; // Initialize the current page
  currentPageTransaction = 1; // Initialize the current page
  totalPagesTransaction = 0; // Initialize the total pages
  pageSize = 10; // Initialize the page size
  totalItemsTransaction = 0; // Initialize the total items
  transactionHistory: any[] = [];
  hasNextPage: boolean = false;
  hasPreviousPage: boolean = false;

  //pin
  showPinModal: boolean = false;
  enteredPin: string = '';
  showAddLocker: boolean = false;
  storedPin: string = '';
  pendingAction: Function | null = null;

  roles: any[] = [];
  roleSearchTerm = '';
  roleCurrentPage = 1;
  roleTotalPages = 1;
  showDetallesRolModal: boolean = false;
  showCreatePermissionModal = false;
  newPermission = {
    role_name: '',
    has_wallet: '',
    type: '',
  };
  selectedRole: any = null; // Rol seleccionado para mostrar en el modal
  //varibale para status de cancelacion de envios
  shipmentCancellationStatus: string = '';
  //Alertas
  alertSuccess: boolean = true;

  constructor(
    private userService: UserServiceService,
    private cancellationService: CancellationService,
    private packingService: ShipmentsService,
    private refillRequestService: RefillRequestService,
    private servicesService: ServicesService,
    private walletService: WalletService,
    private transactionService: TransactionService,
    private rolesService: RolesService,
    private authService: AuthLoginService,
    private addFoundsService: RechargeRequestService
  ) {}

  openCreatePermissionModal() {
    this.showCreatePermissionModal = true;
  }

  closeCreatePermissionModal() {
    this.showCreatePermissionModal = false;
    this.resetForm();
  }

  resetForm() {
    this.newPermission = {
      role_name: '',
      has_wallet: '',
      type: '',
    };
  }

  createPermission() {
    // Valida que todos los campos estén completos
    if (
      !this.newPermission.role_name ||
      !this.newPermission.has_wallet ||
      !this.newPermission.type
    ) {
      alert('Todos los campos son obligatorios');
      return;
    }

    // Realiza la petición POST al servidor
    this.rolesService.register(this.newPermission).subscribe(
      (res) => {
        if (res.success) {
          Swal.fire('Permiso creado correctamente', '', 'success');
          this.loadRoles();
          this.closeCreatePermissionModal();
        } else {
          Swal.fire('Error', 'Ocurrió un error al crear el permiso', 'error');
        }
      },
      (error) => {
        Swal.fire('Error', 'Ocurrió un error al crear el permiso', 'error');
        console.error('Error al crear el permiso:', error);
      }
    );
  }

  ngOnInit(): void {
    this.loadUsers();
    this.loadCancellationRequests();
    this.loadPackings();
    this.loadRefillRequests();
    this.loadProviderServices();
    this.initializeUtilities();
    this.loadRoles();
    this.getUserPin();
  }

  //pin
  openAddLocker() {
    this.enteredPin = '';
    this.showPinModal = true;
  }

  closePinModal(): void {
    this.showPinModal = false;
    this.enteredPin = ''; // Limpia el NIP
    this.pendingAction = null; // Limpia la acción pendiente
  }

  closeAddLocker(): void {
    this.showAddLocker = false;
  }

  //gestionar usuario

  validateAndOpenUtilityUpdate(): void {
    this.enteredPin = ''; // Limpia el NIP ingresado
    this.showPinModal = true; // Abre el modal del NIP
    this.pendingAction = this.updateUserUtilities; // Establece la acción pendiente
  }

  validateAndOpenRoleModal(): void {
    this.enteredPin = '';
    this.showPinModal = true;
    this.pendingAction = this.confirmRoleChange;
  }

  //crear rol

  validateAndShowRoleCreationModal(): void {
    this.enteredPin = '';
    this.showPinModal = true;
    this.pendingAction = this.openRoleCreationModal;
  }

  openRoleCreationModal(): void {
    this.showCreatePermissionModal = true;
  }

  //validacion

  validatePin(action: Function): void {
    this.enteredPin = ''; // Limpia el NIP ingresado
    this.pendingAction = action; // Asigna la acción pendiente
    this.showPinModal = true; // Muestra el modal de NIP
  }

  validateNipAndProceed(): void {
    if (this.enteredPin === this.storedPin) {
      this.showPinModal = false; // Cierra el modal del NIP
      if (this.pendingAction) {
        this.pendingAction(); // Ejecuta la acción pendiente
        this.pendingAction = null; // Limpia la acción pendiente
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'NIP incorrecto',
        text: 'El NIP ingresado no es correcto.',
      });
    }
  }

  //acceder al pin

  getUserPin(): void {
    this.authService.userProfile().subscribe(
      (res) => {
        this.storedPin = res.data.pin;
      },
      (error) => {
        console.log('Error al obtener el perfil del usuario', error);
      }
    );
  }

  onPageSizeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.pageSize = +selectElement.value; // Actualiza pageSize con el valor seleccionado
    console.log(`Tamaño de página cambiado a: ${this.pageSize}`);

    this.showTransactionHistory();
    // Aquí puedes llamar a una función para recargar los datos de acuerdo al nuevo pageSize
  }

  cerrarModalTransactionHistory() {
    this.showTransactionHistoryModal = false;
    this.transactionHistory = [];
  }

  getPageNumbers(): number[] {
    return Array.from({ length: this.totalPagesTransaction }, (_, i) => i + 1);
  }

  goToPage(page: number) {
    this.currentPageTransaction = page;
    console.log(`Ir a la página ${page}`);

    this.showTransactionHistory();

    // Lógica para cambiar de página
  }

  toggleServiceStatus(service: any, subProvider: string, providerName: string) {
    service.status = !service.status;

    this.selectedService = {
      ...service,
      providerName,
      serviceName: service.name,
      subProvider,
    };

    if (service.status) {
      this.activateService();
    } else {
      this.deactivateService();
    }
  }

  activateService() {
    const state = true;
    this.servicesService
      .updateServiceStatus(
        this.selectedService.providerName,
        this.selectedService.subProvider,
        this.selectedService.idServicio,
        state
      )
      .subscribe(
        (res) => {
          console.log('Service activated successfully', res);

          // Actualizar el estado del servicio específico en la lista de proveedores
          this.selectedService.status = state;

          // Mostrar el mensaje de éxito sin recargar todos los proveedores
          this.showToastMessage('Servicio activado con éxito', 'success');
        },
        (error) => {
          console.error('Error activating service:', error);
          this.showToastMessage('Error al activar el servicio', 'error');
        }
      );
  }

  deactivateService() {
    const state = false;
    this.servicesService
      .updateServiceStatus(
        this.selectedService.providerName,
        this.selectedService.subProvider,
        this.selectedService.idServicio,
        state
      )
      .subscribe(
        (res) => {
          console.log('Service deactivated successfully', res);

          // Actualizar el estado del servicio específico en la lista de proveedores
          this.selectedService.status = state;

          // Mostrar el mensaje de éxito sin recargar todos los proveedores
          this.showToastMessage('Servicio desactivado con éxito', 'success');
        },
        (error) => {
          console.error('Error deactivating service:', error);
          this.showToastMessage('Error al desactivar el servicio', 'error');
        }
      );
  }

  getProviderLogo(providerName: string): string {
    switch (providerName.toLowerCase()) {
      case 'fedex':
        return '../../../../assets/paqueterias/Fedex.png';
      case 'superenvios':
        return '../../../../assets/paqueterias/superenvios.webp';
      case 'paquete express':
        return '../../../../assets/paqueterias/Paquete Express.png';
      case 'dhl':
        return '../../../../assets/paqueterias/DHL.png';
      case 'estafeta':
        return '../../../../assets/paqueterias/Estafeta.png';
      default:
        return 'assets/images/default-logo.png';
    }
  }

  loadProviderServices() {
    this.servicesService.getAllServices().subscribe(
      (res: any[]) => {
        this.providers = res.map((provider) => ({
          ...provider,
          isExpanded: false,
        }));
        console.log(this.providers);
      },
      (error) => {
        console.error('Error loading provider services:', error);
      }
    );
  }

  toggleProvider(provider: any) {
    provider.isExpanded = !provider.isExpanded;
  }

  openUtilityModal(service: any, providerName: string, serviceName: string) {
    this.selectedService = { ...service, providerName, serviceName };
    console.log('Selected service:', this.selectedService);
    this.utilityPercentage = service.percentage;
    this.showUtilityModal = true;
  }

  closeUtilityModal() {
    this.showUtilityModal = false;
  }

  saveUtility() {
    this.servicesService
      .updateServiceUtility(
        this.selectedService.serviceName,
        this.selectedService.providerName,
        this.selectedService.idServicio,
        this.utilityPercentage
      )
      .subscribe(
        (res) => {
          console.log('Utility updated successfully', res);
          this.loadProviderServices();
          this.showToastMessage('Utilidad actualizada con éxito', 'success');
        },
        (error) => {
          console.error('Error updating utility:', error);
          this.showToastMessage('Error al actualizar la utilidad', 'error');
        }
      );
    this.closeUtilityModal();
  }

  confirmDelete() {
    this.isLoading = true;
    this.packingService.deletePacking(this.selectedPackingId).subscribe(
      (res) => {
        if (res.success == true) {
          console.log(res);
          this.alertSuccess = true;
          this.alertMessage = res.message;
          this.showAlertPacking = res.message;
          this.showDeleteModal = false;
          this.isLoading = false;
          this.loadPackings();
        } else {
          console.log(res);
          this.alertSuccess = false;
          this.alertMessage = res.message;
          this.showAlertPacking = res.message;
          this.showDeleteModal = false;
          this.isLoading = false;
          this.loadPackings();
        }
        setTimeout(() => {
          this.alertMessage = '';
          this.showAlertPacking = false;
        }, 5000);
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  cancelDelete() {
    this.showDeleteModal = false;
  }

  editPacking(packing: any) {
    this.selectedPacking = { ...packing };
    this.selectedPackingId = packing._id;
    this.showEditModal = true;
  }

  openDeletePacking(packing_id: string) {
    this.selectedPackingId = packing_id;
    this.showDeleteModal = true;
  }

  cancelEdit() {
    this.showEditModal = false;
    this.editingPacking = {};
    this.selectedImage = null;
  }

  // User methods
  loadUsers() {
    this.userService
      .listUsers(this.currentPage, this.itemsPerPage, this.searchTerm)
      .subscribe(
        (response) => {
          this.users = response.data.users;
          this.totalItems = response.data.totalUsers;
          this.totalPages = response.data.totalPages;
        },
        (error) => {
          console.error('Error fetching users:', error);
        }
      );
  }

  updatePacking(updatePackingForm: NgForm) {
    this.isLoading = true;
    this.packingService
      .updatePacking(this.selectedPackingId, updatePackingForm.value)
      .subscribe((res) => {
        if (res.success == true) {
          this.alertMessage = res.message;
          this.alertSuccess = res.success;
          this.showAlertPacking = res.message;
          this.showEditModal = false;
          this.isLoading = false;

          this.loadPackings();
          setTimeout(() => {
            this.alertMessage = '';
            this.showAlertPacking = false;
          }, 5000);
        } else {
          this.alertMessage = res.message;
          this.alertSuccess = res.success;
          console.log(res);
          setTimeout(() => {
            this.alertMessage = '';
          }, 5000);
        }
      });
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.loadUsers();
  }

  onSearch() {
    this.currentPage = 1;
    this.loadUsers();
  }

  editUser(user: any): void {
    this.selectedUser = user;
    this.showEditUserDialog = true;
  }

  toggleUserAccount(userId: string, isActive: boolean): void {
    if (isActive) {
      this.deactivateUserAccount(userId);
    } else {
      this.activateUserAccount(userId);
    }
  }

  activateUserAccount(userId: string): void {
    const user = this.users.find((u) => u._id === userId);
    if (!user || user.active) return;

    this.userService.activateAccount(userId).subscribe(
      (response) => {
        console.log('Cuenta activada:', response);
        if (user) user.active = true;
      },
      (error) => {
        console.error('Error al activar la cuenta:', error);
      }
    );
  }

  deactivateUserAccount(userId: string): void {
    const user = this.users.find((u) => u._id === userId);
    if (!user || !user.active) return;

    this.userService.deactivateAccount(userId).subscribe(
      (response) => {
        console.log('Cuenta desactivada:', response);
        if (user) user.active = false;
      },
      (error) => {
        console.error('Error al desactivar la cuenta:', error);
      }
    );
  }

  openUserModal(user: any) {
    this.selectedUser = { ...user }; // Copia profunda para evitar modificar el original directamente
    this.showUserModal = true;
    this.selectedUserOption = 'info';
    if (user.role === 'CAJERO' || user.role === 'CLIENTE_CORPORATIVO') {
      this.loadPotentialParentUsers();
    }
  }

  closeUserModal() {
    this.showUserModal = false;
    this.selectedUser = null;
    this.selectedUserOption = 'info';
  }
  selectUserOption(option: string) {
    this.selectedUserOption = option;
    if (
      option === 'permissions' &&
      (this.selectedUser.role === 'CAJERO' ||
        this.selectedUser.role === 'CLIENTE_CORPORATIVO')
    ) {
      this.loadPotentialParentUsers();
    }
  }

  loadPotentialParentUsers() {
    this.userService.getPotentialParentUsers().subscribe(
      (response) => {
        this.potentialParentUsers = response.data;
      },
      (error) => {
        console.error('Error al cargar usuarios potenciales:', error);
      }
    );
  }

  assignParentUser() {
    if (this.selectedParentUserId && this.selectedUser) {
      this.userService
        .assignParentUser(this.selectedUser._id, this.selectedParentUserId)
        .subscribe(
          (response) => {
            this.showToastMessage(
              'Usuario padre asignado exitosamente',
              'success'
            );
            this.selectedUser.parentUser = this.selectedParentUserId;
          },
          (error) => {
            console.error('Error al asignar usuario padre:', error);
            this.showToastMessage('Error al asignar usuario padre', 'error');
          }
        );
    }
  }

  saveUserInfo() {
    if (this.selectedUser && this.selectedUser._id) {
      this.isLoading = true;
      const userData = {
        name: this.selectedUser.name,
        surname: this.selectedUser.surname,
        email: this.selectedUser.email,
        phone: this.selectedUser.phone,
        contractType: this.selectedUser.contractType,
        role: this.selectedUser.role,
        wallet: this.selectedUser.wallet,
      };

      this.userService.updateUser(this.selectedUser._id, userData).subscribe(
        (response) => {
          this.isLoading = false;
          if (response.success) {
            this.showToastMessage('Usuario actualizado con éxito', 'success');
            // Actualizar el usuario en la lista de usuarios
            const index = this.users.findIndex(
              (u) => u._id === this.selectedUser._id
            );
            if (index !== -1) {
              this.users[index] = { ...this.selectedUser };
            }
          } else {
            this.showToastMessage(response.message, 'error');
          }
        },
        (error) => {
          this.isLoading = false;
          this.showToastMessage('Error al actualizar el usuario', 'error');
          console.error('Error al actualizar el usuario:', error);
        }
      );
    }
  }

  // Cancellation methods
  loadCancellationRequests(): void {
    this.cancellationService
      .getAllCancellations(
        this.cancellationCurrentPage,
        this.cancellationItemsPerPage,
        this.cancellationSortBy,
        this.cancellationSortOrder,
        this.cancellationSearchTerm
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.cancellationRequests = response.data.cancellations;
            this.cancellationTotalPages = response.data.totalPages;
            this.cancellationTotalItems = response.data.totalCancellations;
          } else {
            console.error(
              'Error loading cancellation requests:',
              response.message
            );
          }
        },
        (error) => {
          console.error('Error loading cancellation requests:', error);
        }
      );
  }

  onCancellationPageChange(page: number): void {
    if (page >= 1 && page <= this.cancellationTotalPages) {
      this.cancellationCurrentPage = page;
      this.loadCancellationRequests();
    }
  }

  searchCancellationRequests(): void {
    this.cancellationCurrentPage = 1;
    this.loadCancellationRequests();
  }

  verDetalles(solicitud: any): void {
    this.solicitudSeleccionada = solicitud;
    this.showDetallesModal = true;
  }

  triggerAccionConPin(
    solicitud: any,
    accion: 'aprobar' | 'rechazar' | 'comision'
  ): void {
    this.validatePin(() => this.abrirModalAccion(solicitud, accion));
  }

  abrirModalAccion(
    solicitud: any,
    accion: 'aprobar' | 'rechazar' | 'comision'
  ): void {
    console.log('Solicitud para acción:', solicitud);
    console.log('Acción:', accion);
    this.solicitudParaAccion = solicitud;
    if (accion === 'rechazar') {
      this.shipmentCancellationStatus = 'rechazar';
      this.showRechazarModal = true;
    } else if (accion === 'aprobar') {
      this.shipmentCancellationStatus = 'aprobar';
      this.showAprobarModal = true;
    } else {
      this.shipmentCancellationStatus = 'comision';
      this.showAprobarModal = true;
    }
  }

  cerrarModalRechazo(): void {
    this.showRechazarModal = false;
    this.solicitudParaAccion = null;
    this.razonRechazo = '';
  }

  cerrarModalAprobacion(): void {
    this.showAprobarModal = false;
    this.solicitudParaAccion = null;
  }

  confirmarAprobacion(): void {
    if (this.shipmentCancellationStatus == 'aprobar') {
      this.confirmarAccion('Aprobado');
    } else if (this.shipmentCancellationStatus == 'rechazar') {
      this.confirmarAccion('Rechazado');
    } else {
      this.confirmarAccion('Comision');
    }

    this.cerrarModalAprobacion();
  }
  confirmarAccion(status: 'Aprobado' | 'Rechazado' | 'Comision'): void {
    if (!this.solicitudParaAccion) return;

    let datos: any = { status };
    let query = '';

    console.log('Solicitud para acción:', this.solicitudParaAccion);
    console.log('Status:', status);

    if (status === 'Rechazado') {
      if (!this.razonRechazo.trim()) {
        Swal.fire(
          'Error',
          'Por favor, ingrese una razón para el rechazo.',
          'error'
        );
        return;
      }
      datos.rejection_reason = this.razonRechazo;
    }

    if (status === 'Comision') {
      datos.type = 'Comision';
      datos.status = 'Aprobado';
    }

    this.cancellationService
      .updateCancellationRequest(this.solicitudParaAccion._id, datos)
      .subscribe(
        (response) => {
          if (response.success) {
            console.log(
              `Solicitud ${status.toLowerCase()} correctamente:`,
              response
            );
            this.loadCancellationRequests();

            if (status === 'Rechazado') {
              this.cerrarModalRechazo();
            } else {
              this.cerrarModalAprobacion();
            }

            this.closeDetallesModal();

            Swal.fire(
              'Éxito',
              `La solicitud ha sido ${status.toLowerCase()} correctamente`,
              'success'
            );
          } else {
            console.error(
              `Error al ${status.toLowerCase()} la solicitud:`,
              response.message
            );
            Swal.fire(
              'Error',
              response.message || 'Ocurrió un error al procesar la solicitud.',
              'error'
            );
          }
        },
        (error) => {
          console.error(
            `Error al ${status.toLowerCase()} la solicitud:`,
            error
          );
          Swal.fire(
            'Error',
            'Ocurrió un error al procesar la solicitud. Por favor, intente nuevamente.',
            'error'
          );
        }
      );
  }

  closeDetallesModal(): void {
    this.showDetallesModal = false;
    this.solicitudSeleccionada = null;
  }

  // Packing methods
  loadPackings(): void {
    this.packingService
      .getAllPackingList(
        this.packingCurrentPage,
        this.packingItemsPerPage,
        this.packingSearchTerm
      )
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.packings = response.data.packings;
            this.packingTotalPages = response.data.totalPages;
            this.packingTotalItems = response.data.totalItems;
          } else {
            console.error('Error loading packings:', response.message);
          }
        },
        (error) => {
          console.error('Error loading packings:', error);
        }
      );
  }

  onPackingPageChange(page: number): void {
    if (page >= 1 && page <= this.packingTotalPages) {
      this.packingCurrentPage = page;
      this.loadPackings();
    }
  }

  searchPackings(): void {
    this.packingCurrentPage = 1;
    this.loadPackings();
  }

  openNewPackingModal() {
    this.showNewPackingModal = true;
  }

  closeNewPackingModal(result: { success: boolean; message: string }) {
    console.log('Resultado del modal de nuevo empaque:', result);

    if (result.message == 'Operación cancelada') {
      this.showNewPackingModal = false;
      return;
    }
    this.showNewPackingModal = false;

    this.alertMessage = result.message;
    this.alertSuccess = result.success;
    this.isError = !result.success;

    if (result.success) {
      this.alertMessage = result.message;
      this.alertSuccess = result.success;
      this.loadPackings();
    }
    setTimeout(() => {
      this.alertMessage = '';
      this.isError = false;
    }, 5000);
  }

  // Refill methods
  loadRefillRequests(): void {
    this.refillRequestService
      .getRefillRequests(
        this.refillCurrentPage,
        this.refillItemsPerPage,
        this.refillSearchTerm
      )
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.refillRequests = response.data.requests;
            this.refillTotalPages = response.data.totalPages;
            this.refillTotalItems = response.data.totalItems;
          } else {
            console.error('Error loading refill requests:', response.message);
          }
        },
        (error) => {
          console.error('Error loading refill requests:', error);
        }
      );
  }

  onRefillPageChange(page: number): void {
    if (page >= 1 && page <= this.refillTotalPages) {
      this.refillCurrentPage = page;
      this.loadRefillRequests();
    }
  }

  searchRefillRequests(): void {
    this.refillCurrentPage = 1;
    this.loadRefillRequests();
  }

  approveRefillRequest(requestId: string): void {
    const adminNotes = ''; // Puedes implementar un modal para capturar estas notas
    this.refillRequestService
      .approveRefillRequest(requestId, adminNotes)
      .subscribe(
        (response) => {
          if (response.success) {
            console.log('Refill request approved');
            this.loadRefillRequests();
          } else {
            console.error('Error approving refill request:', response.message);
          }
        },
        (error) => {
          console.error('Error approving refill request:', error);
        }
      );
  }

  rejectRefillRequest(requestId: string): void {
    const adminNotes = ''; // Puedes implementar un modal para capturar estas notas
    this.refillRequestService
      .rejectRefillRequest(requestId, adminNotes)
      .subscribe(
        (response) => {
          if (response.success) {
            console.log('Refill request rejected');
            this.loadRefillRequests();
          } else {
            console.error('Error rejecting refill request:', response.message);
          }
        },
        (error) => {
          console.error('Error rejecting refill request:', error);
        }
      );
  }

  // Utility methods
  toggleContainer(id: string | number): void {
    this.showContainer[id] = !this.showContainer[id];
  }

  showToastMessage(message: string, type: 'success' | 'error') {
    this.toastMessage = message;
    this.toastType = type;
    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 3000);
  }

  onImageSelected(event: any) {
    this.imageFile = event.target.files[0];
  }

  selectOption(option: string) {
    this.selectedOption = option;
  }

  openRoleConfirmation(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.newRole = selectElement.value;
    this.showRoleConfirmation = true;
  }

  cancelRoleChange() {
    this.showRoleConfirmation = false;
    this.newRole = '';
    // Revertir la selección en el dropdown
    if (this.selectedUser) {
      this.selectedUser.role = this.selectedUser.role;
    }
  }

  confirmRoleChange() {
    if (this.selectedUser && this.selectedUser._id) {
      this.isLoading = true;
      this.userService
        .assignRole(this.selectedUser._id, this.newRole)
        .subscribe(
          (response) => {
            this.isLoading = false;
            if (response.success) {
              this.showToastMessage(
                'Rol de usuario actualizado con éxito',
                'success'
              );
              if (this.selectedUser) {
                this.selectedUser.role = this.newRole;
              }
              // Actualizar el usuario en la lista de usuarios si es necesario
              const index = this.users.findIndex(
                (u) => u._id === this.selectedUser?._id
              );
              if (index !== -1) {
                this.users[index].role = this.newRole;
              }
            } else {
              this.showToastMessage(
                response.message || 'Error al actualizar el rol',
                'error'
              );
            }
            this.showRoleConfirmation = false;
            this.newRole = '';
          },
          (error) => {
            this.isLoading = false;
            this.showToastMessage(
              'Error al actualizar el rol del usuario',
              'error'
            );
            console.error('Error al actualizar el rol del usuario:', error);
            this.showRoleConfirmation = false;
            this.newRole = '';
          }
        );
    }
  }

  initializeUtilities() {
    if (this.selectedUser) {
      this.selectedUser.dagpacketPercentaje =
        this.selectedUser.dagpacketPercentaje?.$$numberDecimal || 0;
      this.selectedUser.servicesPercentaje =
        this.selectedUser.servicesPercentaje?.$$numberDecimal || 0;
      this.selectedUser.recharguesPercentage =
        this.selectedUser.recharguesPercentage?.$$numberDecimal || 0;
      this.selectedUser.packingPercentage =
        this.selectedUser.packingPercentage?.$$numberDecimal || 0;
    }
  }

  updateUserUtilities() {
    if (!this.selectedUser || !this.selectedUser._id) {
      console.error('No hay usuario seleccionado o falta el ID');
      return;
    }

    this.isUpdating = true;

    const updatedPercentages = {
      dagpacketPercentaje:
        parseFloat(this.selectedUser.dagpacketPercentaje) || 0,
      servicesPercentaje: parseFloat(this.selectedUser.servicesPercentaje) || 0,
      recharguesPercentage:
        parseFloat(this.selectedUser.recharguesPercentage) || 0,
      packingPercentage: parseFloat(this.selectedUser.packingPercentage) || 0,
    };

    if (
      updatedPercentages.dagpacketPercentaje < 1 ||
      updatedPercentages.dagpacketPercentaje > 100
    ) {
      Swal.fire(
        'Error',
        'El porcentaje de Envios debe estar entre 1 y 100',
        'error'
      );
      this.toastType = 'error';
      this.toastMessage = 'El porcentaje de Envios debe estar entre 1 y 100';
      this.showToast = true;
      this.isInitializing = false;
      this.isUpdating = false;
      return;
    }

    if (
      updatedPercentages.servicesPercentaje < 1 ||
      updatedPercentages.servicesPercentaje > 100
    ) {
      Swal.fire(
        'Error',
        'El porcentaje de servicios debe estar entre 1 y 100',
        'error'
      );
      this.toastType = 'error';
      this.toastMessage = 'El porcentaje de servicios debe estar entre 1 y 100';
      this.showToast = true;
      this.isInitializing = false;
      this.isUpdating = false;
      return;
    }

    if (
      updatedPercentages.recharguesPercentage < 1 ||
      updatedPercentages.recharguesPercentage > 100
    ) {
      Swal.fire(
        'Error',
        'El porcentaje de recargas debe estar entre 1 y 100',
        'error'
      );
      this.toastType = 'error';
      this.toastMessage = 'El porcentaje de recargas debe estar entre 1 y 100';
      this.showToast = true;
      this.isInitializing = false;
      this.isUpdating = false;
      return;
    }

    if (
      updatedPercentages.packingPercentage < 1 ||
      updatedPercentages.packingPercentage > 100
    ) {
      Swal.fire(
        'Error',
        'El porcentaje de empaques debe estar entre 1 y 100',
        'error'
      );
      this.toastType = 'error';
      this.toastMessage = 'El porcentaje de empaques debe estar entre 1 y 100';
      this.showToast = true;
      this.isInitializing = false;
      this.isUpdating = false;
      return;
    }

    this.userService
      .updateUserPercentages(this.selectedUser._id, updatedPercentages)
      .subscribe(
        (response: any) => {
          this.toastType = 'success';
          this.toastMessage = 'Utilidades actualizadas';
          this.showToast = true;
          this.isInitializing = false;
          this.isUpdating = false;
          // Actualizar la información del usuario después de actualizar las utilidades
          // this.loadUsers();

          console.log('Utilidades actualizadas:', response);
        },
        (error) => {
          Swal.fire(
            'Error',
            error.error.message || 'No se pudieron actualizar las utilidades',
            'error'
          );
          this.toastType = 'error';
          this.toastMessage =
            error.error.message || 'No se pudieron actualizar las utilidades';
          this.showToast = true;
          this.isInitializing = false;
          this.isUpdating = false;
        }
      );
  }

  updateUserDiscounts() {
    this.saveUserInfo();
  }

  updateContractType() {
    this.saveUserInfo();
  }

  verGuia(guia: string) {
    console.log('Ver guía:', guia);
    // Implementa la lógica para ver la guía
  }

  deletePacking(packingId: string) {
    this.showDeleteModal = true;
    this.selectedPackingId = packingId;
  }

  initializeWallet(userId: string) {
    this.isInitializing = true;
    this.walletService.initializeWallet(userId).subscribe(
      (res) => {
        this.toastType = 'success';
        this.toastMessage = 'Wallet inicializado correctamente';
        this.showToast = true;
        this.isInitializing = false;
        // Actualizar la información del usuario después de inicializar el wallet
        this.loadUsers();
      },
      (error) => {
        this.toastType = 'error';
        this.toastMessage =
          error.error.message || 'No se pudo inicializar el wallet';
        this.showToast = true;
        this.isInitializing = false;
      }
    );
  }

  addFunds(type: 'send' | 'recharge' | 'services') {
    if (!this.selectedUser || !this.selectedUser.wallet) {
      this.showToastMessage(
        'El usuario no tiene un wallet inicializado',
        'error'
      );
      return;
    }

    // Implementa la lógica para agregar fondos
    console.log(
      `Agregar fondos de tipo ${type} para el usuario ${this.selectedUser.name}`
    );
    // Aquí deberías abrir un modal o formulario para capturar la cantidad a agregar
  }

  changePage(page: number) {
    this.currentPageTransaction = page;
    this.showTransactionHistory();
  }

  nextPage() {
    this.currentPageTransaction++;
    this.showTransactionHistory();
  }

  previousPage() {
    this.currentPageTransaction--;
    this.showTransactionHistory();
  }

  showTransactionHistory() {
    if (!this.selectedUser || !this.selectedUser.wallet) {
      this.showToastMessage(
        'El usuario no tiene un wallet inicializado',
        'error'
      );
      return;
    }

    this.transactionService
      .getTransaccionByUser(
        this.selectedUser._id,
        this.currentPageTransaction,
        this.pageSize
      )
      .subscribe(
        (response) => {
          console.log('Historial de transacciones:', response);
          this.transactionHistory = response.message.transactions;
          this.hasNextPage = response.message.hasNextPage;
          this.hasPreviousPage = response.message.hasPreviousPage;
          this.totalItemsTransaction = response.message.total;
          this.showTransactionHistoryModal = true;
          this.totalPagesTransaction = response.message.totalPages;
        },
        (error) => {
          console.error(
            'Error al cargar el historial de transacciones:',
            error
          );
        }
      );

    // Implementa la lógica para mostrar el historial de transacciones
    // console.log(`Mostrar historial de transacciones para el usuario ${this.selectedUser._id}`);
    // Aquí deberías abrir un modal o navegar a una nueva vista con el historial de transacciones
  }

  loadRoles(): void {
    this.userService.getRoles().subscribe({
      next: (data) => {
        this.roles = data.data; // Ajusta según la estructura de la respuesta.
        this.roleTotalPages = Math.ceil(this.roles.length / 10); // Ejemplo de paginación.
      },
      error: (error) => {
        console.error('Error al cargar roles:', error);
      },
    });
  }

  onRolePageChange(newPage: number): void {
    if (newPage > 0 && newPage <= this.roleTotalPages) {
      this.roleCurrentPage = newPage;
      // Implementa lógica para cargar roles por página si es necesario.
    }
  }

  searchRoles(): void {
    // Lógica para filtrar roles por el término de búsqueda.
    console.log('Buscando roles para:', this.roleSearchTerm);
  }

  viewRoleDetails(role: any): void {
    console.log('Detalles del rol:', role);
    // Abre un modal para mostrar los detalles.
  }

  openDetallesRolModal(role: any): void {
    this.selectedRole = role;
    this.selectedItems = {}; // Reinicia la selección de items

    // Itera sobre las categorías del checklist
    this.data.forEach((category) => {
      this.selectedItems[category.category] = {};

      // Valida que el rol tenga permisos antes de buscar la categoría
      const roleCategory = role.permissions?.find(
        (perm: any) => perm.category === category.category
      );

      // Marca los items que coinciden con los permisos del rol
      category.items.forEach((item) => {
        this.selectedItems[category.category][item] =
          roleCategory?.actions?.includes(item) || false;
      });
    });

    // Asegúrate de que el modal siempre se abra
    this.showDetallesRolModal = true;
  }

  toggleItem(category: string, item: string): void {
    // Alterna el estado del checkbox
    this.selectedItems[category][item] = !this.selectedItems[category][item];
  }

  savePermissions(): void {
    // Preparar la estructura de permisos para guardar
    const newPermissions = this.data.map((category) => ({
      category: category.category,
      actions: category.items.filter(
        (item) => this.selectedItems[category.category][item]
      ),
    }));

    // Aquí guardas los nuevos permisos, por ejemplo:
    this.rolesService
      .updateRolePermissions(
        this.selectedRole._id,
        newPermissions as any // Cast to any to avoid type error
      )
      .subscribe(
        (response) => {
          console.log('Permissions updated successfully', response);
          Swal.fire('Permisos actualizados correctamente', '', 'success');
          this.showToastMessage('Permisos actualizados con éxito', 'success');
        },
        (error) => {
          console.error('Error updating permissions:', error);
          Swal.fire(
            'Error',
            'Ocurrió un error al actualizar los permisos',
            'error'
          );
          this.showToastMessage('Error al actualizar los permisos', 'error');
        }
      );
    console.log('Permisos a guardar:', newPermissions);
    this.selectedRole.permissions = newPermissions; // Actualiza los permisos del rol
    this.closeDetallesRolModal();
  }

  closeDetallesRolModal(): void {
    this.showDetallesRolModal = false; // Cierra el modal
    this.selectedRole = null; // Limpia el rol seleccionado
  }

  addFundsToWallet() {
    if (!this.selectedUser || !this.selectedUser.wallet) {
      this.showToastMessage(
        'El usuario no tiene un wallet inicializado',
        'error'
      );
      return;
    }

    // Implementa la lógica para agregar fondos

    console.log(`Agregar fondos para el usuario ${this.selectedUser.name}`);

    this.addFoundsService
      .addFundsToUserWallet(
        this.selectedUser._id,
        this.fundAmount,
        this.typeOfAddFunds
      )
      .subscribe(
        (response) => {
          console.log('Fondos agregados:', response);

          this.alertMessage = response.message;
          this.alertSuccess = response.success;
          this.showAddFundsModal = false;
        },
        (error) => {
          console.error('Error al agregar fondos:', error);
          this.alertMessage = error.error.message || 'Error al agregar fondos';
          this.alertSuccess = false;
        }
      );
  }

  triggerAddFunds(type: string): void {
    this.validatePin(() => this.openModalAddFunds(type));
  }

  openModalAddFunds(type: string) {
    this.typeOfAddFunds = type;
    this.showAddFundsModal = true;
  }

  closeModalAddFunds() {
    this.showAddFundsModal = false;
    this.fundAmount = 0;
  }
}
