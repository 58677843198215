<!-- Login con diseño moderno centrado -->
<div class="min-h-screen bg-gray-50 flex flex-col justify-center">
    <!-- Barra superior con logo y navegación -->
    <nav class="fixed top-0 w-full bg-white shadow-sm py-4 px-6 z-10">
        <div class="max-w-6xl mx-auto flex justify-between items-center">
            <img src="../../../assets/images/LogotipoA.png" alt="DagPacket Logo" class="h-10">
            <button [routerLink]="['/home']" class="text-dagblue hover:text-dagpk transition-colors flex items-center">
                <span class="mr-2">Volver a inicio</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </button>
        </div>
    </nav>

    <!-- Tarjeta de login -->
    <div class="max-w-md w-full mx-auto my-8 bg-white rounded-xl shadow-lg overflow-hidden md:flex-row">
        <!-- Cabecera con color de la marca -->
        <div class="h-3 bg-dagpk"></div>
        
        <div class="px-8 py-10">
            <!-- Encabezado -->
            <div class="text-center mb-8">
                <h2 class="text-2xl font-bold text-gray-800">Inicia sesión en tu cuenta</h2>
                <p class="text-gray-600 mt-2">Bienvenido de nuevo</p>
            </div>

            <!-- Mensaje de error -->
            <div *ngIf="errorMessage" class="mb-6 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <span class="block sm:inline">{{ errorMessage }}</span>
            </div>

            <!-- Formulario -->
            <form #loginForm="ngForm" (submit)="signIn(loginForm)" class="space-y-6">
                <!-- Campo email -->
                <div>
                    <label for="email" class="block text-sm font-medium text-gray-700 mb-1">Correo electrónico</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                            </svg>
                        </div>
                        <input name="email" type="email" id="email" placeholder="nombre@ejemplo.com" 
                               class="pl-10 w-full py-3 border border-gray-300 rounded-lg focus:ring-dagpk focus:border-dagpk"
                               ngModel required>
                    </div>
                </div>

                <!-- Campo contraseña -->
                <div>
                    <div class="flex justify-between items-center mb-1">
                        <label for="password" class="block text-sm font-medium text-gray-700">Contraseña</label>
                        <a [routerLink]="['/reset-request']" class="text-xs text-dagblue hover:underline">
                            ¿Olvidaste la contraseña?
                        </a>
                    </div>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                            </svg>
                        </div>
                        <input name="password" [type]="showPassword ? 'text' : 'password'" id="password" placeholder="••••••••"
                               class="pl-10 w-full py-3 border border-gray-300 rounded-lg focus:ring-dagpk focus:border-dagpk"
                               ngModel required>
                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
                            <button type="button" (click)="togglePassword()" class="text-gray-400 hover:text-gray-600">
                                <svg *ngIf="!showPassword" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                                <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Botón de login -->
                <button type="submit" class="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-dagpk hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dagpk">
                    INGRESAR
                </button>
            </form>

            <!-- Separador -->
            <div class="my-6 flex items-center">
                <div class="flex-grow border-t border-gray-300"></div>
                <span class="flex-shrink mx-4 text-sm text-gray-500">O</span>
                <div class="flex-grow border-t border-gray-300"></div>
            </div>

            <!-- Registro -->
            <div class="text-center">
                <p class="text-sm text-gray-600 mb-4">¿Aún no tienes una cuenta?</p>
                <button [routerLink]="['/signup']" 
                        class="w-full py-3 px-4 rounded-lg border-2 border-dagpk text-dagpk font-medium hover:bg-dagpk hover:text-white transition-colors">
                    CREAR CUENTA NUEVA
                </button>
            </div>
        </div>
    </div>
    
    <!-- Footer simple -->
    <footer class="py-4 text-center text-sm text-gray-500">
        <p>© 2025 DagPacket. Todos los derechos reservados.</p>
    </footer>
</div>