<app-sidebar></app-sidebar>
<div class="ml-64 pt-20 mx-auto p-3">
  <!-- Steps -->
  <div class="flex items-center mb-4">
    <div class="flex items-center space-x-2">
      <div
        class="bg-dagblue text-white font-bold rounded-full h-8 w-8 flex items-center justify-center"
      >
        1
      </div>
      <span>Cart</span>
    </div>
    <div class="flex-grow border-t border-gray-300 mx-2"></div>
    <div class="flex items-center space-x-2">
      <div
        class="bg-gray-300 text-white font-bold rounded-full h-8 w-8 flex items-center justify-center"
      >
        2
      </div>
      <span class="text-gray-500">Pago</span>
    </div>
  </div>

  <!-- Main content -->x
  <div class="flex space-x-6">
    <!-- Cart -->
    <div class="bg-white shadow-lg rounded-lg p-6 flex-1">
      <div class="flex justify-between items-center mb-6">
        <div class="flex gap-4 items-center">
          <img
            [src]="
              '../../../../assets/paqueterias/' +
              paqueteriaData.proveedor +
              '.png'
            "
            alt=""
            class="w-24 h-15"
          />
          <span class="text-lg font-bold">{{ paqueteriaData.proveedor }}</span>
        </div>
        <span class="text-dagblue text-xl font-bold">{{
          paqueteriaData?.precio | currency
        }}</span>
      </div>

      <div class="space-y-4">
        <span class="block font-bold">Descripción del paquete:</span>
        <span class="block">{{ shipmentData.description }}</span>
      </div>

      <div class="space-y-6">
        <!-- Origen -->
        <div class="border p-4 rounded-lg shadow-md">
          <span class="block font-bold">Origen</span>
          <div class="border-t border-gray-300 mt-2 mb-2"></div>
          <span class="block">Ciudad: {{ fromData.city }}</span>
          <span class="block">C.P.: {{ fromData.zip_code }}</span>
          <span class="block">Calle: {{ fromData.street }}</span>
          <span class="block">Nombre: {{ fromData.name }}</span>
          <span class="block">Email: {{ fromData.email }}</span>
          <span class="block">Phone: {{ fromData.phone }}</span>
          <button
            class="mt-2 text-dagblue flex items-center"
            (click)="openEditForm('remitente')"
          >
            <i class="fa fa-pencil mr-1"></i> Editar Remitente
          </button>
        </div>

        <!-- Destino -->
        <div class="border p-4 rounded-lg shadow-md">
          <span class="block font-bold">Destino</span>
          <div class="border-t border-gray-300 mt-2 mb-2"></div>
          <span class="block">Ciudad: {{ toData.city }}</span>
          <span class="block">C.P.: {{ toData.zip_code }}</span>
          <span class="block">Calle: {{ toData.street }}</span>
          <span class="block">Nombre: {{ toData.name }}</span>
          <span class="block">Email: {{ toData.email }}</span>
          <span class="block">Phone: {{ toData.phone }}</span>
          <button
            class="mt-2 text-dagblue flex items-center"
            (click)="openEditForm('destinatario')"
          >
            <i class="fa fa-pencil mr-1"></i> Editar Destinatario
          </button>
        </div>
      </div>

      <!-- Dimensiones -->
      <div class="border p-4 rounded-lg shadow-md mt-4">
        <span class="block font-bold">Dimensiones del paquete</span>
        <div class="border-t border-gray-300 mt-2 mb-2"></div>
        <div class="flex gap-4">
          <span class="block font-bold"
            >Alto:
            <span class="font-light">{{ dimensionesData.alto }}cm</span></span
          >
          <span class="block font-bold"
            >Ancho:
            <span class="font-light">{{ dimensionesData.ancho }}cm</span></span
          >
          <span class="block font-bold"
            >Largo:
            <span class="font-light">{{ dimensionesData.largo }}cm</span></span
          >
          <span class="block font-bold"
            >Peso:
            <span class="font-light">{{ dimensionesData.peso }}kg</span></span
          >
        </div>
      </div>

      <div class="flex justify-between items-center mt-4">
        <button class="bg-dagblue text-white p-2 rounded-lg w-full mr-2">
          Agregar otro paquete
        </button>
        <button class="text-red-500">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>

    <!-- Summary -->
    <div class="bg-white rounded-lg p-6 w-1/3">
      <h3 class="text-xl font-bold mb-4">Sumario de Compra</h3>
      <div class="space-y-3">
        <div class="flex justify-between">
          <span>Sub Total Paquetes</span>
          <span>{{ subtotal | currency }}</span>
        </div>
        <div class="flex justify-between">
          <span
            >Empaque:
            {{ selectedPackage ? selectedPackage.name : "Ninguno" }}</span
          >
          <span>{{ packageCost | currency }}</span>
        </div>
        <div class="flex justify-between" *ngIf="discount !== 0">
          <span>Descuento</span>
          <span
            [ngClass]="{
              'text-green-500': discount > 0,
              'text-red-500': discount < 0
            }"
          >
            {{ discount > 0 ? "-" : "+" }}{{ Math.abs(discount) | currency }}
          </span>
        </div>
      </div>

      <button
        class="bg-dagblue text-white p-2 rounded-lg mt-4 w-full"
        (click)="openDiscountDialog()"
      >
        {{ discount === 0 ? "Aplicar Descuento" : "Modificar Descuento" }}
      </button>

      <div class="border-t border-gray-300 mt-4 mb-4"></div>
      <div class="flex justify-between mt-4">
        <span class="text-lg font-bold">Total</span>
        <span class="text-lg font-bold text-dagblue">{{
          total | currency
        }}</span>
      </div>

      <button
        (click)="createNewShipment()"
        [disabled]="isDisabled"
        [class.bg-gray-400]="isDisabled"
        [class.bg-dagblue]="!isDisabled"
        class="text-white p-2 rounded-lg mt-4 w-full"
      >
        Generar Pedido
      </button>
    </div>

    <!-- Discount Dialog -->
    <div
      *ngIf="isDiscountDialogOpen"
      class="fixed z-10 inset-0 overflow-y-auto"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity"
          (click)="closeDiscountDialog()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Aplicar Descuento
            </h3>
            <div class="mt-2">
              <label class="block">Descuento:</label>
              <input
                [(ngModel)]="discountAmount"
                type="number"
                min="0"
                class="border p-2 rounded w-full"
                placeholder="Ej. $50"
              />
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="bg-dagblue text-white p-2 rounded-lg w-full sm:ml-3"
              (click)="applyDiscount()"
            >
              Guardar
            </button>
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
              (click)="closeDiscountDialog()"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isEditModalOpen" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen">
    <div
      class="fixed inset-0 bg-gray-500 opacity-75"
      (click)="closeEditModal()"
    ></div>
    <div
      class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
          Editar
          {{ selectedFormType === "remitente" ? "Remitente" : "Destinatario" }}
        </h3>
        <form (ngSubmit)="saveEditedAddress()" class="space-y-4">
          <div>
            <label class="block text-sm font-medium text-gray-700"
              >Nombre:</label
            >
            <input
              [(ngModel)]="editForm.name"
              name="name"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              [value]="editForm.name"
              required
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700"
              >Teléfono:</label
            >
            <input
              [(ngModel)]="editForm.phone"
              name="phone"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              [value]="editForm.phone"
              required
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700"
              >Email:</label
            >
            <input
              [(ngModel)]="editForm.email"
              name="email"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              [value]="editForm.email"
              required
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700"
              >Calle:</label
            >
            <input
              [(ngModel)]="editForm.street"
              name="street"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              [value]="editForm.street"
              required
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700"
              >Ciudad:</label
            >
            <input
              [(ngModel)]="editForm.city"
              name="city"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              [value]="editForm.city"
              required
            />
          </div>
          <div class="flex justify-end space-x-2">
            <button type="submit" class="bg-dagblue text-white p-2 rounded-lg">
              Guardar
            </button>
            <button
              type="button"
              class="bg-gray-300 text-gray-700 p-2 rounded-lg"
              (click)="closeEditModal()"
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
