import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
@Component({
  selector: 'app-personal-inventories',
  templateUrl: './personal-inventories.component.html',
  styleUrls: ['./personal-inventories.component.scss']
})
export class PersonalInventoriesComponent implements OnInit {

  inventories: any[] = [];
  loading: boolean = true;
  error: string | null = null;
  packings: any[] = [];
  showModal: boolean = false;
  showModalTransfer: boolean = false;
  selectedUser: any = null;
  showTransferModal: boolean = false;
  cantidadTransferir: number = 0;
  notasTransferencia:string = '';
  selectedPackingId: any = null;
  selectedUserInventory: any = null;
  userList : any[] = [];
  showUsersModal: boolean = false;
  constructor(
      private refillRequestService: RefillRequestService,
      private packingService: ShipmentsService,
      private userService: UserServiceService
    ) {}
  
  
  
  ngOnInit(): void {
    // this.loadInventory();
    this.loadUsers();
    this.loadPackings();
  }

  closeUsersModal(): void {
    this.showUsersModal = false;
  }

  selectUser(user: any): void {
    console.log('user:', user);
    this.selectedUser = user;
    this.showUsersModal = false;
    this.loadInventory(user);
  }

  loadUsers(): void {
    this.userService.userlistLicencees(
      1,
      10,
      ''
      
    ).subscribe(
      (response) => {
        if (response.success) {
          console.log('users:', response.data.users);
          this.userList = response.data.users;
          this.showUsersModal = true;
        } else {
          this.error = 'No se pudo obtener los usuarios.';
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error al obtener los usuarios:', error);
        this.error = 'Error al cargar los usuarios.';
        this.loading = false;
      }
    );
  }

  closeModal(): void {
    
    this.showModal = false;
  }

  openModal(id:string): void {
    console.log('openModal:', id);
    this.selectedUser = id;
    this.showModal = true;
  }


  confirmTransfer(): void {
    console.log('confirmTransfer');
    console.log('this.selectedUser:', this.selectedUser);
    console.log('descripcion:', this.notasTransferencia);
    console.log('cantidadTransferir:', this.cantidadTransferir);
    this.refillRequestService.createTransferRequest({
      userId: this.selectedUser,
      packingId: this.selectedPackingId,
      quantity_transferred: this.cantidadTransferir,
      admin_notes: this.notasTransferencia
    }).subscribe(
      (response) => {
        if (response.success) {
          console.log('Transferencia realizada:', response);
          this.showModalTransfer = false;
          // this.loadInventory();
        } else {
          console.error('Error al realizar la transferencia:', response);
          this.error = 'Error al realizar la transferencia.';
        }
      },
      (error) => {
        console.error('Error al realizar la transferencia:', error);
        this.error = 'Error al realizar la transferencia.';
      }
    );
  }
  selectPacking(packing: any): void {
    console.log('packing:', packing);
    this.selectedPackingId = packing._id;
    this.showModalTransfer = true;
    // this.showModal = true;
  }
  loadPackings(): void {
    this.packingService.getAllPackingList().subscribe(
      (response) => {
        if (response.success) {
          console.log("empaques:", response.data.packings)
          this.packings = response.data.packings;
        } else {
          this.error = 'No se pudo obtener los empaques.';
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error al obtener los empaques:', error);
        this.error = 'Error al cargar los empaques.';
        this.loading = false;
      }
    );
  }

  openUsersModal(){
    this.showUsersModal = true;
  }
  
  loadInventory(user:string): void {
    this.refillRequestService.getUserInventory(user).subscribe(
      (response) => {
        if (response.success) {
          console.log("inventario:", response.data.inventory)
          this.inventories = response.data.inventories;
          console.log("inventario:", response.data.inventories)
        } else {
          this.error = 'No se pudo obtener el inventario.';
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error al obtener el inventario:', error);
        this.error = 'Error al cargar el inventario.';
        this.loading = false;
      }
    );
  }

}
