<app-sidebar></app-sidebar>
<div class="ml-64 mt-16">
  <div class="container mx-auto px-4 py-8">
    <h2 class="text-3xl font-bold mb-6 text-gray-800">
      Panel de Inversionista
    </h2>
    <nav
      class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1"
    ></nav>
    <div class="mt-3">
      <div class="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md"
        >
          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-800 to-blue-600 text-white shadow-blue-700/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
              class="w-6 h-6 text-white"
            >
              <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"></path>
              <path
                fill-rule="evenodd"
                d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z"
                clip-rule="evenodd"
              ></path>
              <path
                d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z"
              ></path>
            </svg>
          </div>
          <!-- Cuadrito de Ganacias -->
          <div class="p-4 text-right">
            <p
              class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
            >
              Ganancias Totales
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"
            >
              {{ totalGananciasTransacciones | currency }}
            </h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <button
              (click)="openHistorialModal()"
              class="block w-full text-center bg-blue-700 text-white font-semibold py-2 rounded-lg transition duration-300 ease-in-out hover:bg-blue-800"
            >
              Ver Historial
            </button>
            <div class="mt-4"></div>
            <p
              class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600 mb-2"
            >
              <strong class="text-blue-500">3 Transeferencias</strong>&nbsp;
              Nuevas
            </p>
          </div>
        </div>

        <!-- Modal Historial-->
        <div
          *ngIf="showHistorialModal"
          class="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50"
        >
          <div
            class="relative p-5 border w-full max-w-2xl shadow-lg rounded-md bg-white"
          >
            <div class="text-center">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Historial de Transacciones
              </h3>
              <div class="mt-2">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Fecha
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Monto
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr
                      *ngFor="
                        let transaccion of historialTransacciones;
                        let i = index
                      "
                    >
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        {{ i + 1 }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        {{ transaccion.fecha }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        {{ transaccion.monto | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="mt-4">
                  <p class="text-right font-semibold">
                    Total: {{ totalGananciasTransacciones | currency }}
                  </p>
                </div>
              </div>
              <div class="mt-4">
                <div class="flex justify-end">
                  <button
                    (click)="closeHistorialModal()"
                    class="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-500"
                  >
                    <span class="sr-only">Cerrar</span>
                    <svg
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Cuadrito de Envios -->
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md"
        >
          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-800 to-blue-600 text-white shadow-blue-700/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="size-6"
            >
              <path
                d="M12.378 1.602a.75.75 0 0 0-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03ZM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 0 0 .372-.648V7.93ZM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 0 0 .372.648l8.628 5.033Z"
              />
            </svg>
          </div>
          <div class="p-4 text-right">
            <p
              class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
            >
              Envios
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"
            >
              326
            </h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <button
              (click)="openPedidosModal()"
              class="block w-full text-center bg-blue-700 text-white font-semibold py-2 rounded-lg transition duration-300 ease-in-out hover:bg-blue-800"
            >
              Ver Pedidos
            </button>
            <div class="mt-4"></div>
            <p
              class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600 mb-2"
            >
              <strong class="text-blue-600">3 Paquetes eviados</strong>&nbsp;
              Hoy
            </p>
          </div>
        </div>

        <!-- Modal de envios -->
        <div
          *ngIf="showPedidosModal"
          class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
        >
          <div
            class="relative top-20 mx-auto p-5 border w-11/12 max-w-4xl shadow-lg rounded-md bg-white"
          >
            <div class="mt-3 text-center">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Pedidos
              </h3>
              <div class="mt-2 px-7 py-3">
                <div class="flex justify-between mb-4">
                  <input
                    type="text"
                    placeholder="Buscar..."
                    class="px-4 py-2 border rounded-md"
                    [(ngModel)]="searchTerm"
                  />
                  <select
                    class="px-4 py-2 border rounded-md"
                    [(ngModel)]="filterStatus"
                  >
                    <option value="">Todos</option>
                    <option value="En reparto">En reparto</option>
                    <option value="Entregado">Entregado</option>
                    <option value="Cancelado">Cancelado</option>
                  </select>
                </div>
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ID
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Estado
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Fecha
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Cliente
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Dirección
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Ganacias
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr *ngFor="let pedido of filteredPedidos()">
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                      >
                        {{ pedido.id }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                      >
                        {{ pedido.estado }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                      >
                        {{ pedido.fecha }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                      >
                        {{ pedido.cliente }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                      >
                        {{ pedido.direccion }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                      >
                        {{ pedido.gananciaspedidos | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="flex justify-between mt-4 text-sm">
                  <button
                    (click)="prevPage()"
                    [disabled]="currentPage === 1"
                    class="px-2 py-1 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 disabled:opacity-50"
                  >
                    Anterior
                  </button>
                  <span class="text-gray-600"
                    >Página {{ currentPage }} de {{ totalPages }}</span
                  >
                  <button
                    (click)="nextPage()"
                    [disabled]="currentPage === totalPages"
                    class="px-2 py-1 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 disabled:opacity-50"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
              <div class="items-center px-4 py-3">
                <button
                  (click)="closePedidosModal()"
                  class="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">Cerrar</span>
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Cuadrito de provedores -->
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md"
        >
          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-800 to-blue-600 text-white shadow-blue-700/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="size-6"
            >
              <path
                d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z"
              />
              <path
                fill-rule="evenodd"
                d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087Zm6.163 3.75A.75.75 0 0 1 10 12h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>

          <div class="p-4 text-right">
            <p
              class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
            >
              Proveedores
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"
            >
              15
            </h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <a
              href="#"
              (click)="openCuponesModal(); $event.preventDefault()"
              class="block w-full text-center bg-blue-700 text-white font-semibold py-2 rounded-lg transition duration-300 ease-in-out hover:bg-blue-800"
            >
              Ver Proveedores
            </a>
            <div class="mt-4"></div>
            <p
              class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600 mb-2"
            >
              <strong class="text-blue-500">1 Provedor</strong>&nbsp; Imprenta
            </p>
            <p
              class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600 mb-1"
            >
              <strong class="text-blue-500">4 Provedor</strong>&nbsp; Cajas
            </p>
          </div>
        </div>

        <!-- Cuadrito de lockers -->
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md"
        >
          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-800 to-blue-600 text-white shadow-blue-700/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M1.5 9.832v1.793c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875V9.832a3 3 0 0 0-.722-1.952l-3.285-3.832A3 3 0 0 0 16.215 3h-8.43a3 3 0 0 0-2.278 1.048L2.222 7.88A3 3 0 0 0 1.5 9.832ZM7.785 4.5a1.5 1.5 0 0 0-1.139.524L3.881 8.25h3.165a3 3 0 0 1 2.496 1.336l.164.246a1.5 1.5 0 0 0 1.248.668h2.092a1.5 1.5 0 0 0 1.248-.668l.164-.246a3 3 0 0 1 2.496-1.336h3.165l-2.765-3.226a1.5 1.5 0 0 0-1.139-.524h-8.43Z"
                clip-rule="evenodd"
              />
              <path
                d="M2.813 15c-.725 0-1.313.588-1.313 1.313V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-1.688c0-.724-.588-1.312-1.313-1.312h-4.233a3 3 0 0 0-2.496 1.336l-.164.246a1.5 1.5 0 0 1-1.248.668h-2.092a1.5 1.5 0 0 1-1.248-.668l-.164-.246A3 3 0 0 0 7.046 15H2.812Z"
              />
            </svg>
          </div>
          <div class="p-4 text-right">
            <p
              class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
            >
              Ganancias de lockers
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"
            >
              ${{ totalGananciasLocker }}
            </h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <ul class="list-none text-blue-gray-600">
              <li
                *ngFor="let locker of lockers"
                class="flex items-center justify-between mb-2"
              >
                <span>
                  {{ locker.name }} -
                  <strong class="text-blue-600">{{ locker.status }}</strong>
                </span>
                <button
                  (click)="openLockerModal(locker)"
                  class="bg-blue-800 text-white font-semibold py-1 px-3 rounded-lg transition duration-300 ease-in-out hover:bg-blue-900 w-32 text-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-5 h-5 inline-block mr-1"
                  >
                    <path
                      d="M8.25 10.875a2.625 2.625 0 1 1 5.25 0 2.625 2.625 0 0 1-5.25 0Z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.125 4.5a4.125 4.125 0 1 0 2.338 7.524l2.007 2.006a.75.75 0 1 0 1.06-1.06l-2.006-2.007a4.125 4.125 0 0 0-3.399-6.463Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Ver
                </button>
              </li>
            </ul>
            <p
              class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-500 text-left"
            >
              Actualizado
            </p>
          </div>
        </div>

        <!-- Modal de Lockers -->
        <div
          *ngIf="showLockerModal"
          class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
        >
          <div class="bg-white rounded-lg shadow-lg overflow-hidden">
            <div class="container mx-auto p-6">
              <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-bold">
                  Ganancias generadas {{ selectedLocker?.name }}
                </h2>
                <button
                  (click)="closeLockerModal()"
                  class="text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">Cerrar</span>
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <p><strong>Estado:</strong> {{ selectedLocker?.status }}</p>
                <p><strong>Ubicación:</strong> Minerva</p>
                <hr class="my-4" />
                <table class="min-w-full bg-white">
                  <thead class="bg-gray-100">
                    <tr>
                      <th class="py-2 px-4 border-b">Fecha</th>
                      <th class="py-2 px-4 border-b">Ganancias</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ganancia of gananciasLocker">
                      <td class="py-2 px-4 border-b">{{ ganancia.fecha }}</td>
                      <td class="py-2 px-4 border-b">
                        {{ ganancia.monto | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Cuadrito de Emida -->
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md"
        >
          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M19.449 8.448 16.388 11a4.52 4.52 0 0 1 0 2.002l3.061 2.55a8.275 8.275 0 0 0 0-7.103ZM15.552 19.45 13 16.388a4.52 4.52 0 0 1-2.002 0l-2.55 3.061a8.275 8.275 0 0 0 7.103 0ZM4.55 15.552 7.612 13a4.52 4.52 0 0 1 0-2.002L4.551 8.45a8.275 8.275 0 0 0 0 7.103ZM8.448 4.55 11 7.612a4.52 4.52 0 0 1 2.002 0l2.55-3.061a8.275 8.275 0 0 0-7.103 0Zm8.657-.86a9.776 9.776 0 0 1 1.79 1.415 9.776 9.776 0 0 1 1.414 1.788 9.764 9.764 0 0 1 0 10.211 9.777 9.777 0 0 1-1.415 1.79 9.777 9.777 0 0 1-1.788 1.414 9.764 9.764 0 0 1-10.212 0 9.776 9.776 0 0 1-1.788-1.415 9.776 9.776 0 0 1-1.415-1.788 9.764 9.764 0 0 1 0-10.212 9.774 9.774 0 0 1 1.415-1.788A9.774 9.774 0 0 1 6.894 3.69a9.764 9.764 0 0 1 10.211 0ZM14.121 9.88a2.985 2.985 0 0 0-1.11-.704 3.015 3.015 0 0 0-2.022 0 2.985 2.985 0 0 0-1.11.704c-.326.325-.56.705-.704 1.11a3.015 3.015 0 0 0 0 2.022c.144.405.378.785.704 1.11.325.326.705.56 1.11.704.652.233 1.37.233 2.022 0a2.985 2.985 0 0 0 1.11-.704c.326-.325.56-.705.704-1.11a3.016 3.016 0 0 0 0-2.022 2.985 2.985 0 0 0-.704-1.11Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="p-4 text-right">
            <p
              class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
            >
              Ganacias de Emida
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"
            >
              $2,300.00
            </h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <ul class="list-none text-blue-gray-600">
              <li>Hoy <strong class="text-orange-500">+5%</strong></li>
              <li>Ayer <strong class="text-black-500">-2%</strong></li>
              <li>
                Semana pasada <strong class="text-orange-500">+10%</strong>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md"
        >
          <!-- Cuadrito de Anunciante -->
          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="size-6"
            >
              <path
                fill-rule="evenodd"
                d="M11.99 2.243a4.49 4.49 0 0 0-3.398 1.55 4.49 4.49 0 0 0-3.497 1.306 4.491 4.491 0 0 0-1.307 3.498 4.491 4.491 0 0 0-1.548 3.397c0 1.357.6 2.573 1.548 3.397a4.491 4.491 0 0 0 1.307 3.498 4.49 4.49 0 0 0 3.498 1.307 4.49 4.49 0 0 0 3.397 1.549 4.49 4.49 0 0 0 3.397-1.549 4.49 4.49 0 0 0 3.497-1.307 4.491 4.491 0 0 0 1.306-3.497 4.491 4.491 0 0 0 1.55-3.398c0-1.357-.601-2.573-1.549-3.397a4.491 4.491 0 0 0-1.307-3.498 4.49 4.49 0 0 0-3.498-1.307 4.49 4.49 0 0 0-3.396-1.549Zm3.53 7.28a.75.75 0 0 0-1.06-1.06l-6 6a.75.75 0 1 0 1.06 1.06l6-6Zm-5.78-.905a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm4.5 4.5a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="p-4 text-right">
            <p
              class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
            >
              Ganancias por Publicidad
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"
            >
              $ 3,462.00
            </h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <ul class="list-none text-blue-gray-600">
              <li>
                Cocacola
                <strong class="text-orange-500">{{
                  clientesHoy | currency
                }}</strong>
              </li>
              <li>
                Mcdonalds
                <strong class="text-black-500">{{
                  clientesAyer | currency
                }}</strong>
              </li>
              <li>
                Colgate
                <strong class="text-orange-500">{{
                  clientesSemanaPasada | currency
                }}</strong>
              </li>
            </ul>
          </div>
        </div>

        <!-- Cuadrito de Recargas -->
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md"
        >
          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
              class="w-6 h-6 text-white"
            >
              <path
                fill-rule="evenodd"
                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <p
              class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
            >
              Recargas
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"
            >
              2,300
            </h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <ul class="list-none text-blue-gray-600">
              <li>
                Telcel - <strong class="text-orange-500">Pendiente</strong> -
                ID: 12345
              </li>
              <li>
                AT&T - <strong class="text-black-500">Procesado</strong> - ID:
                67890
              </li>
              <li>
                Virgin Mobile -
                <strong class="text-orange-500">Pendiente</strong> - ID: 11223
              </li>
            </ul>
          </div>
        </div>
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md"
        >
          <!-- Cuadrito de Servicios -->
          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
              class="w-6 h-6 text-white"
            >
              <path
                d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z"
              ></path>
              <path d="M11 6h2v6h-2zM11 14h2v2h-2z"></path>
            </svg>
          </div>
          <div class="p-4 text-right">
            <p
              class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
            >
              Servicios
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"
            >
              4 Servicios Pendientes
            </h4>
          </div>
          <div class="border-t border-blue-gray-50 p-4">
            <ul class="list-none text-blue-gray-600">
              <li>
                Siapa - <strong class="text-orange-600">Pendiente</strong> - ID:
                12345
              </li>
              <li>
                CFE - <strong class="text-black-600">Procesado</strong> - ID:
                67890
              </li>
              <li>
                Telmex - <strong class="text-orange-600">Pendiente</strong> -
                ID: 11223
              </li>
              <li>
                Megacable - <strong class="text-black-600">Procesado</strong> -
                ID: 44556
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Tabla -->
      <div class="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-3">
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2"
        >
          <div
            class="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 flex items-center justify-between p-6"
          >
            <div>
              <h6
                class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1"
              >
                Historial
              </h6>
              <p
                class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
              >
                Últimos movimientos
              </p>
            </div>
          </div>
          <div class="overflow-x-auto">
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Fecha
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Descripción
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Monto
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Estado
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">01/10/2023</p>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">Paquete</p>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">$1,000</p>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <span
                      class="relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight"
                    >
                      <span
                        aria-hidden
                        class="absolute inset-0 bg-orange-300 opacity-50 rounded-full"
                      ></span>
                      <span class="relative">Entregado</span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Mensajería local e internacional -->
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden"
        >
          <div
            class="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 flex items-center justify-between p-6"
          >
            <div>
              <h6
                class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1"
              >
                Mensajería local e internacional
              </h6>
              <p
                class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600"
              >
                Servicios de mensajería
              </p>
            </div>
          </div>
          <div class="p-4">
            <ul class="list-none text-blue-gray-600">
              <li>
                Local:
                <strong class="text-orange-500">Entrega en 24 horas</strong>
              </li>
              <li>
                Internacional:
                <strong class="text-orange-500">Entrega en 3-5 días</strong>
              </li>
              <li>Seguimiento en tiempo real</li>
              <li>Seguro de envío</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- modal provedores -->
      <div
        *ngIf="showProvedores"
        class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
      >
        <div
          class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
        >
          <div class="container mx-auto p-4">
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-2xl font-bold">Registro de pagos</h2>
              <button
                (click)="closeCuponesModal()"
                class="text-gray-400 hover:text-gray-500"
              >
                <span class="sr-only">Cerrar</span>
                <svg
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <!-- Sección de búsqueda y filtros -->
            <div class="mb-4 flex flex-wrap justify-between items-center">
              <input
                type="text"
                placeholder="Filtrar por nombre o pro ..."
                class="border rounded px-4 py-2 mb-2 md:mb-0"
              />
              <div class="flex space-x-2 items-center">
                <div class="flex space-x-2">
                  <input
                    type="date"
                    placeholder="Desde"
                    class="border rounded px-4 py-2"
                  />
                  <input
                    type="date"
                    placeholder="Hasta"
                    class="border rounded px-4 py-2"
                  />
                  <button class="bg-white border rounded px-2 py-2">
                    <svg
                      class="h-6 w-6 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M19.707 5.293l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 1.414z"
                      />
                      <path
                        d="M7 10h4a1 1 0 010 2H7a1 1 0 010-2zm-1 5h6a1 1 0 010 2H6a1 1 0 010-2z"
                      />
                    </svg>
                  </button>
                </div>
                <button class="bg-white border rounded px-2 py-2">
                  <svg
                    class="h-6 w-6 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="overflow-x-auto">
              <table class="min-w-full bg-white">
                <thead class="bg-darkblue text-black">
                  <tr>
                    <th class="py-2 px-4 border-b">Usuario</th>
                    <th class="py-2 px-4 border-b">Servicio</th>
                    <th class="py-2 px-4 border-b">Fecha Pago</th>
                    <th class="py-2 px-4 border-b">Num.Referencia</th>
                    <th class="py-2 px-4 border-b">Num.Autorizacion</th>
                    <th class="py-2 px-4 border-b">Monto</th>
                    <th class="py-2 px-4 border-b">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr>
                    <td class="py-2 px-4 border-b">Ejemplo Nombre</td>
                    <td class="py-2 px-4 border-b">ABC123456</td>
                    <td class="py-2 px-4 border-b">01/01/2024</td>
                    <td class="py-2 px-4 border-b">15/01/2024</td>
                    <td class="py-2 px-4 border-b">Activo</td>
                  </tr> -->
                </tbody>
              </table>
              <!-- Paginación -->
              <div class="flex justify-between mt-4 text-sm">
                <button
                  (click)="prevPage()"
                  [disabled]="currentPage === 1"
                  class="px-2 py-1 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 disabled:opacity-50"
                >
                  Anterior
                </button>
                <span class="text-gray-600"
                  >Página {{ currentPage }} de {{ totalPages }}</span
                >
                <button
                  (click)="nextPage()"
                  [disabled]="currentPage === totalPages"
                  class="px-2 py-1 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 disabled:opacity-50"
                >
                  Siguiente
                </button>
              </div>
            </div>
            <div class="items-center px-4 py-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
