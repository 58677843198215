import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Users } from 'src/app/_models/user-model';
import { Rol } from 'src/app/_models/rol_model';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { RolesService } from 'src/app/_services/Roles/roles.service';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { CashRegisterService } from 'src/app/_services/Cashiers/cash-register.service';
import { EmiausService } from 'src/app/_services/Emida/emiaus.service';
import Swal from 'sweetalert2';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { filter, switchMap, tap } from 'rxjs/operators';
import { WalletService } from 'src/app/_services/Wallets/wallet.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';

interface CashierTransaction {
  operation_by: {
    name: string;
    email: string;
  };
  createdAt: string;
  amount: {
    $numberDecimal: string;
  };
}

interface Cashier {
  transactions: CashierTransaction[];
  [key: string]: any; // Para permitir otras propiedades
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('sectionAnimation', [
      state(
        'closed',
        style({
          height: '0',
          opacity: '0',
          overflow: 'hidden',
        })
      ),
      state(
        'open',
        style({
          height: '*',
          opacity: '1',
        })
      ),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  collapsed = false;

  constructor(
    private authService: AuthLoginService,
    private router: Router,
    private shipmentService: ShipmentsService,
    private roleService: RolesService,
    private walletService: WalletService,
    private transactionService: TransactionService,
    private cashRegisterService: CashRegisterService,
    private emiausService: EmiausService
  ) {
    this.currentPage = this.router.url;
  }

  openSections: { [key: string]: boolean } = {};
  user!: Users;
  rol!: Rol;
  hasWallet: boolean = false;
  roleHasWallet: boolean = false;
  parentHasWallet: boolean = false;

  currentPage: string;
  id!: any;
  shipmentBalance: number = 0;
  servicesBalance: number = 0;
  recharguesBalance: number = 0;
  isCartOpen = false;

  cartItems: any[] = [];
  walletCardModalIsOpen = false;
  walletModalIsOpen = false;
  transactionHistory: any[] = [];
  hasNextPage = false;
  hasPreviousPage = false;
  currentPageTransaction = 1;
  totalPages = 0;
  pageSize = 10;
  totalPagesTransaction = 0;
  totalItemsTransaction = 0;
  showTransactionHistoryModal = false;
  isSidebarOpen: boolean = false;

  //Transacciones de cajas registradoras
  cashTransactionHistory: any[] = [];
  hasNextPageCashTransaction = false;
  hasPreviousPageCashTransaction = false;
  currentPageCashTransaction = 1;
  totalPagesCashTransaction = 0;
  pageSizeCashTransaction = 10;
  cashRegister_id: string = '';
  isNotificationModalOpen: boolean = false;
  modalComissionIsOpen: boolean = false;
  rechargeBalance: string = '';
  paymentBalance: string = '';

  initializeWallet(userId: string) {
    console.log('Initializing wallet');
    console.log('User ID:', userId);

    this.walletService.initializeWallet(userId).subscribe(
      (res) => {
        console.log('Wallet created:', res);
        window.location.reload();
      },
      (error) => {
        console.log('Error creating wallet:', error);
      }
    );
  }

  ngOnInit(): void {
    const savedState = localStorage.getItem('sidebarState');
    if (savedState) {
      this.collapsed = savedState === 'collapsed';
    }

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.currentPage = event.url;
        this.saveSectionState();
      });

    this.authService
      .userProfile()
      .pipe(
        tap((res) => {
          this.user = res.data;
          console.log('User:', this.user);

          if (this.user && this.user.name) {
            this.getRoleByName(this.user.role);
          } else {
            console.log('User data is incomplete');
          }
        }),
        switchMap(() => {
          // Now execute checkCashRegisterStatus after userProfile completes
          return this.checkCashRegisterStatus();
        })
      )
      .subscribe({
        next: (cashRegisterResult) => {
          console.log('Cash register status checked:', cashRegisterResult);
        },
        error: (error) => {
          console.error('Error in sequence:', error);
        },
      });

    this.id = this.authService.getId();
    this.loadItems();
    this.getRechargeBalance();
    this.getPaymentBalance();
  }
  //Funcion incompleta
  closeModalComission() {
    this.modalComissionIsOpen = false;
  }

  openModalComission() {
    this.modalComissionIsOpen = true;
  }
  getTransactionHistoryCashRegister(): void {
    this.cashRegisterService
      .getCashTransactionByParent(
        this.currentPageCashTransaction,
        this.pageSizeCashTransaction,
        this.id
      )
      .subscribe(
        (response) => {
          // Filtramos los datos que realmente necesitamos mostrar
          this.cashTransactionHistory = response.data.cashRegisters.map(
            (cashier: any) => {
              // Extraemos solo las transacciones relevantes
              return {
                ...cashier,
                // Aquí aseguramos que las transacciones estén bien formateadas
                transactions: cashier.transactions.map((transaction: any) => ({
                  operation_by: {
                    name: transaction.operation_by?.name || 'Desconocido', // Asegurarse de que operation_by esté presente
                    email: transaction.operation_by?.email || 'Sin email', // Agregar el email también
                  },
                  createdAt: transaction.createdAt,
                  amount: transaction.amount?.$numberDecimal || 0, // Usar un valor por defecto si amount es null o no está definido
                })),
              };
            }
          );

          console.log(
            'Historial de transacciones de cajas registradoras:',
            this.cashTransactionHistory
          );

          // Otros datos de paginación
          this.hasNextPageCashTransaction = response.data.hasNextPage;
          this.hasPreviousPageCashTransaction = response.data.hasPreviousPage;
          this.totalPagesCashTransaction = response.data.totalPages;
        },
        (error) => {
          console.error(
            'Error al cargar el historial de transacciones:',
            error
          );
        }
      );
  }

  getRoleByName(roleName: string): void {
    this.roleService.getRoleByName(roleName).subscribe(
      (res: any) => {
        if (res.success) {
          //console.log('Role:', res);
          if (res.data.has_wallet === 'true') {
            //console.log('Role has wallet');
            this.roleHasWallet = true;
          } else {
            // console.log('Role does not have wallet');
            this.roleHasWallet = false;
          }

          this.rol = {
            role_name: res.data.role_name,
            has_wallet: res.data.has_wallet,
            type: res.data.type,
            permissions: res.data.permissions,
          };

          console.log('Rol:', this.rol);

          // Priority order:
          // 1. If role has wallet access, use user's own wallet
          // 2. If role doesn't have wallet access, use parent's wallet
          // 3. If no wallet available, set hasWallet to false

          if (this.roleHasWallet && this.user.wallet) {
            this.hasWallet = true;
            this.parentHasWallet = false;
            this.shipmentBalance =
              parseFloat(this.user.wallet.sendBalance.$numberDecimal) || 0;
            this.servicesBalance =
              parseFloat(this.user.wallet.servicesBalance.$numberDecimal) || 0;
            this.recharguesBalance =
              parseFloat(this.user.wallet.rechargeBalance.$numberDecimal) || 0;
          } else if (!this.roleHasWallet && this.user.parentUser?.wallet) {
            // console.log("Using parent's wallet (role doesn't have wallet)");
            this.hasWallet = true;
            this.parentHasWallet = true;
            this.shipmentBalance =
              parseFloat(
                this.user.parentUser.wallet.sendBalance.$numberDecimal
              ) || 0;
            this.servicesBalance =
              parseFloat(
                this.user.parentUser.wallet.servicesBalance.$numberDecimal
              ) || 0;
            this.recharguesBalance =
              parseFloat(
                this.user.parentUser.wallet.rechargeBalance.$numberDecimal
              ) || 0;
          } else {
            // console.log('No wallet available');
            this.hasWallet = false;
            this.parentHasWallet = false;
          }
        } else {
          console.log('Error en la respuesta:', res.message);
        }
      },
      (error) => {
        console.log('Error al obtener el rol:', error);
      }
    );
  }

  async checkCashRegisterStatus(): Promise<void> {
    try {
      const response = await this.cashRegisterService
        .hasCashRegisterOpen()
        .toPromise();
      const cashRegister = await this.cashRegisterService
        .getCurrentCashRegister()
        .toPromise();
      this.cashRegister_id = cashRegister.data?._id;
      console.log('Caja registradora actual:', this.cashRegister_id);
      console.log(cashRegister);
      if (response && response.message) {
        const message = response.message;

        // Determinar si el usuario tiene permisos para cerrar caja
        const canCloseCashRegister =
          this.rol &&
          ['ADMIN', 'LICENCIATARIO_TRADICIONAL', 'SUPERADMIN'].includes(
            this.rol.role_name
          );

        // Caso 1: Caja abierta por más de 24 horas
        if (message.includes('horas. Debe cerrarla')) {
          if (canCloseCashRegister) {
            const result = await Swal.fire({
              title: '¡Atención!',
              text: message,
              icon: 'warning',
              confirmButtonText: 'Cerrar Caja',
              showCancelButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });

            if (result.isConfirmed) {
              // Redireccionar a la página de cajeros para cerrar caja
              // this.router.navigate(['/cashiers']);
              this.cashRegisterService
                .closeCashRegisterById(this.cashRegister_id)
                .subscribe(
                  (res) => {
                    console.log('Caja cerrada:', res);
                    Swal.fire({
                      title: 'Caja Cerrada',
                      text: 'La caja registradora ha sido cerrada exitosamente',
                      icon: 'success',
                      showCancelButton: false,
                      confirmButtonText: 'Aceptar',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    });
                  },
                  (error) => {
                    console.error(
                      'Error al cerrar la caja registradora:',
                      error
                    );
                    Swal.fire({
                      title: 'Error',
                      text: 'Ocurrió un error al cerrar la caja registradora',
                      icon: 'error',
                      confirmButtonText: 'Aceptar',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    });
                  }
                );
            }
          } else {
            const closeAlert = await Swal.fire({
              title: '¡Atención!',
              text: message,
              icon: 'warning',
              confirmButtonText: 'Cerrar Caja',
              allowOutsideClick: false,
              allowEscapeKey: false,
            });

            if (closeAlert.isConfirmed) {
              this.cashRegisterService.closeCashRegisterCashier().subscribe(
                (response) => {
                  Swal.fire({
                    title: 'Caja Cerrada',
                    text: 'La caja registradora ha sido cerrada exitosamente',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  });
                },
                (error) => {
                  Swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error al cerrar la caja registradora',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  });
                }
              );
            }
          }
        }
        // Caso 2: No hay caja abierta
        else if (message === 'No hay caja abierta actualmente') {
          const result = await Swal.fire({
            title: 'Sin Caja Abierta',
            text: 'No hay ninguna caja abierta. ¿Desea abrir una nueva caja?',
            icon: 'question',
            confirmButtonText: 'Abrir Caja',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          if (result.isConfirmed) {
            this.cashRegisterService.openCashRegister().subscribe(
              (res) => {
                console.log('Caja abierta:', res);
                Swal.fire({
                  title: 'Caja Abierta',
                  text: 'La caja registradora ha sido abierta exitosamente',
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonText: 'Aceptar',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              },
              (error) => {
                console.error('Error al abrir la caja registradora:', error);
                Swal.fire({
                  title: 'Error',
                  text: 'Ocurrió un error al abrir la caja registradora',
                  icon: 'error',
                  confirmButtonText: 'Aceptar',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
            );
          }
        }
        // Caso 3: Caja abierta pero menos de 24 horas - no hacer nada
        else if (message === 'Caja actual encontrada') {
          // No mostrar ningún mensaje si la caja está abierta pero no han pasado 24 horas
          console.log(
            'Caja abierta dentro del periodo normal, no se requiere acción'
          );
        }
      }
    } catch (error) {
      console.error(
        'Error al verificar si hay caja registradora abierta:',
        error
      );
      await Swal.fire({
        title: 'Error',
        text: 'Ocurrió un error al verificar el estado de la caja',
        icon: 'error',
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  }

  toggleSidebar(): void {
    this.collapsed = !this.collapsed;
    localStorage.setItem(
      'sidebarState',
      this.collapsed ? 'collapsed' : 'expanded'
    );
  }

  toggleSection(section: string): void {
    this.openSections[section] = !this.openSections[section];
    this.saveSectionState();
  }

  isSectionOpen(section: string): boolean {
    return this.openSections[section] || false;
  }

  saveSectionState() {
    localStorage.setItem('sidebarState', JSON.stringify(this.openSections));
  }

  isCurrentPage(route: string): boolean {
    return this.currentPage.includes(route);
  }

  logout() {
    // console.log('Logout button clicked');
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  openNotifications() {
    console.log('Presionado');
  }

  toggleCart() {
    this.isCartOpen = !this.isCartOpen;
  }

  hasRole(role: string): boolean {
    // Verificar que this.user existe antes de acceder a sus propiedades
    if (!this.user) {
      return false; // Si el usuario no está definido, no puede tener ningún rol
    }

    // Ahora es seguro acceder a this.user.role
    return this.user.role === role;
  }

  hasPermission(category: string, action: string): boolean {
    if (this.rol && this.rol.permissions) {
      return this.rol.permissions.some(
        (permission) =>
          permission.category === category &&
          permission.actions.includes(action)
      );
    }
    return false; // o el valor que prefieras si no hay permisos
  }

  openCart(): void {
    this.isCartOpen = true;
  }

  closeCart(): void {
    this.isCartOpen = false;
  }

  handleCartClick(): void {
    this.openCart();
  }

  removeItem(item: any) {
    const index = this.cartItems.indexOf(item);
    if (index > -1) {
      this.cartItems.splice(index, 1);
    }
  }

  getItemPrice(item: any): number {
    return parseFloat(item.price.$numberDecimal) || 0;
  }

  getTotalPrice(): number {
    return this.cartItems.reduce((total, item) => {
      return total + this.getItemPrice(item);
    }, 0);
  }

  loadItems() {
    this.shipmentService.getPendingShipments().subscribe(
      (res) => {
        this.cartItems = res.data;
      },
      (error) => {
        console.log('Error al cargar los envíos pendientes:', error);
        this.cartItems = [];
      }
    );
  }

  openWalletModal() {
    this.walletModalIsOpen = true;
    // console.log('Opening wallet modal');
  }

  openWalletModalCard() {
    this.walletCardModalIsOpen = true;
    this.getTransactionHistoryCashRegister();
    this.showTransactionHistory();
    // console.log('Abriendo card principal');
  }

  closeWalletModalCard() {
    this.walletCardModalIsOpen = false;
    // console.log('Cerando Modal');
  }

  showTransactionHistory() {
    let userId;
    if (this.parentHasWallet) {
      userId = this.user.parentUser._id;
      // console.log('Parent:', this.user.parentUser);
    } else {
      // console.log('User:', this.user);
      userId = this.id;
    }

    this.transactionService
      .getTransaccionByUser(userId, this.currentPageTransaction, this.pageSize)
      .subscribe(
        (response) => {
          // console.log('Historial de transacciones:', response);
          this.transactionHistory = response.message.transactions;
          this.hasNextPage = response.message.hasNextPage;
          this.hasPreviousPage = response.message.hasPreviousPage;
          this.totalItemsTransaction = response.message.total;
          this.showTransactionHistoryModal = true;
          this.totalPagesTransaction = response.message.totalPages;
        },
        (error) => {
          console.error(
            'Error al cargar el historial de transacciones:',
            error
          );
        }
      );

    // Implementa la lógica para mostrar el historial de transacciones
    // console.log(`Mostrar historial de transacciones para el usuario ${this.selectedUser._id}`);
    // Aquí deberías abrir un modal o navegar a una nueva vista con el historial de transacciones
  }

  onPageSizeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.pageSize = +selectElement.value; // Actualiza pageSize con el valor seleccionado
    // console.log(`Tamaño de página cambiado a: ${this.pageSize}`);

    this.showTransactionHistory();
    // Aquí puedes llamar a una función para recargar los datos de acuerdo al nuevo pageSize
  }

  goToPage(page: number) {
    this.currentPageTransaction = page;
    // console.log(`Ir a la página ${page}`);

    this.showTransactionHistory();

    // Lógica para cambiar de página
  }

  previousPage() {
    this.currentPageTransaction--;
    this.showTransactionHistory();
  }

  getPageNumbers(): number[] {
    return Array.from({ length: this.totalPagesTransaction }, (_, i) => i + 1);
  }

  nextPage() {
    this.currentPageTransaction++;
    this.showTransactionHistory();
  }

  closeModal() {
    this.walletModalIsOpen = false;
  }

  //modal de notificacion

  openNotificationModal(): void {
    this.isNotificationModalOpen = true;
  }

  closeNotificationModal(): void {
    this.isNotificationModalOpen = false;
  }

  getRechargeBalance(): void {
    this.emiausService.getRechargeBalance().subscribe(
      (data) => {
        // console.log('Balance de recargas:', data);
        this.rechargeBalance = data.availableBalance || '0.00';
      },
      (error) => {
        console.error('Error al obtener el balance de recargas:', error);
        this.rechargeBalance = '0.00';
      }
    );
  }

  getPaymentBalance(): void {
    this.emiausService.getPaymentBalance().subscribe(
      (res) => {
        // console.log('Balance de pagos:', res);
        this.paymentBalance = res.availableBalance;
      },
      (error) => {
        console.error('Error al obtener el balance de pagos:', error);
      }
    );
  }
}
