<app-sidebar></app-sidebar>
<div class="ml-64 pt-20">
  <div *ngIf="showPinModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
    <div class="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white z-50">
      <h3 class="text-lg font-bold mb-4">Ingresar NIP</h3>
      <form (ngSubmit)="validateNip()">
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="nipInput">NIP de seguridad</label>
          <input
            type="password"
            id="nipInput"
            [(ngModel)]="enteredPin"
            name="nipInput"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div class="flex items-center justify-between">
          <button class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Validar NIP
          </button>
          <button class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" (click)="closePinModal()" type="button">
            Cancelar
          </button>
        </div>
      </form>
    </div>
  </div>  
  <div class="container mx-auto">
    <h1 class="text-3xl font-bold mb-6 text-dagblue">Detalles de Gaveta</h1>
    <div class="space-y-6">
      <!-- Contenedor de detalles de la gaveta -->
      <div class="bg-white rounded-lg shadow-md overflow-hidden">
        <div class="px-6 py-4 bg-dagblue border-b border-gray-200 flex justify-between">
          <div>
            <h2 class="text-xl font-semibold text-white">Gaveta Tipo: {{ gaveta.type }}</h2>
            <p class="text-sm text-white">
              <span>Estado: {{ gaveta.state }} </span>
            </p>
          </div>
        </div>
        <div class="px-6 py-4">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dimensiones</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ubicación</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ciudad</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">País</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ gaveta.id_gabeta }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ gaveta.gabeta_dimension }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ gaveta.ubication }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-bold"
                  [ngClass]="{
                    'text-green-600': gaveta.status, 
                    'text-red-600': !gaveta.status
                  }">
                  {{ gaveta.status ? 'Activo' : 'Inactivo' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ gaveta.type }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ gaveta.city }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ gaveta.country }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Contenedor de botones de acciones -->
      <div class="bg-white p-6 rounded-lg shadow-md border-t border-gray-200">
        <h2 class="text-xl font-semibold text-dagblue mb-4">Acciones de la Gaveta</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <button (click)="openGaveta()" class="bg-gray-100 text-dagblue px-4 py-2 rounded-lg hover:bg-dagblue hover:text-white transition-colors">
            Abrir Gaveta
          </button>
          <button (click)="printGuide()" class="bg-gray-100 text-dagblue px-4 py-2 rounded-lg hover:bg-dagblue hover:text-white transition-colors">
            Imprimir Guía
          </button>
          <button (click)="emptyGaveta()" class="bg-gray-100 text-dagblue px-4 py-2 rounded-lg hover:bg-dagblue hover:text-white transition-colors">
            Vaciar Gaveta
          </button>
          <button (click)="toggleGavetaStatus()" class="bg-gray-100 text-dagblue px-4 py-2 rounded-lg hover:bg-red-600 hover:text-white transition-colors">
            Desactivar Gaveta
          </button>
        </div>
      </div>      
    </div>
  </div>
</div>
