import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LockersService } from 'src/app/_services/Lockers/lockers.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  advertisementId: string | null = null;
  advertisementData: any[] = [];
  currentAdIndex: number = 0;     // Índice del advertisement actual
  currentImageIndex: number = 0;   // Índice de la imagen dentro del advertisement
  currentImage: any = null;
  intervalId: any;
  pollingIntervalId: any;

  constructor(
    private route: ActivatedRoute,
    private lockersService: LockersService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.advertisementId = params.get('id');
      if (this.advertisementId) {
        this.loadAdvertisementDetails(this.advertisementId);
        this.startPollingForUpdates();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.pollingIntervalId) {
      clearInterval(this.pollingIntervalId);
    }
  }

  loadAdvertisementDetails(id: string): void {
    this.lockersService.getPublicityById(id).subscribe({
      next: (response) => {
        this.advertisementData = response.data.advertisements;
        console.log('Detalles de la publicidad:', this.advertisementData);
        if (this.advertisementData.length > 0) {
          this.startCarousel();
        }
      },
      error: (error) => {
        console.error('Error al cargar los detalles de la publicidad:', error);
      }
    });
  }

  startCarousel(): void {
    if (!this.advertisementData.length) return;
    
    const currentAd = this.advertisementData[this.currentAdIndex];
    if (!currentAd?.images?.length) return;

    // Establecer la imagen inicial
    this.updateCurrentImage();
    
    console.log('Iniciando carrusel...');
    console.log('Advertisement actual:', this.currentAdIndex);
    console.log('Imagen actual:', this.currentImageIndex);
    console.log('Total de imágenes en este ad:', currentAd.images.length);
    console.log('Duración:', currentAd.duration);

    // Limpiar intervalo existente si hay uno
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // Iniciar nuevo intervalo
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, currentAd.duration * 1000);
  }

  private updateCurrentImage(): void {
    const currentAd = this.advertisementData[this.currentAdIndex];
    if (currentAd?.images?.length) {
      this.currentImage = currentAd.images[this.currentImageIndex];
      console.log('Imagen actualizada:', {
        adIndex: this.currentAdIndex,
        imageIndex: this.currentImageIndex,
        imageUrl: this.currentImage
      });
    }
  }

  prevSlide(): void {
    const currentAd = this.advertisementData[this.currentAdIndex];
    
    if (this.currentImageIndex > 0) {
      // Retroceder a la imagen anterior del mismo advertisement
      this.currentImageIndex--;
    } else {
      // Ir al advertisement anterior
      this.currentAdIndex = this.currentAdIndex > 0 
        ? this.currentAdIndex - 1 
        : this.advertisementData.length - 1;
      
      // Ir a la última imagen del advertisement anterior
      const prevAd = this.advertisementData[this.currentAdIndex];
      this.currentImageIndex = prevAd.images.length - 1;
    }

    this.updateCurrentImage();
    this.resetInterval();
  }

  nextSlide(): void {
    const currentAd = this.advertisementData[this.currentAdIndex];
    
    if (this.currentImageIndex < currentAd.images.length - 1) {
      // Avanzar a la siguiente imagen del mismo advertisement
      this.currentImageIndex++;
    } else {
      // Ir al siguiente advertisement
      this.currentAdIndex = (this.currentAdIndex + 1) % this.advertisementData.length;
      this.currentImageIndex = 0; // Comenzar con la primera imagen
    }

    this.updateCurrentImage();
    this.resetInterval();
  }

  goToSlide(adIndex: number): void {
    this.currentAdIndex = adIndex;
    this.currentImageIndex = 0;
    this.updateCurrentImage();
    this.resetInterval();
  }

  private resetInterval(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    
    const currentAd = this.advertisementData[this.currentAdIndex];
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, currentAd.duration * 1000);
  }

  startPollingForUpdates(): void {
    this.pollingIntervalId = setInterval(() => {
      if (this.advertisementId) {
        this.lockersService.getPublicityById(this.advertisementId).subscribe({
          next: (response) => {
            const newAdvertisements = response.data.advertisements;

            if (JSON.stringify(this.advertisementData) !== JSON.stringify(newAdvertisements)) {
              console.log('Nuevas imágenes detectadas, actualizando...');
              this.advertisementData = newAdvertisements;
              this.currentAdIndex = 0;
              this.currentImageIndex = 0;
              this.startCarousel();
            }
          },
          error: (error) => {
            console.error('Error al verificar nuevas imágenes:', error);
          }
        });
      }
    }, 14400000);
  }
}