import { Component } from '@angular/core';

@Component({
  selector: 'app-paqueterias',
  templateUrl: './paqueterias.component.html',
  styleUrls: ['./paqueterias.component.scss']
})
export class PaqueteriasComponent {

}
