import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-harvest',
  templateUrl: './harvest.component.html',
  styleUrls: ['./harvest.component.scss'],
})



export class HarvestComponent implements OnInit {
  senderInfoForm!: FormGroup;
  packageRegistrationForm!: FormGroup;
  currentStep = 1;
  paquetes: any[] = [];
  courierOptions = ['DHL', 'FedEx', 'UPS', 'Estafeta', 'Correos de México'];

  isModalOpen = false;
  nipForm!: FormGroup;
  currentDateTime: string = '';

  packagePrice: number = 20; // Precio inicial de $20
  isPriceModalOpen: boolean = false; // Estado del modal para editar el precio



  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.currentDateTime = new Date().toLocaleString();
    this.senderInfoForm = this.fb.group({
      senderName: ['', Validators.required],
      senderPhone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    });
  
    this.packageRegistrationForm = this.fb.group({
      trackingNumber: ['', Validators.required],
      courier: ['', Validators.required],
    });
  
    this.nipForm = this.fb.group({
      nip: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
    });
  }
  

  openModal() {
    this.isModalOpen = true;
  }

  confirmAndNextStep() {
    this.submitForm();
    this.nextStep();
  }

  confirmAndNextStep3() {
    this.nextStep();
  }
  

  closeModal() {
    this.isModalOpen = false;
    this.senderInfoForm.reset();
    this.packageRegistrationForm.reset();
    this.paquetes = [];
    this.currentStep = 1;
  }

  nextStep() {
    // Verificar validación solo en los pasos específicos
    if (this.currentStep === 1 && this.senderInfoForm.invalid) {
      return;
    }
  
    if (this.currentStep === 2 && this.paquetes.length === 0) {
      return;
    }
  
    if (this.currentStep === 5 && this.nipForm.invalid) {
      return;
    }
  
    // Avanzar al siguiente paso si no hay restricciones
    if (this.currentStep < 6) {
      this.currentStep++;
    }
  }
  
  confirmPayment5() {
    // Lógica futura para confirmar el pago puede ir aquí
    console.log('Pago confirmado y entrega realizada');
  
    // Avanzar al paso 6 sin validación
    this.currentStep = 6;
  }  
  
  prevStep() {
    this.currentStep--;
  }

  addPackage(packageInfo: any) {
    this.paquetes.push(packageInfo);
    this.packageRegistrationForm.reset();
  }

  removePackage(index: number) {
    this.paquetes.splice(index, 1);
  }

  submitForm() {
    const formData = {
      senderInfo: this.senderInfoForm.value,
      paquetes: this.paquetes,
    };
    console.log('Form Data:', formData);
    // Aquí iría el código para enviar los datos al servidor o procesarlos según corresponda
  }

  downloadCard() {
    const cardElement = document.getElementById('card');
    if (cardElement) {
      html2canvas(cardElement, { scale: 2 }).then((canvas) => {
        const link = document.createElement('a');
        link.download = 'recepcion_paquete.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
  }

  openPriceModal() {
    this.isPriceModalOpen = true;
  }
  
  closePriceModal() {
    this.isPriceModalOpen = false;
  }
  
  savePrice() {
    this.isPriceModalOpen = false;
    // Aquí podrías agregar validaciones adicionales si lo necesitas
  }

  validatePhone(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '').slice(0, 10);
  }
  
}
