<app-sidebar class="hidden sm:block"></app-sidebar>
<div class="sm:ml-64 mt-14 bg-gray-100 min-h-screen">
  <div class="p-4 sm:p-6 lg:p-8">
    <!-- Título -->
    <h1 class="font-bold text-dagblue uppercase text-2xl sm:text-3xl mb-6">
      Registro de Pagos
    </h1>
    <p class="text-gray-600 text-sm sm:text-base mb-4">
      Detalles de los pagos realizados
    </p>

    <!-- Filtros -->
    <div class="bg-transparent p-4 rounded-lg shadow-md mb-6">
      <div class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
        <input
          type="text"
          placeholder="Buscar pago..."
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-1/4 focus:outline-none focus:ring-2 focus:ring-dagblue"
        />
        <input
          type="date"
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
        />
        <input
          type="date"
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
        />
        <button class="bg-[#D6542B] text-white px-6 py-2 rounded-full font-bold text-sm sm:text-base w-full sm:w-auto hover:bg-blue-900 transition">
          📄 Reporte
        </button>
        <button class="bg-[#D6542B] text-white px-6 py-2 rounded-full font-bold text-sm sm:text-base w-full sm:w-auto hover:bg-blue-900 transition">
          📄 Reporte Conciliación
        </button>
      </div>
    </div>

    <!-- Tabla -->
    <div class="bg-[#F0F0F0] rounded-lg shadow-md overflow-x-auto">
      <table class="w-full text-sm sm:text-base">
        <thead class="uppercase bg-[#F0F0F0] text-dagblue font-bold">
          <tr>
            <th class="px-4 py-2 text-left">Usuario</th>
            <th class="px-4 py-2 text-left">Servicio</th>
            <th class="px-4 py-2 text-left">Fecha Pago</th>
            <th class="px-4 py-2 text-left">Num. Referencia</th>
            <th class="px-4 py-2 text-left">Num. Autorización</th>
            <th class="px-4 py-2 text-left">Monto</th>
            <th class="px-4 py-2 text-left">Status</th>
            <th class="px-4 py-2 text-left">Acciones</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 text-gray-500">
          <tr *ngFor="let payment of payments" class="hover:bg-gray-50">
            <td class="px-4 py-2">{{ payment.user }}</td>
            <td class="px-4 py-2">{{ payment.service }}</td>
            <td class="px-4 py-2">{{ payment.paymentDate }}</td>
            <td class="px-4 py-2">{{ payment.referenceNumber }}</td>
            <td class="px-4 py-2">{{ payment.authorizationNumber }}</td>
            <td class="px-4 py-2">${{ payment.amount }}</td>
            <td class="px-4 py-2 text-center">
              <span
                [ngClass]="{
                  'px-2 py-1 rounded-full text-lg font-bold': true,
                  'text-red-500': payment.status === 'FAIL',
                  'text-green-600': payment.status === 'PAGADO'
                }"
              >
                {{ payment.status }}
              </span>
            </td>
            <td class="px-4 py-2">
              <button class="w-full sm:w-auto bg-dagblue font-bold text-white px-4 py-2 rounded-full hover:bg-blue-900 transition">
                Detalle
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
