import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Transaction } from 'src/app/_models/history_refills';
import { map, catchError } from 'rxjs/operators';  // 🔹 Se importaron correctamente
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private http: HttpClient) { }

  // Transacción de Emida

  // Método para obtener todas las transacciones
  getTransactions(): Observable<Transaction[]> {
    return this.http.get<Transaction[]>(
      `${environment.apiUrl}/transactionsRechargues`
    );
  }

  // Método para obtener una transacción por ID
  getTransactionById(id: string): Observable<Transaction> {
    return this.http.get<Transaction>(`${environment.apiUrl}/transactions/${id}`);
  }

  createTransaction(transaction: Transaction): Observable<Transaction> {
    return this.http.post<Transaction>(
      `${environment.apiUrl}/transactionsRechargues/create`,
      transaction
    );
  }

  // Método para obtener transacciones de un usuario por paginación
  getTransaccionByUser(
    id: string,
    page: number,
    limit: number
  ): Observable<any> {
    return this.http.get<Transaction[]>(
      `${environment.apiUrl}/transactions/user/${id}?page=${page}&limit=${limit}`
    );
  }

  getQuincenalProfitRecargas(userId: string, year: number, month: number, quincena: number): Observable<any> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('year', year.toString())
      .set('month', month.toString())
      .set('quincena', quincena.toString());

    return this.http.get(`${environment.apiUrl}/transactions/quincenal-profit/`, { params })
      .pipe(
        map((response: any) => {
          console.log("Utilidad recargas:", JSON.stringify(response, null, 2));

          if (response && response.success) {
            const recargasProfit = response.message?.[0]?.total?.$numberDecimal
              ? parseFloat(response.message[0].total.$numberDecimal)
              : 0;

            return {
              success: response.success,
              data: {
                recargasProfit
              }
            };
          } else {
            throw new Error(response.message || "Error al obtener utilidad de recargas");
          }
        }),
        catchError(this.handleError('obtener utilidad quincenal de recargas'))
      );
  }


  getQuincenalProfitServices(userId: string, year: number, month: number, quincena: number): Observable<any> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('year', year.toString())
      .set('month', month.toString())
      .set('quincena', quincena.toString());

    return this.http.get(`${environment.apiUrl}/transactions/quincenal-profit-services`, { params })
      .pipe(
        map((response: any) => {
          console.log("utilidad servicios:", JSON.stringify(response, null, 2));

          if (response && response.success) {
            const servicesProfit = response.message?.[0]?.total?.$numberDecimal
              ? parseFloat(response.message[0].total.$numberDecimal)
              : 0;

            return {
              success: response.success,
              data: {
                servicesProfit
              }
            };
          } else {
            throw new Error(response.message || "Error al obtener utilidad de servicios");
          }
        }),
        catchError(this.handleError('obtener utilidad quincenal de servicios'))
      );
  }

  // 🔹 Se agregó la función `handleError` para manejar errores
  private handleError(operation = 'operation') {
    return (error: any): Observable<any> => {
      console.error(`Error en ${operation}:`, error);
      return new Observable(observer => {
        observer.error({ success: false, message: `Error en ${operation}` });
        observer.complete();
      });
    };
  }


  // Método para obtener transacciones por tipo
  getTransactionsByType(
    type: string,
    page: number,
    limit: number

  ): Observable<any> {
    const params = new HttpParams()
      .set('type', type)
      .set('page', page)
      .set('limit', limit);
    return this.http.get<{ success: boolean; message: Transaction[] }>(
      `${environment.apiUrl}/transactions/list-by-type-all`,
      { params }
    );
  }



  getTransactionsByTypeAndUser(
    type: string,
    userId: string,
    page: number,
    limit: number
  ): Observable<any> {
    const params = new HttpParams()
      .set('type', type)
      .set('user_id', userId)
      .set('page', page)
      .set('limit', limit);

    return this.http.get<{ success: boolean; message: Transaction[] }>(
      `${environment.apiUrl}/transactions/list-by-type`,
      { params }
    );
  }

  getAllTransactions(type: string, page: number = 1, limit: number = 10, user_id?: string, sub_user_id?: string, start_date?: string, end_date?: string, sortOder: string = 'asc', locker_id?: string): Observable<any> {
    let params = new HttpParams()
      .set('type', type)
      .set('page', page)
      .set('limit', limit)
      .set('sortOrder', sortOder);

    if (user_id) {
      params = params.set('user_id', user_id);
    }

    if (sub_user_id) {
      params = params.set('sub_user_id', sub_user_id);
    }

    if (start_date) {
      params = params.set('start_date', start_date);
    }



    if (end_date) {
      params = params.set('end_date', end_date);
    }

    if (locker_id) {
      params = params.set('locker_id', locker_id);
    }


    return this.http.get(`${environment.apiUrl}/transactions/list-by-type-all`, { params });
  }
}
