import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-access-denied',
  template: `
    <div class="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center overflow-hidden">
      <div [@fadeInUp] class="text-center p-8 bg-white rounded-2xl shadow-2xl max-w-lg w-full mx-4 relative">
        <!-- Decorative elements -->
        <div class="absolute -top-10 -left-10 w-32 h-32 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
        <div class="absolute -bottom-10 -right-10 w-32 h-32 bg-orange-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
        
        <!-- Icon with animation -->
        <div class="mb-8 relative">
          <div class="absolute inset-0 bg-gradient-to-r from-purple-200 to-orange-100 rounded-full filter blur-md transform scale-110 animate-pulse"></div>
          <div class="relative">
            <svg class="mx-auto h-28 w-28 text-[#45338e] animate-floating" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z M15 4a1 1 0 00-1-1h-4a1 1 0 00-1 1M8 20h8a2 2 0 002-2V6a2 2 0 00-2-2H8a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </div>
        </div>

        <!-- Title with animation -->
        <h1 [@fadeInDown] class="text-4xl font-bold bg-gradient-to-r from-[#45338e] to-[#e85d27] text-transparent bg-clip-text mb-4">
          Acceso Denegado
        </h1>

        <!-- Message with animation -->
        <p [@fadeIn] class="text-gray-600 mb-8 leading-relaxed">
          Lo sentimos, no tienes permisos para acceder a esta página.
        </p>

        <!-- Timer with animation -->
        <div class="text-[#e85d27] font-semibold mb-6 transform hover:scale-105 transition-transform">
          <span class="inline-block animate-bounce-soft">
            Redireccionando en {{ countdown }} segundos
          </span>
        </div>

        <!-- Button with enhanced animation -->
        <button (click)="goHome()" 
          class="relative overflow-hidden bg-[#e85d27] text-white font-bold py-3 px-8 rounded-full
          transition-all duration-300 ease-out transform hover:scale-105 hover:shadow-lg
          before:absolute before:top-0 before:left-0 before:w-full before:h-full
          before:bg-gradient-to-r before:from-[#45338e] before:to-[#e85d27]
          before:origin-left before:scale-x-0 hover:before:scale-x-100
          before:transition-transform before:duration-300">
          <span class="relative z-10">Volver al Inicio</span>
        </button>
      </div>
    </div>
  `,
  styles: [`
    @keyframes floating {
      0%, 100% { transform: translateY(0) rotate(0); }
      50% { transform: translateY(-10px) rotate(2deg); }
    }

    @keyframes bounce-soft {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-3px); }
    }

    @keyframes blob {
      0%, 100% { transform: translate(0, 0) scale(1); }
      25% { transform: translate(20px, -20px) scale(1.1); }
      50% { transform: translate(-20px, 20px) scale(0.9); }
      75% { transform: translate(20px, 20px) scale(1.1); }
    }

    .animate-floating {
      animation: floating 3s ease-in-out infinite;
    }

    .animate-bounce-soft {
      animation: bounce-soft 2s ease-in-out infinite;
    }

    .animate-blob {
      animation: blob 7s infinite;
    }

    .animation-delay-2000 {
      animation-delay: 2s;
    }
  `],
  animations: [
    trigger('fadeInUp', [
      transition(':enter', [
        style({ transform: 'translateY(20px)', opacity: 0 }),
        animate('0.6s ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ])
    ]),
    trigger('fadeInDown', [
      transition(':enter', [
        style({ transform: 'translateY(-20px)', opacity: 0 }),
        animate('0.6s 0.2s ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.6s 0.4s ease-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class AccessDeniedComponent implements OnInit, OnDestroy {
  countdown: number = 7;
  private timer: any;

  constructor(private router: Router) {}

  ngOnInit() {
    this.startCountdown();
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  private startCountdown() {
    this.timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.goHome();
      }
    }, 1000);
  }

  goHome() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.router.navigate(['/']);
  }
}