import { Component, OnInit } from '@angular/core';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss']
})
export class PinComponent implements OnInit {

  user: any = {};
  success: boolean = false;
  error: boolean = false;
  message: string = '';
  showPinModal: boolean = false;
  newPin: string = ''; // No prellenar con el PIN actual
  submitted: boolean = false;
  isPinValid: boolean = true;

  constructor(
    private authService: AuthLoginService,
    private userService: UserServiceService
  ) {}

  ngOnInit(): void {
    // Cargar el perfil del usuario (sin exponer el PIN)
    this.authService.userProfile().subscribe(
      (res) => {
        this.user = res.data;
      },
      (error) => {
        console.error('Error al obtener el perfil del usuario:', error);
      }
    );
  }

  togglePinModal(): void {
    this.newPin = ''; // Siempre empezar vacío
    this.showPinModal = true; // Abrir modal
    this.submitted = false;
    this.isPinValid = true;
  }

  closePinModal(): void {
    this.showPinModal = false; // Cerrar modal
    this.newPin = ''; // Limpiar PIN ingresado
    this.submitted = false;
    this.isPinValid = true;
  }

  setPin(): void {
    this.submitted = true;
    this.isPinValid = this.validatePin(this.newPin);

    if (!this.isPinValid) {
      this.message = 'El PIN debe ser un número de 4 dígitos.';
      this.error = true;
      return;
    }

    // Llamar al servicio para actualizar el PIN
    this.userService.setPin(this.newPin).subscribe(
      (res) => {
        if (res.success) {
          this.message = 'PIN actualizado con éxito';
          this.success = true;
          Swal.fire('¡Éxito!', this.message, 'success');
          this.closePinModal();
        } else {
          this.message = res.message || 'Error al actualizar el PIN';
          this.error = true;
        }
      },
      (error) => {
        console.error('Error al actualizar el PIN:', error);
        this.message = 'Error al actualizar el PIN';
        this.error = true;
      }
    );
  }

  validatePin(pin: string): boolean {
    return /^\d{4}$/.test(pin); // Validar que sea un número de 4 dígitos
  }
}
