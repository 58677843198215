<app-sidebar></app-sidebar>
<div class="ml-64 mt-16">
  <div class="container mx-auto px-4 py-8">
    <h2 class="text-3xl font-bold mb-6 text-gray-800">Panel de Publicidad</h2>

    <!-- Grid de paneles publicitarios -->
    <div class="bg-grey-10 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      <div
        *ngFor="let panel of panels"
        class="relative bg-white rounded-lg shadow-lg p-4 border border-gray-200 flex flex-col justify-between"
      >
        <div class="absolute top-2 right-2">
          <span
            *ngIf="panel.images.length > 0"
            class="inline-block w-3 h-3 bg-green-500 rounded-full"
          ></span>
          <span
            *ngIf="panel.images.length === 0"
            class="text-gray-500 text-sm mt-5"
            >Sin anuncios</span
          >
        </div>
        <div>
          <h3
            class="text-lg font-semibold mb-2 text-gray-700 flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-orange-500 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 2C8.134 2 5 5.134 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.866-3.134-7-7-7zm0 9.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
              />
            </svg>
            {{ panel.name }}
          </h3>
          <div
            class="bg-white-100 h-32 mb-4 flex items-center justify-center relative"
          >
            <!-- Carrusel de imágenes -->
            <div class="relative w-full h-full overflow-hidden">
              <div
                class="flex transition-transform duration-500 ease-in-out"
                [style.transform]="getTransform(panel.id)"
              >
                <div
                  *ngFor="let file of panel.images; let i = index"
                  class="w-full flex-shrink-0 relative"
                >
                  <img
                    [src]="file.preview"
                    class="d-block w-full max-h-32 object-contain"
                    alt="Publicidad {{ i + 1 }}"
                  />
                  <button
                    *ngIf="panel.isEditing"
                    (click)="removeImage(+panel.id, i)"
                    class="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <button
                *ngIf="panel.images.length > 1"
                (click)="prevImage(panel.id)"
                class="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button
                *ngIf="panel.images.length > 1"
                (click)="nextImage(panel.id)"
                class="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>

            <!-- New Image Selectors -->
            <div class="text-xs">
              Publicidad Impresa
              <div class="flex justify-between">
                <div class="w-1/2 flex items-center justify-center p-1">
                  <div
                    class="w-24 h-24 border border-blue-500 rounded-lg shadow-md flex items-center justify-center relative"
                  >
                    <img
                      *ngIf="panel.selectedImage1"
                      [src]="panel.selectedImage1"
                      class="w-full h-full object-contain rounded-md"
                      alt="Selected Image 1"
                    />
                    <label
                      *ngIf="!panel.selectedImage1 && panel.isEditing"
                      class="cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-8 w-8 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                      <input
                        type="file"
                        (change)="onImageSelected($event, panel.id, 1)"
                        class="hidden"
                      />
                    </label>
                    <button
                      *ngIf="panel.selectedImage1 && panel.isEditing"
                      (click)="removeSelectedImage(panel.id, 1)"
                      class="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="w-1/2 flex items-center justify-center p-1">
                  <div
                    class="w-24 h-24 border border-blue-500 rounded-lg shadow-md flex items-center justify-center relative"
                  >
                    <img
                      *ngIf="panel.selectedImage2"
                      [src]="panel.selectedImage2"
                      class="w-full h-full object-contain rounded-md"
                      alt="Selected Image 2"
                    />
                    <label
                      *ngIf="!panel.selectedImage2 && panel.isEditing"
                      class="cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-8 w-8 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                      <input
                        type="file"
                        (change)="onImageSelected($event, panel.id, 2)"
                        class="hidden"
                      />
                    </label>
                    <button
                      *ngIf="panel.selectedImage2 && panel.isEditing"
                      (click)="removeSelectedImage(panel.id, 2)"
                      class="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-between">
            <button
              (click)="openModal(panel)"
              class="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 ml-auto mr-2"
            >
              Agregar
            </button>
            <button
              (click)="viewDetails(panel.id)"
              class="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 ml-auto mr-2"
            >
              Ver Detalles
            </button>
            <button
              (click)="toggleEditMode(panel.id)"
              [ngClass]="{
                'bg-gray-400': panel.isEditing,
                'bg-gray-300': !panel.isEditing
              }"
              class="text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  d="M4 21v-2.586l11.293-11.293 2.586 2.586L6.586 21H4zm15.707-13.293l-2.586-2.586 1.293-1.293a1 1 0 011.414 0l1.172 1.172a1 1 0 010 1.414l-1.293 1.293z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        *ngIf="isModalOpen"
        class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
      >
        <div class="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg relative">
          <span
            class="close text-gray-500 hover:text-gray-700 cursor-pointer absolute top-2 right-2"
            (click)="closeModal()"
            >&times;</span
          >
          <h2 class="text-2xl font-bold mb-4 text-blue-600">
            Publicidad Digital {{ currentPanel?.name }}
          </h2>
          <form (ngSubmit)="guardarPublicidad()">
            <div class="form-group mb-4">
              <label
                for="enterprise"
                class="block text-gray-700 font-semibold mb-2"
                >Nombre de la Empresa:</label
              >
              <input
                type="text"
                id="enterprise"
                name="enterprise"
                (input)="onEnterpriseChange($event)"
                class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div class="form-group mb-4">
              <label for="duration" class="block text-gray-700"
                >Duración del Comercial (20-30 segundos):</label
              >
              <input
                type="number"
                id="duration"
                name="duration"
                [(ngModel)]="intervaloTiempo"
                min="20"
                max="30"
                required
                class="w-full px-3 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div class="form-group mb-4">
              <label for="income" class="block text-gray-700"
                >Ingresos por Anuncio $:</label
              >
              <input
                type="number"
                id="income"
                name="income"
                [(ngModel)]="ingresosPorAnuncio"
                required
                class="w-full px-3 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div class="form-group mb-4">
              <label for="contract" class="block text-gray-700"
                >Adjuntar Contrato (PDF):</label
              >
              <input
                type="file"
                id="contract"
                name="contract"
                (change)="onPDFSelected($event)"
                accept="application/pdf"
                required
                class="w-full px-3 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div class="form-group mb-4">
              <label for="files" class="block text-gray-700"
                >Seleccionar Imágenes (máximo 10):</label
              >
              <input
                type="file"
                id="files"
                name="files"
                (change)="onFileSelected($event)"
                multiple
                accept="image/*"
                [attr.max]="5"
                class="w-full px-3 py-2 border border-gray-300 rounded-lg"
              />
              <div class="mt-2 flex flex-wrap">
                <div
                  *ngFor="let file of tempImages; let i = index"
                  class="relative w-20 h-20 m-1"
                >
                  <img
                    [src]="file.preview"
                    class="w-full h-full object-cover rounded-lg"
                  />
                  <button
                    (click)="removeTempImage(i)"
                    class="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <button
              (click)="guardarPublicidad()"
              type="submit"
              class="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 w-full"
            >
              Guardar
            </button>
          </form>
        </div>
      </div>

      <div
        *ngIf="selectedPanel"
        class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4"
      >
        <div
          class="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-auto p-6 shadow-lg"
        >
          <div class="flex justify-between items-center mb-4">
            <h2 class="text-2xl font-bold">Detalles del Panel</h2>
            <button
              (click)="closeDetailsModal()"
              class="text-gray-500 hover:text-red-500 transition-colors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="mb-4">
            <h3 class="font-semibold mb-2">Información General</h3>
            <div class="space-y-2">
              <p><strong>ID:</strong> {{ selectedPanel.id }}</p>
              <p><strong>Nombre:</strong> {{ selectedPanel.name }}</p>
            </div>
          </div>

          <div>
            <h3 class="font-semibold mb-2">Imágenes</h3>
            <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
              <ng-container *ngFor="let image of selectedPanel.images">
                <div class="relative group">
                  <img
                    [src]="image.preview"
                    alt="Panel Image"
                    class="w-full h-32 object-cover rounded-lg"
                  />
                  <div
                    class="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-all flex items-center justify-center"
                  >
                    <button
                      (click)="openFullImage(image.preview)"
                      class="opacity-0 group-hover:opacity-100 bg-white p-2 rounded-full shadow-lg transition-opacity"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    <button
                      (click)="viewContract(image.contract)"
                      class="ml-2 text-white bg-orange-500 hover:bg-orange-700 transition-colors p-2 rounded"
                    >
                      Ver Contrato
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
