import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SignupComponent } from './pages/signup/signup.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { QuoteComponent } from './components/Cotizaciones/quote/quote.component';
import { QuotePageComponent } from './pages/quote-page/quote-page.component';
import { LockerScreenComponent } from './components/Lockers/locker-screen/locker-screen.component';
import { LicenseesDashboardComponent } from './components/Licensees/licensees-dashboard/licensees-dashboard.component';
import { ShipmentsComponent } from './pages/shipments/shipments.component';
import { HistoryQuoteComponent } from './components/history-quote/history-quote.component';
import { ViewLockersPageComponent } from './pages/view-lockers-page/view-lockers-page.component';
import { DetailsLockerComponent } from './components/details-locker/details-locker.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { FromToComponent } from './components/Cotizaciones/from-to/from-to.component';
import { CheckoutComponent } from './components/Cotizaciones/checkout/checkout.component';
import { UserPackingComponent } from './components/Cotizaciones/user-packing/user-packing.component';
import { CotizarComponent } from './components/Lockers/cotizar/cotizar.component';
import { ShippingKioskComponent } from './components/Lockers/shipping-kiosk/shipping-kiosk.component';
import { CashiersComponent } from './components/Licensees/cashiers/cashiers.component';
import { PayShipmentComponent } from './components/Cotizaciones/pay-shipment/pay-shipment.component';
import { ShipmentsDetailsComponent } from './components/Envios/shipments-details/shipments-details.component';
import { ServicesComponent } from './components/services/services.component';
import { ScanComponent } from './components/scan/scan.component'; // Importa ScanComponent
import { ViewGavetasLogPageComponent } from './pages/gavetas-log-page/view-gaveta-log-page.component';
/* Hola 2*/
//import { EnviosComponent } from './components/Packages/envios/envios.component';
import { OlimpoComponent } from './components/Olimpo/olimpo/olimpo.component';
import { CajaComponent } from './components/Licensees/caja/caja.component';
import { TicketsComponent1 } from './components/Licensees/tickets/tickets.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { DisplayRefillsComponent } from './components/Admin/display-refills/display-refills.component';
import { AdministracionComponent } from './components/Funciones/administracion/administracion.component';
import { LockerScreenv2Component } from './components/Lockers/locker-screenv2/locker-screenv2.component';
import { LockerDeliveryComponent } from './components/Lockers/locker-delivery/locker-delivery.component';
import { LockerReciveComponent } from './components/Lockers/locker-recive/locker-recive.component';
import { AuthRoutesService } from './_services/auth-routes.service';
import { RequestResetComponent } from './components/Users/request-reset/request-reset.component';
import { ResetPasswordComponent } from './components/Users/reset-password/reset-password.component';
import { CashRegisterHistoryComponent } from './components/Licensees/cash-register-history/cash-register-history.component';
import { HomeComponent } from './components/home/home.component';
import { GabetasLogComponent } from './components/gabetas-log/gabetas-log.component';
import { InversorComponent } from './components/Inversor/inversor.component';
import { PublicidadComponent } from './components/publicidad/publicidad.component';
import { HistoryRefillsComponent } from './components/Admin/history-refills/history-refills.component';
import { GabetasDetailsComponent } from './components/gabetas-details/gabetas-details.component';
import { DetailsComponent } from './components/details/details.component';
import { PackingComponent } from './components/packing/packing.component';
import { CashRegisterListLicenseComponent } from './pages/cash-register-list-license/cash-register-list-license.component';
import { RequestRefillsComponent } from './components/request-refills/request-refills.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TrackingComponent } from './components/tracking/tracking.component';
import { PaqueteriasComponent } from './components/terminos-y-condiciones/paqueterias/paqueterias.component';
import { DagpacketComponent } from './components/terminos-y-condiciones/dagpacket/dagpacket.component';
import { HarvestComponent } from './components/harvest/harvest.component';
import { PaymentsRecordComponent } from './components/payments-record/payments-record.component';

const routes: Routes = [
  { path: 'packing', component: UserPackingComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'detalles/:id', component: DetailsComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'loader', component: LoaderComponent },
  { path: 'reset-request', component: RequestResetComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'inversor', component: InversorComponent },
  { path: 'publicidad', component: PublicidadComponent },
  { path: 'cashiers', component: CashiersComponent },
  { path: 'tickets', component: TicketsComponent1 },
  { path: 'inversor', component: InversorComponent },
  { path: 'olimpo', component: OlimpoComponent },
  { path: 'cash-register-history', component: CashRegisterHistoryComponent },
  { path: 'History-Refills', component: HistoryRefillsComponent },
  
  { path: 'publicidad', component: PublicidadComponent },
  {
    path: 'cash-register-history',
    component: CashRegisterHistoryComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN'] },
  },

  { 
    path: 'terminos-y-condiciones', 
    component: DagpacketComponent, 
  },
  { 
    path: 'terminos-y-condiciones-de-paqueterias', 
    component: PaqueteriasComponent, 
  },
  {
    path: 'tracking',
    component: TrackingComponent,

  },
  {
    path: 'cash-register-list-license/:id',
    component: CashRegisterListLicenseComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'registro-pagos',
    component: PaymentsRecordComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  { 
    path: 'recoleccion', 
    component: HarvestComponent, 
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] }
  },
  {
    path: 'landing',
    component: DashboardPageComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'quote',
    component: QuotePageComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'locker',
    component: LockerScreenComponent,
    canActivate: [AuthRoutesService],
  },
  {
    path: 'licensees',
    component: LicenseesDashboardComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN'] },
  },
  {
    path: 'shipments',
    component: ShipmentsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'history',
    component: HistoryQuoteComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'viewlockers',
    component: ViewLockersPageComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'details-locker/:id',
    component: DetailsLockerComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'gabetas-log/:id',
    component: ViewGavetasLogPageComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'detalles/:id',
    component: DetailsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'address',
    component: FromToComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'request-refill',
    component: RequestRefillsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'admin',
    component: AdminPageComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN'] },
  },
  {
    path: 'packing',
    component: UserPackingComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'locker-cotizar',
    component: CotizarComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'pesar',
    component: ShippingKioskComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'cashiers',
    component: CashiersComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'pay-shipments',
    component: PayShipmentComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'shipments-details',
    component: ShipmentsDetailsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'shipment-details/:id',
    component: ShipmentsDetailsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['USER', 'ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'tickets',
    component: TicketsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'services',
    component: ServicesComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL_TRADICIONAL'] },
  },
  {
    path: 'olimpo',
    component: OlimpoComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN'] },
  },
  {
    path: 'caja',
    component: CajaComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'displayRefills',
    component: DisplayRefillsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'administracion',
    component: AdministracionComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN'] },
  },
  {
    path: 'locker-screen',
    component: LockerScreenv2Component,
    canActivate: [AuthRoutesService],
  },
  {
    path: 'locker-delivery',
    component: LockerDeliveryComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'locker-recive',
    component: LockerReciveComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'scan',
    component: ScanComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'History-Refills',
    component: HistoryRefillsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  {
    path: 'gabetas-details/:id_gabeta',
    component: GabetasDetailsComponent,
    canActivate: [AuthRoutesService],
    data: { roles: ['ADMIN', 'LICENCIATARIO_TRADICIONAL'] },
  },
  { path: '**', pathMatch: 'full', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
