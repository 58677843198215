import { Component, OnInit } from '@angular/core';
import { CashRegisterService } from 'src/app/_services/Cashiers/cash-register.service';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-cash-register-list-license',
  templateUrl: './cash-register-list-license.component.html',
  styleUrls: ['./cash-register-list-license.component.scss'],
})
export class CashRegisterListLicenseComponent {
  cashRegisters: any[] = [];
  currentPage = 1;
  totalPages = 1;
  itemsPerPage = 10;
  totalItems = 0;
  loading = false;
  error: string | null = null;
  startDate: string = '';
  endDate: string = '';
  Math = Math;
  id: any;
  childSelected: any;
  childUsers: any[] = [];
  modalOpen: boolean = false;
  transactionDetails: any = null;
  statusSelected: string = '';
  statusCount: any = null;
  constructor(private cashRegisterService: CashRegisterService,
    private transactionService: TransactionService,
    private userService: UserServiceService
  ) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.loadChildUsers();
    this.loadCashRegisters();
  }

  onSelectChildUser(child: any): void {
    const selectedUserId = child.target.value;
    this.childSelected = selectedUserId;
    this.loadCashRegisters();
  }

  onSelectStatus(event: any): void {
    const selectedStatus = event.target.value;  // Accedemos al valor del select
    console.log('Status seleccionado:', selectedStatus);
    this.statusSelected = selectedStatus;  // Asignamos el valor a la variable correspondiente
    this.loadCashRegisters();  // Recargamos los registros de caja
  }
  

  loadChildUsers(): void {
    this.loading = true;
    this.userService.findChildUsers(this.id).subscribe(
      (response) => {
        console.log('Usuarios hijos:', response);
        this.childUsers = response.data;
        this.loading = false;
      },
      (error) => {
        console.error('Error al cargar los usuarios hijos:', error);
        this.loading = false;
      }
    );
  };
  loadCashRegisters(): void {
    // Si no se pasa un selectedUser, se asigna un valor por defecto.


    // Indicamos que la carga está en progreso.
    this.loading = true;
    console.log("Child selected", this.childSelected);
    // Llamada al servicio para obtener los registros de caja.
    this.cashRegisterService
      .getAllCashRegistersByLicense(
        this.currentPage,
        this.itemsPerPage,
        this.id,
        this.startDate,
        this.endDate,
        this.statusSelected,
        this.childSelected  // Pasamos el ID del empleado (o vacío si no se pasa ninguno)
      )
      .subscribe(
        (response) => {
          // Al recibir la respuesta, asignamos los datos correspondientes
          console.log('Registros de caja raw:', response.data);

          // Asignamos los registros de caja a la propiedad 'cashRegisters'
          this.cashRegisters = response.data.cashRegisters; 
          this.statusCount = response.data.statusCounts;
          // Otros datos de la respuesta (paginación y conteo)
          console.log('Registros de caja:', this.cashRegisters);
          this.currentPage = response.data.currentPage;
          this.totalPages = response.data.totalPages;
          this.totalItems = response.data.totalRegisters;

          // Marcamos que la carga ha terminado
          this.loading = false;
        },
        (error) => {
          // Si ocurre un error, mostramos el mensaje correspondiente
          this.error = 'Error al cargar los registros de caja';

          // Marcamos que la carga ha terminado
          this.loading = false;
        }
      );
  }





  getSumByType(transactions: any[], type: string): number {
    return transactions
      .filter(t => {
        const desc = t.description?.toLowerCase() || '';
        switch (type.toLowerCase()) {
          case 'recarga':
            return desc.includes('recarga') || desc.includes('telefonica');
          case 'envio':
            return desc.includes('envío') || desc.includes('envios') || desc.includes('envio');
          case 'servicio':
            return desc.includes('servicio');
          default:
            return false;
        }
      })
      .reduce((sum, t) => sum + Number(t.amount.$numberDecimal || 0), 0);
  }

  getSumByTypePayment(transactions: any[], type: string): number {
    return transactions
      .filter(t => {
        const desc = t.payment_method?.toLowerCase() || '';
        console.log(desc);
        switch (type.toLowerCase()) {
          case 'saldo':
            return desc.includes('saldo');
          case 'td-debito':
            return desc.includes('td-debito');
          case 'td-credito':
            return desc.includes('td-credito');
          default:
            return false;
        }
      })
      .reduce((sum, t) => sum + Number(t.amount.$numberDecimal || 0), 0);
  }

  // Función para obtener el total general
  getTotalSum(transactions: any[]): number {
    return transactions
      .reduce((sum, t) => sum + Number(t.amount.$numberDecimal || 0), 0);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadCashRegisters();
  }

  applyDateFilter(): void {
    this.currentPage = 1;
    this.loadCashRegisters();
  }

  clearDateFilter(): void {
    this.startDate = '';
    this.endDate = '';
    this.currentPage = 1;
    this.loadCashRegisters();
  }

  formatCurrency(amount: number): string {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(amount);
  }

  openModal() {
    this.modalOpen = true;
  }



  // Puedes añadir este método si aún no lo tienes
  closeModal() {
    this.modalOpen = false;
    this.transactionDetails = null;
  }

  transactionDetailsModal(transaction: any) {
    this.modalOpen = true;
    this.transactionDetails = null; // Resetear para mostrar el loader
    console.log('Detalles de la transacción:', transaction);
    this.transactionService.getTransactionById(transaction.transaction_id._id).subscribe(
      (response: any) => {
        console.log('Detalles de la transacción:', response);
        this.transactionDetails = response.message;
      },
      (error) => {
        console.error('Error al obtener los detalles de la transacción:', error);
        // Mostrar mensaje de error
        this.modalOpen = false;
      }
    );
  }
  closeCashRegister(id: any): void {
    this.cashRegisterService.closeCashRegisterById(id).subscribe(
      (response) => {
        if (response.success) {
          this.loadCashRegisters();
          console.log(response.message);
          Swal.fire('Caja cerrada', response.message, 'success');

        } else {
          this.error = response.message;
        }
        this.loading = false;
      }
    );
  }
}
