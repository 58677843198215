import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Users } from 'src/app/_models/user-model';
import { Rol } from 'src/app/_models/rol_model';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { RolesService } from 'src/app/_services/Roles/roles.service';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { CashRegisterService } from 'src/app/_services/Cashiers/cash-register.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { filter } from 'rxjs/operators';
import { WalletService } from 'src/app/_services/Wallets/wallet.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';

interface CashierTransaction {
  operation_by: {
    name: string;
    email: string;
  };
  createdAt: string;
  amount: {
    $numberDecimal: string;
  };
}

interface Cashier {
  transactions: CashierTransaction[];
  [key: string]: any; // Para permitir otras propiedades
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('sectionAnimation', [
      state(
        'closed',
        style({
          height: '0',
          opacity: '0',
          overflow: 'hidden',
        })
      ),
      state(
        'open',
        style({
          height: '*',
          opacity: '1',
        })
      ),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  openSections: { [key: string]: boolean } = {};
  user!: Users;
  rol!: Rol;
  hasWallet: boolean = false;
  roleHasWallet: boolean = false;
  parentHasWallet: boolean = false;

  currentPage: string;
  id!: any;
  shipmentBalance: number = 0;
  servicesBalance: number = 0;
  recharguesBalance: number = 0;
  isCartOpen = false;

  cartItems: any[] = [];
  walletCardModalIsOpen = false;
  walletModalIsOpen = false;
  transactionHistory: any[] = [];
  hasNextPage = false;
  hasPreviousPage = false;
  currentPageTransaction = 1;
  totalPages = 0;
  pageSize = 10;
  totalPagesTransaction = 0;
  totalItemsTransaction = 0;
  showTransactionHistoryModal = false;
  isSidebarOpen: boolean = false;

  //Transacciones de cajas registradoras
  cashTransactionHistory: any[] = [];
  hasNextPageCashTransaction = false;
  hasPreviousPageCashTransaction = false;
  currentPageCashTransaction = 1;
  totalPagesCashTransaction = 0;
  pageSizeCashTransaction = 10;

  isNotificationModalOpen: boolean = false;


  constructor(
    private authService: AuthLoginService,
    private router: Router,
    private shipmentService: ShipmentsService,
    private roleService: RolesService,
    private walletService: WalletService,
    private transactionService: TransactionService,
    private cashRegisterService: CashRegisterService
  ) {
    this.currentPage = this.router.url;
  }

  initializeWallet(userId: string) {
    console.log('Initializing wallet');
    console.log('User ID:', userId);

    this.walletService.initializeWallet(userId).subscribe(
      (res) => {
        console.log('Wallet created:', res);
        window.location.reload();
      },
      (error) => {
        console.log('Error creating wallet:', error);
      }
    );
  }

  ngOnInit(): void {
    const savedState = localStorage.getItem('sidebarState');
    if (savedState) {
      this.openSections = JSON.parse(savedState);
    }

    console.log('Rol', this.rol);

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.currentPage = event.url;
        this.saveSectionState();
      });

    this.authService.userProfile().subscribe((res) => {
      this.user = res.data;
      console.log('User:', this.user);
      // if (!this.user.wallet ) {
      //   console.log('No wallet found');
      //   this.hasWallet = false;
      // } else {
      //   this.hasWallet = true;
      //   console.log('Wallet found');
      // }

      if (this.user && this.user.name) {
        this.getRoleByName(this.user.role);
      } else {
        console.log('User data is incomplete');
      }
    });

    this.id = this.authService.getId();
    this.loadItems();
  }
  //Funcion incompleta
  getTransactionHistoryCashRegister(): void {
    this.cashRegisterService
      .getCashTransactionByParent(
        this.currentPageCashTransaction,
        this.pageSizeCashTransaction,
        this.id
      )
      .subscribe(
        (response) => {
          // Filtramos los datos que realmente necesitamos mostrar
          this.cashTransactionHistory = response.data.cashRegisters.map(
            (cashier: any) => {
              // Extraemos solo las transacciones relevantes
              return {
                ...cashier,
                // Aquí aseguramos que las transacciones estén bien formateadas
                transactions: cashier.transactions.map((transaction: any) => ({
                  operation_by: {
                    name: transaction.operation_by?.name || 'Desconocido', // Asegurarse de que operation_by esté presente
                    email: transaction.operation_by?.email || 'Sin email', // Agregar el email también
                  },
                  createdAt: transaction.createdAt,
                  amount: transaction.amount?.$numberDecimal || 0, // Usar un valor por defecto si amount es null o no está definido
                })),
              };
            }
          );

          console.log(
            'Historial de transacciones de cajas registradoras:',
            this.cashTransactionHistory
          );

          // Otros datos de paginación
          this.hasNextPageCashTransaction = response.data.hasNextPage;
          this.hasPreviousPageCashTransaction = response.data.hasPreviousPage;
          this.totalPagesCashTransaction = response.data.totalPages;
        },
        (error) => {
          console.error(
            'Error al cargar el historial de transacciones:',
            error
          );
        }
      );
  }

  getRoleByName(roleName: string): void {
    this.roleService.getRoleByName(roleName).subscribe(
      (res: any) => {
        if (res.success) {
          //console.log('Role:', res);
          if (res.data.has_wallet === 'true') {
            //console.log('Role has wallet');
            this.roleHasWallet = true;
          } else {
            // console.log('Role does not have wallet');
            this.roleHasWallet = false;
          }

          this.rol = {
            role_name: res.data.role_name,
            has_wallet: res.data.has_wallet,
            type: res.data.type,
            permissions: res.data.permissions,
          };

          // Priority order:
          // 1. If role has wallet access, use user's own wallet
          // 2. If role doesn't have wallet access, use parent's wallet
          // 3. If no wallet available, set hasWallet to false

          if (this.roleHasWallet && this.user.wallet) {
            console.log("Using user's wallet (role has wallet)");
            this.hasWallet = true;
            this.parentHasWallet = false;
            this.shipmentBalance =
              parseFloat(this.user.wallet.sendBalance.$numberDecimal) || 0;
            this.servicesBalance =
              parseFloat(this.user.wallet.servicesBalance.$numberDecimal) || 0;
            this.recharguesBalance =
              parseFloat(this.user.wallet.rechargeBalance.$numberDecimal) || 0;
          } else if (!this.roleHasWallet && this.user.parentUser?.wallet) {
            console.log("Using parent's wallet (role doesn't have wallet)");
            this.hasWallet = true;
            this.parentHasWallet = true;
            this.shipmentBalance =
              parseFloat(
                this.user.parentUser.wallet.sendBalance.$numberDecimal
              ) || 0;
            this.servicesBalance =
              parseFloat(
                this.user.parentUser.wallet.servicesBalance.$numberDecimal
              ) || 0;
            this.recharguesBalance =
              parseFloat(
                this.user.parentUser.wallet.rechargeBalance.$numberDecimal
              ) || 0;
          } else {
            console.log('No wallet available');
            this.hasWallet = false;
            this.parentHasWallet = false;
          }
        } else {
          console.log('Error en la respuesta:', res.message);
        }
      },
      (error) => {
        console.log('Error al obtener el rol:', error);
      }
    );
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
    const sidebarElement = document.querySelector('app-sidebar');
    if (sidebarElement) {
      if (this.isSidebarOpen) {
        sidebarElement.classList.add('open');
      } else {
        sidebarElement.classList.remove('open');
      }
    }
  }

  toggleSection(section: string): void {
    this.openSections[section] = !this.openSections[section];
    this.saveSectionState();
  }

  isSectionOpen(section: string): boolean {
    return this.openSections[section] || false;
  }

  saveSectionState() {
    localStorage.setItem('sidebarState', JSON.stringify(this.openSections));
  }

  isCurrentPage(route: string): boolean {
    return this.currentPage.includes(route);
  }

  logout() {
    console.log('Logout button clicked');
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  openNotifications() {
    console.log('Presionado');
  }

  toggleCart() {
    this.isCartOpen = !this.isCartOpen;
  }

  hasRole(role: string): boolean {
    //   console.log('Role:', this.user.role);
    return this.user.role === role;
  }

  hasPermission(category: string, action: string): boolean {
    if (this.rol && this.rol.permissions) {
      return this.rol.permissions.some(
        (permission) =>
          permission.category === category && permission.actions.includes(action)
      );
    }
    return false; // o el valor que prefieras si no hay permisos
  }
  

  openCart(): void {
    this.isCartOpen = true;
  }

  closeCart(): void {
    this.isCartOpen = false;
  }

  handleCartClick(): void {
    this.openCart();
  }

  removeItem(item: any) {
    const index = this.cartItems.indexOf(item);
    if (index > -1) {
      this.cartItems.splice(index, 1);
    }
  }

  getItemPrice(item: any): number {
    return parseFloat(item.price.$numberDecimal) || 0;
  }

  getTotalPrice(): number {
    return this.cartItems.reduce((total, item) => {
      return total + this.getItemPrice(item);
    }, 0);
  }

  loadItems() {
    this.shipmentService.getPendingShipments().subscribe(
      (res) => {
        this.cartItems = res.data;
      },
      (error) => {
        console.log('Error al cargar los envíos pendientes:', error);
        this.cartItems = [];
      }
    );
  }

  openWalletModal() {
    this.walletModalIsOpen = true;
    console.log('Opening wallet modal');
  }

  openWalletModalCard() {
    this.walletCardModalIsOpen = true;
    this.getTransactionHistoryCashRegister();
    this.showTransactionHistory();
    console.log('Abriendo card principal');
  }

  closeWalletModalCard() {
    this.walletCardModalIsOpen = false;
    console.log('Cerando Modal');
  }

  showTransactionHistory() {
    let userId;
    if (this.parentHasWallet) {
      userId = this.user.parentUser._id;
      console.log('Parent:', this.user.parentUser);
    } else {
      console.log('User:', this.user);
      userId = this.id;
    }

    this.transactionService
      .getTransaccionByUser(userId, this.currentPageTransaction, this.pageSize)
      .subscribe(
        (response) => {
          console.log('Historial de transacciones:', response);
          this.transactionHistory = response.message.transactions;
          this.hasNextPage = response.message.hasNextPage;
          this.hasPreviousPage = response.message.hasPreviousPage;
          this.totalItemsTransaction = response.message.total;
          this.showTransactionHistoryModal = true;
          this.totalPagesTransaction = response.message.totalPages;
        },
        (error) => {
          console.error(
            'Error al cargar el historial de transacciones:',
            error
          );
        }
      );

    // Implementa la lógica para mostrar el historial de transacciones
    // console.log(`Mostrar historial de transacciones para el usuario ${this.selectedUser._id}`);
    // Aquí deberías abrir un modal o navegar a una nueva vista con el historial de transacciones
  }

  onPageSizeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.pageSize = +selectElement.value; // Actualiza pageSize con el valor seleccionado
    console.log(`Tamaño de página cambiado a: ${this.pageSize}`);

    this.showTransactionHistory();
    // Aquí puedes llamar a una función para recargar los datos de acuerdo al nuevo pageSize
  }

  goToPage(page: number) {
    this.currentPageTransaction = page;
    console.log(`Ir a la página ${page}`);

    this.showTransactionHistory();

    // Lógica para cambiar de página
  }

  previousPage() {
    this.currentPageTransaction--;
    this.showTransactionHistory();
  }

  getPageNumbers(): number[] {
    return Array.from({ length: this.totalPagesTransaction }, (_, i) => i + 1);
  }

  nextPage() {
    this.currentPageTransaction++;
    this.showTransactionHistory();
  }

  closeModal() {
    this.walletModalIsOpen = false;
  }

  //modal de notificacion

  openNotificationModal(): void {
    this.isNotificationModalOpen = true;
  }
  
  closeNotificationModal(): void {
    this.isNotificationModalOpen = false;
  }
  
}
