import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-payments-record',
  templateUrl: './payments-record.component.html',
})
export class PaymentsRecordComponent implements OnInit {
  // Definir las variables para el componente
  type: string = 'all';
  page: number = 1;
  limit: number = 10;
  transactions: any[] = [];
  totalPage: number = 0;
  totalItems: number = 0;
  currentPage: number = 1;
  sortBy: string = 'date';
  sortOrder: 'asc' | 'desc' = 'desc';
  searchName: string = '';
  status: string = '';
  user_id: string = '';
  sub_user_id: string = '';
  locker_id: string = '';
  start_date: string = '';
  end_date: string = '';
  hasNextPage: boolean = false;
  hasPrevPage: boolean = false;

  // Inyectar el servicio en el constructor
  constructor(private transactionService: TransactionService) {}

  ngOnInit(): void {
    // Cargar las transacciones cuando el componente se inicializa
    this.loadTransactions(
      this.type,
      this.page,
      this.limit,
      this.user_id,
      this.sub_user_id,
      this.start_date,
      this.end_date,
      this.sortOrder,
      this.locker_id
    );
  }

  getVisiblePages(): number[] {
    const visiblePages: number[] = [];
    const maxVisiblePages = window.innerWidth < 640 ? 3 : 5; // Menos páginas en móvil
    
    let startPage = Math.max(this.currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = startPage + maxVisiblePages - 1;
    
    if (endPage > this.totalPage) {
      endPage = this.totalPage;
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }
    
    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }
    
    return visiblePages;
  }
  // Método para cargar las transacciones
  loadTransactions(
    type: string,
    page: number,
    limit: number,
    user_id?: string,
    sub_user_id?: string,
    start_date?: string,
    end_date?: string,
    sortOrder: string = 'desc',
    locker_id?: string
  ): void {
    this.transactionService
      .getAllTransactions(
        type,
        page,
        limit,
        user_id,
        sub_user_id,
        start_date,
        end_date,
        sortOrder,
        locker_id
      )
      .subscribe(
        (response) => {
          // Actualizar los valores del componente con la respuesta
          console.log(response.message);
          this.transactions = response.message.transactions;
          this.totalItems = response.message.total;
          this.totalPage = response.message.totalPages;
          this.currentPage = response.message.currentPage;
          this.hasNextPage = response.message.hasNextPage;
          this.hasPrevPage = response.message.hasPreviousPage;
        },
        (error) => {
          console.error('Error loading transactions', error);
        }
      );
  } 


  nextPage(): void {
    if (this.hasNextPage) {
      this.page = this.currentPage + 1;
      this.loadTransactions(
        this.type,
        this.page,
        this.limit,
        this.user_id,
        this.sub_user_id,
        this.start_date,
        this.end_date,
        this.sortOrder,
        this.locker_id
      );
    }
  }

  prevPage(): void {
    if (this.hasPrevPage) {

      this.page = this.currentPage - 1;
      this.loadTransactions(
        this.type,
        this.page,
        this.limit,
        this.user_id,
        this.sub_user_id,
        this.start_date,
        this.end_date,
        this.sortOrder,
        this.locker_id
      );
    }
  }

  getInitials(name: string): string {
    if (!name) return '';
  
    const words = name.trim().split(' '); 
    if (words.length > 1) {
      return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
    } else {
      return words[0].charAt(0).toUpperCase();
    }
  }

  getPages(): number[] {
    return Array.from({ length: this.totalPage }, (_, i) => i + 1);
  }

  selectPage(page: number): void {
    this.currentPage = page;
    this.loadTransactions(
      this.type,
      page,
      this.limit,
      this.user_id,
      this.sub_user_id,
      this.start_date,
      this.end_date,
      this.sortOrder,
      this.locker_id
    );
    
  }
}
