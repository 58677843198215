import { Component } from '@angular/core';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-ware-house',
  templateUrl: './ware-house.component.html',
  styleUrls: ['./ware-house.component.scss']
})
export class WareHouseComponent {

  warehouseInventory: any;
  packages: any[] = [];
  showModal = false;
  selectedId: string | undefined;
  requestQuantity: number = 0;

  constructor(private refillRequestService: RefillRequestService) { }

  ngOnInit() {
    this.loadWareHouse();
  }

  loadWareHouse() {
    this.refillRequestService.getWarehouseInventory().subscribe(
      (response) => {
        this.warehouseInventory = response.data.stock.map((item: any) => ({
          ...item,
          imageUrl: item.packing?.image?.data ? this.getImageUrl(new Uint8Array(item.packing.image.data)) : ''
        }));
        console.log(this.warehouseInventory);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  
  openModal(id:string, quantity: number) {
    this.selectedId = id;
    this.showModal = true;
    console.log(this.selectedId);
    this.requestQuantity = quantity;
  }

  updateQuantity() {
    if (this.selectedId) {
      this.refillRequestService.updatePackingQuantity(this.selectedId, this.requestQuantity).subscribe(
        (response) => {
          this.loadWareHouse();
          this.closeModal();
          Swal.fire('Exito', 'Stock de Empaque Actualizado Correctamente', 'success');
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('Selected ID is undefined');
    }
  }

  closeModal() {
    this.showModal = false;
    this.selectedId = undefined;
    this.requestQuantity = 0;
  }

getImageUrl(imageData: any): string {
  if (!imageData || !(imageData instanceof Uint8Array)) return '';

  try {
    const blob = new Blob([imageData], { type: 'image/png' });
    return URL.createObjectURL(blob); // Genera una URL de objeto en lugar de Base64
  } catch (error) {
    console.error('Error al convertir la imagen:', error);
    return '';
  }
}

  

}
