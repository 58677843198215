import { Component } from '@angular/core';

@Component({
  selector: 'app-payments-record',
  templateUrl: './payments-record.component.html',
})
export class PaymentsRecordComponent {
  payments = [
    {
      user: 'qrooplaya Hugo Sergio Mendoza Cervantes',
      service: 'Pago CFE',
      paymentDate: '11 Dec 2024 20:57',
      referenceNumber: '018010412853824120000027303',
      authorizationNumber: '',
      amount: 2745,
      status: 'FAIL',
    },
    {
      user: 'JALELSALTO-001 Leticia Gamboa Dorado',
      service: 'Pago CFE',
      paymentDate: '11 Dec 2024 13:53',
      referenceNumber: '01501071208582412000000086',
      authorizationNumber: '581920861',
      amount: 193,
      status: 'PAGADO',
    },
    {
      user: 'Dayana Paola Covarrubias Orozco',
      service: 'Pago TELMEX',
      paymentDate: '11 Dec 2024 11:56',
      referenceNumber: '3336158932',
      authorizationNumber: '400372707',
      amount: 802,
      status: 'PAGADO',
    },
    {
      user: 'gtolsen-002 ZonaPiel2',
      service: 'Pago Tarjeta Spotify 115',
      paymentDate: '10 Dec 2024 17:14',
      referenceNumber: '5577958001',
      authorizationNumber: '',
      amount: 115,
      status: 'FAIL',
    },
    {
      user: 'cajero jaltolija-001 Evangelina Atrip Escalante',
      service: 'Pago ATT',
      paymentDate: '10 Dec 2024 16:15',
      referenceNumber: '3335936444',
      authorizationNumber: '',
      amount: 464,
      status: 'FAIL',
    },
  ];
}
