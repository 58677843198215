import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; // Importa HttpClient
import { Observable } from 'rxjs'; // Importa Observable para manejar la respuesta
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class DealerService {

  // URL de la API
  // private apiUrl = 'http://localhost:3000/api/v1/users/delivery-users';
  private apiUrl = environment.apiUrl; // URL de la API desde el entorno
  constructor(private http: HttpClient) { } // Inyecta HttpClient

  // Método para obtener los usuarios delivery
  getDeliveryUsers(): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/delivery-users`); // Realiza la petición GET
  }
}