<app-sidebar></app-sidebar>
<nav class="navbar fixed top-0 z-50 w-full bg-white">
  <div
    class="px-3 py-3 lg:px-5 lg:pl-3 flex justify-between items-center relative overflow-hidden"
  >
    <!-- Moto animada -->
    <div class="vehicle-container relative z-10">
      <img
        src="../../../assets/images/Moto_animacion.png"
        alt="Moto"
        class="vehicle-image moto"
      />
      <img
        src="../../../assets/images/Camion_animacion.png"
        alt="Camión"
        class="vehicle-image camion"
      />
      <img
        src="../../../assets/images/Avion_animacion.png"
        alt="Avión"
        class="vehicle-image avion"
      />
    </div>

    <!-- Botón de hamburguesa -->
    <button class="hamburger-button" (click)="toggleSidebar()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 5.25h16.5M3.75 12h16.5m-16.5 6.75h16.5"
        />
      </svg>
    </button>

    <!-- Botones de acción (Carrito, Notificaciones, Cerrar sesión) -->
    <div class="flex items-center gap-4 z-20 relative">
      <!-- Botón de carrito -->
      <button
        (click)="toggleCart()"
        class="nav-icon p-2 hover:bg-dagblue hover:text-white rounded-full relative"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
          />
        </svg>
        <span
          *ngIf="cartItems.length > 0"
          class="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center"
        >
          {{ cartItems.length }}
        </span>
      </button>

      <!-- Botón de notificaciones -->
      <button
        (click)="openNotifications()"
        class="nav-icon p-2 hover:bg-dagblue hover:text-white rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 17h5l-1.405-1.405A7.956 7.956 0 0017.787 11.5C17.787 7.477 14.31 4 10 4S2.213 7.477 2.213 11.5a7.956 7.956 0 001.192 4.05L2 17h5m6 0v.5a2.5 2.5 0 11-5 0V17m6 0H9"
          />
        </svg>
      </button>

      <!-- Botón de cerrar sesión -->
      <button
        (click)="logout()"
        class="nav-item flex items-center rounded-lg p-2 hover:bg-dagblue hover:text-white duration-200 gap-2 text-dagblue"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            fill-rule="evenodd"
            d="M16.5 3.75a1.5 1.5 0 0 1 1.5 1.5v13.5a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5V15a.75.75 0 0 0-1.5 0v3.75a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3V9A.75.75 0 1 0 9 9V5.25a1.5 1.5 0 0 1 1.5-1.5h6ZM5.78 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-1.72-1.72H15a.75.75 0 0 0 0-1.5H4.06l1.72-1.72a.75.75 0 0 0 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Salir</span>
      </button>
    </div>
  </div>
</nav>

<ng-content></ng-content>
