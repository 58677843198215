import { Component, OnInit } from '@angular/core';
import { RecolectService } from 'src/app/_services/recolect/recolect.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
interface Recolect {
  name: string;
  telephone: string;
  tracking_number: string;
  delivery: string;
  status: string;
 }
@Component({
  selector: 'app-harvest',
  templateUrl: './harvest.component.html',
  styleUrls: ['./harvest.component.scss'],
})
export class HarvestComponent implements OnInit {
  senderInfoForm!: FormGroup;
  packageRegistrationForm!: FormGroup;
  currentStep = 1;
  paquetes: any[] = [];
  courierOptions = ['DHL', 'FedEx', 'UPS', 'Estafeta', 'Correos de México'];
  recolects: Recolect[] = [];
  totalPages: number = 0;
  currentPage: number = 1;
  
  isModalOpen = false;
  nipForm!: FormGroup;
  currentDateTime: string = '';

  packagePrice: number = 20; // Precio inicial de $20
  isPriceModalOpen: boolean = false; // Estado del modal para editar el precio

  constructor(
    private fb: FormBuilder,
    private recolectService: RecolectService
  ) {}

  ngOnInit() {
    this.currentDateTime = new Date().toLocaleString();
    this.loadUserRecolects();
    this.senderInfoForm = this.fb.group({
      senderName: ['', Validators.required],
      senderPhone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    });

    this.packageRegistrationForm = this.fb.group({
      trackingNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]{11,}$') 
        ]
      ],
      courier: ['', Validators.required],
    });

    this.nipForm = this.fb.group({
      nip: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
    });
  }

  openModal() {
    this.isModalOpen = true;
  }

  confirmAndNextStep() {
    this.submitForm();
    this.nextStep();
  }

  confirmAndNextStep3() {
    this.nextStep();
  }

  closeModal() {
    this.isModalOpen = false;
    this.senderInfoForm.reset();
    this.packageRegistrationForm.reset();
    this.paquetes = [];
    this.currentStep = 1;
  }

  nextStep() {
    console.log('Current Step:', this.currentStep);
    console.log('Form Valid:', this.senderInfoForm.valid);
    
    if (this.currentStep === 1 && this.senderInfoForm.invalid) {
      console.log('Step 1 validation failed');
      return;
    }

    if (this.currentStep === 2 && this.paquetes.length === 0) {
      console.log('Step 2 validation failed');
      return;
    }
    if (this.currentStep === 5 && this.nipForm.invalid) {
      console.log('Step 5 validation failed');
      return;
    }
    if (this.currentStep < 6) {
      this.currentStep++;
      console.log('Moved to step:', this.currentStep);
    }
  }
  confirmPayment5() {
    // Lógica futura para confirmar el pago puede ir aquí
    console.log('Pago confirmado y entrega realizada');

    // Avanzar al paso 6 sin validación
    this.currentStep = 6;
  }

  prevStep() {
    this.currentStep--;
  }

  addPackage(packageInfo: any) {
    this.paquetes.push(packageInfo);
    this.packageRegistrationForm.reset();
  }

  removePackage(index: number) {
    this.paquetes.splice(index, 1);
  }

  async submitForm() {
    const base64Image = await this.captureCardAsBase64();
    const id = localStorage.getItem('id');

    if (!id) {
      console.error('User ID not found');
      return;
    }

    // Create a recolect for each package
    const recolectPromises = this.paquetes.map((paquete) => {
      const recolectData = {
        user_id: id,
        name: this.senderInfoForm.get('senderName')?.value || '',
        telephone: this.senderInfoForm.get('senderPhone')?.value || '',
        tracking_number: paquete.trackingNumber || '',
        delivery: paquete.courier || '',
        note: '',
        status: 'Recepcionado',
        image64: base64Image,
      };

      return this.recolectService.createRecolect(recolectData).toPromise();
    });

    try {
      await Promise.all(recolectPromises);
      console.log('All recolects created successfully');
      this.nextStep();
    } catch (error) {
      console.error('Error creating recolects:', error);
    }
  }
  private async captureCardAsBase64(): Promise<string> {
    const cardElement = document.getElementById('card');
    if (cardElement) {
      const canvas = await html2canvas(cardElement);
      return canvas.toDataURL('image/png').split(',')[1];
    }
    return '';
  }

  downloadCard() {
    const cardElement = document.getElementById('card');
    if (cardElement) {
      // Datos dinámicos
      const senderName = this.senderInfoForm.get('senderName')?.value || 'Nombre no disponible';
      const senderPhone = this.senderInfoForm.get('senderPhone')?.value || 'Teléfono no disponible';
      const totalPrice = this.paquetes.length * this.packagePrice;
  
      // Crear el ticket en formato de recibo
      const receiptFormat = document.createElement('div');
      receiptFormat.style.width = '300px';
      receiptFormat.style.padding = '10px';
      receiptFormat.style.border = '1px dashed #000';
      receiptFormat.style.fontFamily = 'Courier, monospace';
      receiptFormat.style.fontSize = '12px';
      receiptFormat.style.textAlign = 'center';
  
      // Encabezado
      receiptFormat.innerHTML = `
        <div style="margin-bottom: 10px;">
          <img 
            src="assets/images/Icon Dagpacket.png" 
            alt="Logo Dagpacket" 
            style="width: 80px; height: auto; margin: 0 auto; display: block;"
          />
          <p style="margin: 0; font-weight: bold;">Dagpacket</p>
          <p style="margin: 0;">Recepción de Paquetería</p>
          <p style="margin: 0;">${this.currentDateTime}</p>
        </div>
        <hr style="border-top: 1px dashed #000; margin: 10px 0;" />
        <div style="text-align: left; margin-bottom: 10px;">
          <p style="margin: 2px 0;"><strong>Remitente:</strong> ${senderName}</p>
          <p style="margin: 2px 0;"><strong>Teléfono:</strong> ${senderPhone}</p>
        </div>
        <hr style="border-top: 1px dashed #000; margin: 10px 0;" />
        <div style="text-align: left; margin-bottom: 10px;">
          <p style="margin: 2px 0; font-weight: bold;">Detalles de Paquetes:</p>
        </div>
      `;
  
      // 📦 **Agregar cada paquete a la lista**
      this.paquetes.forEach((paquete, index) => {
        receiptFormat.innerHTML += `
          <div style="text-align: left; margin-bottom: 5px;">
            <p style="margin: 2px 0;"><strong>Paquete ${index + 1}:</strong></p>
            <p style="margin: 2px 0;"><strong>Paquetería:</strong> ${paquete.courier || 'Sin paquetería'}</p>
            <p style="margin: 2px 0;"><strong>N° Tracking:</strong> ${paquete.trackingNumber || 'Sin tracking'}</p>
          </div>
          <hr style="border-top: 1px dashed #000; margin: 5px 0;" />
        `;
      });
  
      // Total a pagar
      receiptFormat.innerHTML += `
        <div style="text-align: left; margin-bottom: 10px;">
          <p style="margin: 2px 0; font-weight: bold;">Total: $${totalPrice} MXN</p>
        </div>
        <p style="margin-top: 10px; font-style: italic;">¡Gracias por su preferencia!</p>
      `;
  
      document.body.appendChild(receiptFormat);
  
      // Generar y descargar la imagen
      html2canvas(receiptFormat, { scale: 2 }).then((canvas) => {
        const link = document.createElement('a');
        link.download = 'recepcion_paquete.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
  
        // Eliminar el elemento temporal después de descargar
        document.body.removeChild(receiptFormat);
      });
    }
  }
  
  
  

  openPriceModal() {
    this.isPriceModalOpen = true;
  }

  closePriceModal() {
    this.isPriceModalOpen = false;
  }

  savePrice() {
    this.isPriceModalOpen = false;
    // Aquí podrías agregar validaciones adicionales si lo necesitas
  }

  validatePhone(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '').slice(0, 10);
  }

  isButtonDisabled(): boolean {
    return this.packageRegistrationForm.invalid;
  }

  loadUserRecolects() {
    const userId = localStorage.getItem('id');
    if (userId) {
      this.recolectService.getRecolectsByUser(userId).subscribe({
        next: (response) => this.recolects = response.recolects,
        error: (error) => console.error('Error:', error)
      });
    }
   }
   

}
