import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,  
} from '@angular/router';
import { AuthLoginService } from './users/auth-login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthRoutesService implements CanActivate {
  constructor(private authService: AuthLoginService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    console.log("route", route);
    console.log('Checking if user is logged in');

    if (!this.authService.isLogged()) {
      this.router.navigate(['/login']);
      return false;
    }

    const requiredRoles = route.data['roles'] as string[]; // Ahora es un array

    if (
      requiredRoles &&
      requiredRoles.length > 0 &&
      !requiredRoles.some((role) => this.authService.hasRole(role))
    ) {
      console.log('Access denied - Required roles: ', requiredRoles);

      this.router.navigate(['/access-denied']);
      return false;
    }

    return true;
  }
}
