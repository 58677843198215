import { Component, OnInit } from '@angular/core';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { CuponService } from 'src/app/_services/cupon/cupon.service';

@Component({
  selector: 'app-cupons',
  templateUrl: './cupons.component.html',
  styleUrls: ['./cupons.component.scss']
})
export class CuponsComponent implements OnInit {

  userid: any;
  showCupones = false;
  cupons: any[] = [];
  userForCupon: any = null;


  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number = 1;


  constructor(
    private authService: AuthLoginService,
    private cuponService: CuponService,
  ){
    this.userid = this.authService.getId();
  }


  ngOnInit(): void {
    this.fetchCupons();
  }

  fetchCupons(): void {
    console.log(`Ejecutando fetchCupons...`);

    this.cuponService.getCuponsByUser(this.userid).subscribe(
      (response) => {
        console.log('Respuesta completa del servicio:', response);

        if (response && response.message) {
          this.cupons = response.message; // Se asigna la lista de cupones
        } else {
          console.warn('La estructura de la respuesta no es la esperada.');
        }
      },
      (error) => {
        console.error('Error al cargar los cupones:', error);
      }
    );
  }





  // prevPage(): void {
  //   if (this.currentPage > 1) {
  //     this.fetchCupons(this.currentPage - 1);
  //   }
  // }

  // nextPage(): void {
  //   if (this.currentPage < this.totalPages) {
  //     this.fetchCupons(this.currentPage + 1);
  //   }
  // }
  

}
