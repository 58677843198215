import { Component, HostListener, OnInit } from '@angular/core';
import { RechargeRequestService } from 'src/app/_services/Rechargues/rechargue-request.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-refills',
  templateUrl: './request-refills.component.html',
  styleUrls: ['./request-refills.component.scss'],
})
export class RequestRefillsComponent implements OnInit {
  rechargeRequests: any[] = [];
  filteredRequests: any[] = [];
  searchTerm: string = '';
  selectedStatus: string = '';
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10;
  totalRequests: number = 0;
  loading: boolean = false;
  id: string = '';
  showProofDialog: boolean = false;
  currentProofImage: string = '';
  currentRequest: any = null;
  Math = Math;
  showApproveDialog: boolean = false;
  currentApproveRequest: any = null;

  showRejectDialog: boolean = false;
  currentRejectId: string = '';
  rejectionReason: string = '';
  currentRejectRequest: any = null;

  showToast: boolean = false;
  toastMessage: string = '';
  toastType: 'success' | 'error' = 'success';
  enteredPin: string = '';
  storedPin: string = '';
  showPinModal: boolean = false;
  pendingAction: Function | null = null;

  isSidebarOpen = true; // Sidebar abierto por defecto
  isMobile = false; // Detecta si está en un dispositivo móvil

  constructor(
    private rechargeRequestService: RechargeRequestService,
    private authService: AuthLoginService
  ) {}

  ngOnInit(): void {
    this.getUserId();
    this.loadRechargeRequests();
    this.checkScreenSize();
  }

  // Escucha cambios en el tamaño de la ventana
  @HostListener('window:resize', [])
  onResize(): void {
    this.checkScreenSize();
  }

  showToastMessage(
    message: string,
    type: 'success' | 'error' = 'success'
  ): void {
    this.toastMessage = message;
    this.toastType = type;
    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 3000);
  }

  loadRequest() {
    this.rechargeRequestService.getRechargues().subscribe((res) => {
      console.log('Solcitudes de recarga', res.data);
    });
  }

  getUserId(): void {
    const userId = this.authService.getId();
    if (userId) {
      this.id = userId;
      console.log('User ID:', this.id);
    } else {
      console.error('ID not found');
    }
  }

  loadRechargeRequests(): void {
    this.loading = true;

    this.rechargeRequestService
      .getRechargeRequests(
        this.currentPage,
        this.itemsPerPage,
        this.searchTerm,
        this.id
      )
      .subscribe(
        (response: any) => {
          this.loading = false;
          if (response.success) {
            console.log('Recharge requests:', response.data.requests);
            this.rechargeRequests = response.data.requests;
            this.filteredRequests = [...this.rechargeRequests]; // Inicializa filteredRequests con todos los datos
            this.currentPage = response.data.currentPage;
            this.totalPages = response.data.totalPages;
            this.totalRequests = response.data.totalRequests;
          } else {
            console.error('Error loading recharge requests:', response.message);
            this.showToastMessage(
              'Error al cargar las solicitudes de recarga',
              'error'
            );
          }
        },
        (error) => {
          this.loading = false;
          console.error('Error loading recharge requests:', error);
          this.showToastMessage(
            'Error al cargar las solicitudes de recarga',
            'error'
          );
        }
      );
  }

  search(): void {
    this.currentPage = 1;
    this.loadRechargeRequests();
  }

  changePage(newPage: number): void {
    if (
      newPage >= 1 &&
      newPage <= this.totalPages &&
      newPage !== this.currentPage
    ) {
      this.currentPage = newPage;
      this.loadRechargeRequests();
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.changePage(this.currentPage + 1);
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.changePage(this.currentPage - 1);
    }
  }

  calculateDisplayedItems(): { start: number; end: number } {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(
      this.currentPage * this.itemsPerPage,
      this.totalRequests
    );
    return { start, end };
  }

  viewProof(rechargeId: string): void {
    const request = this.rechargeRequests.find((r) => r._id === rechargeId);
    if (request && request.proofImage) {
      this.currentProofImage = `data:image/jpeg;base64,${request.proofImage}`;
      this.currentRequest = request; // Set the current request
      this.showProofDialog = true;
    } else {
      console.error('Comprobante no encontrado');
    }
  }

  closeProofDialog(): void {
    this.showProofDialog = false;
    this.currentProofImage = '';
    this.currentRequest = null; // Clear the current request
  }

  openApproveDialog(request: any): void {
    this.currentApproveRequest = request;
    this.showApproveDialog = true;
  }

  closeApproveDialog(): void {
    this.showApproveDialog = false;
    this.currentApproveRequest = null;
  }

  confirmApproveRequest(): void {
    if (this.currentApproveRequest) {
      this.rechargeRequestService
        .approveRechargeRequest(this.currentApproveRequest._id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.showToastMessage(
                `Recarga de ${this.getRechargeTypeLabel(
                  this.currentApproveRequest.rechargeType
                )} aprobada con éxito`
              );
              this.loadRechargeRequests(); // Recargar la lista
            } else {
              this.showToastMessage(
                'Error al aprobar la recarga: ' + response.message,
                'error'
              );
            }
            this.closeApproveDialog();
          },
          (error) => {
            this.showToastMessage(
              'Error al aprobar la recarga: ' + error.message,
              'error'
            );
            this.closeApproveDialog();
            this.closeProofDialog();
          }
        );
    }
  }

  rejectRequest(): void {
    if (this.rejectionReason.trim()) {
      this.rechargeRequestService
        .rejectRechargeRequest(this.currentRejectId, this.rejectionReason)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.showToastMessage('Recarga rechazada con éxito');
              this.loadRechargeRequests(); // Recargar la lista
              this.closeRejectDialog();
            } else {
              this.showToastMessage(
                'Error al rechazar la recarga: ' + response.message,
                'error'
              );
            }
          },
          (error) => {
            this.showToastMessage(
              'Error al rechazar la recarga: ' + error.message,
              'error'
            );
          }
        );
    } else {
      this.showToastMessage('Se requiere un motivo de rechazo', 'error');
    }
  }

  openRejectDialog(request: any): void {
    this.currentRejectId = request._id;
    this.currentRejectRequest = request;
    this.showRejectDialog = true;
  }

  closeRejectDialog(): void {
    this.showRejectDialog = false;
    this.currentRejectId = '';
    this.currentRejectRequest = null;
    this.rejectionReason = '';
  }

  getRechargeTypeLabel(type: string): string {
    switch (type) {
      case 'envios':
        return 'Envíos';
      case 'servicios':
        return 'Servicios';
      case 'recargas':
        return 'Recargas';
      default:
        return 'Desconocido';
    }
  }

  // Verifica si la pantalla es móvil
  private checkScreenSize(): void {
    this.isMobile = window.innerWidth < 640; // Define móvil como pantallas menores a 640px
    if (this.isMobile) {
      this.isSidebarOpen = false; // Colapsa el sidebar por defecto en móviles
    }
  }

  // Alterna el estado del sidebar
  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  filterByStatus(): void {
    if (this.selectedStatus) {
      this.filteredRequests = this.rechargeRequests.filter(
        (request) => request.status === this.selectedStatus
      );
    } else {
      this.filteredRequests = this.rechargeRequests;
    }
  }
}
