import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private http: HttpClient) {}
  // Método
  getTracking(id:string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/shipping/track-guide/${id}`
    );
  }

}
