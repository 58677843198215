<app-sidebar></app-sidebar>
<div class="main-content">
  <!-- Contenido del componente Packing -->
  <div class="ml-64 mt-14">
    <div class="p-2">
      <div
        class="flex flex-col justify-between items-start py-2 overflow-x-auto"
      >
        <div class="flex flex-col py-4 gap-2 rounded-lg">
          <div
            class="flex py-4 justify-between hover:bg-slate-300 hover:cursor-pointer p-2 rounded-lg"
          >
            <div class="min-w-[200px] flex flex-col items-start">
              <span class="font-bold text-dagblue">PROPIETARIO</span>
              <p class="text-sm">DAGPACKET Puebla-001</p>
            </div>
            <div class="min-w-[200px] flex flex-col items-start mr-6">
              <span class="font-bold text-dagblue">ASIGNADO</span>
              <p class="text-sm">Brayam Ayala Perez/ jalgdl-008</p>
            </div>
            <div class="min-w-[200px] flex flex-col items-start">
              <span class="font-bold text-dagblue">FECHA</span>
              <span
                class="bg-gradient-to-tr from-dagblue to to-blue-500 text-sm text-white rounded-lg px-2 font-bold"
                >21 Dec 2024 11:30</span
              >
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">PRECIO</span>
              <p class="text-sm">$49</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">COSTO</span>
              <p class="text-sm">$25.78</p>
            </div>
            <div class="min-w-[150px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="fo justify-betweennt-bold text-dagblue"
                >LICENCIATARIO</span
              >
              <p class="text-sm">$23.22</p>
            </div>
            <div class="min-w-[150px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="font-bold text-dagblue">DAGPACKET</span>
              <p class="text-sm">$6.97</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="font-bold text-dagblue">TOTAL</span>
              <p class="text-sm text-emerald-700 font-bold">$23.22</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">Estatus</span>
              <span
                class="bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 text-white font-bold rounded-lg"
              >
                PAGADO
              </span>
            </div>
          </div>
          <div
            class="flex py-4 hover:bg-slate-300 hover:cursor-pointer p-2 rounded-lg"
          >
            <div class="min-w-[200px] flex flex-col items-start">
              <span class="font-bold text-dagblue">PROPIETARIO</span>
              <p class="text-sm">DAGPACKET Puebla-001</p>
            </div>
            <div class="min-w-[200px] flex flex-col items-start mr-6">
              <span class="font-bold text-dagblue">ASIGNADO</span>
              <p class="text-sm">Brayam Ayala Perez/ jalgdl-008</p>
            </div>
            <div class="min-w-[200px] flex flex-col items-start">
              <span class="font-bold text-dagblue">FECHA</span>
              <span
                class="bg-gradient-to-tr from-dagblue to to-blue-500 text-sm text-white rounded-lg px-2 font-bold"
                >21 Dec 2024 11:30</span
              >
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">PRECIO</span>
              <p class="text-sm">$49</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">COSTO</span>
              <p class="text-sm">$25.78</p>
            </div>
            <div class="min-w-[150px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="fo justify-betweennt-bold text-dagblue"
                >LICENCIATARIO</span
              >
              <p class="text-sm">$23.22</p>
            </div>
            <div class="min-w-[150px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="font-bold text-dagblue">DAGPACKET</span>
              <p class="text-sm">$6.97</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="font-bold text-dagblue">TOTAL</span>
              <p class="text-sm text-emerald-700 font-bold">$23.22</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">Estatus</span>
              <span
                class="bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 text-white font-bold rounded-lg"
              >
                PAGADO
              </span>
            </div>
          </div>
          <div
            class="flex py-4 hover:bg-slate-300 hover:cursor-pointer p-2 rounded-lg"
          >
            <div class="min-w-[200px] flex flex-col items-start">
              <span class="font-bold text-dagblue">PROPIETARIO</span>
              <p class="text-sm">DAGPACKET Puebla-001</p>
            </div>
            <div class="min-w-[200px] flex flex-col items-start mr-6">
              <span class="font-bold text-dagblue">ASIGNADO</span>
              <p class="text-sm">Brayam Ayala Perez/ jalgdl-008</p>
            </div>
            <div class="min-w-[200px] flex flex-col items-start">
              <span class="font-bold text-dagblue">FECHA</span>
              <span
                class="bg-gradient-to-tr from-dagblue to to-blue-500 text-sm text-white rounded-lg px-2 font-bold"
                >21 Dec 2024 11:30</span
              >
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">PRECIO</span>
              <p class="text-sm">$49</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">COSTO</span>
              <p class="text-sm">$25.78</p>
            </div>
            <div class="min-w-[150px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="fo justify-betweennt-bold text-dagblue"
                >LICENCIATARIO</span
              >
              <p class="text-sm">$23.22</p>
            </div>
            <div class="min-w-[150px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="font-bold text-dagblue">DAGPACKET</span>
              <p class="text-sm">$6.97</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="font-bold text-dagblue">TOTAL</span>
              <p class="text-sm text-emerald-700 font-bold">$23.22</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">Estatus</span>
              <span
                class="bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 text-white font-bold rounded-lg"
              >
                PAGADO
              </span>
            </div>
          </div>
          <div
            class="flex py-4 hover:bg-slate-300 hover:cursor-pointer p-2 rounded-lg"
          >
            <div class="min-w-[200px] flex flex-col items-start">
              <span class="font-bold text-dagblue">PROPIETARIO</span>
              <p class="text-sm">DAGPACKET Puebla-001</p>
            </div>
            <div class="min-w-[200px] flex flex-col items-start mr-6">
              <span class="font-bold text-dagblue">ASIGNADO</span>
              <p class="text-sm">Brayam Ayala Perez/ jalgdl-008</p>
            </div>
            <div class="min-w-[200px] flex flex-col items-start">
              <span class="font-bold text-dagblue">FECHA</span>
              <span
                class="bg-gradient-to-tr from-dagblue to to-blue-500 text-sm text-white rounded-lg px-2 font-bold"
                >21 Dec 2024 11:30</span
              >
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">PRECIO</span>
              <p class="text-sm">$49</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">COSTO</span>
              <p class="text-sm">$25.78</p>
            </div>
            <div class="min-w-[150px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="font-bold text-dagblue">LICENCIATARIO</span>
              <p class="text-sm">$23.22</p>
            </div>
            <div class="min-w-[150px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="font-bold text-dagblue">DAGPACKET</span>
              <p class="text-sm">$6.97</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">UTILIDAD</span>
              <span class="font-bold text-dagblue">TOTAL</span>
              <p class="text-sm text-emerald-700 font-bold">$23.22</p>
            </div>
            <div class="min-w-[100px] flex flex-col items-start">
              <span class="font-bold text-dagblue">Estatus</span>
              <span
                class="bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 text-white font-bold rounded-lg"
              >
                PAGADO
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
