import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmidaService {

  constructor(private http: HttpClient) { }

  getEmidaServices(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/emida/products`);
  }

  getEmidaPaymentService(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/emida/payment-services`);
  }

  doRecharge(paymentData: any,id:any, paymentMethod:any): Observable<any> {
    const body = {
      ...paymentData,
      id: id,
      paymentMethod: paymentMethod
    };
    return this.http.post(`${environment.apiUrl}/emida/recharge`, body);
  }

  doBillPayment(paymentData: any,id:any,paymentMethod:any): Observable<any> {

    const body = {
      ...paymentData,
      id:id,
      paymentMethod: paymentMethod
    };

    return this.http.post(`${environment.apiUrl}/emida/bill-payment`, body);
  }
  // Método para obtener los datos de scan_service
  getAvailableServices(): Observable<any> {
    return this.http.get('http://localhost:3000/api/scan_service'); // Cambia la URL según tu backend
  }
}