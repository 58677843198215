<app-sidebar></app-sidebar>
<app-envios-modal [visible]="isModalVisible" (close)="closeModal()" ></app-envios-modal>
<div class="sm:ml-64 mt-14 min-h-screen p-6 bg-gray-50">
  <!-- dashboard.component.html -->

  <div class="p-6 min-h-screen">
    <!-- Header del dashboard -->
    <div class="mb-6">
      <h1 class="text-2xl font-bold text-gray-800">Dashboard</h1>
      <p class="text-sm text-gray-500">Bienvenido de vuelta, administrador</p>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-4 gap-5">
      <!-- Estadísticas resumen - ocupa 3 columnas en desktop -->
      <div class="lg:col-span-3 flex flex-col lg:flex-row gap-4">
        <div
          class="bg-white rounded-lg shadow-sm p-5 flex items-center flex-1"
          *ngFor="let stat of summaryStats"
        >
          <div
            class="w-12 h-12 rounded-lg flex items-center justify-center mr-4 text-xl"
            [ngClass]="{
              'bg-red-50 text-red-500': stat.type === 'danger',
              'bg-indigo-50 text-indigo-600': stat.type === 'primary',
              'bg-green-50 text-green-500': stat.type === 'success'
            }"
          >
            {{ stat.icon }}
          </div>
          <div>
            <h3 class="text-sm font-medium text-gray-500 mb-1">
              {{ stat.title }}
            </h3>
            <div class="text-2xl font-bold text-gray-900">{{ stat.value }}</div>
            <div
              class="text-xs text-gray-500 mt-0.5"
              [ngClass]="{
                'text-red-500': stat.type === 'danger',
                'text-green-500': stat.type === 'success'
              }"
            >
              {{ stat.label }}
            </div>
          </div>
        </div>
      </div>

   

      <!-- Panel de envíos - ocupa 2 columnas -->
      <div 
      (click)="openModal()"
      class="bg-white rounded-lg shadow-sm p-5 lg:col-span-2">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-base font-semibold text-gray-800">Envíos</h2>
        </div>

        <div class="h-48 mt-5 relative">
          <div class="h-full flex items-end gap-3 pb-8">
            <div
              class="flex-1 relative rounded-t bg-gradient-to-t from-indigo-100 to-indigo-600"
              *ngFor="let day of weekDays; let i = index"
              [style.height]="i % 2 === 0 ? '70%' : i % 3 === 0 ? '55%' : '85%'"
            >
              <div
                class="absolute -bottom-8 left-0 right-0 text-center text-xs text-gray-500"
              >
                {{ day }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-between mt-5">
          <div *ngFor="let metric of shippingMetrics">
            <div class="text-sm font-medium text-gray-600">
              {{ metric.label }}
            </div>
            <div class="text-lg font-semibold text-gray-900 mt-1">
              {{ metric.value }}
            </div>
          </div>
        </div>
      </div>

      <!-- Panel de paquetes -->
      <div class="bg-white rounded-lg shadow-sm p-5">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-base font-semibold text-gray-800">Empaques</h2>
        </div>

        <!-- Gráfico de dona personalizado con CSS -->
        <div
          class="w-32 h-32 mx-auto mt-5 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-600 relative"
        >
          <div
            class="w-20 h-20 bg-white rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
          >
            <div class="text-lg font-semibold text-gray-900">$NaN</div>
          </div>
        </div>

        <div class="text-center text-xs text-gray-500 mt-3">
          Utilidad esta quincena
        </div>
      </div>

      <!-- Panel de usuarios -->
      <div class="bg-white rounded-lg shadow-sm p-5">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-base font-semibold text-gray-800">Usuarios</h2>
        </div>

        <div class="mt-4 space-y-3">
          <div
            class="flex items-center py-2 border-b border-gray-100 last:border-0"
            *ngFor="let user of users"
          >
            <div
              class="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-sm mr-3"
            >
              👤
            </div>
            <div>
              <h4 class="text-sm font-medium text-gray-800">{{ user.name }}</h4>
              <p class="text-xs text-gray-500">{{ user.status }}</p>
            </div>
          </div>
        </div>

        <div class="text-center mt-4">
          <div class="text-2xl font-semibold text-gray-900">19</div>
          <div class="text-sm text-gray-500">Total de usuarios</div>
        </div>
      </div>

      <!-- Panel de pagos - ocupa 2 columnas -->
      <div class="bg-white rounded-lg shadow-sm p-5 lg:col-span-2">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-base font-semibold text-gray-800">
            Pagos y Recargas
          </h2>
        </div>

        <div class="flex gap-5 mt-4">
          <div
            class="flex-1 p-4 bg-gray-50 rounded-lg"
            *ngFor="let payment of payments"
          >
            <h4 class="text-sm font-medium text-gray-600 mb-2">
              {{ payment.title }}
            </h4>
            <div class="text-xl font-semibold text-gray-900">
              {{ payment.value }}
            </div>
            <div class="text-xs text-green-500 mt-1">{{ payment.label }}</div>
          </div>
        </div>
      </div>

      <!-- Panel de reparto multimarca - ocupa 2 columnas -->
      <div class="bg-white rounded-lg shadow-sm p-5 lg:col-span-2">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-base font-semibold text-gray-800">
            Reparto Multimarca
          </h2>
        </div>

        <div class="flex justify-between items-center mt-4">
          <div class="text-2xl font-semibold text-gray-900">36</div>
          <div class="text-sm text-gray-500">de 50 repartos objetivo</div>
        </div>

        <div class="h-2 bg-gray-200 rounded-full mt-4 overflow-hidden">
          <div
            class="h-full bg-indigo-600 rounded-full"
            style="width: 65%"
          ></div>
        </div>

        <div class="text-xs text-gray-500 mt-2">Progreso mensual: 72%</div>
      </div>

      <!-- Panel de actividad reciente - ocupa 3 columnas -->
      <div class="bg-white rounded-lg shadow-sm p-5 lg:col-span-3">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-base font-semibold text-gray-800">
            Actividad Reciente
          </h2>
        </div>

        <div class="mt-5 pl-8 relative">
          <!-- Línea vertical para la línea de tiempo -->
          <div class="absolute top-0 bottom-0 left-3 w-0.5 bg-gray-200"></div>

          <div class="relative pb-5" *ngFor="let activity of recentActivity">
            <!-- Punto en la línea de tiempo -->
            <div
              class="absolute left-[-30px] w-5 h-5 rounded-full bg-white border-2 border-indigo-600 z-10"
            ></div>

            <div>
              <h4 class="text-sm font-medium text-gray-800">
                {{ activity.title }}
              </h4>
              <p class="text-sm text-gray-500 mt-1">
                {{ activity.description }}
              </p>
              <div class="text-xs text-gray-400 mt-1">{{ activity.time }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Panel de ubicaciones -->
      <div class="bg-white rounded-lg shadow-sm p-5">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-base font-semibold text-gray-800">Direcciones</h2>
        </div>

        <div
          class="h-48 bg-gray-100 rounded-lg mt-4 flex items-center justify-center"
        >
          <div class="text-sm text-gray-500">Mapa de ubicaciones</div>
        </div>

        <div class="flex justify-between mt-4">
          <div *ngFor="let stat of locationStats">
            <div class="text-sm font-medium text-gray-600">
              {{ stat.label }}
            </div>
            <div class="text-lg font-semibold text-gray-900 mt-1">
              {{ stat.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
