// src/app/_services/refill-request.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class RefillRequestService {
  private apiUrl = `${environment.apiUrl}/refill-requests`;

  constructor(private http: HttpClient) { }

  createRefillRequest(request: {
    userId: string,
    packingId: string,
    quantityRequested: number
  }): Observable<any> {
    return this.http.post(`${this.apiUrl}/create`, request);
  }

  getRefillRequests(page: number = 1, limit: number = 10, search: string = '', sortBy: string = 'created_at', sortOrder: string = 'desc'): Observable<any> {
    let params = new HttpParams()
        .set('page', page.toString())
        .set('limit', limit.toString())
        .set('sortBy', sortBy)
        .set('sortOrder', sortOrder);

    if (search) {
        params = params.set('search', search);
    }

    return this.http.get(`${this.apiUrl}/refill-requests`, { params });
}

  approveRefillRequest(requestId: string, adminNotes: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/approve/${requestId}`, { adminNotes });
  }

  rejectRefillRequest(requestId: string, adminNotes: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/reject/${requestId}`, { adminNotes });
  }


  getUserTransferRequests(userId: string, status?: string): Observable<any> {
    let params = new HttpParams();

    if (status && status.trim() !== '') {
        params = params.set('status', status.toLowerCase());
    }

    console.log("Petición GET con parámetros:", params.toString()); // Verificar qué se está enviando

    return this.http.get(`${this.apiUrl}/user-transfer-requests/${userId}`, { params });
  }

  createTransferRequest(request: {
    userId: string,
    packingId: string,
    quantity_transferred: number,
    admin_notes: string
  }): Observable<any> {
    return this.http.post(`${this.apiUrl}/create-transfer`, request);
  }

  getAllUsersInventory(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/refill-requests/getAllUsersInventory`);
  }

  getUserUtility(userId: string): Observable<any> {
    const params = new HttpParams().set('user_id', userId);
    return this.http.get<any>(`${this.apiUrl}/refill-requests/utilidad-lic`, { params });
  }
  

  getUserInventory(userId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/refill-requests/inventory/${userId}`);
  }

  updatePackingQuantity(packingId: string, quantity: number): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/packing/update-quantity`, { quantity,packingId });
  }

  getWarehouseInventory(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/packing/warehouse`);
  }

}