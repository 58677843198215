<app-sidebar></app-sidebar>

<div class="ml-64 mt-14 p-6">
    <h2 class="text-2xl font-bold text-dagblue border-b pb-2">Transferencias hechas a usuarios</h2>

    <!-- Filtro -->
    <div class="flex justify-between items-center mt-4">
        <p class="text-gray-600">Detalles de las transferencias completadas</p>
        <select [(ngModel)]="selectedTransferStatus" (change)="filterTransferRequests()"
            class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-dagblue">
            <option value="">Todas</option>
            <option value="Completada">Completada</option>
            <option value="Pendiente">Pendiente</option>
            <option value="Rechazada">Rechazada</option>
        </select>
    </div>

    <!-- Tabla de transferencias -->
    <div class="overflow-x-auto shadow-md sm:rounded-lg bg-[#F0F0F0] mt-4">
        <table class="w-full text-sm text-left text-gray-500">
            <thead class="uppercase bg-[#F0F0F0] text-dagblue font-bold">
                <tr>
                    <th class="px-6 py-3">Empaque</th>
                    <th class="px-6 py-3">Cantidad</th>
                    <th class="px-6 py-3">Fecha</th>
                    <th class="px-6 py-3">Estado</th>
                    <th class="px-6 py-3 text-center">Acciones</th>
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
                <tr *ngFor="let transfer of transferRequests" class="hover:bg-gray-50">
                    <td class="px-6 py-4">{{ transfer.packing.name }}</td>
                    <td class="px-6 py-4">{{ transfer.quantity_transferred }}</td>
                    <td class="px-6 py-4">{{ transfer.requested_date | date: 'short' }}</td>
                    <td class="px-6 py-4 font-bold text-lg"
                        [ngClass]="{
                            'text-yellow-500': transfer.status === 'pendiente',
                            'text-green-600': transfer.status === 'completada',
                            'text-red-600': transfer.status === 'rechazada'
                        }">
                        {{ transfer.status }}
                    </td>
                    <td class="px-6 py-4 text-center">
                        <button
                            class="bg-green-500 font-bold hover:bg-green-700 duration-200 text-white px-4 py-2 rounded">
                            Aprobar
                        </button>
                        <button
                            class="bg-red-500 font-bold hover:bg-red-700 duration-200 text-white px-4 py-2 rounded ml-2">
                            Rechazar
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
