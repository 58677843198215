import { UserRole } from './role-type';

export const ROLES: Record<string, ReadonlyArray<UserRole>> = {
  ALL_ROLES: [
    'USER',
    'ADMIN',
    'LICENCIATARIO_TRADICIONAL',
    'CAJERO',
    'PENDIENTE',
    'CLIENTE_CORPORATIVO',
    'REPARTIDOR',
    'SUPER_ADMIN',
    'INVERSIONISTA'
  ],
  SUPER_ADMIN_ONLY: ['SUPER_ADMIN'],
  ADMIN_ONLY: ['SUPER_ADMIN','ADMIN', 'SUPER_ADMIN'],
  STAFF_ROLES: ['SUPER_ADMIN','ADMIN', 'LICENCIATARIO_TRADICIONAL'],
  OPERATIONAL_ROLES: ['SUPER_ADMIN', 'ADMIN', 'LICENCIATARIO_TRADICIONAL', 'CLIENTE_CORPORATIVO'],
  EXTENDED_STAFF: ['SUPER_ADMIN', 'ADMIN', 'LICENCIATARIO_TRADICIONAL', 'CAJERO','CLIENTE_CORPORATIVO'],
  INVERSORS: ['SUPER_ADMIN','ADMIN', 'INVERSIONISTA'],
} as const;