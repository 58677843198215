<app-sidebar></app-sidebar>
<div class="ml-64 pt-20">
  <div class="container mx-auto">
    <h1 class="text-3xl font-bold mb-6 text-dagblue">
      Historial de Cortes de Caja
    </h1>

    <!-- Filtros de fecha -->
    <div class="mb-6 bg-white p-4 rounded-lg shadow-md">
      <div class="flex flex-wrap items-end space-x-4">
        <div>
          <label
            for="startDate"
            class="block text-sm font-medium text-gray-700 mb-1"
            >Fecha Inicio</label
          >
          <input
            type="date"
            id="startDate"
            [(ngModel)]="startDate"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label
            for="endDate"
            class="block text-sm font-medium text-gray-700 mb-1"
            >Fecha Fin</label
          >
          <input
            type="date"
            id="endDate"
            [(ngModel)]="endDate"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <button
          (click)="applyDateFilter()"
          class="px-4 py-2 bg-dagpk text-white rounded-md hover:bg-orange transition duration-300"
        >
          Filtrar
        </button>
        <button
          (click)="clearDateFilter()"
          class="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-300"
        >
          Limpiar
        </button>
      </div>
    </div>

    <!-- Lista de cortes de caja -->
    <div *ngIf="loading" class="text-center py-4">
      <div class="spinner"></div>
      <p class="mt-2 text-gray-600">Cargando registros...</p>
    </div>

    <div
      *ngIf="error"
      class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4"
      role="alert"
    >
      <p>{{ error }}</p>
    </div>

    <div
      *ngIf="!loading && cashRegisters.length === 0"
      class="text-center py-4 text-gray-600"
    >
      No se encontraron registros de caja para el período seleccionado.
    </div>

    <div *ngIf="!loading && cashRegisters.length > 0" class="space-y-8">
      <div
        *ngFor="let register of cashRegisters"
        class="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
      >
        <!-- Encabezado del registro con barra de estado -->
        <div class="relative">
          <div 
            [ngClass]="{
              'bg-green-600': register.status === 'open',
              'bg-red-600': register.status === 'closed',
              'bg-amber-500': register.status === 'pending_review'
            }"
            class="h-1 w-full"
          ></div>
          
          <div class="px-6 py-5 flex flex-wrap md:flex-nowrap justify-between items-start gap-4 border-b border-gray-200">
            <!-- Información principal -->
            <div class="flex-grow">
              <div class="flex items-center gap-3 mb-2">
                <h2 class="text-xl font-bold text-gray-800">
                  Corte de Caja #{{ register.opened_by.name }}
                </h2>
                <span
                  [ngClass]="{
                    'bg-red-50 text-red-800': register.status === 'closed',
                    'bg-green-50 text-green-800 animate-pulse': register.status === 'open',
                    'bg-amber-50 text-amber-800': register.status === 'pending_review'
                  }"
                  class="px-3 py-1 rounded-full text-xs font-medium"
                >
                  <span *ngIf="register.status === 'closed'">Cerrada</span>
                  <span *ngIf="register.status === 'open'">Abierta</span>
                  <span *ngIf="register.status === 'pending_review'">Pendiente</span>
                </span>
              </div>
              
              <p class="text-sm text-gray-600 flex items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Abierta: {{ register.opened_at | date : "dd MMM yyyy, HH:mm" }}
                <span *ngIf="register.closed_at" class="flex items-center gap-1 ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Cerrada: {{ register.closed_at | date : "dd MMM yyyy, HH:mm" }}
                </span>
              </p>
            </div>
    
            <!-- Resumen de pagos y totales -->
            <div class="flex flex-wrap md:flex-nowrap gap-6 mt-2 w-full md:w-auto">
              <!-- Métodos de pago -->
              <div class="bg-blue-50 p-4 rounded-lg flex-1 shadow-sm border border-blue-100">
                <h3 class="text-sm font-semibold text-gray-700 mb-2 border-b border-gray-200 pb-1">Métodos de Pago</h3>
                <div class="space-y-1">
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Tarjeta de Débito:</span>
                    <span class="font-medium">{{ formatCurrency(getSumByTypePayment(register.transactions, "td-debito")) }}</span>
                  </div>
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Tarjeta de Crédito:</span>
                    <span class="font-medium">{{ formatCurrency(getSumByTypePayment(register.transactions, "td-credito")) }}</span>
                  </div>
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Saldo:</span>
                    <span class="font-medium">{{ formatCurrency(getSumByTypePayment(register.transactions, "saldo")) }}</span>
                  </div>
                </div>
              </div>
              
              <!-- Totales por tipo -->
              <div class="bg-gray-50 p-4 rounded-lg flex-1 shadow-sm border border-gray-200">
                <h3 class="text-sm font-semibold text-gray-700 mb-2 border-b border-gray-200 pb-1">Resumen por Tipo</h3>
                <div class="space-y-1">
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Envíos:</span>
                    <span class="font-medium">{{ formatCurrency(getSumByType(register.transactions, "envio")) }}</span>
                  </div>
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Servicios:</span>
                    <span class="font-medium">{{ formatCurrency(getSumByType(register.transactions, "servicio")) }}</span>
                  </div>
                  <div class="flex justify-between text-sm">
                    <span class="text-gray-600">Recargas:</span>
                    <span class="font-medium">{{ formatCurrency(getSumByType(register.transactions, "recarga")) }}</span>
                  </div>
                  <div class="flex justify-between font-medium pt-1 border-t border-gray-200 mt-1">
                    <span class="text-gray-800">Total:</span>
                    <span class="text-gray-800">{{ formatCurrency(getTotalSum(register.transactions)) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <!-- Tabla de transacciones -->
        <div class="px-6 py-4">
          <h3 class="text-lg font-semibold text-gray-700 mb-4 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>
            Transacciones
          </h3>
          
          <div
            *ngIf="register.transactions.length === 0"
            class="text-gray-500 text-sm bg-gray-50 p-6 rounded-lg text-center italic shadow-inner"
          >
            No hay transacciones registradas para esta caja.
          </div>
          
          <div *ngIf="register.transactions.length > 0" class="overflow-x-auto rounded-lg border border-gray-200 shadow-sm">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                    Método de Pago
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                    Acción
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                    Realizada por
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                    Monto
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                    Fecha
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  *ngFor="let transaction of register.transactions"
                  class="hover:bg-gray-50 transition-colors"
                >
                  <td class="px-4 py-3 text-sm">
                    <span 
                      [ngClass]="{
                        'bg-blue-50 text-blue-700': transaction.payment_method === 'td-debito',
                        'bg-purple-50 text-purple-700': transaction.payment_method === 'td-credito',
                        'bg-green-50 text-green-700': transaction.payment_method === 'efectivo',
                        'bg-yellow-50 text-yellow-700': transaction.payment_method === 'saldo',
                        'bg-gray-50 text-gray-700': !transaction.payment_method
                      }"
                      class="px-2 py-1 rounded-full text-xs font-medium border"
                    >
                      {{ transaction.payment_method || "Desconocido" }}
                    </span>
                  </td>
                  <td class="px-4 py-3 text-sm text-gray-900">
                    {{ transaction.transaction_id.details || "Desconocido"}}
                  </td>
                  <td class="px-4 py-3 text-sm">
                    <div class="flex items-center">
                      <div class="h-8 w-8 rounded-full bg-gray-100 text-gray-700 flex items-center justify-center mr-2 border border-gray-200">
                        <span class="font-bold text-xs">{{ (transaction?.operation_by?.name || "?").charAt(0) }}</span>
                      </div>
                      <div>
                        <div class="font-medium">{{ transaction?.operation_by?.name || "Desconocido" }}</div>
                        <div class="text-xs text-gray-500">{{ transaction?.operation_by?.email || "Desconocido" }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="px-4 py-3 text-sm font-medium">
                    <span
                      [ngClass]="{
                        'text-green-700': transaction.amount.$numberDecimal > 0,
                        'text-red-700': transaction.amount.$numberDecimal < 0
                      }"
                    >
                      {{ formatCurrency(transaction.amount.$numberDecimal) }}
                    </span>
                  </td>
                  <td class="px-4 py-3 text-sm text-gray-500">
                    {{ transaction.createdAt | date : "dd/MM/yyyy HH:mm" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <!-- Botón para cerrar caja -->
        <div
          class="px-6 py-4 border-t border-gray-200 bg-gray-50"
          *ngIf="register.status === 'open' || register.status === 'pending_review'"
        >
          <button
            (click)="closeCashRegister(register._id)"
            class="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all duration-300 flex items-center shadow-sm"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
            Cerrar Caja
          </button>
        </div>
      </div>
    </div>

    <!-- Paginación -->
    <div
      *ngIf="totalPages > 1"
      class="mt-6 flex justify-between items-center bg-white p-4 rounded-lg shadow"
    >
      <div class="text-sm text-gray-700">
        Mostrando
        <span class="font-medium">{{
          (currentPage - 1) * itemsPerPage + 1
        }}</span>
        a
        <span class="font-medium">{{
          Math.min(currentPage * itemsPerPage, totalItems)
        }}</span>
        de <span class="font-medium">{{ totalItems }}</span> registros
      </div>
      <div class="flex space-x-2">
        <button
          (click)="onPageChange(currentPage - 1)"
          [disabled]="currentPage === 1"
          class="px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out"
        >
          Anterior
        </button>
        <button
          (click)="onPageChange(currentPage + 1)"
          [disabled]="currentPage === totalPages"
          class="px-3 py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</div>
