import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';

@Component({
  selector: 'app-envios-modal',
  templateUrl: './envios-modal.component.html',
  styleUrls: ['./envios-modal.component.scss'],
})
export class EnviosModalComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Output() close = new EventEmitter<void>();

  constructor(private shipmentsService: ShipmentsService) {}

  page = 1;
  currentPage = 1;
  limit = 10;
  totalPage = 0;
  sortBy: string = 'date';
  sortOrder: 'asc' | 'desc' = 'asc';
  searchName: string = '';
  status: string = '';
  dateFrom: string = '';
  dateTo: string = '';
  shipments: any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] && this.visible) {
      this.loadShipments(
        this.page,
        this.limit,
        this.sortBy,
        this.sortOrder as 'asc' | 'desc'
      );
    }
  }

  getPages(): number[] {
    return Array.from({ length: this.totalPage }, (_, i) => i + 1);
  }
  
  selectPage(page: number): void {
    this.currentPage = page;
    this.loadShipments(
      page,
      this.limit,
      this.sortBy,
      this.sortOrder as 'asc' | 'desc',
      this.searchName,
      this.status,
      this.dateFrom,
      this.dateTo
    );
    
  }

  loadShipments(
    page: number,
    limit: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    searchName?: string,
    status?: string,
    dateFrom?: string,
    dateTo?: string
  ): void {
    this.shipmentsService
      .getAllShipments(
        page,
        limit,
        sortBy,
        sortOrder,
        searchName,
        status,
        dateFrom,
        dateTo
      )
      .subscribe(
        (response) => {
          console.log(response.data);
          this.shipments = response.data.shipments;
          this.totalPage = response.data.totalPages;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getInitials(name: string): string {
    if (!name) return '';
  
    const words = name.trim().split(' '); 
    if (words.length > 1) {
      return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
    } else {
      return words[0].charAt(0).toUpperCase();
    }
  }
  
  cerrarModal() {
    this.close.emit();
  }
}
