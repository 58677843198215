import { Component, OnInit } from '@angular/core';
import { ChartData } from 'chart.js/auto';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { DeliveryService } from 'src/app/_services/delivery/delivery.service';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';
import { RechargeRequestService } from 'src/app/_services/Rechargues/rechargue-request.service';
import { CancellationService } from 'src/app/_services/cancellations/cancellation.service';
import { AddressService } from 'src/app/_services/Address/address.service';
import { CustomerService } from 'src/app/_services/Customer/customer.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { Router } from '@angular/router';
import { CuponInterface } from 'src/app/_models/cupon';
import { CuponService } from 'src/app/_services/cupon/cupon.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { DealerService } from 'src/app/_services/Dealer/dealer.service';
@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.scss'],
})
export class AdministracionComponent implements OnInit {
  reportes = [
    {
      folio: '4026',
      usuario: 'gtoleon-001 Fabian Gonzalez Villanueva',
      usuarioImagen: 'ruta_a_la_imagen_del_usuario',
      cajero: 'gtoleon-001 Fabian Gonzalez Villanueva',
      fechaPago: '23 July 2024',
      fechaCreacion: '23 Jul 2024 09:21',
      fechaAutorizacion: '-',
      monto: 410,
      estado: 'Pendiente',
    },
  ];

  userid: any;
  totalPagesTransaction: number = 0;
  showRembolsoModal: boolean = false;
  userForCupon: any = null;
  showTransactionsModal = false;
  selectedUser: any = null;
  transactions: any[] = [];
  showDownloadForm = false;
  showUserModal = false;
  showCancellationModal = false;
  showReporteModal = false;
  showPedidoModal = false;
  showEmpaques = false;
  showPagoS = false;
  showPagoR = false;
  showRepartoM = false;
  showCupones = false;
  repartidores: any[] = []; // Lista de repartidores
  showAssignDriverModal: boolean = false;
  showCuponConfigModal: boolean = false;
  showCrearCuponModal: boolean = false;
  showDirecciones = false;
  showPMercado = false;
  showRecotizciones = false;
  selectedDate: string = '';
  users: any[] = [];
  shipments: any[] = [];
  cancellations: any[] = [];
  filteredPackages: any[] = [];
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10;
  totalCancellations: number = 0;
  showEnviosModal: boolean = false;
  totalShipments: number = 0;
  quincenalProfit: number = 0;

  hasNextPage: boolean = false;
  hasPreviousPage: boolean = false;

  EmpaquequincenalProfit = {
    packingProfit: 0, // Valor inicial
  };

  profitData: any;
  errorMessage: string | null = null;

  cupons: any[] = [];

  chartData: ChartData<'bar'> = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
    datasets: [
      {
        label: 'Series A',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Series B',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  showDetallesModal: boolean = false;
  reporteSeleccionado: any = null;

  globalProfit: any;
  shipmentDetails: any;

  addresses: any[] = [];
  totalDirecciones: number = 0;
  totalUsers: number = 0;

  searchTerm: string = '';
  filteredUsers: any[] = [];

  empaques: any[] = [];
  recargas: any[] = [];
  servicios: any[] = [];

  currentPageTransaction: number = 1;
  //Paginador de envios
  currentPageShipment: number = 1;
  totalPageShipment: number = 0;
  filterName: string = '';
  filterDateFrom: string = '';
  filterDateTo: string = '';
  filterStatus: string = 'Guia Generada';
  // Paginador de servicios
  currentServicePage: number = 1;
  serviceItemsPerPage: number = 10;
  totalServicePages: number = 0;
  hasNextServicePage: boolean = false;
  hasPreviousServicePage: boolean = false;
  // Paginador de Recargas
  currentRechargePage: number = 1; 
  rechargeItemsPerPage: number = 10; 
  totalRechargePages: number = 0; 
  hasNextRechargePage: boolean = false; 
  hasPreviousRechargePage: boolean = false; 
  // Paginador de Empaques
  currentPackagePage: number = 1; 
  packageItemsPerPage: number = 10; 
  totalPackagePages: number = 0;
  hasNextPackagePage: boolean = false; 
  hasPreviousPackagePage: boolean = false; 
  // Paginador de usuarios
  currentUserPage: number = 1; 
  userItemsPerPage: number = 10; 
  totalUserPages: number = 0; 
  hasNextUserPage: boolean = false; 
  hasPreviousUserPage: boolean = false;
  // Paginador de Cupones
  currentCouponPage: number = 1; // Página actual
  couponItemsPerPage: number = 10; // Elementos por página
  totalCouponPages: number = 0; // Total de páginas
  hasNextCouponPage: boolean = false; // Si hay una página siguiente
  hasPreviousCouponPage: boolean = false; // Si hay una página anterior
  //paginador direcciones 
  currentAddressPage: number = 1;
  totalAddressPages: number = 0;  // Este valor se debe actualizar desde la respuesta del backend
  hasNextAddressPage: boolean = false;
  hasPreviousAddressPage: boolean = false;
  addressItemsPerPage: number = 10;
  selectShipment: any;
  totalPendingRecharges: number = 0;  

  constructor(
    private authService: AuthLoginService,
    private userService: UserServiceService,
    private shipmentsService: ShipmentsService,
    private cancellationService: CancellationService,
    private addressService: AddressService,
    private refillRequestService: RefillRequestService,
    private customerService: CustomerService,
    private router: Router,
    private rechargeRequestService: RechargeRequestService,
    private transactionService: TransactionService,
    private cuponService: CuponService,
    private dealerService: DealerService,
    private deliveryService: DeliveryService,
  ) {
    this.userid = this.authService.getId();
  }

  cuponData: CuponInterface = {
    user_id: this.userForCupon?._id || '',
    code: '',
    type: 'Cupon Dagpacket',
    type_value: 'Porcentaje',
    value: 0,
    quantity: 0,
    is_unlimited: false,
    start_date: new Date(),
    end_date: new Date(),
    status: true,
    description: '',
  };
  //carga al iniciar
  ngOnInit(): void {
    this.loadGlobalProfit();
    this.loadShipments(1, 10, 'date', 'asc');
    console.log('Cargando envíos...');

    this.loadCancellations(
      this.currentPage,
      this.itemsPerPage,
      'requested_at',
      'desc',
    );
    this.updateChartData();
    this.loadAddresses();
    this.loadTotalDirecciones();
    this.loadAllUsers();
    this.loadTotalUsers();
    this.fetchQuincenalProfit();
    this.fetchTransactionsByType();
    this.getCountPendingRecharges();
    this.fetchPackageTransactions();
    this.fetchRechargeTransactions();
    this.fetchServiceTransactions();
    this.loadDeliveryUsers();
    this.getCountPendingCancellations();
    this.hasNextPage = this.currentPageTransaction < this.totalPagesTransaction;
    const userId = this.authService.getId(); // Obtén el ID del usuario desde el almacenamiento local
    if (userId) {
      // Verifica si userId no es null
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const quincena = 1;

      this.loadQuincenalProfit(userId, year, month, quincena); // Llama a la función
    } else {
      console.error('No se encontró el ID de usuario en localStorage');
    }
  }

  getCountPendingCancellations(): void {
    this.cancellationService.getPendingCancellations().subscribe(
      (data) => {
        this.totalCancellations = data.data.totalPendingCancellations;
        console.log('Datos de cancelaciones pendientes:', data);
      },
      (error) => {
        console.error('Error al cargar los datos de cancelaciones pendientes:', error);
      }
    );
  }

  getCountPendingRecharges(): void {
    this.rechargeRequestService.getPendingRechargeCount().subscribe(
      (data) => {
        this.totalPendingRecharges = data.data.totalPendingRequests;
        console.log('Datos de recargas pendientes:', data);
      },
      (error) => {
        console.error('Error al cargar los datos de recargas pendientes:', error);
      }
    );
  }

  loadGlobalProfit(): void {
    this.shipmentsService.getGlobalProfit().subscribe(
      (data) => {
        this.globalProfit = data.message.monthlyProfit.totalProfit;
        console.log('Utilidad global:', this.globalProfit);
      },
      (error) => {
        console.error('Error al cargar los datos de ganancias:', error);
      }
    );
  }
  applyFilters(): void {
    this.currentPageShipment = 1; // Reset to first page when applying filters
    console.log('Aplicando filtros con estado:', this.filterStatus);
  
    this.loadShipmentsModal(
      1,
      10,
      'date',
      'asc',
      this.filterName,
      this.filterStatus,
      this.filterDateFrom,
      this.filterDateTo
    );
  }
  
  
  loadShipments(
    page: number,
    limit: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    searchName?: string,
    status?: string,
    dateFrom?: string,
    dateTo?: string
  ): void {
    this.shipmentsService
      .getAllShipments(
        page,
        limit,
        sortBy,
        sortOrder,
        searchName,
        status,
        dateFrom,
        dateTo
      )
      .subscribe(
        (data) => {
          console.log('Datos de envíos pagados:', data);
          this.totalShipments = data.data.totalShipments;
        },
        (error) => {
          console.error('Error al cargar los envíos pagados:', error);
        }
      );
  }

  loadShipmentsModal(
    page: number,
    limit: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    searchName?: string,
    status?: string,
    dateFrom?: string,
    dateTo?: string
  ): void {
    console.log('Cargando envíos con el siguiente filtro de estado:', status);
    this.shipmentsService
      .getAllShipments(
        page,
        limit,
        sortBy,
        sortOrder,
        searchName,
        status,
        dateFrom,
        dateTo
      )
      .subscribe(
        (data) => {
          console.log('Datos de envíos:', data);
          this.shipments = data.data.shipments;
          this.totalShipments = data.data.totalShipments;
          this.totalPageShipment = data.data.totalPages;
        },
        (error) => {
          console.error('Error al cargar los envíos:', error);
        }
      );
  }


  asingDelivery(shipmentId: string, userId: string): void {
    this.deliveryService.asignDelivery({ shipmentId, userId }).subscribe(
      (data) => {
        this.showAssignDriverModal = false;
        console.log('Asignación de envío:', data);
      },
      (error) => {
        console.error('Error al asignar el envío:', error);
      }
    );
  }

  // direcciones
loadAddresses(): void {
  this.addressService.getAddresses(this.currentAddressPage, this.addressItemsPerPage).subscribe(
    (response: any) => {
      // Log para depurar la respuesta completa
      console.log('Respuesta de getAddresses:', response);

      // Asignar las direcciones obtenidas
      this.addresses = response.data || response; // Ajusta según la estructura de tu respuesta

      // Calcular y log de la paginación
      this.totalAddressPages = response.totalPages || Math.ceil(response.total / this.addressItemsPerPage);
      this.hasNextAddressPage = this.currentAddressPage < this.totalAddressPages;
      this.hasPreviousAddressPage = this.currentAddressPage > 1;

      console.log(
        'Página actual de direcciones:', this.currentAddressPage,
        'Total de páginas de direcciones:', this.totalAddressPages,
        '¿Tiene página siguiente?', this.hasNextAddressPage,
        '¿Tiene página anterior?', this.hasPreviousAddressPage
      );
    },
    (error: any) => {
      // Log para manejar errores
      console.error('Error al cargar las direcciones:', error);
    }
  );
}

  

  loadTotalDirecciones(): void {
    this.addressService.getAddresses(10000).subscribe(
      (response: any) => {
        this.totalDirecciones = response.data.length; // Usa la longitud de data
        console.log('Total de direcciones:', this.totalDirecciones);
      },
      (error: any) => {
        console.error('Error al obtener el total de direcciones:', error);
      }
    );
  }

  openTransactionsModal(user: any): void {
    this.selectedUser = user;
    this.showTransactionsModal = true;
    this.loadUserTransactions(user.id);
  }

  loadUserTransactions(userId: number): void {
    this.transactions = [
      { date: '2023-01-01', amount: 100, description: 'Transaction 1' },
      { date: '2023-02-01', amount: 200, description: 'Transaction 2' },
    ];
  }

  closeTransactionsModal(): void {
    this.showTransactionsModal = false;
    this.selectedUser = null;
    this.transactions = [];
  }

  onApply() {
    if (this.selectedDate) {
      this.updateChartData();
    }
  }

  onDateChange(event: any) {
    this.selectedDate = event.target.value;
  }

  toggleDownloadForm() {
    this.showDownloadForm = !this.showDownloadForm;
  }

  updateChartData() {
    this.chartData = {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
      datasets: [
        {
          label: 'Series A',
          data: [65, 59, 80, 81, 56, 55, 40],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
        {
          label: 'Series B',
          data: [28, 48, 40, 19, 86, 27, 90],
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
        },
      ],
    };
  }

  //usuarios

  openUserModal() {
    this.showUserModal = true;
    this.loadAllUsers(); // Cargar usuarios al abrir el modal
  }

  closeUserModal() {
    this.showUserModal = false;
  }

  // Cargar todos los usuarios y mostrarlos al abrir el modal
  loadAllUsers(): void {
    this.userService.userlistLicencees(1, 10000).subscribe(
      (response: any) => {
        this.users = response.data.users;
        this.filteredUsers = [...this.users]; // Mostrar todos los usuarios inicialmente
        console.log('Usuarios obtenidos:', this.users);

        // Actualizar paginación
        this.totalUserPages = response.data.totalPages || 1; // Total de páginas desde el servidor
        this.hasNextUserPage = this.currentUserPage < this.totalUserPages;
        this.hasPreviousUserPage = this.currentUserPage > 1;
      },
      (error: any) => {
        console.error('Error al obtener usuarios:', error);
      }
    );
  }

  // Método para buscar en la lista de usuarios
  onSearch(): void {
    const term = this.searchTerm.toLowerCase();
    if (!term) {
      // Si no hay término de búsqueda, mostrar todos los usuarios
      this.filteredUsers = [...this.users];
    } else {
      // Filtrar la lista de usuarios según el término de búsqueda
      this.filteredUsers = this.users.filter(
        (user) =>
          user.name.toLowerCase().includes(term) ||
          user.email.toLowerCase().includes(term) ||
          user.role.toLowerCase().includes(term)
      );
    }
  }

  loadTotalUsers(): void {
    this.userService.listUsers(1, 10000).subscribe(
      (response: any) => {
        console.log('Usuarios obtenidos:', response);
        if (
          response &&
          response.data &&
          response.data.users &&
          Array.isArray(response.data.users)
        ) {
          this.totalUsers = response.data.users.length;
        } else {
          this.totalUsers = 0;
          console.warn(
            'No se encontró la lista de usuarios o la estructura es incorrecta.'
          );
        }
        console.log('Total de usuarios:', this.totalUsers);
      },
      (error: any) => {
        console.error('Error al obtener el total de usuarios:', error);
      }
    );
  }

  openReporteModal() {
    this.showReporteModal = true;
  }

  openRembolsoModal(): void {
    this.showRembolsoModal = true;
  }

  openPedidoModal() {
    this.showPedidoModal = true;
  }

  closeEnviosModal(): void {
    this.showEnviosModal = false;
  }

  openEnviosModal(): void {
    this.showEnviosModal = true;
    this.filterStatus = 'Guia Generada';
    this.loadShipmentsModal(1, 10, 'date', 'asc');
    this.applyFilters();
  }

  loadShipmentDetails(shipmentId: string): void {
    this.shipmentsService.getShipmentDetails(shipmentId).subscribe(
      (data) => {
        console.log('Datos de cancelaciones:', data);
        this.shipmentDetails = data;
      },
      (error) => {
        console.error('Error al cargar los detalles del envío:', error);
      }
    );
  }

  // utilidad quincenal de los envios y de los empaques

  loadQuincenalProfit(
    userId: string,
    year: number,
    month: number,
    quincena: 1 | 2
  ): void {
    this.shipmentsService
      .getQuincenalProfit(userId, year, month, quincena)
      .subscribe(
        (response) => {
          if (response && response.data) {
            // Verificar y asignar shipmentProfit
            if (response.data.shipmentProfit) {
              const shipmentProfit = response.data.shipmentProfit as any;
              this.quincenalProfit = parseFloat(shipmentProfit.$numberDecimal);
            } else {
              console.warn(
                'No se recibió shipmentProfit en el formato esperado'
              );
            }

            // Verificar y asignar packingProfit
            if (response.data.packingProfit) {
              const packingProfit = response.data.packingProfit as any;
              this.EmpaquequincenalProfit.packingProfit = parseFloat(
                packingProfit.$numberDecimal
              );
              console.log(
                'Packing Profit:',
                this.EmpaquequincenalProfit.packingProfit
              );
            } else {
              console.warn(
                'No se recibió packingProfit en el formato esperado'
              );
            }
          } else {
            console.warn('No se recibió respuesta válida del servidor.');
          }
        },
        (error) => {
          console.error('Error al obtener la utilidad quincenal:', error);
        }
      );
  }

  openCancellationModal(): void {
    this.showCancellationModal = true;
    this.loadCancellations(
      this.currentPage,
      this.itemsPerPage,
      'requested_at',
      'desc',
    );
  }

  openEmpaquesModal() {
    this.showEmpaques = true;
  }

  openPagoSModal() {
    this.showPagoS = true;
  }

  openPagoRModal() {
    this.showPagoR = true;
  }

  openRepartoMModal() {
    this.showRepartoM = true;
  }

  openCuponesModal() {
    this.showCupones = true;
    this.fetchCupons();
  }
  openCrearCuponModal() {
    this.showCrearCuponModal = true;
    this.loadAllUsers();
  }

  openDireccionesModal() {
    this.showDirecciones = true;
  }

  openRecotizcionesModal() {
    this.showRecotizciones = true;
  }

  openAssignDriverModal(shipmentId: string): void {
    this.showAssignDriverModal = true;
    this.selectShipment = shipmentId; // Guardamos el ID del envío seleccionado
  }

  closeAssignDriverModal(): void {
    this.showAssignDriverModal = false;
  }

  closeCancellationModal() {
    this.showCancellationModal = false;
  }

  closeReporteModal() {
    this.showReporteModal = false;
  }

  closePedidoModal() {
    this.showPedidoModal = false;
  }

  closeEmpaquesModal() {
    this.showEmpaques = false;
  }

  closeRembolsoModal(): void {
    this.showRembolsoModal = false;
  }

  closePagosSModal() {
    this.showPagoS = false;
  }

  closePagoRModal() {
    this.showPagoR = false;
  }

  closeRepartoMModal() {
    this.showRepartoM = false;
  }

  closeCuponesModal() {
    this.showCupones = false;
  }
  closeCrearCuponModal() {
    this.showCrearCuponModal = false;
  }

  closeCuponConfigModal(): void {
    this.showCuponConfigModal = false;
    this.userForCupon = null; // Restablece la variable si es necesario
  }

  closeDireccionesModal() {
    this.showDirecciones = false;
  }

  closeRecotizcionesModal() {
    this.showRecotizciones = false;
  }

  verDetalles(solicitud: any): void {
    this.showDetallesModal = true;
  }

  aceptarSolicitud(solicitud: any): void {
    console.log('Aceptar solicitud:', solicitud);
  }

  rechazarSolicitud(solicitud: any): void {
    const motivoRechazo = prompt('Ingrese el motivo de rechazo:');
    if (motivoRechazo) {
      console.log('Rechazar solicitud:', solicitud, 'Motivo:', motivoRechazo);
    }
  }

  closeDetallesModal(): void {
    this.showDetallesModal = false;
  }

  aplicarReporte(reporte: any): void {
    console.log('Aplicar reporte:', reporte);
  }

  rechazarReporte(reporte: any): void {
    const motivoRechazo = prompt('Ingrese el motivo de rechazo del reporte:');
    if (motivoRechazo) {
      console.log('Rechazar reporte:', reporte, 'Motivo:', motivoRechazo);
    }
  }

  loadCancellations(
    page: number = 1,
    limit: number = 10,
    sortBy: string = 'requested_at',
    sortOrder: 'desc'
   ): void {
    this.cancellationService
      .getAllPendingCancellations(page, limit, sortBy, sortOrder)
      .subscribe({
        next: (data) => {
          this.cancellations = data.data.cancellations;
          this.totalPages = data.totalPages;
        },
        error: (error) => {
          console.error('Error al cargar las cancelaciones pendientes:', error);
        }
      });
   }

  fetchQuincenalProfit(): void {
    const userId = this.authService.getId();
    if (!userId) {
      console.error('No se encontró el ID del usuario.');
      return;
    }

    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const quincena = 1;

    this.transactionService
      .getQuincenalProfitServices(userId, year, month, quincena)
      .subscribe({
        next: (data: any) => {
          console.log('Utilidad quincenal:', data);
          if (data?.message?.length > 0) {
            const firstMessage = data.message[0];
            this.profitData = parseFloat(firstMessage.total.$numberDecimal);
          } else {
            console.warn('No se encontró información de utilidad quincenal.');
            this.profitData = 0;
          }
        },
        error: (error: any) => {
          console.error('Error al obtener la utilidad quincenal:', error);
          this.profitData = 0;
        },
      });
  }

  fetchTransactionsByType(): void {
    const userId = this.userid; // Asegúrate de que `userid` esté definido en tu componente.
    if (!userId) {
      console.error('No se encontró el ID del usuario.');
      return;
    }

    const types = ['empaque', 'recarga', 'servicio', 'envio']; // Tipos de transacciones

    types.forEach((type) => {
      console.log(
        `Cargando datos para el tipo "${type}" y usuario "${userId}" en página ${this.currentPageTransaction}`
      );

      this.transactionService
        .getTransactionsByTypeAndUser(
          type,
          userId,
          this.currentPageTransaction,
          this.itemsPerPage
        )
        .subscribe(
          (response) => {
            if (response.success) {
              const transactions = response.message.transactions || [];

              // Procesar datos para "envio"
              if (type === 'envio') {
                this.filteredPackages = transactions.flatMap(
                  (transaction: any) => {
                    return (transaction.shipment_ids || []).map(
                      (shipment: any) => {
                        console.log('Shipment data:', shipment); // Verifica los datos de cada envío
                        return {
                          provider: shipment.provider || 'Sin proveedor',
                          licensee: transaction.user_id?.name || 'Sin licencia',
                          user:
                            shipment.sub_user_id?.name ||
                            transaction.user_id?.name ||
                            'Sin usuario',
                          distribution_at: shipment.distribution_at || null,
                          price: shipment.price?.$numberDecimal || '0.00',
                          cost: shipment.cost?.$numberDecimal || '0.00',
                          globalProfit:
                            shipment.dagpacket_profit?.$numberDecimal || '0.00',
                          userProfit:
                            shipment.utilitie_lic?.$numberDecimal || '0.00',
                          dagpacketProfit:
                            shipment.utilitie_dag?.$numberDecimal || '0.00',
                          origin: shipment.from?.city || 'Sin origen',
                          destination: shipment.to?.city || 'Sin destino',
                          paymentStatus:
                            shipment.payment?.status ||
                            'Estado de pago desconocido',
                          shipmentStatus:
                            shipment.status || 'Estado no especificado',
                          id: shipment._id || 'Sin ID',
                        };
                      }
                    );
                  }
                );
                console.log('Filtered Packages:', this.filteredPackages); // Verifica los paquetes procesados

                // Actualizar paginación envios
                this.totalPagesTransaction = response.message.totalPages || 0;
                this.hasNextPage =
                this.currentPageTransaction < this.totalPagesTransaction;
                this.hasPreviousPage = this.currentPageTransaction > 1;
              }
              
            } else {
              console.error(
                `Error al cargar datos para el tipo "${type}":`,
                response.message
              );
            }
          },
          (error) => {
            console.error(
              `Error al obtener datos para el tipo "${type}":`,
              error
            );
          }
        );
    });
  }

  // empaques
  fetchPackageTransactions(): void {
    const userId = this.userid;
    this.transactionService.getTransactionsByTypeAndUser('empaque', userId, this.currentPackagePage, this.itemsPerPage)
      .subscribe(response => {
        if (response.success) {
          this.empaques = response.message.transactions || [];
          this.totalPackagePages = response.message.totalPages || 0;
          this.hasNextPackagePage = this.currentPackagePage < this.totalPackagePages;
          this.hasPreviousPackagePage = this.currentPackagePage > 1;
          console.log('Página actual en enpaques:', this.currentPackagePage, 'Total de páginas:', this.totalPackagePages);
        } else {
          console.error('Error al cargar datos:', response.message);
        }
      }, error => {
        console.error('Error de conexión:', error);
      });
  }

  //repartidores
  loadDeliveryUsers(): void {
    this.dealerService.getDeliveryUsers().subscribe({
      next: (data) => {
        this.repartidores = data.data.users; // Ajuste para obtener los repartidores correctamente
      },
      error: (error) => {
        console.error('Error al obtener los datos:', error);
        this.errorMessage = 'Error al cargar los datos.';  
      }
    });
  }

  
  

  // Función para cargar transacciones de recargas
  fetchRechargeTransactions(): void {
    const userId = this.userid;
    if (!userId) {
      console.error('No se encontró el ID del usuario.');
      return;
    }
   this.transactionService.getTransactionsByTypeAndUser('recarga', userId, this.currentRechargePage, this.itemsPerPage)
    .subscribe(response => {
      if (response.success) {
        this.transactions = response.message.transactions || [];
        this.totalRechargePages = response.message.totalPages || 0;
        this.hasNextRechargePage = this.currentRechargePage < this.totalRechargePages;
        this.hasPreviousRechargePage = this.currentRechargePage > 1;
      } else {
        console.error('Error al cargar datos para recargas:', response.message);
      }
    }, error => {
      console.error('Error al obtener datos para recargas:', error);
    });
  }

  // servicios
fetchServiceTransactions(): void {
  const userId = this.userid;
  if (!userId) {
    console.error('No se encontró el ID del usuario.');
    return;
  }
  this.transactionService.getTransactionsByTypeAndUser('servicio', userId, this.currentServicePage, this.itemsPerPage)
    .subscribe(response => {
      if (response.success) {
        this.totalServicePages = response.message.totalPages || 0;
        this.hasNextServicePage = this.currentServicePage < this.totalServicePages;
        this.hasPreviousServicePage = this.currentServicePage > 1;

        // Log para depuración
        console.log(
          'Página actual de servicios:', this.currentServicePage, 
          'Total de páginas de servicios:', this.totalServicePages,
          '¿Tiene página siguiente?', this.hasNextServicePage,
          '¿Tiene página anterior?', this.hasPreviousServicePage
        );
      } else {
        console.error('Error al cargar datos para servicios:', response.message);
      }
    }, error => {
      console.error('Error al obtener datos para servicios:', error);
    });
}

  

  viewDetails(shipmentId: string) {
    if (!shipmentId) {
      console.error('El ID del envío no es válido:', shipmentId);
      return;
    }
    console.log('Navigating to details for shipment:', shipmentId);
    this.router.navigateByUrl(`/shipment-details/${shipmentId}`);
  }

  formatProfit(profit: any): string {
    if (typeof profit === 'number') {
      return `$${profit.toFixed(2)}`;
    } else if (profit && profit.$numberDecimal) {
      return `$${parseFloat(profit.$numberDecimal).toFixed(2)}`;
    }
    return '$0.00';
  }

  //paginador
  nextPage(): void {
    if (this.hasNextPage) {
      this.currentPageTransaction++;
      this.fetchTransactionsByType();
    }
  }

  previousPage(): void {
    if (this.hasPreviousPage) {
      this.currentPageTransaction--;
      this.fetchTransactionsByType();
    }
  }

  goToPage(page: number): void {
    this.currentPageTransaction = page;
    this.fetchTransactionsByType();
  }

  goToPageShipment(page: number): void {
    this.currentPageShipment = page;

    // Mantener los filtros actuales al cambiar de página
    this.loadShipmentsModal(
      page,
      10,
      'distribution_at',
      'desc',
      this.filterName,
      this.filterStatus,
      this.filterDateFrom,
      this.filterDateTo
    );
  }

  getPageNumbers(): number[] {
    return Array.from({ length: this.totalPagesTransaction }, (_, i) => i + 1);
  }

  getPageNumbersOfShipment(): number[] {
    return Array.from({ length: this.totalPageShipment }, (_, i) => i + 1);
  }

  //cupones
  selectUser(user: any): void {
    this.userForCupon = user;
    this.showCrearCuponModal = false;
    this.showCuponConfigModal = true;
    console.log('ID del usuario seleccionado:', user._id);
  }

  clearUserForCupon(): void {
    this.userForCupon = null;
    this.showCuponConfigModal = false;
    this.showCrearCuponModal = true;
  }
  goBackToUserSelection(): void {
    this.showCuponConfigModal = false;
    this.showCrearCuponModal = true;
  }

  createCupon(form: NgForm) {
    if (form.valid) {
      const formData = new FormData();
      (Object.keys(this.cuponData) as (keyof CuponInterface)[]).forEach(
        (key) => {
          formData.append(key, this.cuponData[key] as any);
        }
      );

      console.log('Formulario:', form.value);

      if (this.cuponData.value < 0) {
        alert('El valor del cupón no puede ser negativo.');
        return;
      }

      this.cuponData.user_id = this.userForCupon?._id || '';

      if (this.cuponData.is_unlimited) {
        this.cuponData.quantity = 0;
      }

      this.cuponService.createCupon(this.cuponData).subscribe(
        (response) => {
          console.log('Cupón creado:', response);
          this.clearUserForCupon();
          alert('Cupón creado exitosamente');
          this.showCrearCuponModal = false;
          this.fetchCupons();
        },
        (error) => {
          console.error('Error al crear el cupón:', error);
          alert('Ocurrió un error al crear el cupón');
        }
      );
    }
  }

  fetchCupons(): void {
    this.cuponService.getAllCupon(1, 10).subscribe(
      (response) => {
        // Log para depurar los cupones obtenidos
        console.log('Cupones obtenidos:', response.message?.cupones || []);
        
        this.cupons = response.message.cupones || [];
  
        // Actualizar y log de la paginación
        this.totalCouponPages = response.message.totalPages || 1; // Total de páginas desde el servidor
        this.hasNextCouponPage = this.currentCouponPage < this.totalCouponPages;
        this.hasPreviousCouponPage = this.currentCouponPage > 1;
  
        console.log(
          'Página actual de cupones:', this.currentCouponPage,
          'Total de páginas de cupones:', this.totalCouponPages,
          '¿Tiene página siguiente?', this.hasNextCouponPage,
          '¿Tiene página anterior?', this.hasPreviousCouponPage
        );
      },
      (error) => {
        console.error('Error al cargar los cupones:', error);
      }
    );
  }
  

  exportToCSV(): void {
    if (this.shipments.length === 0) {
      console.warn('No hay datos para exportar');
      return;
    }

    // Encabezados del archivo CSV
    const headers = [
      'Paquetería',
      'Licencia',
      'Usuario',
      'Fecha',
      'Precio',
      'Costo',
      'Utilidad Global',
      'Tu Utilidad',
      'U. Dagpacket',
      'Origen',
      'Destino',
      'Estado de Pago',
      'Estado de Envío',
    ];

    // Formatear los datos
    const csvData = this.shipments.map((envio) => [
      envio.provider || '',
      envio.user_id?.name || '',
      envio.sub_user_id?.name || envio.user_id?.name || '',
      envio.distribution_at
        ? new Date(envio.distribution_at).toLocaleDateString()
        : '',
      envio.price?.$numberDecimal || '',
      envio.cost?.$numberDecimal || '',
      envio.dagpacket_profit?.$numberDecimal || '',
      envio.utilitie_lic?.$numberDecimal || '',
      envio.utilitie_dag?.$numberDecimal || '',
      `${envio.from?.city || ''}, ${envio.from?.state || ''}`,
      `${envio.to?.city || ''}, ${envio.to?.state || ''}`,
      envio.payment?.status || '',
      envio.status || '',
    ]);

    // Crear el contenido CSV
    const csvContent = [
      headers.join(','), // Agregar encabezados
      ...csvData.map((row) => row.map((value) => `"${value}"`).join(',')), // Agregar filas de datos con comillas para evitar problemas con comas
    ].join('\n');

    // Crear un blob con el contenido CSV y descargar el archivo
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Historial_Envios_${new Date().toLocaleDateString()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  //paginacion de servicios 
  nextServicePage(): void {
    if (this.hasNextServicePage) {
      this.currentServicePage++;
      this.fetchServiceTransactions();
    }
  }

  previousServicePage(): void {
    if (this.currentServicePage > 1) {
      this.currentServicePage--;
      this.fetchServiceTransactions();
    }
  }

  goToSpecificServicePage(page: number): void {
    if (page >= 1 && page <= this.totalServicePages) {
      this.currentServicePage = page;
      this.fetchServiceTransactions();
    }
  }
  getServicePageNumbers(): number[] {
    return Array.from({ length: this.totalServicePages }, (_, i) => i + 1);
  }
  
  


  //paginacion de recargas

  nextRechargePage(): void {
    if (this.hasNextRechargePage) {
      this.currentRechargePage++; 
      this.fetchRechargeTransactions(); 
    }
  }

  previousRechargePage(): void {
    if (this.hasPreviousRechargePage) {
      this.currentRechargePage--; 
      this.fetchRechargeTransactions(); 
    }
  }

  goToRechargePage(page: number): void {
    if (page >= 1 && page <= this.totalRechargePages) {
      this.currentRechargePage = page; 
      this.fetchRechargeTransactions(); 
    }
  }

  getRechargePageNumbers(): number[] {
    return Array.from({ length: this.totalRechargePages }, (_, i) => i + 1);
  }

  
  //paginacion de empaques

  getPackagePageNumbers(): number[] {
    return Array.from({ length: this.totalPackagePages }, (_, i) => i + 1);
  }
  
  
  goToPackagePage(page: number): void {
    this.currentPackagePage = page;
    this.fetchPackageTransactions();
  }
  
  nextPackagePage(): void {
    if (this.hasNextPackagePage) {
      this.currentPackagePage++;
      this.fetchPackageTransactions();
    }
  }
  

  previousPackagePage(): void {
    if (this.hasPreviousPackagePage) {
      this.currentPackagePage--;
      this.fetchPackageTransactions();
    }
  }
  //paginacion de usuarios 

  nextUserPage(): void {
    if (this.currentUserPage < this.totalUserPages) {
      this.currentUserPage++;
      this.loadAllUsers(); 
    }
  }
  
  previousUserPage(): void {
    if (this.currentUserPage > 1) {
      this.currentUserPage--;
      this.loadAllUsers(); 
    }
  }
  
  goToUserPage(page: number): void {
    if (page >= 1 && page <= this.totalUserPages) {
      this.currentUserPage = page;
      this.loadAllUsers(); 
    }
  }
  
  getUserPageNumbers(): number[] {
    return Array.from({ length: this.totalUserPages }, (_, i) => i + 1);
  }  

  //paginacion de cupones
  

  nextCouponPage(): void {
    if (this.currentCouponPage < this.totalCouponPages) {
      this.currentCouponPage++;
      this.fetchCupons(); 
    }
  }
  
  previousCouponPage(): void {
    if (this.currentCouponPage > 1) {
      this.currentCouponPage--;
      this.fetchCupons(); 
    }
  }
  
  goToCouponPage(page: number): void {
    if (page >= 1 && page <= this.totalCouponPages) {
      this.currentCouponPage = page;
      this.fetchCupons(); 
    }
  }
  
  getCouponPageNumbers(): number[] {
    return Array.from({ length: this.totalCouponPages }, (_, i) => i + 1);
  }


  //direccion
   // Actualiza el estado de los botones de paginación
   updateAddressPageState(): void {
    this.hasNextAddressPage = this.currentAddressPage < this.totalAddressPages;
    this.hasPreviousAddressPage = this.currentAddressPage > 1;
  }

  // Métodos de navegación
  nextAddressPage(): void {
    if (this.hasNextAddressPage) {
      this.currentAddressPage++;
      this.loadAddresses();
    }
  }

  previousAddressPage(): void {
    if (this.hasPreviousAddressPage) {
      this.currentAddressPage--;
      this.loadAddresses();
    }
  }

  goToAddressPage(page: number): void {
    if (page >= 1 && page <= this.totalAddressPages) {
      this.currentAddressPage = page;
      this.loadAddresses();
    }
  }
  getAddressPageNumbers(): number[] {
    return Array.from({ length: this.totalAddressPages }, (_, i) => i + 1);
  }
  


  
  
}
