<div class="ml-28 mt-16 p-6 w-auto h-[100vh]">
    <div class="px-6 ml-36">
        <span class="font-bold text-xl"> Configuracion </span>
    </div>
    <div class="flex p-6 ml-36  ">
        <ul class="flex gap-6 bg-dagblue rounded-md py-4 px-12 ">
            <li class=""> 
                <button 
                    (click)="showBasicInfo()" 
                    [ngClass]="{
                        'bg-gray-50 text-dagblue border-opacity-100 border-b-2 border-dagblue rounded-md scale-105 px-2': basicInfo, 
                        'border-dagblue text-white border-b-2 transition-all border-opacity-0 hover:border-b-2 hover:border-opacity-100 outline-none hover:scale-105 hover:bg-gray-50 hover:text-dagblue hover:px-2 hover:rounded-md': !basicInfo
                    }">
                    <span> Informacion basica </span>
                </button>
            </li>
            
            <li> 
                <button 
                    (click)="showAddress()" 
                    [ngClass]="{
                        'bg-gray-50 text-dagblue border-opacity-100 border-b-2 border-dagblue rounded-md scale-105 px-2': address, 
                        'border-dagblue text-white border-b-2 transition-all border-opacity-0 hover:border-b-2 hover:border-opacity-100 outline-none hover:scale-105 hover:bg-gray-50 hover:text-dagblue hover:px-2 hover:rounded-md': !address
                    }">
                    <span> Direccion </span>
                </button>
            </li>
            
            <li>
                <button 
                    (click)="showPIN()" 
                    [ngClass]="{
                        'bg-gray-50 text-dagblue border-opacity-100 border-b-2 border-dagblue rounded-md px-2': pin, 
                        'border-dagblue border-b-2 text-white transition-all border-opacity-0 hover:border-b-2 hover:border-opacity-100 outline-none hover:bg-gray-50 hover:text-dagblue hover:px-2 hover:rounded-md': !pin
                    }">
                    <span> NIP </span>
                </button>
            </li>   
            <li>
                <button 
                    (click)="showSecurity()" 
                    [ngClass]="{
                        'bg-gray-50 text-dagblue border-opacity-100 border-b-2 border-dagblue rounded-md px-2': security, 
                        'border-dagblue border-b-2 text-white transition-all border-opacity-0 hover:border-b-2 hover:border-opacity-100 outline-none hover:bg-gray-50 hover:text-dagblue hover:px-2 hover:rounded-md': !security
                    }">
                    <span> Seguridad </span>
                </button>
            </li>

            <li>
                <button 
                    (click)="showCup()" 
                    [ngClass]="{
                        'bg-gray-50 text-dagblue border-opacity-100 border-b-2 border-dagblue rounded-md px-2': cupones, 
                        'border-dagblue border-b-2 text-white transition-all border-opacity-0 hover:border-b-2 hover:border-opacity-100 outline-none hover:bg-gray-50 hover:text-dagblue hover:px-2 hover:rounded-md': !cupones
                    }">
                    <span> Cupones </span>
                </button>
            </li>
                      
        </ul>
    </div>
    <app-account *ngIf="basicInfo"></app-account>
    <app-address *ngIf="address"></app-address>
    <app-pin *ngIf="pin"></app-pin>
    <app-password *ngIf="security"></app-password>
    <app-cupons *ngIf="cupones"></app-cupons>
</div> 