import { Component, OnInit } from '@angular/core';
import { LockersService } from 'src/app/_services/Lockers/lockers.service';
import { ActivatedRoute } from '@angular/router';
import { Gavetas } from 'src/app/_models/gavetas';
import { GavetaService } from 'src/app/_services/gavetas/gavetas.service';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { MqttService } from 'src/app/_services/mqtt/mqtt.service';
import { Lockers } from 'src/app/_models/lockers';
import Swal from 'sweetalert2'; // Importar SweetAlert
import { Router } from '@angular/router';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';

@Component({
  selector: 'app-details-locker',
  templateUrl: './details-locker.component.html',
  styleUrls: ['./details-locker.component.scss'],
})
export class DetailsLockerComponent implements OnInit {
  newUser = {
    user_id: '',     // ID del usuario
    username: '',    // Nombre de usuario
    password: '',    // Contraseña
    id_locker: ''    // ID del locker
  };

  selectedGavetasIds: string[] = [];// Almacenar los IDs de las gavetas seleccionadas
  filteredGavetas: Gavetas[] = []; // Lista de gavetas filtradas
  // Variables para el formulario de nueva gaveta y edición

  showLockerModal = false;
  editMode = false;
  editingLocker: any = {}
  lockerDetails: any;// Almacenar los detalles del locker


  // Variables para el formulario de nuevo usuario y edición
  showChangeOwnerModal = false;
  selectedUserId: string | null = null; // Variable para guardar el ID del usuario seleccionado
  currentPage = 1;// Establecer el valor inicial de la página en 1
  limit = 10; // Establecer el valor inicial del límite en 10
  totalItems = 0; // Establecer el valor inicial de los elementos totales en 0
  search = ''; // Establecer el valor inicial de la búsqueda en vacío
  user: any; // Cambia de any[] a any para almacenar un único usuario que es el dueño de la gabeta.
  locker_status : any;
  users: any[] = []; // Almacenar la lista de usuarios este se usa por si el locker no tiene dueño, para asignarle uno.
  isChecked = false;
  gavetas: Gavetas[] = [];
  showOwnerDetails = false;
  showAddOwner = false;
  showEditOwner = false;
  showAddGaveta = false;
  gavetasSize: any[] = [];
  newGabeta: Gavetas = this.initializeNewGaveta();
  locker_user_id : string | null = null;
  allSelected: boolean = false;
  enteredPin: string = '';       // NIP ingresado por el usuario
  storedPin: string = '';        // NIP almacenado para validación
  showPinModal: boolean = false; // Controla la visibilidad del modal de NIP
  pendingAction: Function | null = null;


  constructor(
    private route: ActivatedRoute,
    private lockersService: LockersService,
    private gavetaService: GavetaService,
    private userService: UserServiceService,
    private router: Router,
    private mqttService: MqttService,
    private authService: AuthLoginService

  ) {}

  

  private initializeNewGaveta(): Gavetas {
    return {
      _id: '',
      id_gabeta: '',
      id_locker: '',
      type: '',
      gabeta_dimension: '',
      size: '',
      weight: '',
      package: '',
      status: true,
      ubication: '',
      street: '',
      cp: '',
      city: '',
      state: '',
      country: '',
      saturation: false,
      pin: '',
      client_pin: '',
    };
  }

  
  selectAll(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.allSelected = checkbox.checked;
    this.filteredGavetas.forEach(gaveta => gaveta.selected = this.allSelected );
    this.updateSelectedGavetasIds();
  }

  toggleGavetaSelection(gaveta: Gavetas, event: Event) {
    const checkbox = event.target as HTMLInputElement;
    gaveta.selected = checkbox.checked;
    this.updateSelectedGavetasIds();
    this.updateAllSelected();
  }
  updateSelectedGavetasIds() {
    this.selectedGavetasIds = this.filteredGavetas
      .filter(gaveta => gaveta.selected)
      .map(gaveta => gaveta._id.toString());
  }

  updateAllSelected() {
    this.allSelected = this.filteredGavetas.every(gaveta => gaveta.selected);
  }

  async deleteSelectedGavetas() {
    // Check if there are any selected gavetas
    if (this.selectedGavetasIds.length === 0) {
      await Swal.fire('No Selection', 'No se han seleccionado gavetas para eliminar.', 'warning');
      return; // Exit the function if no gavetas are selected
    }
  
    try {
      // Convert the Observable to a Promise and await it
      await this.gavetaService.deleteGavetas(this.selectedGavetasIds).toPromise();
  
      // Show success message
      await Swal.fire('Success', 'Gavetas eliminadas correctamente.', 'success');
      
      // Clear the selected IDs after deletion
      this.selectedGavetasIds = [];
      
      // Refresh the list of gavetas
      this.getGavetas(this.lockerDetails._id);
    } catch (error) {
      // Log error and show error message
      console.error('Error al eliminar gavetas:', error);
      await Swal.fire('Error', 'No se pudo eliminar las gavetas. Inténtalo de nuevo.', 'error');
    }
  }
  



  onToggle() {
    this.isChecked = !this.isChecked;
    console.log('Toggle state:', this.isChecked);
  }

  ngOnInit(): void {
    this.getUsers();
    this.getUserPin();

    const lockerId = this.route.snapshot.paramMap.get('id');
    // console.log(lockerId);

    if (lockerId) {
      this.lockersService.getLocker(lockerId).subscribe(
        (data) => {
          this.lockerDetails = data.message;
          // console.log('Detalles del locker:', this.lockerDetails);
          this.getGavetas(lockerId);
          this.getSizeGaveta();
          this.getUser(lockerId); // Pasar el lockerId directamente
          // console.log('Usuario del locker:', this.user);
        },
        (error) => {
          console.log('Error al obtener los detalles del locker', error);
        }
      );
    }
  }
   // Método para seleccionar un usuario
   selectUser(userId: string): void {
    this.selectedUserId = userId;
    // console.log('Usuario seleccionado:', userId);
  }

  //pin

  openPinModal(action: () => void): void {
    this.enteredPin = '';
    this.pendingAction = action;
    this.showPinModal = true;
  }

  closePinModal(): void {
    this.showPinModal = false;
    this.enteredPin = '';
    this.pendingAction = null;
  }

  validateNip(): void {
    console.log('NIP ingresado:', this.enteredPin); // Imprime el NIP ingresado
    console.log('NIP almacenado:', this.storedPin); // Imprime el NIP almacenado

    if (this.enteredPin === this.storedPin) {
      this.showPinModal = false;
      if (this.pendingAction) {
        this.pendingAction();
        this.pendingAction = null;
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'NIP incorrecto',
        text: 'El NIP ingresado no es correcto.',
      });
    }
  }

  getUserPin(): void {
    this.authService.userProfile().subscribe(
      (res) => {
        this.storedPin = res.data.pin;
      },
      (error) => {
        console.log('Error al obtener el perfil del usuario', error);
      }
    );
  }

  openGavetaWithPin(gaveta: any): void {
    this.openPinModal(() => this.openGaveta(gaveta));
  }

  showDetailsWithPin(gavetaId: string): void {
    this.openPinModal(() => this.router.navigate(['/gabetas-details', gavetaId]));
  }

  showHistoryWithPin(gavetaId: string): void {
    this.openPinModal(() => this.router.navigate(['/gabetas-log', gavetaId]));
  }

  toggleGavetaStatusWithPin(gaveta: any): void {
    this.openPinModal(() => this.updateGavetaStatus(gaveta, !gaveta.status));
  }

  openEditLockerWithPin() {
    this.openPinModal(() => this.openChangeOwner());
  }
  
  openChangeOwnerWithPin() {
    this.openPinModal(() => this.openChangeOwner());
  }
  
  openAddOwnerWithPin() {
    this.openPinModal(() => this.openAddOwner());
  }
  
  openAddGavetaWithPin() {
    this.openPinModal(() => this.openAddGaveta());
  }
  
  toggleLockerStatusWithPin() {
    this.openPinModal(() => this.updateLockerStatus(!this.lockerDetails.status));
  }
  
  deleteSelectedGavetasWithPin() {
    this.openPinModal(() => this.deleteSelectedGavetas());
  }



  openAddOwner() {
    this.showAddOwner = true;
  }

  closeAddOwner() {
    this.showAddOwner = false;
  }


  openAddGaveta() {
    this.showAddGaveta = true;
    this.newGabeta = { ...this.initializeNewGaveta(), id_locker: this.lockerDetails._id, ubication: this.lockerDetails.ubication, street: this.lockerDetails.street, cp: this.lockerDetails.cp, city: this.lockerDetails.city, state: this.lockerDetails.state, country: this.lockerDetails.country };
  }

  openChangeOwner() {
    this.showChangeOwnerModal = true;
  }


  closeChangeOwner() {
    this.showChangeOwnerModal = false
  }
  openOwerUserForm() {
    this.showOwnerDetails = true;
  }

  openEditOwner() {
    this.showEditOwner = true;
  }
  closeAddGaveta() {
    this.showAddGaveta = false;
    this.newGabeta = this.initializeNewGaveta(); // Resetear el formulario
  }
  // Este método se encarga de buscar si el locker ya tiene un dueño asignado
  getUser(lockerId: string) {
    this.lockersService.getLockerUser(lockerId).subscribe(
      (data) => {
        this.user = data;
        if (!this.user || this.user.length === 0) {
          console.log('Error al obtener usuario o aún no se ha asignado uno');
        } else {
          this.locker_user_id = this.user.locker._id;
        }
      },
      (error) => {
        console.log('Error al obtener el usuario del locker:', error);
      }
    );
  }

  getUsers(): void {
    console.log('Búsqueda actual:', this.search); // Para verificar la entrada
    this.userService.listUsers(this.currentPage, this.limit, this.search).subscribe(
      (response: any) => {
        if (response.success) {
          this.users = response.data.users;
          console.log('Usuarios obtenidos:', this.users);
        } else {
          console.log('Error: ', response.message);
        }
      },
      (error) => {
        console.log('Error al obtener los usuarios', error);
      }
    );
  }

    // Esta función se llamará cuando el usuario cambie la opción del filtro
    onFilterChange(event: Event): void {
      const filterValue = (event.target as HTMLSelectElement).value;
      // Filtrar las gavetas según el valor seleccionado
      if (filterValue === 'available') {
        console.log('Gavetas disponibles:', this.gavetas.filter(gaveta => gaveta.saturation === false));
        this.filteredGavetas = this.gavetas.filter(gaveta => gaveta.saturation === false);
      } else if (filterValue === 'occupied') {
        this.filteredGavetas = this.gavetas.filter(gaveta => gaveta.saturation === true);
        console.log('Gavetas ocupadas:', this.filteredGavetas);
      } else {
        this.filteredGavetas = [...this.gavetas];
      }
    }
  
  updateLockerStatus(newStatus: boolean) {
    
    console.log('Nuevo estado del locker:', newStatus);
    this.lockersService.updateLockerStatus(this.lockerDetails._id, newStatus).subscribe(
      (data) => {
        console.log('Estado del locker actualizado', data);
        this.lockerDetails.status = newStatus; // Actualiza el estado localmente
        
        Swal.fire({
          title: 'Estado actualizado',
          text: `El estado del locker ahora es: ${newStatus ? 'Activado' : 'Desactivado'}`,
          icon: 'success',
          confirmButtonText: 'OK'
        });
      },
      (error) => {
        console.log('id del locker:', this.lockerDetails._id);
        console.log('status:', newStatus);
        console.log('Error al actualizar el estado del locker', error);
        Swal.fire({
          title: 'Error',
          text: 'No se pudo actualizar el estado del locker. Inténtalo de nuevo.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    );
  }
  
  
  
  

 
  addGaveta() {
    const quantity = this.newGabeta.quantity || 1; // Obtener la cantidad seleccionada, por defecto 1 si no se selecciona
    const baseId = this.newGabeta.id_gabeta.toString(); // Asegúrate de que sea un string
  
    for (let i = 0; i < quantity; i++) {
      // Crear una copia de la gaveta actual
      let gavetaToAdd = { ...this.newGabeta };
  
      // Modificar el ID para que sea secuencial
      gavetaToAdd.id_gabeta = (parseInt(baseId, 10) + i).toString(); // Genera 101, 102, 103, etc.
  
      this.gavetaService.createGaveta(gavetaToAdd).subscribe(
        (data: any) => {
          console.log('Gaveta añadida', data);
          // Actualizar la lista de gavetas tras añadir cada una
          this.getGavetas(this.lockerDetails._id);
        },
        (error) => {
          console.log('Error al añadir la gaveta', error);
          // Manejo de errores para cada gaveta
        }
      );
    }
  
    this.closeAddGaveta(); // Cierra el modal después de añadir todas las gavetas
  }
  

  updateGavetaStatus(gaveta: Gavetas, newStatus: boolean) {
    console.log('ID de la gaveta:', gaveta._id);
    console.log('Nuevo estado de la gaveta:', newStatus);

    this.gavetaService.updateGavetaStatus(gaveta._id as string, newStatus).subscribe(
      (data) => {
        console.log('Estado de la gaveta actualizado', data);
        this.getGavetas(this.lockerDetails._id);
      },
      (error) => {
        console.log('Error al actualizar el estado de la gaveta', error);
      }
    );
  }

  getGavetas(lockerId: string): void {
    this.gavetaService.getGaveta({ id_locker: lockerId }).subscribe(
      (data: Gavetas[]) => {
        this.gavetas = data;
        this.filteredGavetas = [...this.gavetas]; // Inicialmente, mostrar todas

        // console.log('Lista de gavetas:', this.gavetas);
      },
      (error) => {
        console.log('Error al obtener la lista de gavetas', error);
      }
    );
  }

  get availableGavetasCount(): number {
    const currentGavetasCount = this.gavetas.length; // Number of existing gavetas
    return this.lockerDetails.capacity - currentGavetasCount; // Available gavetas
}


  getSizeGaveta(): void {
    this.gavetaService.getSizeGaveta().subscribe(
      (data) => {
        this.gavetasSize = data.message; // Asegúrate de que contenga el formato correcto
        console.log('Tamaños de gavetas:', this.gavetasSize);
      },
      (error) => {
        console.log('Error al obtener los tamaños de gavetas', error);
      }
    );
  }

  onSizeChange(event: Event) {
    const selectedSize = (event.target as HTMLSelectElement).value;
    const selectedGaveta = this.gavetasSize.find(gaveta => gaveta.size === selectedSize);

    if (selectedGaveta) {
      this.newGabeta.gabeta_dimension = selectedGaveta.dimension || '';
    } else {
      console.log('Tamaño no encontrado');
    }
  }

  createLockerUser(): void {
    console.log('Nuevo usuario:', this.newUser);
    
    // Asignar el ID del locker
    this.newUser.id_locker = this.lockerDetails._id; 
   this.newUser.user_id = this.selectedUserId as string;
    this.lockersService.createLockerUser(this.newUser.id_locker, this.newUser).subscribe(
      (data) => {
        console.log('Usuario añadido al locker', data);
        this.getUser(this.lockerDetails._id);
        this.showAddOwner = false;
        this.newUser = {
          user_id: '',     // Asegúrate de inicializar correctamente las propiedades
          username: '',
          password: '',
          id_locker: '',
        };
      },
      (error) => {
        console.log('Error al añadir el usuario al locker', error);
        // Mostrar un mensaje de error aquí si es necesario
      }
    );
  }
  

  
  updateUserLocker(): void {
    // Asignar el ID del locker
    this.newUser.id_locker = this.lockerDetails._id;
    this.newUser.user_id = this.selectedUserId as string;


    console.log('Información del usuario a actualizar:', this.newUser);
    this.lockersService.updateLockerUserInfo(this.locker_user_id as string, this.newUser).subscribe(
      (data) => {
        console.log('Información del usuario actualizada', data);
        this.getUser(this.lockerDetails._id);
        this.showEditOwner = false;
        this.newUser = {
          user_id: '',     // Asegúrate de inicializar correctamente las propiedades
          username: '',
          password: '',
          id_locker: '',
        };
      },
      (error) => {
        console.log('Error al actualizar la información del usuario', error);
        // Mostrar un mensaje de error aquí si es necesario
 }
    );
  }

  generateRandomPassword() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }
    this.newUser.password = password; // Asigna la contraseña generada
  }

  updatePassword(): void {
    // Generar una nueva contraseña
    this.generateRandomPassword();
    
    // Llamar al servicio para actualizar la contraseña
    this.lockersService.generatePassword(this.user.locker.username, this.newUser.password).subscribe(
      (response) => {
        // Mostrar mensaje de éxito con la nueva contraseña
        Swal.fire({
          title: 'Contraseña actualizada',
          text: `La nueva contraseña es del Locker con usuario: ${this.user.locker.username} se actualizo a: ${this.newUser.password}`  , // Mostrar la nueva contraseña
          icon: 'success',
          confirmButtonText: 'OK'
        });
        // Opcional: limpiar o restablecer el formulario
        this.newUser.password = ''; // Limpiar la contraseña si es necesario
      },
      (error) => {
        // Mostrar mensaje de error
        Swal.fire({
          title: 'Error',
          text: 'No se pudo actualizar la contraseña. Inténtalo de nuevo.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    );
  }
  

  openGaveta(gaveta: Gavetas) {
    this.mqttService.openDoor(this.lockerDetails._id, gaveta.id_gabeta).subscribe(
        (response) => {
            console.log('Gaveta abierta con éxito:', response);
            Swal.fire({
                title: 'Gaveta abierta',
                text: `La gaveta con ID ${gaveta._id} ha sido abierta.`,
                icon: 'success',
                confirmButtonText: 'OK'
            });
        },
        (error) => {
            console.log('Error al abrir la gaveta',this.lockerDetails._id, gaveta.id_gabeta, error);
            Swal.fire({
                title: 'Error',
                text: 'No se pudo abrir la gaveta. Inténtalo de nuevo.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    );
}

openLockerModal(editMode: boolean = false) {
  this.editMode = editMode;
  if (editMode) {
    // Clone the locker details to avoid modifying the original data
    this.editingLocker = { ...this.lockerDetails };
  } else {
    // Reset the form for adding a new locker
    this.editingLocker = {};
  }
  this.showLockerModal = true;
}


closeLockerModal() {
  this.showLockerModal = false;
  this.editMode = false;
  this.editingLocker = {};
}

// saveLocker() {
//   if (this.editMode) {
//     // Update existing locker
//     const lockerId = this.lockerDetails._id;
//     this.lockersService.updateLocker(lockerId,this.editingLocker).subscribe(
//       (response) => {
//         console.log('Locker updated successfully', response);
//         this.getLockerDetails(); // Refresh locker details
//         this.closeLockerModal();
//       },
//       (error) => {
//         console.error('Error updating locker:', error);
//       }
//     );
//   } else {
//     // Add new locker
//     this.lockersService.addLocker(this.editingLocker).subscribe(
//       (response) => {
//         console.log('Locker added successfully', response);
//         this.getLockerDetails(); // Refresh locker details
//         this.closeLockerModal();
//       },
//       (error) => {
//         console.error('Error adding locker:', error);
//       }
//     );
//   }
// }

  




}