import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dashboard-general',
  templateUrl: './dashboard-general.component.html',
  styleUrls: ['./dashboard-general.component.scss'],
})
export class DashboardGeneralComponent {
 
  isModalVisible:boolean = false;

  openModal() {
    this.isModalVisible = true;
  }

  closeModal() {
    this.isModalVisible = false;
  }

  summaryStats = [
    {
      icon: '✕',
      title: 'Envíos para cancelación',
      value: 7,
      label: 'Pendientes',
      type: 'danger',
    },
    {
      icon: '⊞',
      title: 'Solicitudes Recargas',
      value: 2,
      label: 'Pendientes',
      type: 'danger',
    },
    {
      icon: '⊞',
      title: 'Pedidos de Factura',
      value: 1,
      label: 'Pendientes',
      type: 'danger',
    },
  ];

  alerts = [
    {
      title: 'Envío #4589 esperando confirmación',
      description: 'Hace 2 horas',
    },
    {
      title: 'Reporte de wallets pendiente',
      description: 'Hace 5 horas',
    },
    {
      title: 'Recarga #784 por confirmar',
      description: 'Hace 1 día',
    },
  ];

  shippingMetrics = [
    { label: 'Total envíos', value: 168 },
    { label: 'Utilidad', value: '$184.62' },
    { label: 'En tránsito', value: 43 },
  ];

  weekDays = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'];

  users = [
    {
      name: 'Usuario Activo',
      status: 'Último acceso: Hoy',
    },
    {
      name: 'Usuario Nuevo',
      status: 'Registrado: Ayer',
    },
  ];

  payments = [
    {
      title: 'Pago servicios',
      value: '$16.80',
      label: 'Utilidad esta quincena',
    },
    {
      title: 'Pago recarga',
      value: '$0.00',
      label: 'Utilidad esta quincena',
    },
  ];

  recentActivity = [
    {
      title: 'Nuevo envío creado',
      description: 'Envío #4589 registrado con éxito',
      time: 'Hace 2 horas',
    },
    {
      title: 'Solicitud de recarga',
      description: 'Nueva solicitud de recarga #784',
      time: 'Hace 5 horas',
    },
    {
      title: 'Pedido de factura',
      description: 'Cliente solicitó factura para el envío #4562',
      time: 'Hace 1 día',
    },
  ];

  locationStats = [
    { label: 'Total', value: 12 },
    { label: 'Activas', value: 9 },
    { label: 'Nuevas', value: 3 },
  ];
}
