import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, of } from 'rxjs';
import { map, mergeMap ,catchError} from 'rxjs/operators';

import { ShipmentData, ShipmentDimensions, Address } from '../../_models/shipments'; // Asegúrate de que la ruta sea correcta

interface QuoteRequest {
  pais_origen: string;
  pais_destino: string;
  cp_origen: string;
  cp_destino: string;
  alto: number;
  ancho: number;
  largo: number;
  peso: number;
  seguro: number;
  valor_declarado: number;
}

interface QuoteResponse {
  superenvios: {
    success: boolean;
    data: {
      paqueterias: any[];
    };
  };
  fedex: {
    success: boolean;
    data: {
      paqueterias: any[];
    };
  };
  paqueteexpress: {
    success: boolean;
    data: {
      paqueterias: any[];
    };
  };
  dhl: {
    success: boolean;
    data: {
      paqueterias: any[];
    };
  },
  estafeta: {
    success: boolean;
    data: {
      paqueterias: any[];
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  constructor(private http: HttpClient) { }

  saveGuide(shipmentId: string, guideUrl: string, guideNumber: string): Observable<any> {
    return this.http.patch<any>(`${environment.apiUrl}/shipments/save-guide/${shipmentId}`, {
      guide: guideUrl,
      guide_number: guideNumber
    });
  }
  getQuote(quoteData: any): Observable<{ superenvios: { paqueterias: any[] }, fedex: any[], paqueteexpress: any[], dhl: any[], estafeta: any[] }> {
    return this.http.post<QuoteResponse>(`${environment.apiUrl}/shipping/quote`, quoteData)
      .pipe(
        map(response => {
          const filterByPrice = (paqueterias: any[]) => paqueterias.filter(p => p.precio > 0);
  
          const result = {
            superenvios: {
              paqueterias: filterByPrice(response?.superenvios?.success ? response.superenvios.data.paqueterias : [])
            },
            fedex: filterByPrice(response?.fedex?.success ? response.fedex.data.paqueterias : []),
            paqueteexpress: filterByPrice(response?.paqueteexpress?.success ? response.paqueteexpress.data.paqueterias : []),
            dhl: filterByPrice(response?.dhl?.success ? response.dhl.data.paqueterias : []),
            estafeta: filterByPrice(response?.estafeta?.success ? response.estafeta.data.paqueterias : [])
          };
  
          // Log the result to the console
          // console.log("Cotizaciones obtenidas (filtradas): ", result);
  
          return result;
        }),
        catchError(error => {
          console.error("Error al obtener cotizaciones: ", error);
          return of({
            superenvios: { paqueterias: [] },
            fedex: [],
            paqueteexpress: [],
            dhl: [],
            estafeta: []
          });
        })
      );
  }
  
  generateAndSaveGuide(shipmentData: any): Observable<any> {
    return this.generateGuide(shipmentData).pipe(
      mergeMap(response => {
        if (response.success) {
          return this.saveGuide(
            shipmentData._id, 
            response.data.guideUrl, 
            response.data.guideNumber
          ).pipe(
            map(saveResponse => ({
              ...response,
              saveSuccess: saveResponse.success,
              saveMessage: saveResponse.message
            }))
          );
        } else {
          return of(response); // Usamos 'of' en lugar de 'Observable.of'
        }
      })
    );
  }
  
  generateGuide(shipmentData: any): Observable<any> {
    const guideData = this.prepareGuideData(shipmentData);
    return this.http.post<any>(`${environment.apiUrl}/shipping/generate-guide`, guideData);
  }

  private prepareGuideData(shipmentData: any): any {
    const provider = shipmentData.apiProvider.toLowerCase();
    
    return {
      provider: provider,
      from: this.formatAddress(shipmentData.from),
      to: this.formatAddress(shipmentData.to),
      package: {
        weight: shipmentData.shipment_data.package_weight,
        height: shipmentData.shipment_data.height,
        width: shipmentData.shipment_data.width,
        length: shipmentData.shipment_data.length,
        service_id: this.formatServiceId(shipmentData.idService, provider),
        package_type: shipmentData.shipment_type === 'Paquete' ? 1 : 2,
        content: shipmentData.description || 'Contenido no especificado',
        detailed_content: shipmentData.description || 'Contenido no especificado',
        insurance: this.parseNumberDecimal(shipmentData.insurance) || 0,
        declared_value: this.parseNumberDecimal(shipmentData.cost) || 0
      },
      impresion: {
        tipo_impresion: 2,
        tipo_impresora: "ZPLII",
        tipo_papel: "PAPER_4X6"
      },
      items: [
        {
          clave_producto: "53101601",
          descripcion_producto: shipmentData.description || 'Producto no especificado',
          clave_unidad: "EA",
          cantidad_producto: "1",
          alto_producto: shipmentData.shipment_data.height.toString(),
          ancho_producto: shipmentData.shipment_data.width.toString(),
          largo_producto: shipmentData.shipment_data.length.toString(),
          valor_producto: (this.parseNumberDecimal(shipmentData.cost) || 0).toString(),
          peso_producto: shipmentData.shipment_data.package_weight.toString()
        }
      ]
    };
  }

  


  private formatServiceId(serviceId: string | number, provider: string): string | number {
    console.log("serviceId: ", serviceId);
    if (provider === 'fedex' || provider === 'estafeta') {
      return serviceId.toString(); 
    } else {
      return parseInt(serviceId.toString(), 10); 
    }
  }

  private parseNumberDecimal(value: any): number {
    if (typeof value === 'object' && value.$numberDecimal) {
      return parseFloat(value.$numberDecimal);
    }
    return parseFloat(value.toString());
  }


  private formatAddress(address: any): any {
    return {
      name: address.name,
      rfc: address.rfc || "",  // Cambiado a cadena vacía si no se proporciona
      phone: address.phone,
      street: address.street,
      external_number: address.external_number,
      internal_number: address.internal_number || '',
      settlement: address.settlement,
      locality_key: address.locality_key || "01",
      municipality_key: address.municipality_key || "001",
      zip_code: address.zip_code,
      city: address.city,
      iso_estado: address.iso_estado,
      iso_pais: address.iso_pais,
      cross_street_1: address.cross_street_1 || "",
      cross_street_2: address.cross_street_2 || "",
      description: address.description || "",
      reference: address.reference || ''
    };
  }
}




