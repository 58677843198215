  <div class="px-6 ml-36 relative">
    <div class="bg-gray-100 rounded-lg w-[60vw] shadow-lg p-6">
      <div class="flex justify-between items-center border-b pb-4">
        <span class="font-bold text-xl text-gray-800">Cupones</span>
      </div>
  
      <div class="overflow-x-auto mt-4">
        <table class="w-full bg-[#F0F0F0] rounded-lg shadow-md overflow-hidden">
          <thead class="bg-[#E0E0E0] text-dagblue font-semibold text-sm uppercase">
            <tr class="text-gray-700">
              <th class="py-3 px-4 text-left">Cupón</th>
              <th class="py-3 px-4 text-left">Licenciatario</th>
              <th class="py-3 px-4 text-left">Fecha</th>
              <th class="py-3 px-4 text-left">Tipo</th>
              <th class="py-3 px-4 text-left">Valor</th>
              <th class="py-3 px-4 text-left">Disponibilidad</th>
              <th class="py-3 px-4 text-left">Usos</th>
              <th class="py-3 px-4 text-left">Estado</th>
            </tr>
          </thead>
          <tbody class="text-gray-600 text-sm divide-y divide-gray-300">
            <tr *ngFor="let coupon of cupons" class="hover:bg-gray-200 transition">
              <td class="py-3 px-4 text-center font-medium">{{ coupon.code }}</td>
              <td class="py-3 px-4">{{ coupon.user_id?.name || 'N/A' }}</td>
              <td class="py-3 px-4">
                {{ coupon.start_date | date : "dd/MM/yyyy" }} - 
                {{ coupon.end_date | date : "dd/MM/yyyy" }}
              </td>
              <td class="py-3 px-4">{{ coupon.type }}</td>
              <td class="py-3 px-4">
                {{ coupon.value }}{{ coupon.type_value === "Porcentaje" ? "%" : "" }}
              </td>
              <td class="py-3 px-4">
                <span class="px-2 py-1 rounded-full text-xs font-semibold"
                  [ngClass]="{
                    'bg-green-100 text-green-700': coupon.is_unlimited,
                    'bg-yellow-100 text-yellow-700': !coupon.is_unlimited
                  }">
                  {{ coupon.is_unlimited ? "Ilimitado" : "Limitado" }}
                </span>
              </td>
              <td class="py-3 px-4">{{ coupon.is_unlimited ? "N/A" : coupon.quantity || 0 }}</td>
              <td class="py-3 px-4">
                <span class="px-2 py-1 rounded-full text-xs font-semibold"
                  [ngClass]="{
                    'bg-green-500 text-white': coupon.status,
                    'bg-red-500 text-white': !coupon.status
                  }">
                  {{ coupon.status ? "Activo" : "Inactivo" }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- Paginador -->
      <!-- <div class="flex justify-between items-center mt-4">
        <button 
          class="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition disabled:opacity-50"
          (click)="prevPage()"
          [disabled]="currentPage === 1 || totalPages === 1">
          ⬅ Anterior
        </button>

        <span class="text-gray-700 font-medium">Página {{ currentPage }} de {{ totalPages }}</span>

        <button 
          class="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition disabled:opacity-50"
          (click)="nextPage()"
          [disabled]="currentPage === totalPages || totalPages === 1">
          Siguiente ➡
        </button>
      </div> -->



    </div>
</div>
  