<!-- Barra lateral de la aplicación -->
<app-sidebar></app-sidebar>
<div class="ml-64 mt-14">
  <div
    *ngIf="showPinModal"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
  >
    <div
      class="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white z-50"
    >
      <h3 class="text-lg font-bold mb-4">Ingresar NIP</h3>
      <form (ngSubmit)="validateNip()">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="nipInput"
            >NIP de seguridad</label
          >
          <input
            type="password"
            id="nipInput"
            [(ngModel)]="enteredPin"
            name="nipInput"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div class="flex items-center justify-between">
          <button
            class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Validar NIP
          </button>
          <button
            class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            (click)="closePinModal()"
            type="button"
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="flex justify-between items-center p-6 rounded-lg flex-wrap">
    <div *ngIf="isAdmin" class="flex items-center space-x-4 flex-wrap">
      <label for="userSelect" class="text-gray-700 font-semibold"
        >Seleccionar Usuario:</label
      >
      <select
        id="userSelect"
        [(ngModel)]="selectedUserId"
        (change)="onUserChange()"
        class="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-dagblue focus:border-transparent"
      >
        <option *ngFor="let user of users" [value]="user._id">
          {{ user.name }}
        </option>
      </select>
    </div>
    <!-- Selectores para año, mes y quincena -->
    <div class="flex items-center space-x-4 flex-wrap">
      <select
        [(ngModel)]="selectedYear"
        (change)="onQuincenaChange()"
        class="border border-gray-300 rounded-md p-2"
      >
        <option *ngFor="let year of [2023, 2024, 2025]" [value]="year">
          {{ year }}
        </option>
      </select>
      <select
        [(ngModel)]="selectedMonth"
        (change)="onQuincenaChange()"
        class="border border-gray-300 rounded-md p-2"
      >
        <option *ngFor="let month of months" [value]="month.value">
          {{ month.name }}
        </option>
      </select>

      <select
        [(ngModel)]="selectedQuincena"
        (change)="onQuincenaChange()"
        class="border border-gray-300 rounded-md p-2"
      >
        <option [value]="1">Primera Quincena</option>
        <option [value]="2">Segunda Quincena</option>
      </select>

      <button
          class="bg-gray-300 text-gray-700 px-4 py-2 rounded-full flex items-center justify-center w-full sm:w-auto text-sm sm:text-base"
          (click)="Reporte()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#217346"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-5 sm:w-6 h-5 sm:h-6 mr-2"
          >
            <path
              d="M4.5 3.75A1.5 1.5 0 0 1 6 2.25h12a1.5 1.5 0 0 1 1.5 1.5v16.5a1.5 1.5 0 0 1-1.5 1.5H6a1.5 1.5 0 0 1-1.5-1.5V3.75z"
              fill="#217346"
            />
            <path
              fill="#fff"
              d="M8.5 8.25h2.75L12.75 12l-1.5 3.75H8.5L10 12 8.5 8.25zm7 0h1.5v7.5H15.5V8.25z"
            />
          </svg>
          Exportar a CSV
        </button>
    </div>
    
    <div class="text-gray-700 font-semibold mt-2">
      {{ quincenaLeyenda }}
    </div>
  </div>
  <!-- Sección de gráficos y balance de cuenta -->
  <div class="px-6 grid grid-cols-1 md:grid-cols-3 gap-6">
    <!-- Gráfico de utilidad quincenal -->
    <div class="col-span-1 md:col-span-2 rounded-lg bg-slate-100 p-2">
      <div class="w-full h-full">
        <canvas id="profitChart" class="w-full"></canvas>
      </div>
    </div>

    <!-- Tarjeta de balance de cuenta -->
    <div
      class="bg-gradient-to-r from-dagblue to-blue-600 flex flex-col rounded-lg"
    >
      <!-- Sección de balance -->
      <!-- Sección de balance -->
      <div *ngIf="userSelected" class="p-4 flex flex-col w-full">
        <span class="text-white font-bold text-lg">Balance de cuenta</span>
        <div class="mt-2">
          <div class="flex justify-between items-center">
            <span class="text-white">Saldo de Envíos:</span>
            <h2 class="text-white font-bold text-xl">
              $
              {{
                userSelected?.wallet?.sendBalance
                  ? (userSelected?.wallet?.sendBalance | number : "1.2-2")
                  : "No disponible"
              }}
            </h2>
          </div>
          <div class="flex justify-between items-center mt-1">
            <span class="text-white">Saldo de Pago de Servicios:</span>
            <h2 class="text-white font-bold text-xl">
              $
              {{
                userSelected?.wallet?.servicesBalance
                  ? (userSelected?.wallet?.servicesBalance | number : "1.2-2")
                  : "No disponible"
              }}
            </h2>
          </div>
          <div class="flex justify-between items-center mt-1">
            <span class="text-white">Saldo para Recargas:</span>
            <h2 class="text-white font-bold text-xl">
              $
              {{
                userSelected?.wallet?.rechargeBalance
                  ? (userSelected?.wallet?.rechargeBalance | number : "1.2-2")
                  : "No disponible"
              }}
            </h2>
          </div>
        </div>
      </div>

      <div *ngIf="!userSelected" class="p-4 flex flex-col w-full">
        <span class="text-white font-bold text-lg">Balance de cuenta</span>
        <div class="mt-2">
          <div class="flex justify-between items-center">
            <span class="text-white">Saldo de Envíos:</span>
            <h2 class="text-white font-bold text-xl">
              $
              {{
                (userSelected?.wallet.sendBalance || user.wallet.sendBalance)
                  ?.$numberDecimal | number : "1.2-2"
              }}
            </h2>
          </div>
          <div class="flex justify-between items-center mt-1">
            <span class="text-white">Saldo de Pago de Servicios:</span>
            <h2 class="text-white font-bold text-xl">
              $
              {{
                (
                  userSelected?.wallet?.servicesBalance ||
                  user.wallet.servicesBalance
                )?.$numberDecimal | number : "1.2-2"
              }}
            </h2>
          </div>
          <div class="flex justify-between items-center mt-1">
            <span class="text-white">Saldo para Recargas:</span>
            <h2 class="text-white font-bold text-xl">
              $
              {{
                (
                  userSelected?.wallet?.rechargeBalance ||
                  user.wallet.rechargeBalance
                )?.$numberDecimal | number : "1.2-2"
              }}
            </h2>
          </div>
        </div>
      </div>
      <!-- Sección de nombre de usuario -->
      <div
        class="p-4 flex flex-col justify-start w-full backdrop-filter-blur-md bg-black/30"
      >
        <p class="text-white">Nombre</p>
        <span class="text-white font-bold text-xl">
          {{ userSelected?.name || user.name }}
        </span>
      </div>
      <!-- Sección de botón para añadir fondos -->
      <div
        class="p-4 flex flex-col justify-start w-full backdrop-filter-blur-md bg-black/30 rounded-b-lg"
      >
        <button
          (click)="openPinModalForAddFunds()"
          class="p-2 w-full hover:bg-dagpk duration-200 rounded-lg text-white font-bold border border-dagpk"
        >
          Añadir fondos
        </button>
      </div>
    </div>
  </div>
  <div
    class="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-center"
  >
    <!-- Tarjeta de envíos -->
    <div
      class="bg-slate-100 flex justify-start gap-6 p-2 items-center rounded-lg"
    >
      <button
        class="p-2 bg-dagblue text-white rounded-lg"
        (click)="openModalEnvios()"
      >
        <!-- Icono SVG de envíos -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            fill-rule="evenodd"
            d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 0 1 .75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 0 1 9.75 22.5a.75.75 0 0 1-.75-.75v-4.131A15.838 15.838 0 0 1 6.382 15H2.25a.75.75 0 0 1-.75-.75 6.75 6.75 0 0 1 7.815-6.666ZM15 6.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
            clip-rule="evenodd"
          />
          <path
            d="M5.26 17.242a.75.75 0 1 0-.897-1.203 5.243 5.243 0 0 0-2.05 5.022.75.75 0 0 0 .625.627 5.243 5.243 0 0 0 5.022-2.051.75.75 0 1 0-1.202-.897 3.744 3.744 0 0 1-3.008 1.51c0-1.23.592-2.323 1.51-3.008Z"
          />
        </svg>
      </button>
      <div class="flex flex-col">
        <span class="font-bold">Envíos</span>
        <span class="font-bold text-2xl">{{
          formatProfit(quincenalProfit?.shipmentProfit)
        }}</span>
      </div>
    </div>

    <!-- Modal -->
    <div
      *ngIf="isModalOpenEnvios"
      class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
    >
    <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-5xl w-full max-h-[90vh] overflow-y-auto"
      >

        <div class="container mx-auto p-4">
          <div class="flex justify-between items-center mb-4">
            <h2 class="text-2xl font-bold text-dagblue">Detalles del Envío</h2>
            <button
              (click)="closeModalEnvios()"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Cerrar</span>
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="bg-transparent p-4 rounded-lg shadow-md mb-6">
            <div
              class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4"
            >
          <select
            [(ngModel)]="filtroEmpaque"
            (change)="onFilterChange()"
            class="border border-gray-300 rounded px-3 py-1 text-gray-600 text-sm focus:outline-none focus:ring-1 focus:ring-dagblue"
          >
            <option value="">Todos</option>
            <option value="Si">Con empaque</option>
            <option value="No">Sin empaque</option>
          </select>
            </div>
          </div>
          <div class="overflow-x-auto">
            <table class="w-full text-sm sm:text-base">
              <thead class="uppercase bg-[#F0F0F0] text-dagblue font-bold">
                <tr>
                  <th class="px-4 py-1 text-left">Paquetería</th>
                  <th class="px-4 py-1 text-left">Licencia</th>
                  <th class="px-4 py-1 text-left">Usuario</th>
                  <th class="px-4 py-1 text-left">Fecha</th>
                  <th class="px-4 py-1 text-left">Precio</th>
                  <th class="px-4 py-1 text-left">Costo</th>
                  <th class="px-4 py-1 text-left">Utilidad Global</th>
                  <th class="px-4 py-1 text-left">Tu Utilidad</th>
                  <th class="px-4 py-1 text-left">U. Dagpacket</th>
                  <th class="px-4 py-1 text-left">Origen</th>
                  <th class="px-4 py-1 text-left">Destino</th>
                  <th class="px-4 py-1 text-center">Estado de Pago</th>
                  <th class="px-4 py-1 text-center">Estado de Envío</th>
                  <th class="px-4 py-1 text-left">Acciones</th>
                </tr>
              </thead>
              <tbody class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500">
                <tr
                  *ngFor="let envio of filteredPackages"
                  class="hover:bg-gray-50"
                >
                  <td class="px-4 py-1 uppercase">{{ envio.provider }}</td>
                  <td class="px-4 py-1">{{ envio.licensee }}</td>
                  <td class="px-4 py-1">{{ envio.user }}</td>
                  <td class="px-4 py-1">
                    {{ envio.distribution_at | date : "dd/MM/yyyy" }}
                  </td>
                  <td class="px-4 py-1">${{ envio.price }}</td>
                  <td class="px-4 py-1">${{ envio.cost }}</td>
                  <td class="px-4 py-1">${{ envio.globalProfit }}</td>
                  <td class="px-4 py-1">${{ envio.userProfit }}</td>
                  <td class="px-4 py-1">${{ envio.dagpacketProfit }}</td>
                  <td class="px-4 py-1">{{ envio.origin }}</td>
                  <td class="px-4 py-1">{{ envio.destination }}</td>
                  <td class="px-4 py-1 text-center">
                    <span
                      [ngClass]="{
                        'text-yellow-500': envio.paymentStatus === 'Pendiente',
                        'text-green-600': envio.paymentStatus === 'Pagado'
                      }"
                      class="font-bold"
                    >
                      {{ envio.paymentStatus }}
                    </span>
                  </td>
                  <td class="px-4 py-2 text-center font-bold">
                    {{ envio.shipmentStatus }}
                  </td>
                  <td class="px-4 py-2">
                    <button
                      (click)="viewDetails(envio.id)"
                      class="w-full sm:w-auto bg-dagblue font-bold text-white px-4 py-2 rounded-full hover:bg-blue-900 transition"
                    >
                      Ver
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Paginador -->
            <div class="flex justify-between items-center mt-4">
              <!-- Botón Anterior -->
              <button
              (click)="previousPage('envio')"
              [disabled]="!pagination.envio.hasPreviousPage"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
              >
                Anterior
              </button>

              <!-- Números de Página -->
              <div class="flex space-x-1">
                <button
                *ngFor="let page of [].constructor(pagination.envio.totalPages); let i = index"
                (click)="goToPage('envio', i + 1)"
                  [ngClass]="{
                    'bg-[#342C6A] text-white': i + 1 === pagination.envio.currentPage,
                    'bg-gray-300 text-gray-800': i + 1 !== pagination.envio.currentPage
                  }"
                  class="px-4 py-2 rounded-md"
                >
                  {{ page }}
                </button>
              </div>

              <!-- Botón Siguiente -->
              <button
              (click)="nextPage('envio')"
              [disabled]="!pagination.envio.hasNextPage"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tarjeta de empaques -->
    <div
      class="bg-slate-100 flex justify-start gap-6 p-2 items-center rounded-lg"
    >
      <button
        class="p-2 bg-dagblue text-white rounded-lg"
        (click)="openModalEmpaques()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            d="M12.378 1.602a.75.75 0 0 0-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03ZM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 0 0 .372-.648V7.93ZM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 0 0 .372.648l8.628 5.033Z"
          />
        </svg>
      </button>
      <div class="flex flex-col">
        <span class="font-bold">Empaques</span>
        <span class="font-bold text-2xl">{{
          formatProfit(quincenalProfit?.packingProfit)
        }}</span>
      </div>
    </div>
    <!--Modal-->
    <div
      *ngIf="isModalOpenEmpaques"
      class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
    >
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
      >
        <div class="p-6">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-2xl font-bold text-gray-900">Empaques</h3>
            <button
              (click)="closeModalEmpaques()"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Cerrar</span>
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="overflow-auto">
            <div class="flex justify-between items-center mb-4">
              <input
                type="text"
                class="border-2 border-gray-400 rounded-full px-4 py-2"
                placeholder="Buscar..."
              />
            </div>
            <table
              class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto"
            >
              <thead class="bg-[#F0F0F0] text-dagblue font-bold">
                <tr>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Propietario
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Usuario Asignado
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Fecha Creación
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Tipo de Empaque
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Utilidad
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500"
              >
                <tr *ngFor="let empaque of empaques" class="hover:bg-gray-200">
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ empaque.user_id.name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ empaque.sub_user_id.name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ empaque.transaction_date | date : "dd/MM/yyyy HH:mm" }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ empaque.packing_id.type }}
                  </td>
             
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{
                      empaque.utilitie_lic
                    }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Paginador -->
            <div class="flex justify-between items-center mt-4">
              <!-- Botón Anterior -->
              <button
              (click)="previousPage('empaque')"
              [disabled]="!pagination.empaque.hasPreviousPage"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
              >
                Anterior
              </button>

              <!-- Números de Página -->
              <div class="flex space-x-1">
                <button
                *ngFor="let page of [].constructor(pagination.empaque.totalPages); let i = index"
                (click)="goToPage('empaque', i + 1)"
                [ngClass]="{
                  'bg-[#342C6A] text-white': i + 1 === pagination.empaque.currentPage,
                  'bg-gray-300 text-gray-800': i + 1 !== pagination.empaque.currentPage
                }"
                  [ngClass]="{
                    'bg-[#342C6A] text-white': page === currentPageTransaction,
                    'bg-gray-300 text-gray-800': page !== currentPageTransaction
                  }"
                  class="px-4 py-2 rounded-md"
                >
                  {{ page }}
                </button>
              </div>

              <!-- Botón Siguiente -->
              <button
              (click)="nextPage('empaque')"
              [disabled]="!pagination.empaque.hasNextPage"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tarjeta de servicios -->
    <div
      class="bg-slate-100 flex justify-start gap-6 p-2 items-center rounded-lg"
    >
      <button
        class="p-2 bg-dagblue text-white rounded-lg"
        (click)="openModalServicios()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            fill-rule="evenodd"
            d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375ZM6 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V12Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 15a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V15Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 18a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V18Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div class="flex flex-col">
        <span class="font-bold"> Servicios </span>
        <span class="font-bold text-2xl">{{
          formatProfit(quincenalProfit?.servicesProfit)
        }}</span>
      </div>
    </div>

    <!--Modal-->
    <div
      *ngIf="isModalOpenServicios"
      class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
    >
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
      >
        <div class="p-6">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-2xl font-bold text-gray-900">Servicios</h3>
            <button
              (click)="closeModalServicios()"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Cerrar</span>
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="overflow-auto">
            <div class="flex justify-between items-center mb-4">
              <input
                type="text"
                class="border-2 border-gray-400 rounded-full px-4 py-2"
                placeholder="Buscar..."
              />
            </div>
            <table
              class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto"
            >
              <thead class="bg-[#F0F0F0] text-dagblue font-bold">
                <tr>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Tipo de Servicio
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Status
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Fecha de Transacción
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Método de Pago
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Costo
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500"
              >
                <tr
                  *ngFor="let servicio of servicios"
                  class="hover:bg-gray-200"
                >
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ servicio.service }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ servicio.status }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ servicio.transaction_date | date : "dd/MM/yyyy HH:mm" }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ servicio.payment_method }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ servicio.amount?.$numberDecimal }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Paginador -->
            <div class="flex justify-between items-center mt-4">
              <!-- Botón Anterior -->
              <button
              (click)="previousPage('servicio')"
              [disabled]="!pagination.servicio.hasPreviousPage"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
              >
                Anterior
              </button>

              <!-- Números de Página -->
              <div class="flex space-x-1">
                <button
                *ngFor="let page of [].constructor(pagination.servicio.totalPages); let i = index"
                (click)="goToPage('servicio', i + 1)"
                [ngClass]="{
                  'bg-[#342C6A] text-white': i + 1 === pagination.servicio.currentPage,
                  'bg-gray-300 text-gray-800': i + 1 !== pagination.servicio.currentPage
                }"
                  class="px-4 py-2 rounded-md"
                >
                  {{ page }}
                </button>
              </div>

              <!-- Botón Siguiente -->
              <button
              (click)="nextPage('servicio')"
              [disabled]="!pagination.servicio.hasNextPage"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tarjeta de recargas -->
    <div
      class="bg-slate-100 flex justify-start gap-6 p-2 items-center rounded-lg"
    >
      <button
        class="p-2 bg-dagblue text-white rounded-lg"
        (click)="openModalRecargas()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z"
          />
          <path
            fill-rule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div class="flex flex-col">
        <span class="font-bold"> Recargas </span>
        <span class="font-bold text-2xl">{{
          formatProfit(quincenalProfit?.profitData)
        }}</span>
      </div>
    </div>
    <!--Modal-->
    <div
      *ngIf="isModalOpenRecargas"
      class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
    >
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
      >
        <div class="p-6">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-2xl font-bold text-gray-900">Recargas</h3>
            <button
              (click)="closeModalRecarga()"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Cerrar</span>
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="overflow-auto">
            <div class="flex justify-between items-center mb-4">
              <input
                type="text"
                class="border-2 border-gray-400 rounded-full px-4 py-2"
                placeholder="Buscar..."
              />
            </div>
            <table
              class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto"
            >
              <thead class="bg-[#F0F0F0] text-dagblue font-bold">
                <tr>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Tipo de Recarga
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Status
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Fecha de Transacción
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Método de Pago
                  </th>
                  <th scope="col" class="py-2 px-4 border-b text-center">
                    Costo
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500"
              >
                <tr *ngFor="let recarga of recargas" class="hover:bg-gray-200">
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ recarga.service }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ recarga.status }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ recarga.transaction_date | date : "dd/MM/yyyy HH:mm" }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ recarga.payment_method }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ recarga.amount?.$numberDecimal }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Paginador -->
            <div class="flex justify-between items-center mt-4">
              <!-- Botón Anterior -->
              <button
              (click)="previousPage('recarga')"
              [disabled]="!pagination.recarga.hasPreviousPage"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
              >
                Anterior
              </button>

              <!-- Números de Página -->
              <div class="flex space-x-1">
                <button
                *ngFor="let page of [].constructor(pagination.recarga.totalPages); let i = index"
                (click)="goToPage('recarga', i + 1)"
                [ngClass]="{
                  'bg-[#342C6A] text-white': i + 1 === pagination.recarga.currentPage,
                  'bg-gray-300 text-gray-800': i + 1 !== pagination.recarga.currentPage
                }"
                  class="px-4 py-2 rounded-md"
                >
                  {{ page }}
                </button>
              </div>

              <!-- Botón Siguiente -->
              <button
              (click)="nextPage('recarga')"
              [disabled]="!pagination.recarga.hasNextPage"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="isAddFundsModalOpen"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
>
  <div class="bg-white p-5 rounded-lg shadow-lg w-96">
    <h2 class="text-xl font-bold mb-4">Solicitar Fondos</h2>
    <form (ngSubmit)="submitAddFunds(rechargueForm)" #rechargueForm="ngForm">
      <div class="mb-4">
        <label for="amount" class="block text-sm font-bold text-gray-700"
          >Monto a recargar</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            type="number"
            id="amount"
            [(ngModel)]="data.amount"
            name="amount"
            (ngModelChange)="validateAmount()"
            min="1"
            step="0.01"
            class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            placeholder="0.00"
            required
          />
        </div>
      </div>

      <div class="mb-4">
        <label for="folio" class="block text-sm text-gray-700 font-bold"
          >Folio</label
        >
        <input
          required
          type="text"
          id="folio"
          [(ngModel)]="data.referenceNumber"
          name="referenceNumber"
          class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>

      <div class="mb-4">
        <label for="proof" class="block text-sm font-bold text-gray-700"
          >Comprobante</label
        >
        <input
          [(ngModel)]="data.proofImage"
          name="proofImage"
          type="file"
          id="proof"
          (change)="onFileSelected($event)"
          class="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          required
        />
      </div>

      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700"
          >Método de pago</label
        >
        <div class="mt-2">
          <label class="inline-flex items-center ml-6">
            <input
              type="radio"
              class="form-radio"
              name="paymentMethod"
              value="transferencia"
              [(ngModel)]="data.paymentMethod"
              checked
            />
            <span class="ml-2">Transferencia</span>
          </label>
        </div>
      </div>
      <!-- Nuevo campo para seleccionar el tipo de recarga -->
      <div class="mb-4">
        <label for="rechargeType" class="block text-sm font-bold text-gray-700"
          >Tipo de Recarga</label
        >
        <select
          id="rechargeType"
          [(ngModel)]="data.rechargeType"
          name="rechargeType"
          required
          class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value="">Seleccione el tipo de recarga</option>
          <option value="envios">Envíos</option>
          <option value="servicios">Servicios</option>
          <option value="recargas">Recargas</option>
        </select>
      </div>
      <div class="flex justify-center mt-6 w-full">
        <button
          type="button"
          (click)="closeAddFundsModal()"
          class="w-full mr-4 px-6 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-300"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="w-full px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300"
        >
          Solicitar
        </button>
      </div>
    </form>
  </div>
</div>

<div
  *ngIf="showToast"
  [ngClass]="{
    'fixed bottom-5 right-5 p-4 rounded-lg shadow-lg': true,
    'bg-green-500 text-white': toastType === 'success',
    'bg-red-500 text-white': toastType === 'error'
  }"
  class="text-white"
>
  {{ toastMessage }}
</div>
