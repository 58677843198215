import { Component, OnInit } from '@angular/core';
import { EmidaService } from 'src/app/_services/Emida/emida.service';
import { CommonModule } from '@angular/common';
import { Transaction } from 'src/app/_models/history_refills';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { Title } from 'chart.js';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';

interface ExtraReference {
  Mandatory: string;
  ReferenceName: string;
  FieldType: string;
  LengthMin: string;
  LengthMax: string;
  Prefix: string;
  ToolTip: string;
  URLImage: string;
}

interface ReferenceParameter {
  ReferenceName: string;
  FieldType: string;
  LengthMin: string;
  LengthMax: string;
  Prefix: string;
  ToolTip: string;
  URLImage: string;
  ExtraReference1: ExtraReference;
}

interface Service {
  FlowType: string;
  ProductCategory: string;
  ProductSubCategory: string;
  CarrierName: string;
  ProductId: string;
  ProductName: string;
  ProductUFee: string;
  CurrencyCode: string;
  Amount: string;
  AmountMin: string;
  AmountMax: string;
  PaymentType: string;
  ReferenceParameters: {
    [key: string]: ReferenceParameter | ReferenceParameter[];
  };
}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
})
export class ServicesComponent implements OnInit {
  categories: string[] = [];
  subcategories: { [key: string]: string[] } = {};
  selectedCategory: string = '';
  selectedSubcategory: string = '';
  services: { [key: string]: Service[] } = {};

  // Declarar las nuevas propiedades
  commission: number = 0;
  totalAmount: number = 0;

  showPaymentModal: boolean = false;
  selectedService: Service | null = null;
  selectedPrice: number = 0;
  referenceValues: { [key: string]: string } = {};

  alertMessage: string = '';
  showAlert: boolean = false;
  isSuccess: boolean = false;
  id: any;
  phoneNumber: string = '';
  confirmPhoneNumber: string = '';
  inputValues: { [key: string]: string } = {};
  confirmInputValues: { [key: string]: string } = {};
  confirmReferenceValues: { [key: string]: string } = {};

  //pin
  enteredPin: string = '';
  storedPin: string = '';
  showPinModal: boolean = false;

  constructor(
    private emidaService: EmidaService,
    private transactionService: TransactionService,
    private authService: AuthLoginService
  ) {}

  ngOnInit() {
    this.loadAllServices();
    this.getUserPin();
    let user_id = localStorage.getItem('id');
    this.id = user_id;
    console.log('id', this.id);
  }

  getUserPin(): void {
    this.authService.userProfile().subscribe(
      (res) => {
        this.storedPin = res.data.pin;
      },
      (error) => {
        console.log('Error al obtener el perfil del usuario', error);
      }
    );
  }

  loadAllServices() {
    forkJoin({
      recharges: this.emidaService.getEmidaServices(),
      payments: this.emidaService.getEmidaPaymentService(),
    }).subscribe(
      ({ recharges, payments }) => {
        this.organizeServices([...recharges, ...payments]);
      },
      (error) => {
        console.error('Error fetching services:', error);
        this.showAlertMessage(
          'Error al cargar los servicios. Por favor, intente de nuevo.',
          false
        );
      }
    );
  }

  organizeServices(data: Service[]) {
    this.services = {};
    this.subcategories = {};

    data.forEach((service) => {
      const category = service.ProductCategory;
      const subcategory = service.CarrierName;

      if (!this.services[category]) {
        this.services[category] = [];
        this.categories.push(category);
      }
      if (!this.subcategories[category]) {
        this.subcategories[category] = [];
      }
      if (!this.subcategories[category].includes(subcategory)) {
        this.subcategories[category].push(subcategory);
      }

      this.services[category].push(service);
    });

    if (this.categories.length > 0) {
      this.selectedCategory = this.categories[0];
    }
  }

  selectCategory(category: string): void {
    this.selectedCategory = category;
    this.selectedSubcategory = ''; // Limpiar subcategoría seleccionada
  }

  selectSubcategory(subcategory: string): void {
    this.selectedSubcategory = subcategory;
  }

  getFilteredServices(): Service[] {
    if (this.selectedSubcategory) {
      return this.services[this.selectedCategory].filter(
        (service) => service.CarrierName === this.selectedSubcategory
      );
    }
    return this.services[this.selectedCategory];
  }

  openPaymentModal(service: Service): void {
    this.selectedService = service;
    this.selectedPrice = service.Amount ? parseFloat(service.Amount) : 0;
    this.referenceValues = {};
    this.showPaymentModal = true;
  }

  cancelPayment(): void {
    this.showPaymentModal = false;
    this.selectedService = null;
    this.selectedPrice = 0;
    this.referenceValues = {};
  }

  openPinModal(): void {
    if (this.areInputsValid()) {
      this.showPinModal = true; // Muestra el modal del PIN
    } else {
      this.showAlertMessage(
        'Por favor, complete todos los campos requeridos correctamente.',
        false
      );
    }
  }

  confirmPayment(): void {
    console.log('Reference1 value:', this.inputValues['Reference1']);
    console.log('Product:', this.selectedService);

    const paymentData = {
      productId: this.selectedService?.ProductId,
      accountId: this.inputValues['Reference1'],
      amount: this.selectedPrice.toString(),
      referenceData: this.inputValues,
    };

    if (this.selectedService?.FlowType === 'A') {
      // Mostrar la alerta de "Cargando" antes de iniciar el proceso de recarga
      Swal.fire({
        title: 'Cargando...',
        text: 'Por favor, espere mientras procesamos tu Recarga',
        allowOutsideClick: false,
        didOpen: () => {
          // SweetAlert ya muestra automáticamente el spinner cuando showConfirmButton es falso
          Swal.showLoading(Swal.getConfirmButton()); // Se asegura de que el botón "confirm" sea reemplazado por el spinner
        },
        showConfirmButton: false, // Evitar que el botón de confirmación interfiera
      });

      this.emidaService.doRecharge(paymentData, this.id, 'saldo').subscribe(
        (response: any) => {
          // Cerrar la alerta de "Cargando" cuando se reciba la respuesta
          Swal.close();

          console.log('Response:', response);
          console.log('paymentData:', paymentData);
          console.log(
            'Recharge response:',
            response.result.PinDistSaleResponse.ResponseCode
          );
          const responseCode = response.result.PinDistSaleResponse.ResponseCode;
          const specificReason =
            response.result.PinDistSaleResponse.ReasonCode || '';
          console.log('Specific Reason:', specificReason);
          const responseMessage =
            response.result.PinDistSaleResponse.ResponseMessage ||
            'Transacción declinada.';
          let PaymentInvoice = response.result.PinDistSaleResponse.InvoiceNo;
          let carrieControlNumber =
            response.result.PinDistSaleResponse.CarrierControlNo;
          let accountId = paymentData.accountId;
          let amount = paymentData.amount;
          let currentDate = new Date();
          let formattedDate = currentDate
            .toLocaleString('es-MX', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            })
            .replace(/\//g, '/')
            .replace(',', '');

          let productDescription = `${this.selectedService?.CarrierName} - ${this.selectedService?.ProductName}`;
          if (this.selectedService?.ProductCategory) {
            productDescription = `${productDescription} (${this.selectedService.ProductCategory})`;
          }

          let alertMessage = '';
          let isSuccess = false;

          switch (responseCode) {
            case '00':
              isSuccess = true;
              Swal.fire({
                title: 'Transacción Exitosa',
                showCancelButton: true,
                html: `
                  <p>Fecha y Hora: ${formattedDate}</p>
                  <p>Producto: ${productDescription}</p>
                  <p>Teléfono: ${accountId}</p>
                  <p>Monto: ${amount}</p>
                  <p>Autorización: ${carrieControlNumber}</p>
                  <p>Información: Para mayor información, por favor comuníquese al *264.</p>
                  <p>Total: ${amount}</p>
                `,
                icon: 'success',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Descargar Recibo',
              }).then((result) => {
                if (result.isConfirmed) {
                  // Crear un contenedor temporal en el DOM
                  const tempDiv = document.createElement('div');
                  tempDiv.id = 'receiptTempDiv'; // ID del contenedor temporal

                  // Insertar el contenido del recibo
                  tempDiv.innerHTML = `
                    <div style="text-align: center; font-weight: bold; margin-bottom: 10px;">DAGPACKET</div>
                    <div style="text-align: center; font-size: 12px; margin-bottom: 20px;">Tu aliado en logística</div>
                    <p>Fecha y Hora: ${formattedDate}</p>
                    <p>Producto: ${productDescription}</p>
                    <p>Teléfono: ${accountId}</p>
                    <p>Monto: $${amount}</p>
                    <p>Autorización: ${carrieControlNumber}</p>
                    <p style="font-size: 12px;">Información: Para mayor información, por favor comuníquese al *264.</p>
                    <p style="font-weight: bold; font-size: 14px; text-align: right; margin-top: 20px;">Total: $${amount}</p>
                  `;

                  tempDiv.style.position = 'absolute';
                  tempDiv.style.top = '0';
                  tempDiv.style.left = '0';
                  tempDiv.style.padding = '20px';
                  tempDiv.style.width = '300px';
                  tempDiv.style.backgroundColor = '#fff';
                  tempDiv.style.color = '#000';
                  tempDiv.style.fontFamily = 'Courier New, Courier, monospace';
                  tempDiv.style.fontSize = '14px';
                  tempDiv.style.lineHeight = '1.5';
                  tempDiv.style.border = '1px solid #ddd';
                  tempDiv.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
                  tempDiv.style.textAlign = 'left';

                  document.body.appendChild(tempDiv);

                  setTimeout(() => {
                    html2canvas(tempDiv)
                      .then((canvas) => {
                        const link = document.createElement('a');
                        link.href = canvas.toDataURL('image/png');
                        link.download = 'recibo.png';
                        link.click();

                        // Eliminar el contenedor temporal del DOM
                        document.body.removeChild(tempDiv);
                      })
                      .catch((err) =>
                        console.error(
                          'Error al generar la imagen del recibo:',
                          err
                        )
                      );
                  }, 500); //  un retraso
                }
              });
              break;
            case '12':
              Swal.fire({
                title: 'Error',
                text: 'SERVICIO NO DISPONIBLE CODIGO 12.',
                icon: 'error',
              });
              //alertMessage = 'SERVICIO NO DISPONIBLE CODIGO 12.';
              break;
            case '51':
              if (responseMessage.includes('Invalid Amount')) {
                Swal.fire({
                  title: 'Error',
                  text: 'Transacción declinada por monto inválido, favor de realizar un nuevo intento.',
                  icon: 'error',
                });
                //alertMessage = 'Transacción declinada por monto inválido, favor de realizar un nuevo intento.';
              } else if (
                responseMessage.includes(
                  'PRODUCT IS NOT AUTHORIZED FOR YOUR TERMINAL. PLEASE CONTACT YOUR ISO OR SUPPORT REPRESENTATIVE'
                )
              ) {
                Swal.fire({
                  title: 'Error',
                  text: 'Por favor, actualice su lista de productos, el producto no encuentra en la lista actualmente.',
                  icon: 'error',
                });
                // alertMessage = 'Por favor, actualice su lista de productos, el producto no encuentra en la lista actualmente.';
              } else if (responseMessage.includes('Insufficient Funds')) {
                Swal.fire({
                  title: 'Error',
                  text: 'Sin saldo suficiente, favor de realizar un nuevo intento.',
                  icon: 'error',
                });
                // alertMessage = 'Sin saldo suficiente, favor de realizar un nuevo intento.';
              } else if (responseMessage.includes('SITE IS DISABLED')) {
                Swal.fire({
                  title: 'Error',
                  text: 'SITE IS DISABLED - servicio no disponible.',
                  icon: 'error',
                });
                //alertMessage = 'SITE IS DISABLED - servicio no disponible.';
              } else if (responseMessage.includes('Invalid Packet')) {
                Swal.fire({
                  title: 'Error',
                  text: 'Paquete inválido, el paquete que usted seleciono no esta disponible, favor de realizar un nuevo intento.',
                  icon: 'error',
                });
                //alertMessage = 'Paquete inválido, el paquete que usted seleciono no esta disponible, favor de realizar un nuevo intento.';
              } else if (
                responseMessage.includes(
                  'Phone number was reported by carrier and it was blocked'
                )
              ) {
                Swal.fire({
                  title: 'Transacción Declinada',
                  text: 'Contáctanos para desbloquear tu número de teléfono.',
                  icon: 'error',
                });

                // alertMessage = 'Contáctanos para desbloquear tu número de teléfono.';
              } else if (responseMessage.includes('MERCHANT CREDIT REPORT')) {
                Swal.fire({
                  title: 'Transacción Declinada',
                  text: 'Sin saldo suficiente, favor de realizar un nuevo intento.',
                  icon: 'error',
                });
                //alertMessage = 'Sin saldo suficiente, favor de realizar un nuevo intento.';
              } else {
                Swal.fire({
                  title: 'Transacción Declinada',
                  text: 'Transacción declinada.',
                  icon: 'error',
                });
                //alertMessage = 'Transacción declinada.';

                // Swal.fire({
                //   title: 'Transacción Exitosa',
                //   showCancelButton: true,
                //   html: `
                //     <p>Fecha y Hora: ${formattedDate}</p>
                //     <p>Producto: ${productDescription}</p>
                //     <p>Teléfono: ${accountId}</p>
                //     <p>Monto: ${amount}</p>
                //     <p>Autorización: ${carrieControlNumber}</p>
                //     <p>Información: Para mayor información, por favor comuníquese al *264.</p>
                //     <p>Total: ${amount}</p>
                //   `,
                //   icon: 'success',
                //   cancelButtonText: 'Cancelar',
                //   confirmButtonText: 'Descargar Recibo'
                // }).then((result)=>{
                //   if (result.isConfirmed) {
                //     // Crear un contenedor temporal en el DOM
                //     const tempDiv = document.createElement('div');
                //     tempDiv.id = 'receiptTempDiv'; // ID del contenedor temporal

                //     // Insertar el contenido del recibo
                //     tempDiv.innerHTML = `
                //       <div style="text-align: center; font-weight: bold; margin-bottom: 10px;">DAGPACKET</div>
                //       <div style="text-align: center; font-size: 12px; margin-bottom: 20px;">Tu aliado en logística</div>
                //       <p>Fecha y Hora: ${formattedDate}</p>
                //       <p>Producto: ${productDescription}</p>
                //       <p>Teléfono: ${accountId}</p>
                //       <p>Monto: $${amount}</p>
                //       <p>Autorización: ${carrieControlNumber}</p>
                //       <p style="font-size: 12px;">Información: Para mayor información, por favor comuníquese al *264.</p>
                //       <p style="font-weight: bold; font-size: 14px; text-align: right; margin-top: 20px;">Total: $${amount}</p>
                //     `;

                //     // Aplicar estilos al contenedor para que se vea como un recibo de papel
                //     tempDiv.style.position = 'absolute';
                //     tempDiv.style.top = '0';
                //     tempDiv.style.left = '0';
                //     tempDiv.style.padding = '20px';
                //     tempDiv.style.width = '300px'; // Ancho del recibo
                //     tempDiv.style.backgroundColor = '#fff'; // Fondo blanco como el de un recibo
                //     tempDiv.style.color = '#000'; // Texto en negro
                //     tempDiv.style.fontFamily = 'Courier New, Courier, monospace'; // Fuente clásica de recibos
                //     tempDiv.style.fontSize = '14px'; // Tamaño de la fuente
                //     tempDiv.style.lineHeight = '1.5'; // Espaciado entre líneas
                //     tempDiv.style.border = '1px solid #ddd'; // Borde gris claro
                //     tempDiv.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)'; // Sombra para darle profundidad
                //     tempDiv.style.textAlign = 'left'; // Alineación del texto

                //     document.body.appendChild(tempDiv); // Añadirlo al body temporalmente

                //     // Retardo para asegurar que el DOM se actualice antes de capturar
                //     setTimeout(() => {
                //       // Capturar el contenido con html2canvas
                //       html2canvas(tempDiv).then(canvas => {
                //         const link = document.createElement('a');
                //         link.href = canvas.toDataURL('image/png');
                //         link.download = 'recibo.png';
                //         link.click();

                //         // Eliminar el contenedor temporal del DOM
                //         document.body.removeChild(tempDiv);
                //       }).catch(err => console.error('Error al generar la imagen del recibo:', err));
                //     }, 500); // Añadimos un retraso de medio segundo
                //   }

                // });
              }
              break;
            case '24':
            case '34':
              Swal.fire({
                title: 'Error',
                text: 'Por favor, actualice su lista de productos.',
                icon: 'error',
              });
              //alertMessage = 'Por favor, actualice su lista de productos.';
              break;
            case '15':
              Swal.fire({
                title: 'Error',
                text: 'Número de cuenta inválido.',
                icon: 'error',
              });
              //alertMessage = 'Número de cuenta inválido.';
              break;
            case '20':
              Swal.fire({
                title: 'Error',
                text: 'Error en la comunicación con el banco.',
                icon: 'error',
              });
              //alertMessage = 'Error en la comunicación con el banco.';
              break;
            case '26':
              Swal.fire({
                title: 'Error',
                text: 'La transacción fue declinada por seguridad.',
                icon: 'error',
              });
              //alertMessage = 'La transacción fue declinada por seguridad.';
              break;
            case '27':
              Swal.fire({
                title: 'Error',
                text: 'Usuario no autorizado.',
                icon: 'error',
              });
              //alertMessage = 'Usuario no autorizado.';
              break;
            case '28':
              Swal.fire({
                title: 'Error',
                text: 'Transacción no permitida para este comercio',
                icon: 'error',
              });
              // alertMessage = 'Transacción no permitida para este comercio.';
              break;
            default:
              alertMessage = responseMessage;
              break;
          }

          // Mostrar el mensaje de alerta
          this.showAlertMessage(alertMessage, isSuccess);
          this.registerTransaction(
            paymentData,
            PaymentInvoice,
            responseCode,
            responseMessage,
            this.selectedService?.CarrierName,
            '3673604'
          );
        },
        (error) => {
          // Cerrar la alerta de "Cargando" si ocurre un error
          Swal.close();

          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un error al procesar la recarga. Por favor, intente de nuevo.',
            icon: 'error',
          });

          console.error('Error al realizar la recarga:', error);
        }
      );
    } else if (this.selectedService?.FlowType === 'B') {
      Swal.fire({
        title: 'Cargando...',
        text: 'Por favor, espere mientras procesamos tu Recarga',
        allowOutsideClick: false,
        didOpen: () => {
          // SweetAlert ya muestra automáticamente el spinner cuando showConfirmButton es falso
          Swal.showLoading(Swal.getConfirmButton()); // Se asegura de que el botón "confirm" sea reemplazado por el spinner
        },
        showConfirmButton: false, // Evitar que el botón de confirmación interfiera
      });

      this.emidaService.doBillPayment(paymentData, this.id, 'saldo').subscribe(
        (response: any) => {
          Swal.close();

          console.log('Response:', response);
          console.log('paymentData:', paymentData);
          console.log(
            'Recharge response:',
            response.result.BillPaymentUserFeeResponse.ResponseCode
          );
          const responseCode =
            response.result.BillPaymentUserFeeResponse.ResponseCode;
          const specificReason =
            response.result.BillPaymentUserFeeResponse.ResponseMessage || '';
          console.log('Specific Reason:', specificReason);
          const responseMessage =
            response.result.BillPaymentUserFeeResponse.ResponseMessage ||
            'Transacción declinada.';
          let PaymentInvoice =
            response.result.BillPaymentUserFeeResponse.InvoiceNo;
          let carrieControlNumber =
            response.result.BillPaymentUserFeeResponse.CarrierControlNo;
          let accountId = this.referenceValues['Reference1'];
          let subAccountId = this.referenceValues['Reference2'];
          let amount = paymentData.amount;
          let currentDate = new Date();
          let formattedDate = currentDate
            .toLocaleString('es-MX', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            })
            .replace(/\//g, '/')
            .replace(',', '');

          let productDescription = `${this.selectedService?.CarrierName} - ${this.selectedService?.ProductName}`;
          if (this.selectedService?.ProductCategory) {
            productDescription = `${productDescription} (${this.selectedService.ProductCategory})`;
          }
          let alertMessage = '';
          let isSuccess = false;
          switch (responseCode) {
            case '00':
              isSuccess = true;
              Swal.fire({
                title: 'Transacción Exitosa',
                showCancelButton: true,
                html: `
                  <p>Fecha y Hora: ${formattedDate}</p>
                  <p>Producto: ${productDescription}</p>
                  <p>Numero de cuenta   : ${accountId}</p>
                  <p>Numero de cliente: ${subAccountId}</p>
                  <p>Monto: ${amount}</p>
                  <p>Autorización: ${carrieControlNumber}</p>
                  <p>Información: Para mayor información, por favor comuníquese al *264.</p>
                  <p>Total: ${amount}</p>
                `,
              });
              break;
            case '12':
              Swal.fire({
                title: 'Error',
                text: 'SERVICIO NO DISPONIBLE CODIGO 12.',
                icon: 'error',
              });
              break;
            case '51':
              if (responseMessage.includes('Invalid Amount')) {
                Swal.fire({
                  title: 'Error',
                  text: 'Transacción declinada por monto inválido, favor de realizar un nuevo intento.',
                  icon: 'error',
                });
              } else if (
                responseMessage.includes(
                  'PRODUCT IS NOT AUTHORIZED FOR YOUR TERMINAL. PLEASE CONTACT YOUR ISO OR SUPPORT REPRESENTATIVE'
                )
              ) {
                Swal.fire({
                  title: 'Error',
                  text: 'Por favor, actualice su lista de productos, el producto no encuentra en la lista actualmente.',
                  icon: 'error',
                });
              } else if (responseMessage.includes('Insufficient Funds')) {
                Swal.fire({
                  title: 'Error',
                  text: 'Sin saldo suficiente, favor de realizar un nuevo intento.',
                  icon: 'error',
                });
              } else if (responseMessage.includes('SITE IS DISABLED')) {
                Swal.fire({
                  title: 'Error',
                  text: 'SITE IS DISABLED - servicio no disponible.',
                  icon: 'error',
                });
              } else if (responseMessage.includes('Invalid Packet')) {
                Swal.fire({
                  title: 'Error',
                  text: 'Paquete inválido, el paquete que usted seleciono no esta disponible, favor de realizar un nuevo intento.',
                  icon: 'error',
                });
              } else if (
                responseMessage.includes(
                  'Phone number was reported by carrier and it was blocked'
                )
              ) {
                Swal.fire({
                  title: 'Transacción Declinada',
                  text: 'Contáctanos para desbloquear tu número de teléfono.',
                  icon: 'error',
                });
              } else if (responseMessage.includes('MERCHANT CREDIT REPORT')) {
                Swal.fire({
                  title: 'Transacción Declinada',
                  text: 'Sin saldo suficiente, favor de realizar un nuevo intento.',
                  icon: 'error',
                });
              } else {
                Swal.fire({
                  title: 'Transacción Declinada',
                  text: 'Transacción declinada.',
                  icon: 'error',
                });
              }
              break;
            case '24':
            case '34':
              Swal.fire({
                title: 'Error',
                text: 'Por favor, actualice su lista de productos.',
                icon: 'error',
              });
              break;
            case '15':
              Swal.fire({
                title: 'Error',
                text: 'Número de cuenta inválido.',
                icon: 'error',
              });
              break;
            case '20':
              Swal.fire({
                title: 'Error',
                text: 'Error en la comunicación con el banco.',
                icon: 'error',
              });
              break;
            case '26':
              Swal.fire({
                title: 'Error',
                text: 'La transacción fue declinada por seguridad.',
                icon: 'error',
              });
              break;
            case '27':
              Swal.fire({
                title: 'Error',
                text: 'Usuario no autorizado.',
                icon: 'error',
              });
              break;
            case '28':
              Swal.fire({
                title: 'Error',
                text: 'Transacción no permitida para este comercio',
                icon: 'error',
              });
              break;
            default:
              alertMessage = responseMessage;
              break;
          }

          console.log('User id:', this.id);
          console.log('Service:', this.selectedService);
          this.registerTransaction(
            paymentData,
            PaymentInvoice,
            responseCode,
            responseMessage,
            this.selectedService?.CarrierName,
            '4847434'
          );
        },
        (error) => {
          Swal.close();
          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un error al procesar el pago de la factura. Por favor, intente de nuevo.',
            icon: 'error',
          });
          console.error('Error al realizar el pago de la factura:', error);
        }
      );
    }
  }

  validateReferences(): boolean {
    if (!this.selectedService) return false;
    console.log('Selected Service:', this.selectedService);
    const { Reference1, Reference2 } = this.selectedService.ReferenceParameters;

    if (!this.validateReference(Reference1, 'Reference1')) return false;

    if (Reference2) {
      if (Array.isArray(Reference2)) {
        if (!this.validateReference(Reference2[0], 'Reference2')) return false;
      } else {
        if (!this.validateReference(Reference2, 'Reference2')) return false;
      }
    }

    return true;
  }

  validateReference(
    ref: ReferenceParameter | ReferenceParameter[],
    key: string
  ): boolean {
    const value = this.referenceValues[key]; // Toma el valor de la referencia
    console.log(`Validating ${key} with value:`, value);

    if (!value) {
      console.log(`${key} is missing or empty`);
      return false;
    }

    const referenceParameter = Array.isArray(ref) ? ref[0] : ref;
    const length = value.length;

    // Verifica que la longitud del valor esté dentro de los límites permitidos
    const isValidLength =
      length >= parseInt(referenceParameter.LengthMin) &&
      length <= parseInt(referenceParameter.LengthMax);

    console.log(`${key} has valid length:`, isValidLength);

    return isValidLength;
  }

  getReferenceParameter(
    service: Service,
    key: string
  ): ReferenceParameter | undefined {
    const ref = service.ReferenceParameters[key];
    return Array.isArray(ref) ? ref[0] : ref;
  }

  getURLImage(service: Service): string | undefined {
    return this.getReferenceParameter(service, 'Reference1')?.URLImage;
  }

  getToolTip(service: Service): string | undefined {
    return this.getReferenceParameter(service, 'Reference1')?.ToolTip;
  }

  isArray(
    ref: ReferenceParameter | ReferenceParameter[]
  ): ref is ReferenceParameter[] {
    return Array.isArray(ref);
  }

  showAlertMessage(message: string, isSuccess: boolean) {
    this.alertMessage = message;
    this.isSuccess = isSuccess;
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
    }, 10000);
  }

  registerTransaction(
    paymentData: any,
    PaymentInvoice: String,
    responseCode: string,
    responseMessage: any,
    service: any,
    terminal: any
  ): void {
    const newLogRecharge: Transaction = {
      user_id: this.id,
      Service: service,
      Terminal_Id: terminal, // Coloca el Terminal_Id correcto aquí
      Response_Transaction: responseMessage,
      Inovice_Id: PaymentInvoice || 0,
      Product_Id: paymentData.productId,
      Amount_Id: paymentData.amount,
      Account_Id: paymentData.accountId,
      ResponseCode: responseCode,
    };

    this.transactionService.createTransaction(newLogRecharge).subscribe(
      (res) => {
        console.log('Transacción registrada con éxito:', res);
      },
      (error) => {
        console.error('Error al registrar la transacción:', error);
        this.showAlertMessage('Error al registrar la transacción.', false);
      }
    );
  }

  areInputsValid(): boolean {
    for (const key in this.inputValues) {
      if (
        !this.inputValues[key] ||
        !this.confirmInputValues[key] ||
        this.inputValues[key] !== this.confirmInputValues[key]
      ) {
        return false;
      }
    }
    return true;
  }

  //pin
  closePinModal(): void {
    this.showPinModal = false;
    this.enteredPin = '';
  }

  validateNip(): void {
    if (this.enteredPin === this.storedPin) {
      console.log('NIP correcto:', this.enteredPin); // Log para confirmar que el NIP es correcto
      this.showPinModal = false; // Cierra el modal del PIN
      this.confirmPayment(); // Continúa con el proceso de pago
    } else {
      console.log('NIP incorrecto:', this.enteredPin); // Log para indicar que el NIP es incorrecto
      Swal.fire({
        icon: 'error',
        title: 'NIP incorrecto',
        text: 'El NIP ingresado no es correcto.',
      });
    }
  }

  handlePaymentResponse(response: any, paymentData: any): void {
    const responseCode =
      response.result?.PinDistSaleResponse?.ResponseCode ||
      response.result?.BillPaymentUserFeeResponse?.ResponseCode;
    const responseMessage =
      response.result?.PinDistSaleResponse?.ResponseMessage ||
      response.result?.BillPaymentUserFeeResponse?.ResponseMessage ||
      'Transacción declinada.';

    if (responseCode === '00') {
      Swal.fire({
        title: 'Transacción Exitosa',
        text: 'La recarga se ha realizado con éxito.',
        icon: 'success',
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: `Error en la transacción: ${responseMessage}`,
        icon: 'error',
      });
    }
  }

  proceedWithPayment(): void {
    const paymentData = {
      productId: this.selectedService?.ProductId,
      accountId: this.referenceValues['Reference1'],
      amount: this.selectedPrice.toString(),
      referenceData: this.referenceValues,
    };

    if (this.selectedService?.FlowType === 'A') {
      Swal.fire({
        title: 'Cargando...',
        text: 'Por favor, espere mientras procesamos tu Recarga',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(null);
        },
        showConfirmButton: false,
      });

      this.emidaService.doRecharge(paymentData, this.id, 'saldo').subscribe(
        (response: any) => {
          Swal.close();
          const responseCode =
            response.result?.PinDistSaleResponse?.ResponseCode;
          const responseMessage =
            response.result?.PinDistSaleResponse?.ResponseMessage ||
            'Transacción declinada.';

          if (responseCode === '00') {
            Swal.fire({
              title: 'Transacción Exitosa',
              text: 'La recarga se ha realizado con éxito.',
              icon: 'success',
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: `Error en la transacción: ${responseMessage}`,
              icon: 'error',
            });
          }
        },
        (error) => {
          Swal.close();
          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un error al procesar la recarga. Por favor, intente de nuevo.',
            icon: 'error',
          });
        }
      );
    }
  }
}
