<div class="max-w-5xl mx-auto my-10 p-8 bg-white shadow-md rounded-lg">
    <h1 class="text-3xl font-bold mb-6 text-center text-gray-800">Términos y Condiciones</h1>
  
    <section class="mb-10 space-y-4 text-gray-700">
        <p>
          La empresa denominada <strong>Dagpacket</strong> pone a disposición del usuario a través de su sitio los siguientes términos y condiciones, que se encuentran dentro de la página <strong>dagpacket.com.mx</strong>.
        </p>
    
        <p>
          El Usuario se adhiere y se obliga a los siguientes términos y condiciones (Cláusulas) expuestos a continuación.
        </p>
    
        <p>
          En caso de que los términos y condiciones de la Agencia de Transporte contratada por el Usuario tuvieran restricciones adicionales no contempladas en estos Términos y Condiciones, como, por ejemplo, mercancías prohibidas, se aplicarán las condiciones de la Agencia de Transporte, además de las mencionadas en el presente. El usuario podrá consultar éstas en el sitio web del propio transportista.
        </p>
    
        <p>
          La información contenida en el sitio web <strong>dagpacket.com.mx</strong> puede sufrir modificaciones sin previo aviso, siempre procurándose que sea la correcta y se encuentre actualizada.
        </p>
    
        <h2  class="text-2xl font-semibold mb-4">
          Al acceder y navegar a los Sitios web de <strong>dagpacket.com.mx</strong> 
        </h2>

        <p>
            El Usuario acepta sujetarse a los presentes Términos y Condiciones sin limitación o condición alguna, incluyendo las actualizaciones, modificaciones o adiciones que Dagpacket publique.
        </p>
    
        <p>
          Dagpacket se reserva el derecho de llevar a cabo todas las acciones legales que sean necesarias para remediar cualquier violación a los presentes términos y condiciones, incluso el de restringir el acceso a este sitio a determinados usuarios de Internet.
        </p>
    
        <p>
          Todos los términos y condiciones modificados entrarán en vigor al momento de su publicación. Todos los Usuarios tendrán la opción de no aceptar dichas modificaciones; y consecuentemente deberán eliminar sus cuentas, en ese caso quedará disuelto el vínculo contractual.
        </p>
      </section>
    
      <section class="mb-10">
        <h2 class="text-2xl font-semibold mb-4">Contenido del sitio dagpacket.com.mx</h2>
        <ul class="list-disc ml-6 space-y-2">
          <li>
            <strong>Información de Transporte:</strong> El sitio <strong>dagpacket.com.mx</strong> es responsable de proveer información disponible de los diferentes medios de transporte para su paquetería de tal modo que usted pueda seleccionar el transportista de su preferencia con las que el sitio <strong>dagpacket.com.mx</strong> colabore.
          </li>
          <li>
            <strong>Tiempo de Entrega:</strong> Los tiempos de entrega, el servicio de recolección y entrega de la mercancía es responsabilidad de la compañía transportista que usted seleccione dentro del sitio <strong>dagpacket.com.mx</strong>, de acuerdo a los términos publicados en el sitio de internet de cada una de ellas.
          </li>
        </ul>
      </section>
    
      <section class="mb-10">
        <h2 class="text-2xl font-semibold mb-4">Obligaciones del Usuario (Remitente)</h2>
        <ul class="list-disc ml-6 space-y-2">
          <li>
            <strong>a)</strong> El usuario (remitente) manifestará con toda veracidad los datos del paquete que desea enviar, de forma enunciativa y no limitativa, tamaño, peso y contenido del paquete; datos del destinatario, nombre completo, dirección completa, código postal y número telefónico.
          </li>
          <li>
            <strong>b)</strong> El usuario (remitente) se obliga a pagar al sitio <strong>dagpacket.com.mx</strong> las cantidades correspondientes por el envío de su paquete, de forma enunciativa y no limitativa:
            <ul class="list-disc ml-8">
              <li>Los impuestos del servicio.</li>
              <li>Impuestos aduanales y especiales en caso de envío internacional.</li>
              <li>Gastos extraordinarios por almacenaje.</li>
              <li>Cualquier otra cantidad generada por devoluciones imputables al usuario.</li>
            </ul>
          </li>
          <li>
            <strong>c)</strong> El usuario (remitente) declara bajo protesta de decir verdad que cuenta con la legal posesión del o los artículos que se envíen por medio del sitio <strong>dagpacket.com.mx</strong>.
          </li>
          <li>
            <strong>d)</strong> El usuario (remitente) exime de toda responsabilidad al sitio <strong>dagpacket.com.mx</strong> por el contenido de su envío.
          </li>
          <li>
            <strong>e)</strong> El embalaje correrá a cargo del usuario (remitente).
          </li>
        </ul>
    </section>

    <h2 class="text-3xl font-bold mb-6 text-center text-gray-800">Uso del Sitio Web de DAGPACKET</h2>

    <section class="mb-10 space-y-4 text-gray-700">
      <p>
        En esta página web encontrará una versión de referencia de los Términos y condiciones del sitio web de <strong>DAGPACKET</strong>. Tenga en cuenta que se pueden aplicar otros términos y condiciones en algunos países.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Derechos de Autor</h3>
      <p>
        Los derechos de autor de esta publicación son propiedad de <strong>DAGPACKET</strong>.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Autorización para la Reproducción</h3>
      <ul class="list-disc ml-6 space-y-2">
        <li>El material solo puede ser utilizado para propósitos informativos y sin fines de lucro.</li>
        <li>El material no puede ser modificado de ninguna forma.</li>
        <li>Se prohíbe la copia no autorizada de las marcas registradas de <strong>DAGPACKET</strong>.</li>
        <li>
          La reproducción de cualquier fragmento de material debe incluir el siguiente aviso de derechos de autor:  
          <span class="italic">Copyright © DAGPACKET. Todos los derechos reservados.</span>
        </li>
      </ul>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Marcas Registradas de DAGPACKET</h3>
      <p>
        “DAGPACKET, registradas en al menos una jurisdicción. No se otorga expresa ni implícitamente ninguna licencia para ninguna de estas marcas registradas. Estas marcas registradas no pueden copiarse, descargarse, reproducirse, usarse, modificarse ni distribuirse de ninguna manera (excepto como parte integral de una copia autorizada del material que aparece en estas páginas web, según lo establecido en el párrafo de la sección anterior) sin un permiso escrito anticipado.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Otras Marcas Registradas y Nombres Comerciales</h3>
      <p>
        Toda otra marca registrada o nombre comercial incluidos en este material son propiedad de sus respectivos dueños.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Sus Comentarios</h3>
      <p>
        <strong>DAGPACKET</strong> desea recibir sus comentarios y valora sus ideas y sugerencias; sin embargo, no puede responder a todos los comentarios individualmente.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Uso de las Características Interactivas</h3>
      <p>
        <strong>DAGPACKET</strong> podría proporcionar características interactivas, como opciones de rastreo y envío de comentarios, que deben usarse únicamente para los propósitos especificados.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Exactitud del Contenido del Sitio Web</h3>
      <p>
        Estas páginas pueden contener inexactitudes y errores tipográficos. La información se actualiza regularmente, pero podría contener inexactitudes derivadas de cambios entre actualizaciones.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Virus</h3>
      <p>
        <strong>DAGPACKET</strong> toma medidas para evitar virus, pero no garantiza su inexistencia. El usuario debe emplear medidas de seguridad al descargar información.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Exención de Garantías</h3>
      <p>
        Los servicios y contenido se ofrecen «tal como están». <strong>DAGPACKET</strong> renuncia a toda garantía en la medida que lo permita la ley.
        lo permita, a ofrecer cualquier tipo de garantía, ya sea expresa, tácita, legal o de otro tipo, incluidas, entre otras, garantías de comerciabilidad, de capacidad para un propósito particular y de no violación de los derechos de terceros. DAGPACKET y sus afiliados y licenciantes no garantizan ni declaran acerca de la exactitud, integridad, seguridad o capacidad de acceso oportuno a los servicios, el contenido y la información que se ofrece a través del sitio web de DAGPACKET y sus sistemas. La información obtenida a través del sitio web y los sistemas de DAGPACKET no constituye ningún tipo de garantía, a menos que DAGPACKET indique lo contrario de forma explícita en estos términos y condiciones.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Limitación de Responsabilidad</h3>
      <p>
        En la medida que lo permita la ley, <strong>DAGPACKET</strong> no será responsable por daños accidentales, indirectos o pérdidas derivadas del uso del sitio web de DAGPACKET, no serán responsables de ningún modo por ningún daño accidental, indirecto, ejemplar, punitivo, ni de lucro cesante, ni de daños como consecuencia de la pérdida de datos o de la interrupción del negocio a causa del uso o de la imposibilidad del uso del sitio web de DAGPACKET y de los sistemas, de los servicios, del contenido o de la información de DAGPACKET, ya sea que estén basados en una garantía, un contrato, una infracción o cualquier otra teoría legal, e independientemente de si DAGPACKET está o no al tanto de la posibilidad de tales daños. Sin perjuicio de lo anterior y hasta el máximo punto permitido por la ley, acepta que, bajo ninguna circunstancia, la responsabilidad total de DAGPACKET en cuanto a daños (directos o de otro tipo) o pérdidas, sin perjuicio del tipo de acción legal o reclamo llevados a cabo, como obligación contractual, extracontractual o de otro tipo, superará el monto de 100 euros. Hasta el máximo punto permitido por la ley, los recursos ofrecidos en estos términos y condiciones son exclusivos y se limitan solo a aquellos ofrecidos de forma expresa en estos términos y condiciones.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Productos y Servicios</h3>
      <p>
        A menos que se indique lo contrario por escrito, los productos y servicios de transporte que se ofrecen por medio de estas páginas web están sujetos a los Términos y condiciones de transporte de DAGPACKET. Debido a que estos términos y condiciones pueden variar según el país de origen del envío, se recomienda comunicarse con el centro de servicios de DAGPACKET más cercano para obtener una copia de los términos y condiciones locales. Es posible que no todos los productos y servicios de DAGPACKET estén disponibles en todos los países.
      </p>
    </section>
  
    <section>
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Divulgación de Información</h3>
      <p>
        Toda información proporcionada a <strong>DAGPACKET</strong> es confidencial y no será divulgada a terceros, salvo para ofrecer los servicios necesarios.
      </p>
    </section>

    <h2 class="text-3xl font-bold mb-6 text-center text-gray-800">Aviso de Privacidad</h2>

    <section class="mb-6">
      <p class="text-gray-700 mb-2">
        <strong>Fecha de actualización:</strong> 01 de junio de 2020
      </p>
      <p class="text-gray-700">
        Con fundamento en lo previsto en la <strong>Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados</strong>, la empresa <strong>Dagpacket</strong> es responsable del tratamiento al que serán sometidos sus datos personales y protección de los mismos, mediante la Plataforma MéxicoX, tal como se encuentra establecido en el aviso de privacidad integral que se puede consultar en el siguiente sitio web:
      </p>
      <a href="https://www.dagpacket.com.mx" target="_blank" class="text-blue-600 hover:underline">
        https://www.dagpacket.com.mx
      </a>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">¿Con qué finalidad se recaban datos personales?</h3>
      <p class="text-gray-700">
        Los datos personales que se recaben serán utilizados para el registro y emisión de publicidad de los servicios que ofrece <strong>Dagpacket</strong> en caso de ser necesario.
      </p>
    </section>
  
    <section class="mb-10">
      <h3 class="text-2xl font-semibold mb-4 text-gray-700">Transferencia de Datos Personales</h3>
      <p class="text-gray-700 mb-4">
        Se hace su conocimiento que sus datos personales podrán ser transmitidos a otros sujetos obligados, en términos de lo dispuesto en los artículos 70, fracciones II, III, IV, y VI; 71 de la <strong>Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados</strong>, siempre y cuando los datos se utilicen según lo dispuesto en el artículo antes mencionado.
      </p>
      <p class="text-gray-700">
        También se informa que no se realizarán transferencias de datos personales que requieran de su consentimiento. Cuando se realicen transferencias de datos personales que requieran su consentimiento, se informará:
      </p>
      <ul class="list-disc ml-6 space-y-2 text-gray-700">
        <li>
          <strong>a)</strong> Las autoridades, poderes, entidades, órganos y organismos gubernamentales de los tres órdenes de gobierno y las personas físicas o morales a las que se transfieren los datos personales.
        </li>
        <li>
          <strong>b)</strong> Las finalidades de estas transferencias.
        </li>
      </ul>
    </section>

  
  
   
</div>
  