<div class="flex min-h-screen bg-white py-12 px-4 sm:px-6 lg:px-8 relative">
    <!-- Formas decorativas de fondo -->

      <!-- Barra superior con logo y navegación -->
      <nav class="fixed top-0 w-full bg-white shadow-sm py-4 px-6 z-10">
        <div class="max-w-6xl mx-auto flex justify-between items-center">
            <img src="../../../assets/images/LogotipoA.png" alt="DagPacket Logo" class="h-10">
            <button [routerLink]="['/home']" class="text-dagblue hover:text-dagpk transition-colors flex items-center">
                <span class="mr-2">Volver a inicio</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </button>
        </div>
    </nav>


    <!-- Notificaciones -->
    <div *ngIf="successMessage" class="fixed top-4 right-4 bg-green-500 text-white p-4 rounded-xl shadow-lg z-50 flex items-center space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
        </svg>
        <span>{{ successMessage }}</span>
    </div>
    
    <div *ngIf="errorMessage" class="fixed top-4 right-4 bg-red-500 text-white p-4 rounded-xl shadow-lg z-50 flex items-center space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>
        <span>{{ errorMessage }}</span>
    </div>

    <!-- Contenedor principal -->
    <div class="w-full max-w-4xl mx-auto">
     

        <!-- Tarjeta del formulario -->
        <div class="bg-white shadow-xl rounded-3xl p-8 md:p-10 mb-10">
            <h2 class="text-3xl font-bold text-dagblue mb-8 text-center">Crea tu cuenta</h2>
            
            <form #registerForm="ngForm" (submit)="signUp(registerForm)" class="space-y-6">
                <!-- Campos del formulario en grid -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <!-- Campo de Nombre -->
                    <div class="relative">
                        <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <input name="name" type="text" placeholder="Nombre(s)" 
                            class="w-full pl-12 pr-4 py-4 border-2 rounded-full border-gray-400 text-gray-600 placeholder-gray-400 focus:outline-none focus:border-dagpk focus:ring-1 focus:ring-dagpk transition-all duration-200"
                            ngModel required />
                    </div>
                    
                    <!-- Campo de Apellido -->
                    <div class="relative">
                        <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                            </svg>
                        </div>
                        <input name="surname" type="text" placeholder="Apellido(s)" 
                            class="w-full pl-12 pr-4 py-4 border-2 rounded-full border-gray-400 text-gray-600 placeholder-gray-400 focus:outline-none focus:border-dagpk focus:ring-1 focus:ring-dagpk transition-all duration-200"
                            ngModel required />
                    </div>
                    
                    <!-- Campo de Teléfono -->
                    <div class="relative">
                        <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                            </svg>
                        </div>
                        <input name="phone" type="tel" placeholder="Teléfono" 
                            class="w-full pl-12 pr-4 py-4 border-2 rounded-full border-gray-400 text-gray-600 placeholder-gray-400 focus:outline-none focus:border-dagpk focus:ring-1 focus:ring-dagpk transition-all duration-200"
                            ngModel required />
                    </div>
                    
                    <!-- Campo de Email -->
                    <div class="relative">
                        <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                        </div>
                        <input name="email" type="email" placeholder="E-mail" 
                            class="w-full pl-12 pr-4 py-4 border-2 rounded-full border-gray-400 text-gray-600 placeholder-gray-400 focus:outline-none focus:border-dagpk focus:ring-1 focus:ring-dagpk transition-all duration-200"
                            ngModel required />
                    </div>
                </div>
                
                <!-- Campo de Contraseña -->
                <div class="relative">
                    <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none text-gray-400">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <input name="password" [type]="showPassword ? 'text' : 'password'" placeholder="Contraseña" 
                        class="w-full pl-12 pr-12 py-4 border-2 rounded-full border-gray-400 text-gray-600 placeholder-gray-400 focus:outline-none focus:border-dagpk focus:ring-1 focus:ring-dagpk transition-all duration-200"
                        ngModel required />
                    <button type="button" (click)="togglePassword()" 
                        class="absolute inset-y-0 right-4 flex items-center text-gray-400 hover:text-dagpk transition-colors duration-200">
                        <svg *ngIf="!showPassword" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                        </svg>
                        <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                        </svg>
                    </button>
                </div>

                <!-- Botón de envío -->
                <div class="pt-4">
                    <button type="submit" class="group relative w-full py-4 rounded-full bg-dagpk text-white font-bold text-lg shadow-md hover:bg-dagpk/90 transition-all duration-300 transform hover:-translate-y-0.5 focus:outline-none focus:ring-2 focus:ring-dagpk focus:ring-opacity-50 overflow-hidden">
                        <span class="absolute inset-0 w-full h-full opacity-0 group-hover:opacity-20">
                            <span class="absolute inset-0 w-full h-full bg-white transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-700"></span>
                        </span>
                        <span class="relative flex items-center justify-center">
                            CREAR CUENTA
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                        </span>
                    </button>
                </div>
            </form>
        </div>

        <!-- Sección de inicio de sesión -->
        <div class="bg-white shadow-lg rounded-3xl p-6 mb-6">
            <p class="text-gray-700 text-right mb-4">¿Ya tienes cuenta?</p>
            <button [routerLink]="['/login']" 
                class="w-full py-4 border-2 border-dagpk rounded-full text-dagpk font-bold text-lg hover:bg-dagpk hover:text-white transition-all duration-300">
                INICIA SESIÓN
            </button>
        </div>

        <!-- Botón de regreso -->
        <div class="flex justify-start">
            <button [routerLink]="['/login']" class="group flex items-center justify-center w-14 h-14 border-2 border-dagblue rounded-full text-dagblue hover:bg-dagblue hover:text-white transition-all duration-300">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 transition-transform duration-300 group-hover:-translate-x-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
            </button>
        </div>
    </div>
</div>