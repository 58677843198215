import { Component } from '@angular/core';
import { TrackingService } from 'src/app/_services/tracking/tracking.service';
import Swal from 'sweetalert2';
interface TrackingResponse {
  success: boolean;
  message: string;
  paqueteria: string;
  data: TrackingData;
}

interface TrackingData {
  rastreo: string;
  fecha_envio?: string;
  status: string;
  descripcion: string;
  eventos?: TrackingEvento[];
  origen: Ubicacion;
  destino: Ubicacion;
  ultima_actualizacion?: {
    status: string;
    descripcion: string;
  };
}

interface Ubicacion {
  ciudad: string;
  pais: string;
  estado?: string;
  area_servicio?: string;
}

interface TrackingEvento {
  fecha: string;
  descripcion: string;
  lugar: string;
}

interface ProgresoSeguimiento {
  etapa: string;
  estado: 'completado' | 'pendiente';
  fecha?: string;
}

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent {
  trackingId: string = ''; 
  trackingData: TrackingResponse | null = null;
  error: string = ''; 
  paqueteria: string = '';
  progreso: ProgresoSeguimiento[] = [];

  constructor(private trackingService: TrackingService) { }

  getTracking(): void {
    if (!this.trackingId) {
      this.error = 'Por favor, ingresa un número de seguimiento';
      return;
    }

    this.trackingService.getTracking(this.trackingId).subscribe({
      next: (data: TrackingResponse) => {
        this.trackingData = data;
        this.paqueteria = data.paqueteria;
        console.log(`Datos de seguimiento (${this.paqueteria}):`, data);
        
        // Procesar los datos de seguimiento
        this.procesarDatosTracking(data);
      },
      error: (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo obtener la información de seguimiento'
        });
        
        this.error = 'No se pudo obtener la información de seguimiento';
        console.error('Error al obtener los datos:', err);
      }
    });
  }

  procesarDatosTracking(data: TrackingResponse) {
    // Lógica de procesamiento específica por paquetería
    switch(data.paqueteria.toUpperCase()) {
      case 'DHL':
        this.procesarTrackingDHL(data);
        break;
      case 'FEDEX':
        this.procesarTrackingFedex(data);
        break;
      case 'ESTAFETA':
        this.procesarTrackingEstafeta(data);
        break;
      case 'PAQUETEEXPRESS':
        this.procesarTrackingPaqueteExpress(data);
        break;
      default:
        this.generarProgresoGenerico(data);
    }
  }

  procesarTrackingDHL(data: TrackingResponse) {
    // Lógica específica para DHL
    this.progreso = [
      {
        etapa: 'Recibido',
        estado: data.data.status === 'Success' ? 'completado' : 'pendiente',
        fecha: data.data.fecha_envio
      },
      {
        etapa: 'En Tránsito',
        estado: data.data.eventos && data.data.eventos.length > 0 ? 'completado' : 'pendiente'
      },
      {
        etapa: 'En Proceso de Entrega',
        estado: 'pendiente'
      },
      {
        etapa: 'Entregado',
        estado: 'pendiente'
      }
    ];
  }

  procesarTrackingFedex(data: TrackingResponse) {
    // Lógica específica para Fedex
    const eventos = data.data.eventos || [];
    
    this.progreso = [
      {
        etapa: 'Recibido',
        estado: eventos.some(e => e.descripcion.includes('Shipment information')) ? 'completado' : 'pendiente',
        fecha: eventos.find(e => e.descripcion.includes('Shipment information'))?.fecha
      },
      {
        etapa: 'En Tránsito',
        estado: eventos.some(e => e.descripcion.includes('Picked up')) ? 'completado' : 'pendiente',
        fecha: eventos.find(e => e.descripcion.includes('Picked up'))?.fecha
      },
      {
        etapa: 'En Proceso de Entrega',
        estado: 'pendiente'
      },
      {
        etapa: 'Entregado',
        estado: 'pendiente'
      }
    ];
  }

  procesarTrackingEstafeta(data: TrackingResponse) {
    // Lógica para Estafeta (implementar según estructura de datos de Estafeta)
    this.generarProgresoGenerico(data);
  }

  procesarTrackingPaqueteExpress(data: TrackingResponse) {
    // Lógica para PaqueteExpress (implementar según estructura de datos de PaqueteExpress)
    this.generarProgresoGenerico(data);
  }

  generarProgresoGenerico(data: TrackingResponse) {
    // Método de respaldo para paqueterías no específicamente manejadas
    this.progreso = [
      {
        etapa: 'Recibido',
        estado: data.success ? 'completado' : 'pendiente',
        fecha: data.data.fecha_envio
      },
      {
        etapa: 'En Tránsito',
        estado: 'pendiente'
      },
      {
        etapa: 'En Proceso de Entrega',
        estado: 'pendiente'
      },
      {
        etapa: 'Entregado',
        estado: 'pendiente'
      }
    ];
  }

  obtenerDetallesUbicacion(): string {
    if (!this.trackingData) return 'Sin información de ubicación';

    const data = this.trackingData.data;
    const origen = data.origen;
    const destino = data.destino;

    return `Origen: ${origen.ciudad}, ${origen.pais} | Destino: ${destino.ciudad}, ${destino.pais}`;
  }

  obtenerUltimoEvento(): string {
    if (!this.trackingData) return 'Sin eventos';

    const data = this.trackingData.data;
    
    // Para paqueterías con última actualización
    if (this.paqueteria === 'Fedex' && data.ultima_actualizacion) {
      return data.ultima_actualizacion.descripcion;
    }

    // Para paqueterías con eventos
    if (data.eventos && data.eventos.length > 0) {
      const ultimoEvento = data.eventos[data.eventos.length - 1];
      console.log('Último evento:', ultimoEvento);
      return `${ultimoEvento.fecha}: ${ultimoEvento.descripcion}`;
    }

    return 'Sin eventos disponibles';
  }

  obtenerNumeroSeguimiento(): string {
    return this.trackingData?.data.rastreo || 'Sin número de seguimiento';
  }

}