import { Component, OnInit } from '@angular/core';
import { LockersService } from 'src/app/_services/Lockers/lockers.service';

@Component({
  selector: 'app-operations-lockers',
  templateUrl: './operations-lockers.component.html',
  styleUrls: ['./operations-lockers.component.scss']
})
export class OperationsLockersComponent implements OnInit {

  lockers: any[] = []; // Aquí almacenamos los lockers obtenidos del servicio

  constructor(private lockersService: LockersService) {}

  ngOnInit(): void {
    this.fetchLockers();
  }

  fetchLockers(): void {
    this.lockersService.getLockers().subscribe(
      (response: any) => {
        console.log('Respuesta del servicio:', response); // Verificar datos devueltos
        this.lockers = response.message; // Extraer datos de `message`
        console.log('Lockers cargados:', this.lockers); // Confirmar que `lockers` tiene datos
      },
      (error) => {
        console.error('Error al obtener lockers:', error);
      }
    );
  }

  viewLocker(lockerId: string): void {
    console.log('Solicitando envíos del locker con ID:', lockerId); // Depuración
    this.lockersService.getShipmentsByLocker(lockerId).subscribe(
      (response: any) => {
        if (response.success) {
          console.log('Envíos del locker:', lockerId, response.message); // Imprimir los datos del locker
        } else {
          console.error('Error al obtener envíos del locker:', response.message);
        }
      },
      (error) => {
        console.error('Error en la petición al backend:', error);
      }
    );
  }
  
  
  
  
  
}
