import { Component } from '@angular/core';

@Component({
  selector: 'app-dagpacket',
  templateUrl: './dagpacket.component.html',
  styleUrls: ['./dagpacket.component.scss']
})
export class DagpacketComponent {

}
